import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FuseLoading from '@fuse/core/FuseLoading';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import _ from '@lodash';
import history from '@history';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneInput from 'react-phone-input-2';
import Avatar from '@mui/material/Avatar';
import { useTranslation } from 'react-i18next';
import queryData from '../../query/common';
import salesData from '../../query/sales';
import { handleApiRequest } from '../../common/common';
import { getCountryData } from '../../../store/userSlice';

/**
 * Form Validation Schema
 */

const defaultValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  salesId: '',
  address1: '',
  address2: '',
  city: '',
  zipCode: '',
  phone_number: '',
};

const SalesDetails = () => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required(t('signUpPage.formField.firstName.validationMessages.required'))
      .max(15, t('signUpPage.formField.firstName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.firstName.validationMessages.matches')),
    lastName: yup
      .string()
      .required(t('signUpPage.formField.lastName.validationMessages.required'))
      .max(15, t('signUpPage.formField.lastName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.lastName.validationMessages.matches')),
    middleName: yup
      .string()
      .notRequired()
      .when('middle_name', {
        is: (value) => value?.length,
        then: (rule) =>
          rule
            .max(15, t('signUpPage.formField.middleName.validationMessages.max'))
            .matches(
              /^[a-zA-Z ]+$/,
              t('signUpPage.formField.middleName.validationMessages.matches')
            ),
      }),
    city: yup.string().required(t('signUpPage.formField.city.validationMessages.required')),
    address1: yup.string().required(t('signUpPage.formField.address.validationMessages.required')),
    phone_number: yup.string().required(t('signInPage.enterPhoneNumber')),
    zipCode: yup
      .string()
      .required(t('signUpPage.formField.zipCode.validationMessages.required'))
      .matches(/^\d{5,9}$/, t('signUpPage.formField.zipCode.validationMessages.match')),
  });

  const { control, formState, handleSubmit, reset, setValue, getValues, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const form = watch();

  const { isValid, dirtyFields, errors, setError } = formState;

  const routeParams = useParams();

  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState();
  const [phoneValue, setPhoneValue] = useState();
  const [phone, setPhone] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneCountryCode, setPhoneCountryCode] = useState();
  const [phoneIsoCode, setPhoneIsoCode] = useState();
  const [fName, setFName] = useState();
  const [lName, setLName] = useState();
  const [profileUrl, profileImage] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userStatusId, userStatus] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [editDataSuccessfully, setEditDataSuccessfully] = useState(false);
  const [salesUserData, setUserSalesData] = useState({});
  const [salesAccountData, setSalesAccountData] = useState({});
  const [originalData, setOriginalData] = useState({});

  const countriesData = useSelector(getCountryData);

  useEffect(() => {
    setCountryList(countriesData);
  }, [countriesData]);

  useEffect(() => {
    async function fetchData() {
      if (!editDataSuccessfully) {
        setLoading(true);
      }
      const payload = {
        query: salesData.getSalesUsersBySalesUserId,
        variables: {
          salesUserId: parseInt(routeParams.salesId, 10),
        },
      };
      const res = await handleApiRequest(payload);
      setLoading(false);
      if (res?.getSalesUsersBySalesUserId?.status === 200) {
        const result = res.getSalesUsersBySalesUserId.data;
        setEditDataSuccessfully(false);
        setUserSalesData(result);
        setSalesAccountData(res.getSalesUsersBySalesUserId);
        const setValueAndDirty = (field, value) => {
          setValue(field, value || '', {
            shouldDirty: true,
            shouldValidate: true,
          });
        };
        setOriginalData(result);
        setValueAndDirty('firstName', result.firstName);
        setValueAndDirty('middleName', result.middleName);
        setValueAndDirty('salesId', result.salesId);
        setValueAndDirty('lastName', result.lastName);
        setValueAndDirty('address1', result.address);
        setValueAndDirty('address2', result.address2);
        setValueAndDirty('city', result.city);
        setValueAndDirty('zipCode', result.zipcode);
        setValueAndDirty('phone_number', result?.phoneNumber?.phoneNumber);
        setPhoneNumber(result?.phoneNumber?.phoneNumber);
        setCountryId(result?.countries?.id || 0);
        setStateId(result?.states?.id || 0);
        setFName(result.firstName);
        setLName(result.lastName);
        setUserEmail(result.email);
        profileImage(result?.profileUrl || 0);
        userStatus(result?.userStatusId || 0);
        setPhoneCountryCode(result?.phoneNumber?.countryCode);
        setPhoneIsoCode(result?.phoneNumber?.isoCode);
        if (
          result?.phoneNumber &&
          (result?.phoneNumber?.countryCode || result?.phoneNumber?.phoneNumber)
        ) {
          setValueAndDirty('country_code', result?.phoneNumber?.countryCode);
          const countryCode = result?.phoneNumber?.countryCode || '';
          const phoneNumbers = result?.phoneNumber?.phoneNumber || '';
          setPhoneValue(countryCode + phoneNumbers);
        }
      }
    }

    fetchData();
  }, [setValue, routeParams.salesId, editDataSuccessfully]);

  useEffect(() => {
    async function fetchData() {
      if (countryId) {
        const payload = {
          query: queryData.getStates,
          variables: { countryId },
        };
        const res = await handleApiRequest(payload);
        if (res?.getStates?.status === 200) {
          setStateList(res?.getStates?.data);
        }
      }
    }
    fetchData();
  }, [countryId]);

  if (_.isEmpty(form) || loading) {
    return <FuseLoading />;
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function handlePhoneChange(event, data, value, formattedValue) {
    setPhoneNumber(event.slice(data.dialCode.length));
    setPhoneValue(event);
    setPhone(data);
    setValue('phone_number', event.slice(data.dialCode.length), {
      shouldDirty: true,
      shouldValidate: true,
    });
  }

  function handleSelectCountry(event) {
    setCountryId(event.target.value);
    setStateId();
  }

  function handleSelectState(event) {
    setStateId(event.target.value);
  }

  async function onSubmit(data) {
    if (countryId && !stateId) {
      enqueueSnackbar(t('signUpPage.stateRequired'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else {
      setRefreshIcon(true);
      const updatedFields = {};

      const trackField = (fieldName, value) => {
        if (fieldName === 'phoneNumber') {
          if (value !== parseInt(originalData?.phoneNumber?.phoneNumber, 10)) {
            updatedFields.phoneNumber = {
              countryCode: phone?.dialCode ? `+${phone?.dialCode}` : `+${phoneCountryCode}`,
              isoCode: phone?.countryCode ? phone?.countryCode : phoneIsoCode,
              phoneNumber: value,
            };
          }
        } else if (value !== originalData[fieldName]) {
          updatedFields[fieldName] = value;
        }
      };

      trackField('address', data.address1);
      trackField('addressTwo', data.address2);
      trackField('city', data.city);
      trackField('countryId', countryId);
      trackField('firstName', data.firstName);
      trackField('lastName', data.lastName);
      trackField('middleName', data.middleName);
      trackField('salesId', data.salesId);
      trackField('stateId', stateId);
      trackField('zipCode', parseInt(data.zipCode, 10));
      trackField('phoneNumber', parseInt(phoneNumber, 10));
      const payload = {
        query: salesData.editProfile,
        variables: {
          ...updatedFields,
          userId: parseInt(routeParams?.salesId, 10),
        },
      };
      const res = await handleApiRequest(payload);
      if (
        res?.editProfile?.status === 200 &&
        res?.editProfile?.message !== 'Phone Number already exists'
      ) {
        enqueueSnackbar(res?.editProfile?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setRefreshIcon(false);
        setIsEdit(false);
        setEditDataSuccessfully(true);
      } else if (res?.editProfile?.message === 'Phone Number already exists') {
        enqueueSnackbar(res?.editProfile?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setRefreshIcon(false);
      } else {
        enqueueSnackbar(res?.editProfile?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setRefreshIcon(false);
      }
    }
  }

  return (
    <div className="p-20 lg:p-24">
      <form name="userDetails" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between items-center">
          <div>
            <Toolbar className="px-0 font-semibold min-h-fit mb-20">
              <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
                west
              </Icon>
              <Typography
                variant="subtitle1"
                color="inherit"
                className="flex-1 px-12 font-bold text-18"
              >
                {t('salesPerson.title')}
              </Typography>
            </Toolbar>
            <div className="flex flex-1 w-full items-center justify-between mb-36">
              <div className="flex items-center">
                <Typography
                  component={motion.span}
                  initial={{ x: -20 }}
                  animate={{ x: 0, transition: { delay: 0.2 } }}
                  delay={300}
                  className="sm:flex text-16 md:text-28 font-bold"
                >
                  <span className="inline-block bg-white rounded-md w-48 h-48 text-center flex items-center mr-10">
                    <img
                      src="/assets/images/admin-icon/salesperson.png"
                      className="mx-auto"
                      width="35"
                      height="20"
                      alt="..."
                    />
                  </span>{' '}
                  {t('salesPerson.salesPersonDetail.viewTitle')}
                </Typography>
              </div>
            </div>
          </div>

          <div>
            {!isEdit ? (
              <div className="inline-block items-center w-auto float-right">
                <Button
                  className="text-18 px-20"
                  variant="contained"
                  color="secondary"
                  aria-label="Edit"
                  onClick={(e) => setIsEdit(true)}
                >
                  <Icon className="text-16 mr-5 cursor-pointer">edit</Icon> {t('common.edit')}
                </Button>
              </div>
            ) : (
              <div className="inline-block items-center w-auto float-right">
                <Button
                  className="text-18 px-28 hover:bg-black hover:text-white ml-10"
                  variant="contained"
                  aria-label="Edit"
                  onClick={(e) => setIsEdit(false)}
                >
                  {t('common.cancel')}
                </Button>
              </div>
            )}
            {isEdit && (
              <Button
                className="text-18 px-32 disabled:text-black"
                variant="contained"
                color="secondary"
                disabled={_.isEmpty(dirtyFields) || !isValid || refreshIcon}
                type="submit"
              >
                {t('common.save')}
                {refreshIcon && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>
            )}
          </div>
        </div>

        <div className="bg-white rounded-md relative shadow">
          <Tabs
            value={tabValue}
            className="bg-grey-50 rounded-tl-md rounded-tr-md"
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              className="text-18 font-semibold"
              value={0}
              label={t('salesPerson.salesPersonDetail.title')}
            />
          </Tabs>
          <div className="p-20 lg:p-24">
            <div className={tabValue !== 0 ? 'hidden' : ''}>
              <div className="w-full">
                {isEdit ? (
                  <div className="flex justify-start">
                    <div className="basis-1/2  max-w-sm">
                      <div className="rounded-md border border-grey-400 p-14 mb-24">
                        <div className="flex items-center">
                          <Avatar
                            className="w-96 h-96 mr-24 bg-orange-500 text-40 capitalize"
                            alt={fName}
                            src={profileUrl?.toString()}
                          />
                          <div>
                            <h3 className="text-18 font-semibold capitalize">
                              {fName} {lName}
                            </h3>
                            <h4 className="text-16 font-semibold text-grey-500">{userEmail}</h4>
                          </div>
                        </div>
                      </div>
                      <Controller
                        name="firstName"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24 max-w-sm"
                            disabled={!isEdit}
                            label={t('common.firstName')}
                            type="text"
                            error={!!errors.firstName}
                            helperText={errors?.firstName?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                      <Controller
                        name="middleName"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24 max-w-sm"
                            disabled={!isEdit}
                            label={t('userProfile.middleName')}
                            type="text"
                            error={!!errors.middleName}
                            helperText={errors?.middleName?.message}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                      <Controller
                        name="lastName"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24 max-w-sm"
                            disabled={!isEdit}
                            label={t('common.lastName')}
                            type="text"
                            error={!!errors.lastName}
                            helperText={errors?.lastName?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                      <Controller
                        name="salesId"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24 max-w-sm"
                            disabled={!isEdit}
                            label={t('signUpPage.formField.salesId.name')}
                            type="text"
                            error={!!errors.salesId}
                            helperText={errors?.salesId?.message}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                      <div>
                        <PhoneInput
                          placeholder={t('signUpPage.formField.phoneNumber.placeHolder')}
                          inputStyle={{
                            width: '100%',
                            height: '52px',
                          }}
                          className="w-full max-w-sm w-full"
                          name="phone_number"
                          required
                          error={!!errors.phone_number}
                          enableLongNumbers={17}
                          helperText={errors?.phone_number?.message}
                          disabled
                          value={phoneValue}
                          country="us"
                          enableSearch="true"
                          onChange={handlePhoneChange}
                        />
                        <p className="text-black top-5 relative text-md">
                          {t('signUpPage.phoneNumberCannotChange')}
                        </p>
                      </div>
                    </div>
                    <div className="ml-40 basis-1/2  max-w-sm">
                      <Controller
                        name="address1"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24 max-w-sm"
                            disabled={!isEdit}
                            label={t('signUpPage.formField.address.labels.addressOne')}
                            type="text"
                            error={!!errors.address1}
                            helperText={errors?.address1?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                      <Controller
                        name="address2"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24 max-w-sm"
                            disabled={!isEdit}
                            label={t('signUpPage.formField.address.labels.addressTwo')}
                            type="text"
                            error={!!errors.address2}
                            helperText={errors?.address2?.message}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                      <Controller
                        name="country"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="mb-24 max-w-sm w-full"
                            disabled={!isEdit}
                            id="country"
                            value={countryId}
                            displayEmpty
                            error={!!errors.country}
                            onChange={(e) => {
                              handleSelectCountry(e);
                            }}
                          >
                            <MenuItem value={0} disabled>
                              {t('signUpPage.formField.country.selectCountry')}
                            </MenuItem>
                            {countryList?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      <Controller
                        name="state"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="mb-24 max-w-sm"
                            disabled={!isEdit}
                            value={stateId}
                            displayEmpty
                            id="state"
                            onChange={(e) => {
                              handleSelectState(e);
                            }}
                            required
                            fullWidth
                          >
                            <MenuItem value={0} disabled>
                              {t('signUpPage.formField.state.selectState')}
                            </MenuItem>
                            {stateList?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      <Controller
                        name="city"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24 max-w-sm "
                            disabled={!isEdit}
                            label={t('common.city')}
                            type="text"
                            error={!!errors.city}
                            helperText={errors?.city?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                      <Controller
                        name="zipCode"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24 max-w-sm"
                            disabled={!isEdit}
                            label={t('common.zipCode')}
                            type="number"
                            error={!!errors.zipCode}
                            helperText={errors?.zipCode?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-start">
                    <div className="basis-1/2  max-w-sm">
                      <div className="py-14 mb-24">
                        <div className="flex items-center">
                          <Avatar
                            className="w-96 h-96 mr-24 bg-orange-500 text-40 capitalize"
                            alt={fName}
                            src={profileUrl?.toString()}
                          />
                          <div>
                            <h3 className="text-18 font-semibold capitalize">
                              {fName} {lName}
                            </h3>
                            <h4 className="text-16 font-semibold text-grey-500">{userEmail}</h4>
                          </div>
                        </div>
                      </div>
                      <div className="mb-24">
                        <span className="text-15 font-semibold block">{t('common.firstName')}</span>
                        <span className="text-15 font-medium block text-grey-600">
                          {salesUserData?.firstName}
                        </span>
                      </div>
                      <div className="mb-24">
                        <span className="text-15 font-semibold block">
                          {t('userProfile.middleName')}
                        </span>
                        <span className="text-15 font-medium block text-grey-600">
                          {salesUserData?.middleName ? salesUserData?.middleName : '-'}
                        </span>
                      </div>
                      <div className="mb-24">
                        <span className="text-15 font-semibold block">{t('common.lastName')}</span>
                        <span className="text-15 font-medium block text-grey-600">
                          {salesUserData?.lastName}
                        </span>
                      </div>
                      <div className="mb-24">
                        <span className="text-15 font-semibold block">
                          {t('signUpPage.formField.salesId.name')}
                        </span>
                        <span className="text-15 font-medium block text-grey-600">
                          {salesUserData?.salesId ? salesUserData?.salesId : '-'}
                        </span>
                      </div>
                      <div className="mb-24">
                        <span className="text-15 font-semibold block">Business Phone</span>
                        <span className="text-15 font-medium block text-grey-600">
                          {salesUserData?.phoneNumber?.countryCode}{' '}
                          {salesUserData?.phoneNumber?.phoneNumber}
                        </span>
                      </div>
                      <div className="mb-24">
                        <span className="text-15 font-semibold block">
                          {t('signUpPage.formField.address.labels.addressOne')}
                        </span>
                        <span className="text-15 font-medium block text-grey-600">
                          {salesUserData?.address}
                        </span>
                      </div>
                      {salesUserData?.address2 && (
                        <div className="mb-24">
                          <span className="text-15 font-semibold block">
                            {t('signUpPage.formField.address.labels.addressTwo')}
                          </span>
                          <span className="text-15 font-medium block text-grey-600">
                            {salesUserData?.address2 ? salesUserData?.address2 : '-'}
                          </span>
                        </div>
                      )}
                      <div className="mb-24">
                        <span className="text-15 font-semibold block">{t('common.country')}</span>
                        <span className="text-15 font-medium block text-grey-600">
                          {salesUserData?.countries?.name}
                        </span>
                      </div>
                      <div className="mb-24">
                        <span className="text-15 font-semibold block">{t('common.state')}</span>
                        <span className="text-15 font-medium block text-grey-600">
                          {salesUserData?.states?.name}
                        </span>
                      </div>
                      <div className="mb-24">
                        <span className="text-15 font-semibold block">{t('common.city')}</span>
                        <span className="text-15 font-medium block text-grey-600">
                          {salesUserData?.city}
                        </span>
                      </div>
                      <div className="mb-24">
                        <span className="text-15 font-semibold block">{t('common.zipCode')}</span>
                        <span className="text-15 font-medium block text-grey-600">
                          {salesUserData?.zipcode}
                        </span>
                      </div>
                    </div>
                    <div className="ml-40 basis-1/2  max-w-sm">
                      <div>
                        <div className="mb-24 text-center border-1 border-solid border-color-grey-400 inline-block py-24 px-64 rounded-md min-w-360">
                          <span className="text-24 font-semibold block text-darkgreen mb-10">
                            {salesAccountData?.createdDemoAccountCount}
                          </span>
                          <span className="text-18 font-medium block">
                            {t('salesPerson.salesPersonDetail.createdDemoAccountCount')}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="mb-24 text-center border-1 border-solid border-color-grey-400 inline-block py-24 px-64 rounded-md min-w-360">
                          <span className="text-24 font-semibold block text-darkgreen mb-10">
                            {salesAccountData?.visitedAccount}
                          </span>
                          <span className="text-18 font-medium block">
                            {t('salesPerson.salesPersonDetail.visitedAccount')}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="mb-24 text-center border-1 border-solid border-color-grey-400 inline-block py-24 px-64 rounded-md min-w-360">
                          <span className="text-24 font-semibold block text-darkgreen mb-10">
                            {salesAccountData?.convertedRealAccountCount}
                          </span>
                          <span className="text-18 font-medium block">
                            {t('salesPerson.salesPersonDetail.convertedRealAccount')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SalesDetails;
