import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import history from '@history';
import Icon from '@mui/material/Icon';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import * as yup from 'yup';
import selectedBusinessData from 'src/app/main/query/selectedBusiness';
import { useSnackbar } from 'notistack';
import { useLocation, useParams } from 'react-router-dom';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch } from 'react-redux';
import { publicIpv4 } from 'public-ip';
import {
  handleApiRequest,
  getEncryptedData,
  getDataToServer,
  getDecryptData,
  countryData,
} from '../../../common/common';
import UserService from '../../../../services/userService';
import queryData from '../../../query/common';
import { getLocation } from '../../../sign-up/store/signUpSlice';

const defaultValuesFeedback = {
  note: '',
};

const AccountSettings = (props) => {
  const {
    getDemoUserAccountSettingDetails,
    getSalesPersonVisitedStatusDetails,
    getBusinessesOwnerFeedbackOptionDetails,
    selectedBusinessListDetails,
  } = props;

  const schemaFeedback = yup.object().shape({
    note: yup.string().required(t('business.selectedBusinessDetail.labels.noteRequire')),
  });

  const {
    control,
    setValue,
    formState: formStateFeedback,
    reset,
    handleSubmit,
    setError,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValuesFeedback,
    resolver: yupResolver(schemaFeedback),
  });

  const {
    isValid: isValidFeedback,
    dirtyFields: dirtyFieldsFeedback,
    errors: errorsFeedback,
  } = formStateFeedback;

  const dispatch = useDispatch();

  const loginUserData = UserService.getUserData();
  const routeParams = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [visitedStatus, setVisitedStatus] = useState();
  const [feedbackOptionRefreshIcon, setFeedbackOptionRefreshIcon] = useState(false);
  const [feedbackOptionId, setFeedbackOptionId] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState();
  const [phone, setPhone] = useState();
  const [phoneValue, setPhoneValue] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [currentCountry, setCurrentCountry] = useState(loginUserData?.phoneNumber?.isoCode);
  const [accountRefreshIcon, setAccountRefreshIcon] = useState(false);
  const [deactiveAccount, setDeactiveAccount] = useState(false);
  const currentPath = useLocation();
  const [visitedBusiness, setVisitedBusiness] = useState(
    Boolean(getDemoUserAccountSettingDetails?.salesPersonVisited)
  );

  useEffect(() => {
    const ipAddress = publicIpv4();
    const ipAddressData = async () => {
      const payload = {
        query: queryData.getLocation,
        variables: { data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`) },
      };
      const locationResponse = await dispatch(getLocation(payload));
      if (locationResponse?.payload?.data?.getLocation?.data) {
        const locationData = locationResponse?.payload?.data?.getLocation?.data;
        const data =
          JSON.parse(getDecryptData(locationData))?.countryId || loginUserData?.countryCode;
        const country = countryData(data);
        if (country) {
          setPhoneValue(country?.countryCode.toString());
        }
      }
    };
    ipAddressData();
  }, [dispatch, loginUserData?.countryCode]);

  function handlePhoneChange(event, data, value, formattedValue) {
    setPhoneNumber(event.slice(data?.dialCode?.length));
    setPhoneValue(event);
    setPhone(data);
    setValue('phone_number', event.slice(data?.dialCode?.length), {
      shouldDirty: true,
      shouldValidate: true,
    });
  }

  const onSubmit = async (data) => {
    if (!visitedStatus) {
      enqueueSnackbar('Please select one', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else if (
      parseInt(visitedStatus, 10) === 5 &&
      (!getValues('date') || !getValues('time') || !getValues('followUpNote') || !getValues('note'))
    ) {
      enqueueSnackbar('All Fields are required', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else if (parseInt(visitedStatus, 10) === 2 && !getValues('note')) {
      enqueueSnackbar('All Fields are required', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else {
      const isFollowUp = parseInt(visitedStatus, 10) === 5;
      let followUpObj = {};
      if (isFollowUp) {
        const dateFormat = `${(data?.date?.getDate() < 10 ? '0' : '') + data.date.getDate()}-${
          (data.date.getMonth() + 1 < 10 ? '0' : '') + (data.date.getMonth() + 1)
        }-${data.date.getFullYear()}`;

        followUpObj = {
          followUpDate: {
            date: dateFormat,
            time: data.time,
            followUpNote: data.followUpNote,
          },
        };
      }

      setFeedbackOptionRefreshIcon(true);
      const businessFeedbackObj = {
        businessesOwnerFeedbackId: parseInt(feedbackOptionId, 10),
      };
      const payload = {
        query: selectedBusinessData.visitedBusinessAndFeedback,
        variables: {
          ...(isFollowUp ? followUpObj : businessFeedbackObj),
          businessId: parseInt(routeParams.businessId, 10),
          feedback: data.note,
          phoneNumber: parseInt(phoneNumber, 10),
          countryCode: phone?.dialCode ? `+${phone?.dialCode}` : undefined,
          salesPersonResponeId: parseInt(visitedStatus, 10),
          visited: true,
        },
      };

      const result = await handleApiRequest(payload);
      await setFeedbackOptionRefreshIcon(false);
      // await reset(defaultValuesFeedback);
      if (result?.visitedBusinessAndFeedback?.status === 200) {
        const baseUrl = window.location.origin;
        const visitedBusinessUrl = `${baseUrl}/visited-business`;
        window.location.href = visitedBusinessUrl;
        enqueueSnackbar(result?.visitedBusinessAndFeedback?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
      } else {
        enqueueSnackbar(result?.visitedBusinessAndFeedback?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        closeSnackbar(3000);
      }
    }
  };

  const handleDemoAccont = async () => {
    await setAccountRefreshIcon(true);
    await setDeactiveAccount(true);
    const data = getEncryptedData(
      `{"activate": false, "userId": ${parseInt(selectedBusinessListDetails?.users?.id, 10)}}`
    );
    const payload = {
      query: selectedBusinessData.updateDemoAccountBySalesUser,
      variables: { data },
    };
    const result = await handleApiRequest(payload);
    if (result?.updateDemoAccountBySalesUser?.status === 200) {
      const baseUrl = window.location.origin;
      const deactivateAccountUrl = `${baseUrl}/deactivated-account`;
      window.location.href = deactivateAccountUrl;
      enqueueSnackbar(result?.updateDemoAccountBySalesUser?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setAccountRefreshIcon(false);
      setOpenDialog(false);
      if (deactiveAccount) {
        if (getDemoUserAccountSettingDetails?.salesPersonVisited) {
          history.push('/visited-business');
        } else {
          history.push('/selected-business');
        }
      }
      closeSnackbar(3000);
    } else {
      enqueueSnackbar(result?.updateDemoAccountBySalesUser?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setAccountRefreshIcon(false);
      setOpenDialog(false);
      closeSnackbar(3000);
    }
  };

  const handleVisitedToggle = () => {
    if (currentPath.pathname.includes('deactive-business-details')) {
      enqueueSnackbar(t('business.selectedBusinessDetail.labels.reActiveAccount'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else {
      setVisitedBusiness(!visitedBusiness);
    }
  };

  const handleSelectTime = (data) => {
    const formattedTime = data.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    setValue('time', formattedTime);
  };

  return (
    <div>
      {!selectedBusinessListDetails?.salesPersonVisited &&
        !getDemoUserAccountSettingDetails?.convertedRealAccount && (
          <div className="max-w-md bg-grey-50 p-20 rounded-lg mb-20">
            <div className="flex justify-between items-center">
              <span className="text-black text-16 font-bold">
                {t('business.selectedBusinessDetail.labels.visitedBusiness')}
              </span>
              <Switch
                value={visitedBusiness}
                checked={visitedBusiness}
                onChange={() => handleVisitedToggle()}
                color="secondary"
              />
            </div>
            {visitedBusiness && (
              <div>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={visitedStatus}
                  onChange={(event) => setVisitedStatus(event.target.value)}
                >
                  {getSalesPersonVisitedStatusDetails &&
                    getSalesPersonVisitedStatusDetails.length > 0 &&
                    getSalesPersonVisitedStatusDetails.map((visited, i) => (
                      <FormControlLabel
                        key={visited.id}
                        value={visited.id}
                        control={<Radio />}
                        label={visited.type}
                      />
                    ))}
                </RadioGroup>
                <form
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex flex-col md:overflow-hidden"
                >
                  {parseInt(visitedStatus, 10) === 2 && (
                    <Select
                      id="feedbackOption"
                      name="feedbackOption"
                      className="mt-20"
                      size="small"
                      value={feedbackOptionId}
                      onChange={(e) => {
                        setFeedbackOptionId(e.target.value);
                      }}
                    >
                      <MenuItem value={0} disabled>
                        {t('business.selectedBusinessDetail.menu.selectBusinessOwnerResponse')}
                      </MenuItem>
                      {getBusinessesOwnerFeedbackOptionDetails?.map((option) => (
                        <MenuItem className="capitalize" key={option.id} value={option.id}>
                          {option.type}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  {parseInt(visitedStatus, 10) === 5 && (
                    <div>
                      <div className="flex gap-6">
                        <div className="w-full">
                          <Typography>
                            {t('business.selectedBusinessDetail.labels.nextFollowUpDate')}
                          </Typography>
                          <Controller
                            control={control}
                            name="date"
                            render={({ field }) => (
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  {...field}
                                  className="mt-4 w-full"
                                  id="note"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  disablePast
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      InputLabelProps={{
                                        shrink: false,
                                      }}
                                      label="Select a date"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div>
                        <div className="w-full">
                          <Typography>
                            {t('business.selectedBusinessDetail.labels.nextFollowUpTime')}
                          </Typography>
                          <Controller
                            control={control}
                            name="time"
                            render={({ field }) => (
                              <TimePicker
                                className="mt-4 w-full"
                                onChange={handleSelectTime}
                                viewRenderers={{
                                  hours: renderTimeViewClock,
                                  minutes: renderTimeViewClock,
                                  seconds: renderTimeViewClock,
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="mt-12">
                        <Typography>
                          {t('business.selectedBusinessDetail.labels.nextFollowUpNote')}
                        </Typography>
                        <Controller
                          control={control}
                          name="followUpNote"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mt-4"
                              placeholder="Next follow up note"
                              id="followUpNote"
                              multiline
                              rows={2}
                              type="text"
                              // error={!!errorsFeedback.note}
                              // helperText={errorsFeedback?.note?.message}
                              variant="outlined"
                              required
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                  <div>
                    <InputLabel
                      htmlFor="phone_number"
                      className="text-16 font-medium mb-12 mt-20 text-grey-900"
                    >
                      {t('common.phoneNumber')}
                    </InputLabel>
                    <PhoneInput
                      placeholder={t('signUpPage.formField.phoneNumber.placeHolder')}
                      inputStyle={{
                        width: '100%',
                        height: '40px',
                      }}
                      className="w-full w-full"
                      name="phone_number"
                      error={!!errorsFeedback.phone_number}
                      helperText={errorsFeedback?.phone_number?.message}
                      value={phoneValue}
                      country={currentCountry}
                      enableLongNumbers={17}
                      enableSearch="true"
                      onChange={handlePhoneChange}
                    />
                  </div>
                  <InputLabel
                    htmlFor="note"
                    className="text-16 font-medium mb-12 mt-20 text-grey-900"
                  >
                    {t('common.note')}*
                  </InputLabel>
                  <Controller
                    control={control}
                    name="note"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Enter Note"
                        id="note"
                        multiline
                        rows={3}
                        type="text"
                        error={!!errorsFeedback.note}
                        helperText={errorsFeedback?.note?.message}
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                  <div className="text-center mt-24">
                    <Button
                      className="md:text-18 font-semibold disabled:text-black rounded-3xl px-32"
                      variant="contained"
                      color="secondary"
                      disabled={feedbackOptionRefreshIcon}
                      type="submit"
                    >
                      {t('common.save')}
                      {feedbackOptionRefreshIcon && (
                        <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                      )}
                    </Button>

                    <Button
                      className="md:text-18 font-semibold bg-white text-darkgreen border border-solid border-darkgreen rounded-3xl hover:text-white hover:bg-darkgreen ml-10  px-32"
                      variant="contained"
                      aria-label="Edit"
                      onClick={() => {
                        reset();
                      }}
                    >
                      {t('common.cancel')}
                    </Button>
                  </div>
                </form>
              </div>
            )}
          </div>
        )}
      {parseInt(selectedBusinessListDetails?.users?.userStatusId, 10) === 1 && (
        <div className="sm:flex justify-between items-center max-w-md bg-grey-50 p-20 rounded-lg">
          <span className="text-black text-16 font-bold block">
            {t('business.selectedBusinessDetail.buttons.deactivateAccount')}
          </span>
          <Button
            variant="contained"
            color="secondary"
            className="md:text-18 font-semibold disabled:text-black px-28 rounded-3xl  mt-10 sm:mt-10"
            aria-label="deactive account"
            onClick={() => {
              setOpenDialog(true);
            }}
            type="button"
            size="small"
          >
            {t('business.selectedBusinessDetail.buttons.deactivateAccount')}
          </Button>
        </div>
      )}
      {openDialog && (
        <Dialog
          classes={{
            paper: 'm-24 p-28 max-w-400',
          }}
          open={openDialog}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setOpenDialog(false);
            }
          }}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="p-0 text-center">
            <Icon
              onClick={() => {
                setOpenDialog(false);
              }}
              className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
            <Icon className="mx-auto text-64 text-yellow-700 font-normal">info</Icon>
            <Typography className="text-18 font-bold text-black text-center w-full pb-10">
              {t('business.selectedBusinessDetail.labels.deactivatingAccount')}
            </Typography>
          </DialogTitle>
          <DialogContent className="p-0 mb-24">
            <DialogContentText
              id="alert-dialog-description"
              className="text-16 font-medium text-center text-black max-w-sm"
            >
              {t('business.selectedBusinessDetail.labels.removeFromAccount')}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="p-0 justify-center">
            <Button
              variant="contained"
              color="secondary"
              className="md:text-18 font-semibold disabled:text-black rounded-3xl px-28 pb-6"
              aria-label="deactive account"
              onClick={() => {
                handleDemoAccont();
              }}
              disabled={accountRefreshIcon}
              type="button"
              size="small"
            >
              {t('business.selectedBusinessDetail.buttons.deactivateAccount')}
              {accountRefreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default AccountSettings;
