import { Button } from '@mui/material';
import history from '@history';
import React, { useCallback, useEffect, useState } from 'react';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { selectDashboardData } from 'app/store/userSlice';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import { useSelector } from 'react-redux';
import quickSocialQuery from '../../query/quickSocial';
import { handleApiRequest } from '../../common/common';
import businessData from '../../query/business';

const PostDetails = ({ loading, scheduledData, setScheduledData }) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [openDialogueForDelete, setOpenDialogueForDelete] = useState(false);
  const [expandedPosts, setExpandedPosts] = useState({});

  const getScheduledData = useCallback(async () => {
    try {
      const payloadData = {
        query: quickSocialQuery.getQuickSocialSchedulePost,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const resultData = await handleApiRequest(payloadData);
      if (resultData?.getQuickSocialSchedulePost?.status === 200) {
        const data = resultData?.getQuickSocialSchedulePost?.data;
        setScheduledData(Array.isArray(data) ? data : []);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }, [setScheduledData, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id && !loading) {
      getScheduledData();
    }
  }, [getScheduledData, loading, userCurrentBusinessDetails?.id]);

  const deleteScheduledPost = useCallback(
    async (id) => {
      const payloadData = {
        query: businessData.deleteQuickSocialSchedulePost,
        variables: {
          schedulePostId: id,
        },
      };

      try {
        const resultData = await handleApiRequest(payloadData);
        if (resultData?.deleteQuickSocialSchedulePost?.status === 200) {
          enqueueSnackbar(resultData?.deleteQuickSocialSchedulePost?.message, {
            variant: 'success',
            autoHideDuration: 2000,
          });
          getScheduledData();
          setOpenDialogueForDelete(false);
        }
      } catch (error) {
        console.error('Error deleting scheduled post:', error);
      }
    },
    [enqueueSnackbar, getScheduledData]
  );

  const handleCloseDeleteDialog = () => setOpenDialogueForDelete(false);

  const handleEdit = (item) => {
    history.push('/quick-social/post', {
      data: item,
    });
  };

  const formatTimeFromDate = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${formattedMinutes} ${ampm}`;
  };

  const toggleExpandPost = (postId) => {
    setExpandedPosts((prevExpanded) => ({
      ...prevExpanded,
      [postId]: !prevExpanded[postId],
    }));
  };

  return (
    scheduledData?.length > 0 && (
      <div>
        <p className="text-16 font-600">{t('quickSocial.scheduledPosts')}</p>
        {scheduledData?.[0] && (
          <div
            className="flex flex-col gap-20 bg-white p-10 mt-10 sm:p-16 rounded-md"
            key={scheduledData[0]?.id || 0}
          >
            <div className="flex justify-between items-center">
              <div className="flex gap-10 flex-row items-center">
                <img
                  src={userCurrentBusinessDetails?.profileURL}
                  alt="Preview"
                  className="md:w-52 md:h-52 w-40 h-40 object-cover rounded-full"
                />
                <p className="text-14 sm:text-16 font-600 text-grey-600 ">
                  {userCurrentBusinessDetails?.name}
                </p>
              </div>
              <div className="flex gap-10">
                {/* <Button
                  className="!min-w-0 bg-indigo-50 bg-opacity-30"
                  onClick={() => handleEdit(scheduledData[0])}
                >
                  <ModeEditIcon className="text-20 sm:text-24 text-quick-social" />
                </Button> */}
                <Button
                  className="!min-w-0 bg-indigo-50 bg-opacity-30"
                  onClick={() => {
                    setOpenDialogueForDelete(true);
                    setSelectedContactId(scheduledData[0]?.id);
                  }}
                >
                  <DeleteOutlineIcon className="text-20 sm:text-24" />
                </Button>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="text-16 font-500 pr-10">
                <p
                  className={`${
                    expandedPosts[scheduledData[0]?.id]
                      ? 'whitespace-normal text-justify'
                      : 'line-clamp-3'
                  } text-justify`}
                >
                  {scheduledData[0]?.postData?.caption ||
                    t('quickSocialScheduled.noCaptionAvailable')}
                </p>
                {scheduledData[0]?.postData?.caption?.length > 100 && (
                  <Button
                    className="text-blue-500 mt-2 hover:bg-transparent"
                    onClick={() => toggleExpandPost(scheduledData[0]?.id)}
                  >
                    {expandedPosts[scheduledData[0]?.id] ? 'Show Less' : 'Read More'}
                  </Button>
                )}
              </div>
              {scheduledData[0]?.postData?.imageUrl && (
                <img
                  src={scheduledData[0]?.postData?.imageUrl}
                  alt="Preview"
                  className="sm:w-92 w-76 h-76 sm:h-92"
                />
              )}
            </div>
            <div className="border-1 flex items-center w-full p-10 rounded-md border-grey-200">
              <div className="w-full sm:w-1/3 flex items-center gap-10">
                <img
                  src="/assets/images/icon/DateIcon.svg"
                  alt="Preview"
                  className="sm:w-32 sm:h-32 w-24 h-24 object-cover rounded"
                />
                <p className="text-12 sm:text-14 font-500">
                  {new Date(scheduledData[0]?.scheduledDate).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </p>
              </div>
              <div className="w-full sm:w-2/3">
                <p className="text-12 sm:text-14 font-500">
                  {formatTimeFromDate(scheduledData[0]?.scheduledDate)}
                </p>
              </div>
            </div>
            <div className="flex flex-row gap-10">
              {scheduledData[0]?.sharedChannels?.length > 0 &&
                scheduledData[0]?.sharedChannels?.map((sharedData, sharedIndex) => (
                  <div key={sharedIndex}>
                    <img
                      src={sharedData?.logo}
                      alt="Preview"
                      className="sm:w-32 sm:h-32 w-24 h-24 object-cover rounded-full"
                    />
                  </div>
                ))}
            </div>
          </div>
        )}

        <ConformationDialogBox
          open={openDialogueForDelete}
          onClose={handleCloseDeleteDialog}
          onDelete={() => deleteScheduledPost(selectedContactId)}
          confirmationText="Are You Sure?"
          additionalInfo={t('quickSocial.conformationMsg')}
          deleteImage="/assets/images/icon/socialDeleteIcon.png"
          backgroundColor="quick-social"
          textColor="quick-social"
          buttonText={t('common.delete')}
        />
      </div>
    )
  );
};

export default PostDetails;
