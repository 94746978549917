const getQuickAdsObjective = `query {
    getQuickAdsObjective {
      status
      message
      data {
        id
        name
        value
        icon
        description
        objectiveDestinationIds
      }
    }
  }`;

const quickAdsQuery = {
  getQuickAdsObjective,
};

export default quickAdsQuery;
