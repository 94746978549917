import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Dialog, DialogContent, Typography } from '@mui/material';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import quickSocialQuery from '../../query/quickSocial';
import { handleApiRequest } from '../../common/common';

const Linkedin = (props) => {
  const {
    setIsLinkedin,
    fetchAgentSupportStatus,
    setLinkedinRefreshIcon,
    openLinkedinPopUp,
    setOpenLinkedinPopUp,
  } = props;
  const [authCode, setAuthCode] = useState('');
  const [linkedinBusinessData, setLinkedinBusinessData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [connectBusinessId, setConnectBusinessId] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (openLinkedinPopUp) {
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('code');
      setLoading(true);

      if (!code && userCurrentBusinessDetails?.id) {
        const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${
          process.env.REACT_APP_LINKEDIN_CLIENT_ID
        }&redirect_uri=${encodeURIComponent(
          `${process.env.REACT_APP_PORTAL_URL}/quick-social/dashboard/`
        )}&scope=r_basicprofile,w_member_social,rw_organization_admin,r_organization_social,w_organization_social`;

        const popupWidth = 600;
        const popupHeight = 600;
        const screenLeft = window.screenLeft ?? window.screenX;
        const screenTop = window.screenTop ?? window.screenY;
        const screenWidth =
          window.innerWidth ?? document.documentElement.clientWidth ?? window.screen.width;
        const screenHeight =
          window.innerHeight ?? document.documentElement.clientHeight ?? window.screen.height;

        const popupLeft = screenLeft + (screenWidth - popupWidth) / 2;
        const popupTop = screenTop + (screenHeight - popupHeight) / 2;

        const popup = window.open(
          linkedInAuthUrl,
          'LinkedInLogin',
          `width=${popupWidth},height=${popupHeight},top=${popupTop},left=${popupLeft}`
        );

        const interval = setInterval(() => {
          if (popup && popup.closed) {
            clearInterval(interval);
            setOpenLinkedinPopUp(false);
          }
          try {
            const popupUrl = new URL(popup.location);
            const popupCode = popupUrl.searchParams.get('code');
            if (popupCode) {
              setAuthCode(popupCode);
              popup.close();
            }
          } catch (err) {
            console.error(err);
          }
        }, 1000);
      }
    }
  }, [userCurrentBusinessDetails?.id, openLinkedinPopUp, setOpenLinkedinPopUp]);

  useEffect(() => {
    const fetchBusinessList = async () => {
      try {
        setLoading(true);
        setLinkedinRefreshIcon(true);
        const payload = {
          query: quickSocialQuery.getLinkedinBusiness,
          variables: {
            code: authCode,
            userBusinessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        setLinkedinRefreshIcon(false);
        if (result?.getLinkedinBusiness?.status === 200) {
          setOpenDialog(true);
          setLinkedinBusinessData(result?.getLinkedinBusiness?.data);
        } else {
          setAuthCode(null);
          enqueueSnackbar(result?.getLinkedinBusiness.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setLoading(false);
        setLinkedinRefreshIcon(false);
        console.error('Error fetching LinkedIn business data:', error);
        setAuthCode(null);
      }
    };
    if (authCode && userCurrentBusinessDetails?.id) {
      fetchBusinessList();
    }
  }, [authCode, userCurrentBusinessDetails?.id, setLinkedinRefreshIcon, enqueueSnackbar]);

  const handleConnectLinkedinBusiness = async (organizationId) => {
    setRefreshIcon(true);
    setConnectBusinessId(organizationId);
    try {
      const payload = {
        query: quickSocialQuery.connectLinkedinBusiness,
        variables: {
          organizationId,
          userBusinessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.connectLinkedinBusiness?.status === 200) {
        enqueueSnackbar(result?.connectLinkedinBusiness?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
        setOpenDialog(false);
        setIsLinkedin(false);
        fetchAgentSupportStatus();
      } else {
        enqueueSnackbar(result?.connectLinkedinBusiness?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      console.error('Error connecting LinkedIn business:', error);
      enqueueSnackbar(error.message || 'Failed to connect LinkedIn business.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        aria-labelledby="delete-confirmation-dialog"
        classes={{
          paper: `m-24 business-type-dropdown text-center rounded-md`,
        }}
        PaperProps={{ style: { maxWidth: '470px', overflowY: 'auto' } }}
      >
        <div className="flex items-center justify-between pl-16 pt-10">
          <Typography className="text-start font-bold text-16">
            {t('quickSocial.selectUserBusiness')}
          </Typography>
          <Button
            className="hover:bg-white"
            onClick={() => {
              setOpenDialog(false);
              setIsLinkedin(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
        <hr className="mt-16" />
        <DialogContent className="p-16">
          <Typography className="text-16 font-medium leading-7 text-start">
            {t('quickSocial.selectBusinessToConnect')}
          </Typography>
          {linkedinBusinessData?.length > 0 &&
            linkedinBusinessData?.map((item) => {
              return (
                <div className="flex gap-24 mt-16 items-center border border-solid rounded-md p-10">
                  <img className="w-84 h-84 rounded-full" src={item?.profileUrl} alt="" />
                  <div className="w-full text-start">
                    <div className="">
                      <Typography className="font-semibold">{item?.localizedName}</Typography>
                      <Typography>{item?.vanityName}</Typography>
                    </div>
                    <div className="flex items-center justify-between">
                      <Typography>
                        {item?.followerCount} {t('quickSocial.followers')}
                      </Typography>
                      <Button
                        className="p-0 border border-solid rounded-md px-16 py-10 h-32 min-h-32 bg-quick-social-100 font-semibold border-quick-social"
                        onClick={() => handleConnectLinkedinBusiness(item?.organizationId)}
                      >
                        {t('quickSocial.connect')}
                        {refreshIcon && connectBusinessId === item?.organizationId && (
                          <CircularProgress
                            className="text-quick-social absolute mx-auto"
                            size={24}
                          />
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Linkedin;
