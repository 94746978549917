import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const collectInfo = [
  { key: 'full_name', type: 'FULL_NAME' },
  { key: 'phone_number', type: 'PHONE' },
  { key: 'email', type: 'EMAIL' },
];

const InstantFormPopUp = ({
  open,
  onClose,
  refresh,
  backgroundColor,
  textColor,
  Image,
  activeTab,
  setActiveTab,
  createAndUpdateAdsCampaign,
  instantFormTitle,
  setInstantFormTitle,
  instantHeadline,
  setInstantHeadline,
  instantSelected,
  setInstantSelected,
  instantCTA,
  setInstantCTA,
}) => {
  const { t } = useTranslation();

  const handleSelectedData = (data) => {
    setInstantSelected((prevSelected) => {
      const isAlreadySelected = prevSelected?.some((item) => item?.key === data?.key);
      if (isAlreadySelected) {
        return prevSelected?.filter((item) => item?.key !== data?.key);
      }
      return [...prevSelected, data];
    });
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="createPost-confirmation-dialog"
      classes={{
        paper: `m-24 business-type-dropdown text-center rounded-md`,
      }}
      PaperProps={{ style: { maxWidth: '3000px' } }}
    >
      <div className="p-20 border-b-1 border-grey text-left">
        <Typography className="text-20 font-600">
          {' '}
          {t('quickAds.createYourInstantForm')}{' '}
        </Typography>
      </div>
      <DialogContent className="p-0 mb-24 mt-14">
        <div className="flex">
          <div className="w-1/2">
            <div className="px-32">
              <div className="mb-28">
                <h1 className="text-left md:text-18 text-14 font-500 mb-7">
                  {' '}
                  {t('quickAds.formTitle')}{' '}
                </h1>
                <TextField
                  type="text"
                  placeholder="(E.g., Get a Free Quote or Sign Up Now)"
                  variant="outlined"
                  margin="normal"
                  value={instantFormTitle}
                  className="bg-gray-A500 border-none rounded-sm border-grey-A500 m-0 max-h-40 sm:w-320 w-full"
                  onChange={(e) => setInstantFormTitle(e?.target?.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#f5f6fa',
                      },
                      '&:hover fieldset': {
                        borderColor: '#219EBC',
                      },
                    },
                    '& .MuiInputBase-input': {
                      fontWeight: '600',
                      lineHeight: '22px',
                      fontSize: 16,
                    },
                  }}
                />
              </div>
              <div className="mb-28">
                <h1 className="text-left md:text-18 text-14 font-500 mb-7">
                  {' '}
                  {t('quickAds.headline')}{' '}
                </h1>
                <TextField
                  type="text"
                  placeholder="(E.g., Unlock Exclusive Benefits Today)"
                  variant="outlined"
                  margin="normal"
                  value={instantHeadline}
                  className="bg-gray-A500 border-none rounded-sm border-grey-A500 m-0 max-h-40 sm:w-320 w-full"
                  onChange={(e) => setInstantHeadline(e?.target?.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#f5f6fa',
                      },
                      '&:hover fieldset': {
                        borderColor: '#219EBC',
                      },
                    },
                    '& .MuiInputBase-input': {
                      fontWeight: '600',
                      lineHeight: '22px',
                      fontSize: 16,
                    },
                  }}
                />
              </div>
              <div className="flex justify-start mb-28">
                <div>
                  <h3 className="text-18 font-500 mb-16">
                    {' '}
                    {t('quickAds.contactDetailsToCollect')}{' '}
                  </h3>
                  {collectInfo &&
                    collectInfo?.length > 0 &&
                    collectInfo?.map((data, index) => (
                      <div key={index} className="flex justify-start items-center mb-10 gap-10">
                        <Checkbox
                          className="p-0 text-grey-300 rounded-md"
                          checked={
                            data?.key === 'full_name' ||
                            instantSelected?.some((item) => item?.key === data?.key)
                          }
                          disabled={data?.key === 'full_name'}
                          onChange={() => handleSelectedData(data)}
                        />
                        <p className="text-14 font-500 flex items-center">
                          {(data?.key === 'full_name' && 'Full Name') ||
                            (data?.key === 'phone_number' && 'Phone Number') ||
                            (data?.key === 'email' && 'Email Address')}{' '}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
              {/* <div className="mb-20 text-left">
                <h3 className="text-18 font-semibold mb-10">{t('quickAds.selectCTA')}</h3>
                <Select
                  className="w-full border-grey-300 bg-gray-A500"
                  placeholder="Select"
                  value={instantCTA}
                  onChange={(e) => setInstantCTA(e.target.value)}
                  size="medium"
                  displayEmpty
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#f5f6fa',
                      },
                      '&:hover fieldset': {
                        borderColor: '#219EBC',
                      },
                    },
                    '& .MuiSelect-select': {
                      fontWeight: '600',
                      lineHeight: '22px',
                      fontSize: 16,
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    {t('quickAds.select')}
                  </MenuItem>
                  <MenuItem value="GET_STARTED">Submit</MenuItem>
                  <MenuItem value="GET_STARTED"> Get Started </MenuItem>
                  <MenuItem value="CONTACT_US">Contact Us</MenuItem>
                </Select>
              </div> */}
            </div>
          </div>

          <div className="bg-gray-A500 text-left px-20 pb-20 pt-8">
            <h1>{t('quickAds.formPreview')} </h1>
            <div className="bg-white px-20 pb-20 pt-8">
              <div className="mb-16">
                <h2 className="text-20 font-600">{instantFormTitle}</h2>
                <h2 className="text-18 font-500">{instantHeadline}</h2>
              </div>
              <div className="mb-16">
                {instantSelected &&
                  instantSelected?.length > 0 &&
                  instantSelected?.map((input, index) => (
                    <div key={index} className="mb-16">
                      <h4 className="text-16 font-500 mb-7">
                        {(input?.key === 'full_name' && 'Full Name') ||
                          (input?.key === 'phone_number' && 'Phone Number') ||
                          (input?.key === 'email' && 'Email Address')}
                      </h4>
                      <TextField
                        type="text"
                        placeholder={input?.name}
                        variant="outlined"
                        margin="normal"
                        disabled
                        className="bg-gray-A500 border-none rounded-sm border-grey-A500 m-0 max-h-40 sm:w-320 w-full"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#f5f6fa',
                            },
                            '&:hover fieldset': {
                              borderColor: '#219EBC',
                            },
                          },
                          '& .MuiInputBase-input': {
                            fontWeight: '600',
                            lineHeight: '22px',
                            fontSize: 16,
                          },
                        }}
                      />
                    </div>
                  ))}
              </div>
              <div className="justify-end flex items-center">
                <Button
                  className="md:text-16 font-medium rounded-md disabled:bg-quick-social disabled:text-white "
                  variant="contained"
                  disabled
                  color="quickSocial"
                  size="small"
                >
                  {t('quickAds.continue')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className=" p-24 flex justify-end gap-14 border-t-2 border-gray ">
        <Button
          className="md:text-16 font-medium rounded-md"
          variant="contained"
          color="quickSocial"
          onClick={createAndUpdateAdsCampaign}
          size="small"
          disabled={
            instantFormTitle === 'Form Title' ||
            !instantHeadline ||
            instantSelected?.length <= 1 ||
            instantFormTitle === ''
          }
        >
          {t('common.save')}
          {refresh && <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InstantFormPopUp;
