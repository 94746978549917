import React, { useCallback, useEffect, useRef, useState } from 'react';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import history from '@history';
import { useSnackbar } from 'notistack';
import DownloadIcon from '@mui/icons-material/Download';
import { useSelector } from 'react-redux';
import { getAllUserBusinessSubscription, selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import { useLocation, useParams } from 'react-router-dom';
import { Button, CircularProgress, IconButton, TextField } from '@mui/material';
import FuseLoading from '@fuse/core/FuseLoading';
import AddDescription from './AddDescription';
import manageBusinessData from '../../query/manageBusiness';
import { handleApiRequest, storeImageInServer } from '../../common/common';
import SchedulePopUp from './SchedulePopUp';
import SharePost from '../quick-post/SharePost';
import businessData from '../../query/business';
import quickSocialQuery from '../../query/quickSocial';
import quickPostQuery from '../../query/quickPost';

const editable = [
  {
    key: 'review',
    value: '',
  },
  {
    key: 'rating',
    value: '',
  },
  {
    key: 'reviewer',
    value: '',
  },
  {
    key: 'location',
    value: '',
  },
  {
    key: 'platformLogo',
    value: '',
  },
];

const Post = () => {
  const params = useParams();
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const [isBtLoading, setIsBtLoading] = useState(false);
  const [suggestion, setSuggestion] = useState('');
  const [url, setURL] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [editableDes] = useState('');
  const [openDialogueForSchedule, setOpenDialogueForSchedule] = useState(false);
  const [openTemplateChangePopup, setOpenTemplateChangePopup] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const location = useLocation();
  const { data, socialId, reviewTemplate, PFlogo, plateFormID, from } = location?.state || {};
  const [showNextDiv, setShowNextDiv] = useState(false);
  const [editableTexts, setEditableTexts] = useState(
    data?.editableText || data?.share_post_template?.editableText || editable || []
  );
  const [template, setTemplate] = useState(
    data?.template ??
      decodeURIComponent(data?.html) ??
      data?.htmlTemplate ??
      data?.share_post_template?.template ??
      ''
  );
  const [modifiedCards, setModifiedCards] = useState(new Array(editableTexts?.length).fill(false));
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [socialData, setSocialData] = useState([]);
  const [isChecked, setIsChecked] = useState(socialData?.map((platform) => platform?.isConnected));
  const [isHovered, setIsHovered] = useState(false);
  const [scheduledDate, setScheduledDate] = useState();
  const [scheduledTime, setScheduledTime] = useState();
  const [openShare, setOpenShare] = useState(false);
  const [description, setDescription] = useState(data?.postData?.caption || '');
  const [imageUrl, setImageUrl] = useState();
  const imagePath = url;

  useEffect(() => {
    setIsNextEnabled(description?.length > 0);
  }, [description]);

  const getSelectedReviewPlatformIds = () => {
    return socialData
      ?.filter((_, index) => isChecked[index])
      ?.map((platform) => platform?.reviewPlatformId);
  };

  useEffect(() => {
    if (!url) {
      const instagramIndex = socialData.findIndex((p) => p.reviewPlatformId === 10);
      if (instagramIndex !== -1 && isChecked[instagramIndex]) {
        const updatedChecked = [...isChecked];
        updatedChecked[instagramIndex] = true;
        setIsChecked(updatedChecked);
      }
    }
  }, [url, isChecked, socialData]);

  useEffect(() => {
    const socialMediaData = async () => {
      try {
        const payload = {
          query: manageBusinessData.getQuickSocialChannelWiseData,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            pageNumber: 1,
            pageSize: 10,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getQuickSocialChannelWiseData?.status === 200) {
          setSocialData(result?.getQuickSocialChannelWiseData?.data);
        }
      } catch (error) {
        console.error('Error regenerating suggestion:', error);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      socialMediaData();
    }
  }, [userCurrentBusinessDetails]);

  useEffect(() => {
    const initialCheckedState = socialData?.map((platform) => platform?.isConnected);
    setIsChecked(initialCheckedState);
  }, [socialData]);

  const updateTemplate = useCallback(() => {
    let updatedTemplate =
      data?.template ||
      data?.htmlTemplate ||
      data?.share_post_template?.template ||
      (data?.html ? decodeURIComponent(data?.html) : '') ||
      '';
    editableTexts?.forEach(({ key, value }) => {
      const textValue = typeof value === 'string' ? value : value?.toString() || '';
      const placeholder = `###${key}###`;
      updatedTemplate = updatedTemplate?.replace(new RegExp(placeholder, 'g'), textValue);
    });
    setTemplate(updatedTemplate);
  }, [
    data?.template,
    data?.htmlTemplate,
    data?.share_post_template?.template,
    data?.html,
    editableTexts,
  ]);

  useEffect(() => {
    if (
      typeof data?.template ||
      decodeURIComponent(data?.html) ||
      data?.htmlTemplate ||
      data?.share_post_template?.template !== 'undefined'
    ) {
      updateTemplate();
    }
  }, [
    editableTexts,
    data?.template,
    updateTemplate,
    data?.html,
    data?.htmlTemplate,
    data?.share_post_template?.template,
  ]);

  useEffect(() => {
    if (editableDes?.summarizeReviews && editableTexts?.key === 'description') {
      const updatedTexts = editableTexts?.map((item) =>
        item?.key === 'description' ? { ...item, value: editableDes?.summarizeReviews } : item
      );
      setEditableTexts(updatedTexts);
    }
  }, [editableDes, editableTexts]);

  const saveEditableText = useCallback(
    async (editableTex) => {
      if (
        !data?.share_post_template?.template &&
        !data?.html &&
        !data?.template &&
        !data?.htmlTemplate
      ) {
        return;
      }
      try {
        setIsBtLoading(true);
        const payload = {
          query: manageBusinessData.getConvertedSharePostImage,
          variables: {
            htmlTemplate: encodeURIComponent(
              data?.template ||
                data?.htmlTemplate ||
                data?.share_post_template?.template ||
                decodeURIComponent(data?.html)
            ),
            editableText: editableTex,
            review: editableTex.find((item) => item?.key === 'review')?.value || '',
            reviewRating: editableTex.find((item) => item?.key === 'rating')?.value?.length || null,
            reviewerName: editableTex.find((item) => item?.key === 'reviewer')?.value || '',
            businessName: editableTex.find((item) => item?.key === 'location')?.value || '',
            reviewPlatformTypeId: plateFormID || null,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getConvertedSharePostImage?.status === 200) {
          setEditableTexts((prev) =>
            prev?.map((text, i) =>
              i === editableTexts?.indexOf(editableTex)
                ? result?.getConvertedSharePostImage?.data
                : text
            )
          );
          setURL(result?.getConvertedSharePostImage?.data?.socialPostUrl);
        }
        setIsBtLoading(false);
      } catch (error) {
        console.error('Error regenerating suggestion:', error);
      }
    },
    [
      data?.html,
      data?.htmlTemplate,
      data?.share_post_template?.template,
      data?.template,
      editableTexts,
      plateFormID,
    ]
  );

  useEffect(() => {
    if (reviewTemplate) {
      const updatedEditableTexts = editable?.map((item) => {
        switch (item?.key) {
          case 'review':
            if (reviewTemplate?.review) {
              return { ...item, value: reviewTemplate?.review };
            }
            break;
          case 'rating':
            if (reviewTemplate?.reviewRating || reviewTemplate?.rating) {
              const stars = '★'.repeat(reviewTemplate?.reviewRating || reviewTemplate?.rating);
              return { ...item, value: stars };
            }
            break;
          case 'reviewer':
            if (reviewTemplate?.reviewerName) {
              return { ...item, value: reviewTemplate?.reviewerName };
            }
            break;
          case 'location':
            if (userCurrentBusinessDetails?.name) {
              return { ...item, value: userCurrentBusinessDetails?.name };
            }
            break;
          case 'platformLogo':
            if (PFlogo) {
              return { ...item, value: PFlogo };
            }
            break;
          default:
            return item;
        }
        return item;
      });
      setEditableTexts(updatedEditableTexts);
    }
  }, [
    PFlogo,
    reviewTemplate,
    userCurrentBusinessDetails?.name,
    userCurrentBusinessDetails?.reviewplatform,
  ]);

  const handleSave = useCallback(
    (index) => {
      const updatedText = editableTexts[index];
      if (modifiedCards[index]) {
        saveEditableText([updatedText]);
      }
      setModifiedCards((prev) => {
        const updated = [...prev];
        updated[index] = false;
        return updated;
      });
    },
    [editableTexts, modifiedCards, saveEditableText]
  );

  const handleEditableTextDes = useCallback(
    async (index, descRip) => {
      try {
        const payload = {
          query: manageBusinessData.sharePostGenerateSummarizeReviews,
          variables: {
            review: descRip,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.sharePostGenerateSummarizeReviews?.status === 200) {
          const newDescription = result?.sharePostGenerateSummarizeReviews?.data;
          let newValue = '';
          if (typeof newDescription === 'object') {
            newValue = newDescription?.summarizeReviews;
          }
          setEditableTexts((prevTexts) =>
            prevTexts?.map((item, i) => (i === index ? { ...item, value: newValue } : item))
          );
          updateTemplate();
          handleSave(index);
        } else {
          enqueueSnackbar(result?.sharePostGenerateSummarizeReviews?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        console.error('Error regenerating suggestion:', error);
      }
    },
    [enqueueSnackbar, handleSave, updateTemplate]
  );
  const handleShare = useCallback(async () => {
    const payloadData = {
      query: quickPostQuery.saveViewedPosts,
      variables: {
        userBusinessesId: Number(userCurrentBusinessDetails?.id),
        sharePostTemplateId: data?.id,
        editableText: editableTexts,
      },
    };
    await handleApiRequest(payloadData);
  }, [data?.id, editableTexts, userCurrentBusinessDetails?.id]);

  const handleDirectPost = async () => {
    setIsBtLoading(true);
    const createPayload = (type) => {
      switch (type) {
        case 'facebook':
          return {
            query: manageBusinessData.postOnFacebookChannel,
            variables: {
              image: url,
              postType: url === '' ? 'text' : 'image',
              text: description,
              userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            },
            responseKey: 'postOnFacebookChannel',
          };
        case 'instagram':
          if (!url) {
            console.warn('Instagram post requires a URL. Skipping Instagram post creation.');
            return null;
          }
          return {
            query: manageBusinessData.createInstagramPost,
            variables: {
              isTemplate: true,
              templateData: url,
              mediaType: 'image',
              caption: description,
              userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            },
            responseKey: 'createInstagramPost',
          };
        case 'linkedin':
          if (!url) {
            console.warn('Linkedin post requires a URL. Skipping Linkedin post creation.');
            return null;
          }
          return {
            query: quickSocialQuery.createLinkedinPost,
            variables: {
              isTemplate: true,
              templateData: url,
              mediaType: 'image',
              caption: description,
              userBusinessId: Number(userCurrentBusinessDetails?.id),
            },
            responseKey: 'createInstagramPost',
          };
        default:
          return null;
      }
    };

    try {
      if (
        Array.isArray(getSelectedReviewPlatformIds()) &&
        getSelectedReviewPlatformIds().includes(9)
      ) {
        const facebookPayload = createPayload('facebook');
        if (facebookPayload) {
          const result = await handleApiRequest(facebookPayload);
          const response = result?.[facebookPayload?.responseKey];
          enqueueSnackbar(response?.message, {
            variant: response?.status === 200 ? 'success' : 'error',
            autoHideDuration: 3000,
          });
          if (response?.status === 200) {
            if (socialId === 4) {
              handleShare();
            }
            history.push(`quick-social/share-post/`);
          }
          if (response?.status === 190) {
            setTimeout(() => {
              history.push(`quick-social/social-connect/`);
            }, 1000);
          }
        }
      }

      if (
        Array.isArray(getSelectedReviewPlatformIds()) &&
        getSelectedReviewPlatformIds()?.includes(10) &&
        url
      ) {
        const instagramPayload = createPayload('instagram');
        if (instagramPayload) {
          const result = await handleApiRequest(instagramPayload);
          const response = result?.[instagramPayload?.responseKey];
          enqueueSnackbar(response?.message, {
            variant: response?.status === 200 ? 'success' : 'error',
            autoHideDuration: 3000,
          });
          if (response?.status === 200) {
            if (socialId === 4) {
              handleShare();
            }
            history.push(`quick-social/share-post/`);
          }
          if (response?.status === 190) {
            setTimeout(() => {
              history.push(`quick-social/social-connect/`);
            }, 1000);
          }
        }
      }
      if (
        Array.isArray(getSelectedReviewPlatformIds()) &&
        getSelectedReviewPlatformIds()?.includes(12) &&
        url
      ) {
        const instagramPayload = createPayload('linkedin');
        if (instagramPayload) {
          const result = await handleApiRequest(instagramPayload);
          const response = result?.[instagramPayload?.responseKey];
          enqueueSnackbar(response?.message, {
            variant: response?.status === 200 ? 'success' : 'error',
            autoHideDuration: 3000,
          });
          if (response?.status === 200) {
            if (socialId === 4) {
              handleShare();
            }
            history.push(`quick-social/share-post/`);
          }
          if (response?.status === 190) {
            setTimeout(() => {
              history.push(`quick-social/social-connect/`);
            }, 1000);
          }
        }
      }
    } catch (error) {
      console.error('Error posting to platform:', error);
    }
    setIsBtLoading(false);
  };

  const handleTextChange = (index, value) => {
    setEditableTexts((prevEditableTexts) => {
      const updatedTexts = prevEditableTexts?.map((item, i) =>
        i === index ? { ...item, value } : item
      );
      return updatedTexts;
    });
    const updatedChanges = [...modifiedCards];
    updatedChanges[index] = true;
    setModifiedCards(updatedChanges);
    updateTemplate();
  };

  const handleEditableTextDesc = (index, descRip) => {
    handleEditableTextDes(index, descRip);
  };

  const handleSchedule = () => {
    setOpenDialogueForSchedule(true);
  };

  const handleNext = () => {
    setShowNextDiv(true);
    saveEditableText(editableTexts);
  };

  const handleCheckboxChange = (reviewPlatformId) => {
    const platformIndex = socialData.findIndex(
      (platform) => platform.reviewPlatformId === reviewPlatformId
    );
    if (platformIndex !== -1) {
      setIsChecked((prevState) => {
        const updatedChecked = [...prevState];
        updatedChecked[platformIndex] = !prevState[platformIndex];
        return updatedChecked;
      });
    }
  };

  const formatDateTime = (date, time) => {
    const dateTimeString = `${date} ${time}`;
    const dateObj = new Date(dateTimeString);
    dateObj?.setHours(dateObj.getHours() + 5);
    dateObj?.setMinutes(dateObj.getMinutes() + 30);
    const offset = dateObj.getTimezoneOffset();
    const hoursOffset = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
    const minutesOffset = String(Math.abs(offset) % 60).padStart(2, '0');
    const sign = offset > 0 ? '-' : '+';
    const formattedDate = dateObj
      ?.toISOString()
      ?.replace(/:\d{2}\.\d{3}Z$/, '')
      ?.concat(`${sign}${hoursOffset}:${minutesOffset}`);
    return formattedDate;
  };

  const setSchedule = async () => {
    setIsBtLoading(true);
    try {
      const payload = {
        query: manageBusinessData.addQuickSocialSchedulePost,
        variables: {
          sharedChannels: getSelectedReviewPlatformIds(),
          editableText: editableTexts,
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          scheduleDate: formatDateTime(scheduledDate, scheduledTime),
          description,
          html: encodeURIComponent(template),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.addQuickSocialSchedulePost?.status === 200) {
        if (socialId === 4) {
          handleShare();
        }
        history.push(`quick-social/schedule-post/${params?.id ? params.id : ''}`);
        enqueueSnackbar(result?.addQuickSocialSchedulePost?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setOpenDialogueForSchedule(false);
      } else {
        enqueueSnackbar(result?.addQuickSocialSchedulePost?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error regenerating suggestion:', error);
    }
    setIsBtLoading(false);
  };

  const setUpdateSchedule = async () => {
    try {
      const payload = {
        query: quickSocialQuery.updateQuickSocialSchedulePost,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          scheduleDate: formatDateTime(scheduledDate, scheduledTime) || data?.scheduleDate,
          description,
          editableText: editableTexts,
          html: encodeURIComponent(template),
          schedulePostId: data?.id,
          sharedChannels: getSelectedReviewPlatformIds(),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.updateQuickSocialSchedulePost?.status === 200) {
        history.push(`quick-social/schedule-post/`);
        enqueueSnackbar(result?.updateQuickSocialSchedulePost?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        openDialogueForSchedule(false);
      }
    } catch (error) {
      console.error('Error regenerating suggestion:', error);
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDialogueForSchedule(false);
    setOpenTemplateChangePopup(false);
    setOpenShare(false);
    setScheduledDate();
    setScheduledTime();
  };

  // const handleSharePost = () => {
  //   setOpenShare(true);
  // };

  const handleEdit = () => {
    setShowNextDiv(false);
  };

  const isDisabled = () => {
    return (
      isBtLoading ||
      !isChecked?.some((item) => item === true) ||
      (!template &&
        Array.isArray(getSelectedReviewPlatformIds()) &&
        getSelectedReviewPlatformIds()?.includes(10))
    );
  };

  const handleTemplateChange = () => {
    history.push(`/quick-social/template/${params?.id ? params?.id : ''}`);
  };

  const handleOpenShare = async (imageSrc, fileName) => {
    try {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = imageSrc;
      img.onload = async () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(async (blob) => {
          if (blob) {
            const file = new File(
              [blob],
              fileName.endsWith('.png') ? fileName : `${fileName}.png`,
              {
                type: 'image/png',
              }
            );
            const formData = new FormData();
            formData.append('file', file);
            const payload = {
              query: businessData.uploadImage,
              variables: {
                input: [{ image: file }],
              },
            };
            const res = await storeImageInServer(payload);
            if (res?.uploadImage?.status === 200) {
              setImageUrl(res?.uploadImage?.data?.[0]?.imageUrl);
              setOpenShare(true);
            } else {
              enqueueSnackbar(res?.uploadImage?.message, {
                variant: 'error',
                autoHideDuration: 3000,
              });
            }
          } else {
            console.error('Failed to convert canvas to Blob.');
          }
        }, 'image/png');
      };
      img.onerror = (err) => {
        console.error('Failed to load the image', err);
      };
    } catch (error) {
      console.error('An error occurred while uploading the image:', error);
    }
  };

  const handleImageDownload = async (imageSrc, fileName) => {
    try {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = imageSrc;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const pngDataUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = pngDataUrl;
        link.download = fileName.endsWith('.png') ? fileName : `${fileName}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
      img.onerror = (err) => {
        console.error('Failed to load the image', err);
      };
    } catch (error) {
      console.error('An error occurred while downloading the image:', error);
    }
  };

  const formatTimeFromDate = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${formattedMinutes} ${ampm}`;
  };

  return (
    <div className={`lg:p-32 p-24 ${!showNextDiv ? 'max-w-5xl' : ''}`}>
      {!showNextDiv && (
        <div>
          <h1 className="font-bold md:text-28 sm:text-24 text-20 mb-28">
            {t('quickSocialPost.title')}
          </h1>
          {data ? (
            <>
              <div className="bg-white py-20 md:px-32 px-10 mb-20 rounded-md">
                <div>
                  {(data?.template || template) && (
                    <div
                      className="relative"
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      {isHovered && (
                        <div className="absolute top-10 right-10 space-x-2">
                          <IconButton
                            color="primary"
                            aria-label="add photo"
                            onClick={() => setOpenTemplateChangePopup(true)}
                          >
                            <AddPhotoAlternateIcon />
                          </IconButton>
                        </div>
                      )}
                      {template !== undefined && (
                        <div className="bg-gray-A500 md:py-24 py-0 md:px-96 px-0 rounded-md items-center justify-center mb-24">
                          {data?.template || template ? (
                            <div dangerouslySetInnerHTML={{ __html: template }} />
                          ) : (
                            ''
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <ConformationDialogBox
                    open={openTemplateChangePopup}
                    onClose={() => setOpenTemplateChangePopup(false)}
                    onDelete={() => handleTemplateChange()}
                    additionalInfo={t('quickSocialTemplate.confirmationData')}
                    confirmationText={t('quickSocialTemplate.confirmationQuestion')}
                    deleteImage="/assets/images/icon/TemplateConfirm.png"
                    backgroundColor="quick-social"
                    textColor="quick-social"
                    buttonText={t('common.confirm')}
                  />
                </div>
                <h3 className="md:text-20 text-16 font-semibold mb-16">
                  {t('quickSocialPost.editText')}
                </h3>
                <div className="quick-social">
                  {template &&
                    editableTexts?.map((item, index) => (
                      <div
                        key={index}
                        className="bg-gray-A500 px-14 py-16 mb-12 rounded-md flex flex-col gap-4 items-start"
                      >
                        <div className="flex items-center gap-12 w-full">
                          <img src="/assets/images/icon/eye.png" alt="eye" className="h-10" />
                          <TextField
                            placeholder={t('botResponses.typeTheBotResponse')}
                            variant="outlined"
                            fullWidth
                            value={
                              item?.key === 'description' && editableDes?.summarizeReviews
                                ? editableDes?.summarizeReviews
                                : item?.value === `###${item?.key}###`
                                ? `sample${item.key}`
                                : item?.value
                            }
                            onChange={(e) => {
                              const newValue = e.target.value;
                              // if (
                              //   item?.key !== 'description' &&
                              //   item?.key !== 'review' &&
                              //   newValue.length > 20
                              // ) {
                              //   return;
                              // }
                              handleTextChange(index, newValue, item?.key);
                            }}
                            inputProps={{
                              className: 'focus:ring-0 focus:outline-none',
                            }}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                                padding: 0,
                              },
                              '& .MuiOutlinedInput-input': {
                                padding: 0,
                              },
                            }}
                            className="max-w-full text-16 font-medium border-none bg-transparent "
                            color="quick-social"
                            error={
                              (item?.key === 'description' &&
                                item?.value?.length > 0 &&
                                item?.value?.length < 20) ||
                              (item?.key === 'userName' && item?.value?.length > 30)
                            }
                            helperText={
                              item?.key === 'description'
                                ? item?.value?.length === 0
                                  ? t('quickSocialPost.validation')
                                  : item?.value?.length > 0 && item?.value?.length < 20
                                  ? t('quickSocialPost.validation')
                                  : ''
                                : item?.key === 'userName' && item?.value?.length > 30
                                ? t('quickSocialPost.userNameMaxLength')
                                : ''
                            }
                            disabled={reviewTemplate && item?.key !== 'review'}
                          />
                        </div>
                        <div className="flex justify-between items-center w-full">
                          <div className="flex">
                            {(item?.key === 'description' || item?.key === 'review') && (
                              <Button
                                className="md:text-18 font-semibold rounded-full bg-quick-social-100 hover:bg-quick-social-100 text-quick-social px-16 border-solid"
                                variant="contained"
                                size="small"
                                color="quickSocial"
                                type="button"
                                onClick={() => handleEditableTextDesc(index, item?.value)}
                              >
                                {t('quickSocialPost.regenerate')}
                                <img
                                  src="/assets/images/icon/Ai.svg"
                                  alt="Ai"
                                  className="h-12 w-12"
                                />
                              </Button>
                            )}
                          </div>
                          <div className="flex">
                            {modifiedCards[index] ? (
                              <Button
                                className="md:text-18 font-semibold bg-quick-social border rounded-md  text-white py-0 px-10"
                                variant="contained"
                                color="quickSocial"
                                type="button"
                                onClick={() => handleSave(index)}
                              >
                                {t('common.save')}
                              </Button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div>
                <AddDescription
                  suggestion={suggestion}
                  setSuggestion={setSuggestion}
                  setShowNextDiv={setShowNextDiv}
                  showNextDiv={showNextDiv}
                  description={description}
                  setDescription={setDescription}
                  setIsNextEnabled={setIsNextEnabled}
                  handleNext={handleNext}
                  isNextEnabled={isNextEnabled}
                />
              </div>
            </>
          ) : (
            <div>
              <AddDescription
                suggestion={suggestion}
                setSuggestion={setSuggestion}
                setShowNextDiv={setShowNextDiv}
                showNextDiv={showNextDiv}
                description={description}
                setDescription={setDescription}
                setIsNextEnabled={setIsNextEnabled}
                handleNext={handleNext}
                isNextEnabled={isNextEnabled}
              />
            </div>
          )}
        </div>
      )}
      {showNextDiv && (
        <>
          <div className="md:flex gap-24">
            <div className=" bg-white p-20 rounded-md md:w-3/5 w-full mb-10">
              <div className="flex justify-between mb-44">
                <div>
                  <h2 className="text-28 font-semibold">{t('quickSocialPost.yourPost')}</h2>
                </div>
                <div className="gap-20">
                  {/* <div className="">
                    <IconButton
                      color="primary"
                      aria-label="share"
                      onClick={() => handleSharePost()}
                    >
                      <ShareIcon />
                    </IconButton>

                    <IconButton
                      color="primary"
                      aria-label="download"
                      onClick={() => handleDownload()}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </div> */}
                  {/* <SharePost
                    open={openShare}
                    onClose={handleCloseDeleteDialog}
                    url={url}
                    image={url}
                  /> */}
                  {isBtLoading ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    <div>
                      {imagePath && (
                        <IconButton
                          color="primary"
                          aria-label="download"
                          onClick={() => handleImageDownload(imagePath, 'downloaded-image')}
                        >
                          <DownloadIcon />
                        </IconButton>
                      )}
                      <IconButton
                        color="primary"
                        aria-label="share"
                        onClick={() => handleOpenShare(imagePath, 'downloaded-image')}
                      >
                        <ShareIcon />
                      </IconButton>
                    </div>
                  )}
                </div>
              </div>
              <div className="bg-gray-A500 p-20 mb-20 rounded-md ">
                <p>{description}</p>
              </div>
              <div className="relative">
                <div className="absolute top-2 right-2 space-x-2">
                  <IconButton color="primary" aria-label="edit" onClick={() => handleEdit()}>
                    <EditIcon />
                  </IconButton>
                </div>
                <div className="flex justify-center items-center">
                  {isBtLoading ? (
                    <FuseLoading />
                  ) : (
                    imagePath && (
                      <img src={imagePath} alt="post" className="max-w-full max-h-full " />
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="bg-white rounded-md p-24 md:w-2/5 w-full mb-10">
              <h3 className="text-20 font-semibold mb-16 ">
                {t('quickSocialPost.selectPlatform')}
              </h3>
              {!socialData?.some((platform) => platform?.isConnected) ? (
                <div className="bg-gray-A500 rounded-md p-16 flex flex-col items-center">
                  <h3 className="text-24 font-medium mb-32">
                    {t('quickSocialPost.connectToSocialAccount')}
                  </h3>
                  <div className="platform-list flex gap-16 mb-20 justify-center">
                    {socialData?.map((platform, index) => (
                      <div key={index} className="flex items-center gap-8">
                        <img
                          src={platform?.logo}
                          alt={`${platform?.name} Logo`}
                          className="w-32 h-32"
                        />
                        <span>{platform?.name}</span>
                      </div>
                    ))}
                  </div>
                  <p className="text-16 font-500 mb-28 text-center">
                    {t('quickSocialPost.connectToSocialAccountContent')}
                  </p>
                  <Button
                    variant="contained"
                    color="quickSocial"
                    autoFocus
                    onClick={() => history.push(`/quick-social/social-connect`)}
                    className="rounded-md"
                  >
                    {t('quickSocialPost.connect')}
                  </Button>
                </div>
              ) : (
                <div className="connected-platforms-list  gap-16">
                  <div className="bg-gray-A500 px-24 pt-24 mb-24 rounded-md">
                    {socialData
                      ?.filter((platform) => {
                        if (!url && platform?.reviewPlatformId === 10) {
                          return false;
                        }
                        return platform?.isConnected;
                      })
                      ?.map((platform, index) => (
                        <div key={index} className="flex items-center pb-24">
                          <input
                            type="checkbox"
                            id={`checkbox-${platform?.reviewPlatformId}`}
                            checked={
                              isChecked[
                                socialData?.findIndex(
                                  (p) => p?.reviewPlatformId === platform?.reviewPlatformId
                                )
                              ]
                            }
                            onChange={() => handleCheckboxChange(platform?.reviewPlatformId)}
                            className="hidden"
                          />
                          <label
                            htmlFor={`checkbox-${platform?.reviewPlatformId}`}
                            className="flex items-center cursor-pointer"
                          >
                            <span
                              className={`w-16 h-16 inline-block mr-4 rounded border-2 border-gray-400 ${
                                isChecked[
                                  socialData?.findIndex(
                                    (p) => p?.reviewPlatformId === platform?.reviewPlatformId
                                  )
                                ]
                                  ? 'bg-green-500 border-green-500'
                                  : ''
                              }`}
                            >
                              {isChecked[
                                socialData?.findIndex(
                                  (p) => p?.reviewPlatformId === platform?.reviewPlatformId
                                )
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-14 h-14 text-white m-auto"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <polyline points="20 6 9 17 4 12" />
                                </svg>
                              )}
                            </span>

                            <img
                              src={platform?.logo}
                              alt={`${platform?.name} Logo`}
                              className="w-24 h-24 mr-8"
                            />
                            <p className="text-20 font-medium">
                              {platform?.name?.replace(/\b\w/g, (char) => char?.toUpperCase())}
                            </p>
                          </label>
                        </div>
                      ))}
                    {!url &&
                      socialData?.some(
                        (platform) => !platform?.isConnected && platform?.reviewPlatformId === 9
                      ) && (
                        <div className="p-10 flex flex-col items-center justify-center text-center">
                          {socialData
                            ?.filter((platform) => platform?.reviewPlatformId !== 10)
                            ?.map((platform) => (
                              <img
                                key={platform?.reviewPlatformId}
                                src={platform?.logo}
                                alt={`${platform?.name} Logo`}
                                className="w-24 h-24 mb-4"
                              />
                            ))}
                          <h1 className="text-16 font-600 mb-4">
                            Connect Facebook for posting caption
                          </h1>
                          <Button
                            variant="contained"
                            color="quickSocial"
                            autoFocus
                            onClick={() => history.push(`/quick-social/dashboard`)}
                            className="rounded-md"
                          >
                            {t('quickSocialPost.connect')}
                          </Button>
                        </div>
                      )}
                  </div>
                  {from === 'schedule' && (
                    <div className="border sm:flex items-center max-w-400 p-10 rounded-md border-grey-200 gap-32 mb-20">
                      <div className=" flex items-center gap-6">
                        <img
                          src="/assets/images/icon/DateIcon.svg"
                          alt="Date Icon"
                          className="sm:w-32 sm:h-32 w-24 h-24 object-cover"
                        />
                        <p className="text-12 sm:text-14 font-500">
                          {data?.scheduledDate
                            ? new Date(data?.scheduledDate).toLocaleDateString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                              })
                            : t('quickSocialScheduled.noDateScheduled')}
                        </p>
                      </div>
                      <div className="">
                        <p className="text-12 sm:text-14 font-500">
                          {data?.scheduledDate
                            ? formatTimeFromDate(data?.scheduledDate)
                            : t('quickSocialScheduled.NoTimeAvailable')}
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="gap-16 flex justify-end">
                    <div>
                      <Button
                        variant="outlined"
                        color="quickSocial"
                        autoFocus
                        onClick={() => handleSchedule('Schedule')}
                        className="rounded-md px-32 flex items-center justify-center"
                        disabled={isDisabled()}
                      >
                        {isBtLoading ? (
                          <span className="flex items-center gap-2">
                            <CircularProgress size={16} color="quickSocial" />
                            {t('quickSocialPost.schedule')}
                          </span>
                        ) : (
                          t('quickSocialPost.schedule')
                        )}
                      </Button>
                    </div>
                    {from !== 'schedule' && (
                      <div>
                        <Button
                          variant="contained"
                          color="quickSocial"
                          autoFocus
                          onClick={() => handleDirectPost()}
                          className="rounded-md px-48 flex items-center justify-center"
                          disabled={isDisabled()}
                        >
                          {isBtLoading ? (
                            <span className="flex items-center gap-2">
                              <CircularProgress size={16} color="quickSocial" />
                              {t('quickSocialPost.post')}
                            </span>
                          ) : (
                            t('quickSocialPost.post')
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                  <SchedulePopUp
                    open={openDialogueForSchedule}
                    onClose={handleCloseDeleteDialog}
                    onCreatePost={from === 'schedule' ? setUpdateSchedule : setSchedule}
                    backgroundColor="quick-social"
                    textColor="white"
                    buttonText="Schedule"
                    scheduledDate={scheduledDate}
                    setScheduledDate={setScheduledDate}
                    scheduledTime={scheduledTime}
                    setScheduledTime={setScheduledTime}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {openShare && (
        <SharePost
          open={openShare}
          onClose={handleCloseDeleteDialog}
          image={imageUrl}
          businessId={userCurrentBusinessDetails?.id}
          editData={editableTexts}
          plateFormID={location?.state}
        />
      )}
    </div>
  );
};

export default Post;
