import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, MenuItem, Select, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import { Controller, useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import ReactSelect from 'react-select';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import userData from '../../query/user';
import { handleApiRequest, handlePhoneNumberValidationCheck } from '../../common/common';
import queryData from '../../query/common';
import { getPhysicalBusinessTypeData, getCountryData } from '../../../store/userSlice';

const InviteUserDetails = (props) => {
  const { inviteUserData, isEditInviteBusiness, setInviteUserData, setIsEditInviteBusiness } =
    props;
  const defaultValues = {};
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [countryId, setCountryId] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const [prevBusinessId, setPrevBusinessId] = useState(null);
  const [businessAttributesData, setBusinessAttributesData] = useState([]);
  const [prevCountryId, setPrevCountryId] = useState(null);
  const [inviteBusinessUserData, setInviteBusinessUserData] = useState(inviteUserData);
  const [businessId, setBusinessId] = useState();
  const routeParams = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState(true);
  const [phoneValue, setPhoneValue] = useState();
  const [phones, setPhones] = useState();
  const physicalBusinessTypeList = useSelector(getPhysicalBusinessTypeData);
  const countriesData = useSelector(getCountryData);

  useEffect(() => {
    if (inviteUserData?.length > 0) {
      setInviteBusinessUserData(inviteUserData);
    }
  }, [inviteUserData]);

  const CustomMenuList = (prop) => {
    const itemHeight = 35;
    const { options, children, maxHeight, getValue } = prop;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * itemHeight;
    if (options.length > 0) {
      return (
        <div>
          <List
            height={maxHeight}
            itemCount={children.length}
            itemSize={itemHeight}
            initialScrollOffset={initialOffset}
          >
            {({ index, style }) => (
              <div style={style} className="bg-white relative">
                {children[index]}
              </div>
            )}
          </List>
        </div>
      );
    }
    return <div className="p-16">Loading....</div>;
  };
  const schema = yup.object().shape({
    businesses: yup.array().of(
      yup.object().shape({
        user_businesses: yup.object().shape({
          // Corrected the nesting
          name: yup.string().required('Business name is required'), // Updated the error message
          city: yup.string().required('City is required'),
          states: yup.object().shape({
            id: yup.number().positive().required('State is required'), // Updated the error message
          }),
        }),
      })
    ),
  });

  const { control, formState, handleSubmit, reset, setValue, getValues, watch, clearErrors } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });
  const { isValid, dirtyFields, errors, setError } = formState;

  useEffect(() => {
    setCountryList(countriesData);
  }, [countriesData]);

  useEffect(() => {
    if (physicalBusinessTypeList?.length > 0) {
      const businessType = physicalBusinessTypeList?.map((idx) => ({
        value: idx.id,
        label: idx.bType,
      }));
      setBusinessAttributesData(businessType);
    }
  }, [physicalBusinessTypeList]);

  useEffect(() => {
    async function fetchData() {
      if (
        countryId &&
        businessId &&
        (businessId !== prevBusinessId || countryId !== prevCountryId)
      ) {
        setPrevBusinessId(businessId);
        setPrevCountryId(countryId);
        const payload = {
          query: queryData.getStates,
          variables: { countryId },
        };
        const res = await handleApiRequest(payload);
        if (res?.getStates?.status === 200) {
          const updatedBusinessData = inviteBusinessUserData.map((businessItem) => {
            if (businessItem.user_businesses?.id === businessId) {
              return {
                ...businessItem,
                user_businesses: {
                  ...businessItem.user_businesses,
                  countries: {
                    ...businessItem.user_businesses.countries,
                    states: res?.getStates?.data,
                  },
                },
              };
            }
            return businessItem;
          });
          setInviteBusinessUserData(updatedBusinessData);
        }
      }
    }
    fetchData();
  }, [countryId, inviteBusinessUserData, businessId, prevBusinessId, prevCountryId]);

  const handleSelectCountry = (e, id, businessIndex) => {
    const selectedCountryId = e.target.value;
    setCountryId(parseInt(selectedCountryId, 10));
    setBusinessId(id);
    setValue(`businesses[${businessIndex}].user_businesses.countries.id`, selectedCountryId);
    setValue(`businesses[${businessIndex}].user_businesses.states`, '');
    const selectedCountry = countryList.find(
      (country) => parseInt(country.id, 10) === selectedCountryId
    );
    if (selectedCountry) {
      const updatedBusinessData = [...inviteBusinessUserData];
      updatedBusinessData[businessIndex].user_businesses = {
        ...updatedBusinessData[businessIndex].user_businesses,
        countries: {
          id: selectedCountry.id,
          name: selectedCountry.name,
        },
        states: '',
      };
      setInviteBusinessUserData(updatedBusinessData);
    }
  };

  const handleSelectState = (e, businessIndex) => {
    const selectedStateId = e.target.value;
    const selectedState = inviteBusinessUserData[
      businessIndex
    ]?.user_businesses.countries?.states.find((state) => state.id === selectedStateId);
    clearErrors(`businesses[${businessIndex}].user_businesses.states`, { shouldClearErrors: true });
    setValue(
      `businesses[${businessIndex}].user_businesses.states.id`,
      parseInt(selectedStateId, 10)
    );
    if (selectedState) {
      const updatedBusinessData = [...inviteBusinessUserData];
      updatedBusinessData[businessIndex].user_businesses = {
        ...updatedBusinessData[businessIndex].user_businesses,
        states: {
          id: selectedState.id,
          name: selectedState.name,
        },
      };
      setInviteBusinessUserData(updatedBusinessData);
    }
  };

  function handlePhoneChange(event, data, index) {
    setValue(`businesses[${index}].user_businesses.phoneNumber.phoneNumber`, parseInt(event, 10));
    setValue(`businesses[${index}].user_businesses.phoneNumber.countryCode`, `+${data?.dialCode}`);
    setValue(`businesses[${index}].user_businesses.phoneNumber.isoCode`, data?.countryCode);
    const updatedBusinessData = [...inviteBusinessUserData];
    updatedBusinessData[index].user_businesses.phoneNumber = {
      ...updatedBusinessData[index].user_businesses.phoneNumber,
      isoCode: data?.countryCode,
      countryCode: `+${data?.dialCode}`,
      phoneNumber: parseInt(event, 10),
    };
    setInviteBusinessUserData(updatedBusinessData);
  }

  const onSubmit = async (data) => {
    setRefreshIcon(true);
    const updatedBusinessDetails = data.businesses.map((business) => {
      const phoneNumber = business?.user_businesses.phoneNumber;
      return {
        businessId: parseInt(business.user_businesses.id, 10),
        website: business.user_businesses.website,
        name: business.user_businesses.name,
        email: business.user_businesses.email,
        address: business.user_businesses.address,
        countryId: parseInt(business?.user_businesses.countries?.id, 10),
        stateId: parseInt(business?.user_businesses.states?.id, 10),
        city: business.user_businesses.city,
        reviewPlatformId: parseInt(business?.user_businesses.review_platforms?.id, 10),
        businessTypeId: parseInt(business?.user_businesses.business_types?.id, 10),
        zipCode: parseInt(business?.user_businesses.zipCode, 10),
        ...(phoneNumber?.phoneNumber && {
          phoneNumber: {
            isoCode: phoneNumber?.isoCode,
            countryCode: phoneNumber?.countryCode,
            phoneNumber:
              phoneNumber.phoneNumber.toString().length > 10 &&
              phoneNumber.phoneNumber.toString().includes(phoneNumber.countryCode.slice(1))
                ? parseInt(
                    phoneNumber.phoneNumber
                      .toString()
                      .slice(phoneNumber.countryCode.slice(1).length),
                    10
                  )
                : parseInt(phoneNumber?.phoneNumber, 10),
          },
        }),
      };
    });

    const payload = {
      query: userData.updateMultipleBusinessDetail,
      variables: {
        businessDetail: updatedBusinessDetails,
      },
    };
    const res = await handleApiRequest(payload);
    if (res?.updateMultipleBusinessDetail?.status === 200) {
      const dataObj = {
        query: userData.getInvitedBusinessDetailBasedOnUserId,
        variables: {
          userId: parseInt(routeParams.userId, 10),
        },
      };
      enqueueSnackbar(res?.updateMultipleBusinessDetail?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      setIsEditInviteBusiness(false);
      const result = await handleApiRequest(dataObj);
      if (result?.getInvitedBusinessDetailBasedOnUserId?.status === 200) {
        if (result?.getInvitedBusinessDetailBasedOnUserId?.data) {
          setInviteUserData(result?.getInvitedBusinessDetailBasedOnUserId?.data);
        }
      }
    } else {
      enqueueSnackbar(res?.updateMultipleBusinessDetail?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  useEffect(() => {
    inviteUserData?.length > 0 &&
      setValue(
        'businesses',
        inviteUserData?.map((item) => {
          return {
            ...item,
            user_businesses: {
              ...item.user_businesses,
              phoneNumber: {
                ...item.user_businesses.phoneNumber,
                phoneNumber: item?.user_businesses.phoneNumber?.countryCode
                  ?.concat(item.user_businesses.phoneNumber.phoneNumber.toString())
                  ?.slice(1),
              },
            },
          };
        })
      );
  }, [setValue, inviteUserData]);

  const handleSelectBusinessType = (e, businessIndex) => {
    const selectedBusinessTypeId = e.value;
    const selectedBusinessType = businessAttributesData?.find(
      (business) => parseInt(business.value, 10) === selectedBusinessTypeId
    );
    setValue(
      `businesses[${businessIndex}].user_businesses.business_types.id`,
      parseInt(selectedBusinessTypeId, 10)
    );
    if (selectedBusinessType) {
      const updatedBusinessData = [...inviteBusinessUserData];
      updatedBusinessData[businessIndex].user_businesses = {
        ...updatedBusinessData[businessIndex].user_businesses,
        business_types: {
          id: selectedBusinessType.value,
          bType: selectedBusinessType.label,
        },
      };
      setInviteBusinessUserData(updatedBusinessData);
    }
  };

  return (
    <div>
      {!isEditInviteBusiness ? (
        <>
          <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-32">
            {inviteBusinessUserData?.length > 0 &&
              inviteBusinessUserData?.map((item, index) => {
                return (
                  <div key={index}>
                    <h4 className="font-bold text-20 mb-24 flex items-center">
                      {t('users.userDetails.labels.business')} {index + 1}
                    </h4>

                    <div className="mb-24">
                      <span className="text-15 font-semibold block">
                        {t('common.businessName')}
                      </span>
                      <span className="text-15 font-medium block text-grey-600">
                        {item?.user_businesses?.name}
                      </span>
                    </div>
                    <div className="mb-24">
                      <span className="text-15 font-semibold block">
                        {t('signUpPage.formField.address.labels.addressOne')}
                      </span>
                      <span className="text-15 font-medium block text-grey-600">
                        {item?.user_businesses?.address}
                      </span>
                    </div>
                    <div className="mb-24">
                      <span className="text-15 font-semibold block">{t('common.country')}</span>
                      <span className="text-15 font-medium block text-grey-600">
                        {item?.user_businesses?.countries?.name}
                      </span>
                    </div>
                    <div className="mb-24">
                      <span className="text-15 font-semibold block">{t('common.state')}</span>
                      <span className="text-15 font-medium block text-grey-600">
                        {item?.user_businesses?.states?.name}
                      </span>
                    </div>
                    <div className="mb-24">
                      <span className="text-15 font-semibold block">{t('common.city')}</span>
                      <span className="text-15 font-medium block text-grey-600">
                        {item?.user_businesses?.city}
                      </span>
                    </div>
                    <div className="mb-24">
                      <span className="text-15 font-semibold block">{t('common.zipCode')}</span>
                      <span className="text-15 font-medium block text-grey-600">
                        {item?.user_businesses?.zipCode}
                      </span>
                    </div>
                    <div className="mb-24">
                      <span className="text-15 font-semibold block">
                        {t('users.userDetails.labels.businessPhone')}
                      </span>
                      <span className="text-15 font-medium block text-grey-600">
                        {item?.user_businesses?.phoneNumber?.phoneNumber &&
                          item?.user_businesses?.phoneNumber?.countryCode.charAt(0) !== '+' &&
                          '+'}
                        {item?.user_businesses?.phoneNumber?.countryCode}{' '}
                        {item?.user_businesses?.phoneNumber?.phoneNumber}
                      </span>
                    </div>
                    <div className="mb-24">
                      <span className="text-15 font-semibold block">
                        {t('users.userDetails.labels.businessEmail')}
                      </span>
                      <span className="text-15 font-medium block text-grey-600">
                        {item?.user_businesses?.email}
                      </span>
                    </div>
                    <div className="mb-24">
                      <span className="text-15 font-semibold block">
                        {t('users.userDetails.labels.businessType')}
                      </span>
                      <span className="text-15 font-medium block text-grey-600">
                        {item?.user_businesses?.business_types?.bType}
                      </span>
                    </div>
                    <div className="mb-24">
                      <span className="text-15 font-semibold block break-words">
                        {t('users.userDetails.labels.businessWebsite')}
                      </span>
                      <span className="text-15 font-medium block text-grey-600">
                        {item?.user_businesses?.website}
                      </span>
                    </div>
                    <div className="mb-24">
                      <span className="text-15 font-semibold block">
                        {t('users.userDetails.labels.reviewSites')}
                      </span>
                      <span className="text-15 font-medium block text-black-600">
                        {item?.user_businesses?.user_business_review_platforms?.length > 0
                          ? item?.user_businesses?.user_business_review_platforms?.map(
                              (reviewPlatform, i) => (
                                <div
                                  className="block border-b-2 border-dashed pb-12 mt-12 last:border-b-0"
                                  key={i}
                                >
                                  <div className="items-center flex">
                                    <img
                                      width="20"
                                      className="mr-5"
                                      src={reviewPlatform?.logo}
                                      alt="--"
                                    />{' '}
                                    <div>
                                      <Typography className="font-semibold">
                                        {reviewPlatform?.name ? reviewPlatform?.name : '-'}
                                      </Typography>
                                      <Typography>{item?.user_businesses?.address}</Typography>
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          : '-'}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
          {inviteBusinessUserData.length === 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.1 } }}
              className="flex flex-1 items-center justify-center h-full"
            >
              <div className="flex justify-center w-full items-center my-80 ">
                <div>
                  <img
                    src="assets/images/business/business1.png"
                    className="m-auto opacity-100 max-w-200"
                    alt="..."
                  />
                  <Typography
                    color="text-black"
                    variant="h5"
                    className="text-24 py-12 font-bold text-center "
                  >
                    {t('users.userDetails.messages.noInvitedBusiness')}
                  </Typography>
                  <div>
                    <Typography
                      color="text-black"
                      variant="h6"
                      className="text-center tracking-normal text-black opacity-100 "
                    >
                      {t('users.userDetails.messages.inviteBusinessMessage')}
                    </Typography>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </>
      ) : (
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {isEditInviteBusiness && (
              <>
                <Button
                  className="text-18 absolute right-[131px] top-[-88px] px-32 disabled:text-black"
                  variant="contained"
                  color="secondary"
                  disabled={
                    !isValid ||
                    refreshIcon ||
                    errorMessage === false ||
                    phoneValue?.length === 0 ||
                    (phoneValue &&
                      phones?.dialCode &&
                      phoneValue.slice(phones?.dialCode?.length)?.length === 0)
                  }
                  type="submit"
                >
                  {t('common.save')}
                </Button>
                {refreshIcon && (
                  <CircularProgress
                    size={24}
                    className="text-darkgreen absolute right-[168px] top-[-80px] mx-auto"
                  />
                )}
              </>
            )}
            <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-32">
              {inviteBusinessUserData?.map((business, index) => {
                return (
                  <div key={index}>
                    <h4 className="font-bold text-20 mb-24 flex items-center">
                      {t('users.userDetails.labels.business')} {index + 1}{' '}
                    </h4>
                    <div className="mb-24">
                      <Controller
                        name={`businesses[${index}].user_businesses.name`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t('common.businessName')}
                            type="text"
                            variant="outlined"
                            fullWidth
                            error={!!errors?.businesses?.[index]?.user_businesses?.name}
                            helperText={errors?.businesses?.[index]?.name?.message}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-24">
                      <Controller
                        name={`businesses[${index}].user_businesses.address`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t('common.address')}
                            type="text"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div className="mb-0">
                      <Controller
                        name={`businesses[${index}].user_businesses.countries`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="mb-24 max-w-sm w-full"
                            id="country"
                            value={business?.user_businesses?.countries?.id}
                            displayEmpty
                            error={!!errors.country}
                            onChange={(e) =>
                              handleSelectCountry(e, business.user_businesses.id, index)
                            }
                          >
                            <MenuItem value={0} disabled>
                              {t('signUpPage.formField.country.selectCountry')}
                            </MenuItem>
                            {countryList?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </div>
                    <div className="mb-24">
                      <Controller
                        name={`businesses[${index}].user_businesses.states`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className="max-w-sm"
                            value={business?.user_businesses?.states?.id}
                            displayEmpty
                            id="state"
                            onChange={(e) => {
                              handleSelectState(e, index);
                            }}
                            error={!!errors?.businesses?.[index]?.user_businesses?.states}
                            helperText={errors?.businesses?.[index]?.states?.message}
                            fullWidth
                          >
                            <MenuItem value={0} disabled>
                              {t('signUpPage.formField.state.selectState')}
                            </MenuItem>
                            {business?.user_businesses?.countries?.states?.map((option) => {
                              return (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                      {errors?.businesses?.[index]?.user_businesses?.states && (
                        <Typography variant="caption" color="error">
                          {t('signUpPage.stateRequired')}
                        </Typography>
                      )}
                    </div>
                    <div className="mb-24">
                      <Controller
                        name={`businesses[${index}].user_businesses.city`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t('common.city')}
                            type="text"
                            variant="outlined"
                            fullWidth
                            error={!!errors?.businesses?.[index]?.user_businesses?.city}
                            helperText={errors?.businesses?.[index]?.city?.message}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-24">
                      <Controller
                        name={`businesses[${index}].user_businesses.zipCode`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t('common.zipCode')}
                            type="text"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div className="mb-24">
                      <Controller
                        name={`businesses[${index}].user_businesses.phoneNumber`}
                        control={control}
                        render={({ field }) => (
                          <PhoneInput
                            {...field}
                            placeholder={t('signUpPage.formField.phoneNumber.placeHolder')}
                            inputStyle={{
                              width: '100%',
                              height: '52px',
                            }}
                            className="w-full max-w-sm w-full"
                            name="phone_number"
                            required
                            value={field.value?.phoneNumber?.toString() || ''}
                            country={field.value?.isoCode || ''}
                            enableSearch
                            onChange={(value, data) => {
                              setPhoneValue(value);
                              setPhones(data);
                              handlePhoneChange(value, data, index);
                              const validPhoneNumber = handlePhoneNumberValidationCheck(
                                value,
                                data
                              );
                              setErrorMessage(validPhoneNumber);
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-24">
                      <Controller
                        name={`businesses[${index}].user_businesses.email`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={t('users.businessDetails.label.businessEmail')}
                            type="text"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div className="mb-24 relative">
                      <Controller
                        name={`businesses[${index}].user_businesses.business_types.bType`}
                        control={control}
                        render={({ field }) => (
                          <>
                            {business?.business_types?.id && (
                              <label className="label-businessType" htmlFor="businessType">
                                {t('business.selectBusinessType')}
                              </label>
                            )}
                            <ReactSelect
                              labelId="businessType"
                              className="select"
                              placeholder={t('business.selectBusinessType')}
                              id="businessType"
                              name="businessType"
                              value={{
                                label: business.user_businesses.business_types?.bType,
                                value: business.user_businesses.business_types?.id,
                              }}
                              onChange={(e) => {
                                handleSelectBusinessType(e, index);
                              }}
                              options={businessAttributesData}
                              components={{
                                MenuList: CustomMenuList,
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                    <div className="mb-24 z-0">
                      <Controller
                        name={`businesses[${index}].user_businesses.website`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="z-0"
                            label={t('users.businessDetails.label.businessWebsite')}
                            type="text"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default InviteUserDetails;
