import { Button, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { t } from 'i18next';
import history from '@history';

const Posts = () => {
  return (
    <div className="p-16 sm:p-24">
      <div className="flex items-center justify-between">
        <Typography className="text-20 sm:text-28 font-bold">{t('posts.title')}</Typography>
        <Button
          className="bg-darkgreen text-white font-semibold rounded-md hover:bg-darkgreen px-4 text-12 xs:text-14 xs:px-20"
          onClick={() => history.push('quick-hub/createNewPost')}
        >
          <AddIcon className="text-16 xs:text-24" />
          {t('posts.createNewPost')}
        </Button>
      </div>
      <div className="pt-12">
        <div className="w-360 p-12 border border-solid rounded-md">
          <img
            className="w-full h-320 border border-solid rounded-md"
            src="https://aaft.com/blog/wp-content/uploads/2024/09/pikaso_texttoimage_a-young-MiddleEastern-man-reviewing-a-marketing-ca-1024x701.jpeg"
            alt=""
          />
          <Typography className="font-bold text-20 pt-12">Offer Post</Typography>
          <Typography className="font-medium text-18 py-12">
            Buy one Google jetpack, get a second one free!!
          </Typography>
          <div className="flex items-center justify-between bg-gray-A500 rounded-lg p-4 w-full">
            <div className="">
              <p className="font-bold text-16">{t('posts.start')} -</p>
              <p className="font-medium text-14">23 Dec, 2024</p>
              <p className="font-medium text-14">06:00 Hrs</p>
            </div>
            <div className="flex items-center justify-center w-1/3 relative">
              <div className="w-full h-1 bg-black" />
              <div className="absolute left-0 w-8 h-8 bg-black rounded-full" />
              <div className="absolute right-0 w-8 h-8 bg-black rounded-full" />
            </div>
            <div className="">
              <p className="font-bold text-16">{t('posts.end')} -</p>
              <p className="font-medium text-14">30 Dec, 2024</p>
              <p className="font-medium text-14">06:00 Hrs</p>
            </div>
          </div>
          <Typography className="font-bold text-20 pt-12">
            20 {t('navigation.businesses')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Posts;
