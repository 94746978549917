import { useEffect, useState, useRef, useCallback } from 'react';
import Slider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import { selectDashboardData } from 'src/app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import { useParams } from 'react-router-dom';
import CommonPendingTask from 'src/app/main/CommonComponent/CommonPendingTask';
import { useSnackbar } from 'notistack';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import quickAdsQuery from 'src/app/main/query/quickAds';
import CommonFreeTrial from 'src/app/main/CommonComponent/CommonFreeTrial';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import FacebookPagesPopUp from 'src/app/main/CommonComponent/FacebookPagesPopUp';
import {
  formatDateWithTimeZone,
  handleApiRequest,
  FBConnectAds,
  getDecryptData,
  getEncryptedData,
} from '../../../common/common';
import InstagramConnectPopUp from '../InstagramConnectPopUp';
import NoBusinessPopUp from '../NoBusinessPopUp';

function Dashboards() {
  const vennRef = useRef();
  const { t } = useTranslation();
  const params = useParams();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const appKey = process.env.REACT_APP_WABA_APP_ID;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [cardsData, setCardsData] = useState([]);
  const [pageData, setPageData] = useState({});
  const [pages, setPages] = useState(false);
  const [instagramConnected, setInstagramConnected] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);

  const fetchCardDetails = useCallback(async () => {
    try {
      const payload = {
        query: quickAdsQuery.getAdsConnectApis,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getAdsConnectApis?.status === 200) {
        const cards = result?.getAdsConnectApis?.data || [];
        const filteredCards = cards?.filter((card) => card?.isConnect === false);
        setCardsData(filteredCards);
      }
    } catch (error) {
      console.error('error', error);
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) fetchCardDetails();
  }, [fetchCardDetails, userCurrentBusinessDetails?.id]);

  const saveMetaAdsDetails = useCallback(
    async (response, page) => {
      const data = getEncryptedData(
        JSON.stringify({
          pageDetails: {
            name: page?.name,
            id: page?.id,
            access_token: page?.access_token,
            category: page?.category,
          },
          accountId: response?.accountId,
          adsAccountId: response?.adsAccountId,
          accountDetails: {
            name: response?.accountDetails?.name,
            account_id: response?.accountDetails?.account_id,
            currency: response?.accountDetails?.currency,
            account_status: response?.accountDetails?.account_status,
            business_name: response?.accountDetails?.business_name,
            id: response?.accountDetails?.id,
          },
          tokenDetails: {
            access_token: response?.tokenDetails?.access_token,
            token_type: response?.tokenDetails?.token_type,
          },
          isFacebookConnect: true,
          isInstagramConnect: instagramConnected,
          userBusinessId: Number(userCurrentBusinessDetails?.id),
        })
      );

      try {
        const payload = {
          query: quickAdsQuery.saveMetaAdsDetails,
          variables: { data: String(data) },
        };
        const result = await handleApiRequest(payload);
        if (result?.saveMetaAdsDetails?.status === 200) {
          fetchCardDetails();
          enqueueSnackbar(result?.saveMetaAdsDetails?.message, {
            variant: 'success',
            autoHideDuration: 2000,
          });
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    },
    [enqueueSnackbar, fetchCardDetails, instagramConnected, userCurrentBusinessDetails?.id]
  );

  const checkInstagramConnectivity = useCallback(async (response) => {
    if (!response) {
      console.error('Response is undefined or null.');
      return;
    }
    try {
      const payload = {
        query: quickAdsQuery.checkInstagramConnectivity,
        variables: {
          accessToken: response?.access_token,
          pageId: response?.id,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.checkInstagramConnectivity?.status === 200) {
        setInstagramConnected(result?.checkInstagramConnectivity?.isConnected);
        result?.checkInstagramConnectivity?.isConnected ? setOpenPopUp(false) : setOpenPopUp(true);
      } else {
        console.error(
          result?.checkInstagramConnectivity?.errorMessage,
          result?.checkInstagramConnectivity?.errorCode
        );
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }, []);

  const verifyMetaAdsCode = useCallback(
    async (response) => {
      setIsLoading(true);
      try {
        const payload = {
          query: quickAdsQuery.verifyMetaAdsCode,
          variables: {
            accessToken: response?.accessToken,
          },
        };

        const result = await handleApiRequest(payload);
        if (result?.verifyMetaAdsCode?.status === 200) {
          setPageData(JSON.parse(getDecryptData(result?.verifyMetaAdsCode?.data)));
          setPages(true);
        } else {
          console.error(
            result?.verifyMetaAdsCode?.errorMessage,
            result?.verifyMetaAdsCode?.errorCode
          );
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
      setIsLoading(false);
    },
    [setPageData]
  );

  const handleLogin = (socialName) => {
    if (socialName === 'Facebook') {
      FBConnectAds(socialName, verifyMetaAdsCode, appKey);
    }
  };

  const settings = {
    dots: true,
    infinite: cardsData?.length > 1,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,

    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  if (loading) {
    return <FuseLoading />;
  }

  const firstMetricsData = [
    {
      id: 1,
      icon: '👁️',
      value: '1205',
      label: 'Impressions',
    },
    {
      id: 2,
      icon: '📈',
      value: '9876',
      label: 'Total Reach',
    },
    {
      id: 3,
      icon: '📊',
      value: '76.5%',
      label: 'Engagement Rate',
    },
    {
      id: 4,
      icon: '🖱️',
      value: '3450',
      label: 'Clicks',
    },
  ];

  const channels = [
    {
      name: 'Facebook',
      clicks: 21000,
      icon: 'https://cdn-icons-png.flaticon.com/512/733/733547.png',
    },
    {
      name: 'Instagram',
      clicks: 999,
      icon: 'https://cdn-icons-png.flaticon.com/512/733/733558.png',
    },
  ];

  const campaigns = [
    {
      id: 1,
      dateCreated: '10 Nov, 2024',
      post: 'Winter Sale',
      campaignName: 'Winter Sale',
      startDate: '10 Nov, 2024',
      endDate: '10 Nov, 2024',
      status: 'Active',
      statusColor: 'bg-green-200 text-green-800',
      impression: 10265,
      click: 4111,
    },
    {
      id: 2,
      dateCreated: '10 Nov, 2024',
      post: 'Winter Sale',
      campaignName: 'Winter Sale',
      startDate: '10 Nov, 2024',
      endDate: '10 Nov, 2024',
      status: 'Completed',
      statusColor: 'bg-pink-200 text-pink-800',
      impression: 10265,
      click: 4111,
    },
    {
      id: 3,
      dateCreated: '10 Nov, 2024',
      post: 'Winter Sale',
      campaignName: 'Winter Sale',
      startDate: '--',
      endDate: '--',
      status: 'Draft',
      statusColor: 'bg-yellow-200 text-yellow-800',
      impression: 10265,
      click: 4111,
    },
    {
      id: 4,
      dateCreated: '10 Nov, 2024',
      post: 'Winter Sale',
      campaignName: 'Winter Sale',
      startDate: '10 Nov, 2024',
      endDate: '10 Nov, 2024',
      status: 'Scheduled',
      statusColor: 'bg-blue-200 text-blue-800',
      impression: 10265,
      click: 4111,
    },
    {
      id: 5,
      dateCreated: '10 Nov, 2024',
      post: 'Winter Sale',
      campaignName: 'Winter Sale',
      startDate: '10 Nov, 2024',
      endDate: '--',
      status: 'Draft',
      statusColor: 'bg-yellow-200 text-yellow-800',
      impression: 10265,
      click: 4111,
    },
  ];

  const campaignData = [
    { label: 'Summer Sale', value: 222 },
    { label: 'Winter Clearance', value: 201 },
    { label: 'Black Friday Deal', value: 170 },
    { label: 'Spring Collection', value: 126 },
    { label: 'Winter Clearance', value: 106 },
  ];

  function getChart(data, names, color) {
    const chart = {
      series: [
        {
          name: 'Campaign Data',
          data,
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '45px',
            borderRadius: 15,
            borderRadiusApplication: 'end',
          },
        },
        colors: [color],
        dataLabels: {
          enabled: true,
          position: 'end',
          offsetX: 0,
          style: {
            fontSize: '16px',
            fontWeight: 500,
            colors: ['#222'],
          },
          formatter(val) {
            return val;
          },
        },
        xaxis: {
          categories: names,
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: true,
            align: 'left',
            offsetX: -20,
            style: {
              fontSize: '16px',
              fontWeight: 500,
              colors: ['#2A75EE'],
            },
          },
        },
        grid: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    };

    return (
      <div id="chart" style={{ width: '100%' }}>
        <ReactApexChart options={chart.options} series={chart.series} type="bar" height={300} />
      </div>
    );
  }

  const maxClicks = Math.max(...channels.map((channel) => channel.clicks));

  function formatNumber(count) {
    if (count >= 1000000) {
      return `${(count / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
    }
    if (count >= 1000) {
      return `${(count / 1000).toFixed(1).replace(/\.0$/, '')}k`;
    }
    return count;
  }

  return (
    <div className="p-20 lg:p-24 h-full l-padding">
      {isLoading ? (
        <div>
          <FuseLoading />
        </div>
      ) : (
        <div className="flex flex-col gap-32">
          <CommonFreeTrial callFrom="quickAds" productName="Quick Ads" packageBundle={7} />
          <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-0 sm:gap-x-32 gap-y-28">
            <div className="col-span-2">
              <div className="flex flex-col mb-24 items-center gap-32">
                <CommonPendingTask
                  productType="quick_ads"
                  bgColor="quick-social"
                  buttonColor="quickSocial"
                  videoImg="/assets/images/icon/playVideoIcon-review.svg"
                />
              </div>
              <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 md:gap-20 gap-10 mb-24">
                {firstMetricsData &&
                  firstMetricsData?.length > 0 &&
                  firstMetricsData?.map((metricsValue, i) => (
                    <div
                      className="bg-white rounded-md p-24 border-1 border-solid border-grey-A500 shadow"
                      key={i}
                    >
                      <div className="flex items-center">
                        <img
                          src="https://app-uat.quickreviews.click/assets/images/icon/todayConversation.svg"
                          width={56}
                          height={56}
                          className="h-auto max-w-full mr-10"
                          alt="..."
                        />
                        <div>
                          <span className="block font-semibold text-24 mb-6">
                            {metricsValue?.value}
                          </span>
                          <span className="block font-semibold text-14">{metricsValue?.label}</span>
                        </div>
                      </div>
                      setOpenPopUp(false);
                    </div>
                  ))}
              </div>
              <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 md:gap-20 gap-10 mb-32">
                <div>
                  <div className="bg-white rounded-md p-24 border-1 border-solid border-grey-A500 mb-20 shadow">
                    <div className="flex items-center">
                      <img
                        src="https://app-uat.quickreviews.click/assets/images/icon/todayConversation.svg"
                        width={56}
                        height={56}
                        className="h-auto max-w-full mr-10"
                        alt="..."
                      />
                      <div>
                        <span className="block font-semibold text-24 mb-6">$35,020</span>
                        <span className="block font-semibold text-14">Total Amount Spend</span>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded-md p-24 border-1 border-solid border-grey-A500 shadow">
                    <div className="flex items-center">
                      <img
                        src="https://app-uat.quickreviews.click/assets/images/icon/todayConversation.svg"
                        width={56}
                        height={56}
                        className="h-auto max-w-full mr-10"
                        alt="..."
                      />
                      <div>
                        <span className="block font-semibold text-24 mb-6">$35,020</span>
                        <span className="block font-semibold text-14">Total Conversions</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-md p-12 border-1 border-solid border-grey-A500 shadow">
                  <h3 className="text-18 font-semibold mb-16 mt-0">Gender</h3>
                  <div id="venn" />
                  <ul>
                    <li style={{ color: '#6FCF97' }}>Male: 2864</li>
                    <li style={{ color: '#F2C94C' }}>Female: 3552</li>
                    <li style={{ color: '#9B51E0' }}>Others: 416</li>
                  </ul>
                </div>
                <div className="bg-white rounded-md p-12 border-1 border-solid border-grey-A500 shadow">
                  <h3 className="text-18 font-semibold mb-16 mt-0">Top Channel (Click)</h3>
                  {channels.map((channel, index) => (
                    <div key={index} className="flex items-center mt-32">
                      <div
                        className="flex-grow h-32 rounded-md bg-quick-social"
                        style={{
                          width: `${(channel.clicks / maxClicks) * 100}%`,
                          maxWidth: 'calc(100% - 80px)',
                        }}
                      />
                      {/* Icon and text */}
                      <div className="flex items-center ml-4">
                        <div className="w-44 h-44 border-2 -ml-20 bg-white border-white rounded-full">
                          <div className="w-40 h-40 rounded-full flex items-center justify-center border-2 border-quick-social">
                            <img
                              src={
                                channel.name === 'Facebook'
                                  ? 'assets/images/quick-hub/quick-ads/facebook.png'
                                  : 'assets/images/quick-hub/quick-ads/instagram.png'
                              }
                              alt={channel.name}
                              className="w-20 h-20"
                            />
                          </div>
                        </div>
                        <span className="ml-2 font-semibold">{formatNumber(channel.clicks)}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex items-center justify-between mb-12 ">
                <h3 className="text-20 font-bold mt-0">Recent Campaign</h3>
                <span
                  aria-hidden
                  className="text-20 font-bold capitalize text-quick-social cursor-pointer"
                >
                  See All
                </span>
              </div>

              <TableContainer
                component={Paper}
                className="rounded-md border-1 border-solid border-grey-A500 shadow"
              >
                <Table>
                  <TableHead className="border-b-2 border-grey-400">
                    <TableRow>
                      <TableCell className="font-bold text-18">
                        {t('quickCampaign.campaign.srNo')}
                      </TableCell>
                      <TableCell className="font-bold text-18">Date Created</TableCell>
                      <TableCell className="font-bold  text-18">Post</TableCell>
                      <TableCell className="font-bold text-18">Campaign Name</TableCell>
                      <TableCell className="font-bold  text-18">Start Date</TableCell>
                      <TableCell className="font-bold  text-18">End Date</TableCell>
                      <TableCell className="font-bold  text-18">{t('common.status')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {campaigns &&
                      campaigns?.map((data, index) => (
                        <TableRow
                          key={index}
                          className="cursor-pointer hover:bg-quick-social-100 hover:bg-opacity-30 border-b-2"
                        >
                          <TableCell className="font-medium text-16 p-12">{index + 1}</TableCell>
                          <TableCell className="font-medium text-16 p-12">
                            {formatDateWithTimeZone(data?.dateCreated, 'MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="font-medium text-16 p-12">Post</TableCell>
                          <TableCell className="font-medium text-16 p-12">
                            {data?.campaignName}
                          </TableCell>

                          <TableCell className="font-medium text-16 p-12">
                            {formatDateWithTimeZone(data?.startDate, 'MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="font-medium text-16 p-12">
                            {formatDateWithTimeZone(data?.endDate, 'MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="items-center justify-center p-12 border-b-0">
                            <span
                              className={`${
                                data?.status === 'Scheduled'
                                  ? 'bg-quick-social-100 text-quick-social border-1 border-quick-social'
                                  : data?.status === 'Active'
                                  ? 'bg-green-100 text-quick-review border-1 border-quick-review'
                                  : data?.status === 'Completed'
                                  ? 'bg-[#f1cdea] text-[#C837AB] border-1 border-[#C837AB]'
                                  : 'bg-yellow-100 text-quick-chat border-1 border-quick-chat'
                              } rounded-full text-14 font-medium text-center px-10 py-5`}
                            >
                              {data?.status}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="flex flex-col gap-32 w-full items-center">
              {cardsData && cardsData?.length > 0 && (
                <div className="rounded-md relative shadow w-full">
                  <Slider {...settings}>
                    {cardsData?.map((card, index) => (
                      <div className="pr-0 md:pr-16" key={index}>
                        {card && (
                          <div
                            className="flex gap-16 items-center w-full min-h-200 max-h-200 justify-start p-12 md:p-16 rounded-md bg-blue"
                            style={{
                              backgroundImage:
                                card?.name === 'Facebook'
                                  ? "url('/assets/images/background/FBBackground.png')"
                                  : card?.name === 'Instagram'
                                  ? "url('/assets/images/background/InstaBackground.png')"
                                  : '',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                            }}
                          >
                            <div>
                              <h1
                                className="mb-16 text-20 md:text-24 font-semibold text-white"
                                style={{ color: card?.textColor || 'white' }}
                              >
                                {card?.title}
                              </h1>
                              <Button
                                className="text-12 md:text-16 text-center rounded-md bg-white text-black min-h-28 h-28 min-w-136 hover:text-white"
                                variant="contained"
                                color="quickSocial"
                                size="small"
                                onClick={() => handleLogin(card?.name)}
                              >
                                {card?.buttonName}
                              </Button>
                            </div>
                            <div className="w-1/3">
                              <img
                                className="hidden w-192 sm:block"
                                src={card?.bgImage}
                                alt="Card"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </Slider>
                </div>
              )}

              <div className="bg-white rounded-md relative border-1 border-solid border-grey-A500 shadow md:p-20 p-10 w-full">
                <h3 className="text-20 font-bold">Top Campaigns (Clicks)</h3>
                {getChart(
                  campaignData.map((item) => item.value),
                  campaignData.map((item) => item.label),
                  '#219EBC'
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {pageData?.pageDetails && pages ? (
        <FacebookPagesPopUp
          pageData={pageData}
          open={pages}
          onClose={() => setPages(false)}
          checkInstagramConnectivity={checkInstagramConnectivity}
          saveMetaAdsDetails={saveMetaAdsDetails}
          setPages={setPages}
        />
      ) : (
        <NoBusinessPopUp open={pages} onClose={() => setPages(false)} setPages={setPages} />
      )}
      {openPopUp && (
        <InstagramConnectPopUp pageData={pageData} open={openPopUp} setOpenPopUp={setOpenPopUp} />
      )}
    </div>
  );
}
export default Dashboards;
