import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import history from '@history';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FuseLoading from '@fuse/core/FuseLoading';
import { handleApiRequest, imageDataToServer } from 'src/app/main/common/common';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { selectDashboardData } from 'app/store/userSlice';
import quickAdsQuery from 'src/app/main/query/quickAds';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Toolbar,
  Icon,
  Box,
  CircularProgress,
} from '@mui/material';
import ChoosePlatform from './choose-platform';
import AudienceSegmentBudget from './audienceSegment-budget';
import ChooseMedia from './choose-media';
import AdCopy from './adCopy';

const platforms = [
  {
    id: 1,
    name: 'Facebook',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg',
    enabled: true,
  },
  {
    id: 2,
    name: 'Instagram',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png',
    enabled: true,
  },
];

function CreateAds() {
  const location = useLocation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const { data, campaignName, draftId } = location.state;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [allFeaturesTrialDataId, setAllFeaturesTrialDataId] = useState();
  const [scheduledDate, setScheduledDate] = useState();
  const [scheduledTime, setScheduledTime] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [budgetType, setBudgetType] = useState('daily_budget');
  const [amount, setAmount] = useState(0);
  const [platformStates, setPlatformStates] = useState(platforms);
  const [budgetAmount, setBudgetAmount] = useState(100);
  const [addedInterests, setAddedInterests] = useState([]);
  const [addedAreas, setAddedAreas] = useState([]);
  const [timeRange, setTimeRange] = useState([7, 18]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [ageRange, setAgeRange] = useState([20, 38]);
  const [mediaFiles, setMediaFiles] = useState(null);
  const [selected, setSelected] = useState();
  const [headline, setHeadline] = useState('');
  const [primaryText, setPrimaryText] = useState('');
  const [instantFormTitle, setInstantFormTitle] = useState('Form Title');
  const [instantHeadline, setInstantHeadline] = useState('');
  const [instantSelected, setInstantSelected] = useState([{ key: 'full_name', type: 'FULL_NAME' }]);
  const [instantCTA, setInstantCTA] = useState();
  const [CTA, setCTA] = useState();
  const [frequency, setFrequency] = useState(1);
  const [gender, setGender] = useState(3);
  const [from, setFrom] = useState('');
  const [adsCampaignId, setAdsCampaignId] = useState();
  const [openPreview, setOpenPreview] = useState(false);
  const [preview, setPreview] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [instantForm, setInstantForm] = useState(false);
  const [mobileApp, setMobileApp] = useState(null);
  const [appName, setAppName] = useState(null);
  const [endDate, setEndDate] = useState(false);
  const [scheduleEndDate, setScheduleEndDate] = useState();
  const [scheduleEndTime, setScheduleEndTime] = useState();
  const [phoneValue, setPhoneValue] = useState();
  const [website, setWebsite] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [trialStatus, setTrialStatus] = useState({
    social: false,
    chat: false,
    campaign: false,
  });

  const interest = addedInterests
    ?.filter((int) => int?.type === 'interests')
    ?.map((area) => ({ id: area?.id, name: area?.name }));

  const behavior = addedInterests
    ?.filter((int) => int?.type === 'behaviors')
    ?.map((area) => ({ id: area?.id, name: area?.name }));

  const countryKeys = addedAreas
    ?.filter((area) => area?.type === 'country')
    ?.map((area) => area?.key);

  const state = addedAreas
    ?.filter((area) => area?.type === 'region')
    ?.map((area) => ({ key: area?.key, name: area?.name }));

  const city = addedAreas
    ?.filter((area) => area?.type === 'city')
    ?.map((area) => ({ key: area?.key, name: area?.name }));

  const enabledPlatformNames = platformStates
    ?.filter((platform) => platform?.enabled)
    ?.map((platform) => platform?.name?.toLowerCase());

  const formatDateTime = (date, time) => {
    const originalDate = new Date(date);
    const formattedDat = originalDate?.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const [month, day, year] = formattedDat.split('/');
    const desiredFormat = `${year}-${month}-${day}`;

    const setTime = time.toLocaleTimeString();

    const dateTimeString = `${desiredFormat} ${setTime}`;
    const dateObj = new Date(dateTimeString);
    dateObj?.setHours(dateObj.getHours() + 5);
    dateObj?.setMinutes(dateObj.getMinutes() + 30);
    const offset = dateObj.getTimezoneOffset();
    const hoursOffset = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
    const minutesOffset = String(Math.abs(offset) % 60).padStart(2, '0');
    const sign = offset > 0 ? '-' : '+';
    const formattedDate = dateObj
      ?.toISOString()
      ?.replace(/:\d{2}\.\d{3}Z$/, '')
      ?.concat(`${sign}${hoursOffset}:${minutesOffset}`);
    return formattedDate;
  };

  const publishAds = useCallback(async () => {
    try {
      if (draftId) {
        const payload = {
          query: quickAdsQuery.publishAds,
          variables: {
            adsCampaignId: draftId,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.publishAds?.status === 200) {
          enqueueSnackbar(result?.publishAds?.message, {
            variant: 'success',
            autoHideDuration: 2000,
          });
          history.push('/quick-ads/ads-campaign/');
        } else {
          enqueueSnackbar(result?.publishAds?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      }
    } catch {
      console.error('An error occurred');
    }
  }, [draftId, enqueueSnackbar]);

  const fetchAdsPreview = useCallback(
    async (Id) => {
      setRefresh(true);
      try {
        if (adsCampaignId && activeTab) {
          const payload = {
            query: quickAdsQuery.getAdsPreview,
            variables: {
              adsCampaignId,
              viewFor: activeTab === 0 ? 'FACEBOOK' : 'INSTAGRAM',
            },
          };
          const result = await handleApiRequest(payload);
          if (result?.getAdsPreview?.status === 200) {
            setPreview(result?.getAdsPreview?.data);
          }
        }
      } catch {
        console.error('An error occurred');
      }
      setRefresh(false);
    },
    [activeTab, adsCampaignId]
  );

  useEffect(() => {
    fetchAdsPreview();
  }, [fetchAdsPreview]);

  const createAndUpdateAdsCampaign = useCallback(
    async (onClick) => {
      setRefresh(true);
      try {
        const payload = {
          query: quickAdsQuery.createAndUpdateAdsCampaign,
          variables: {
            adDraftId: draftId,
            userBusinessId: Number(userCurrentBusinessDetails?.id),
            campaign: {
              campaignName,
              objectiveId: data?.id,
            },
            adSets: {
              geoLocation:
                countryKeys?.length > 0 || city?.length > 0 || state?.length > 0
                  ? {
                      countries: countryKeys?.length > 0 ? countryKeys : null,
                      cities: city?.length > 0 ? city : null,
                      regions: state?.length > 0 ? state : null,
                    }
                  : null,
              ageRange: {
                maxAge: ageRange[1],
                minAge: ageRange[0],
              },
              publisherPlatforms: enabledPlatformNames,
              startDate:
                scheduledTime && scheduledDate
                  ? formatDateTime(scheduledDate, scheduledTime)
                  : null,

              endDate:
                scheduleEndDate && scheduleEndTime
                  ? formatDateTime(scheduleEndDate, scheduleEndTime)
                  : null,
              totalBudget: Number(budgetAmount) || null,
              bidAmount: Number(amount) || null,
              budgetType,
              interests: interest,
              behaviors: behavior,
              billingOn: 'IMPRESSIONS',
              destinationId: Number(selected) || null,
            },
            adCreative: {
              description: primaryText || '',
              title: headline || '',
              media: mediaFiles,
              destinationId: Number(selected) || null,
              mediaUrl: null,
              CTA,
              phoneNumber: phoneValue ? `+${phoneValue}` : null,
              link: website,
              instantForm: {
                headline: instantHeadline,
                formName: instantFormTitle,
                questions: instantSelected,
                CTA: instantCTA,
              },
            },
          },
        };
        const result =
          from === 'preview' ? await imageDataToServer(payload) : await handleApiRequest(payload);
        if (result?.createAndUpdateAdsCampaign?.status === 200) {
          setInstantForm(false);
          setAdsCampaignId(result?.createAndUpdateAdsCampaign?.data?.adDraftId);
          if (onClick === 'preview') {
            fetchAdsPreview();
          }
          if (onClick === 'Draft') {
            history.push('quick-ads/ads-campaign/');
          }
        } else {
          enqueueSnackbar(result?.createAndUpdateAdsCampaign?.errorMessage, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch {
        console.error('An error occurred');
      }
      setRefresh(false);
    },

    [
      CTA,
      ageRange,
      amount,
      behavior,
      budgetAmount,
      budgetType,
      campaignName,
      city,
      countryKeys,
      data?.id,
      draftId,
      enabledPlatformNames,
      enqueueSnackbar,
      fetchAdsPreview,
      from,
      headline,
      instantCTA,
      instantFormTitle,
      instantHeadline,
      instantSelected,
      interest,
      mediaFiles,
      phoneValue,
      primaryText,
      scheduleEndDate,
      scheduleEndTime,
      scheduledDate,
      scheduledTime,
      selected,
      state,
      userCurrentBusinessDetails?.id,
      website,
    ]
  );

  const steps = [
    t('quickAds.choosePlatform&Schedule'),
    t('quickAds.audienceSegment'),
    t('quickAds.chooseMedia'),
    t('quickAds.adCopy&CTASelection'),
  ];

  if (loading) {
    return <FuseLoading />;
  }

  const handleClick = (index) => {
    setActiveStep(index);
  };

  return (
    <div className="p-16 lg:p-24 h-full">
      <div className="sm:flex justify-between lg:mb-20 mb-16">
        <h1 className=" md:text-28 text-20 font-semibold mb-16 sm:mb-0 flex items-center">
          {t('quickCampaigns.createCampaigns')}
        </h1>
        <Button
          className="text-16 font-medium rounded-md px-28 disabled:bg-gray flex"
          variant="outlined"
          color="quickSocial"
        >
          {t('quickAds.discardCampaign')}
          <DeleteOutlineIcon className="text-20 text-black ml-10 " />
          {refreshIcon && (
            <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
          )}
        </Button>
      </div>
      <div>
        <div className="flex mb-32">
          {steps?.map((label, index) => (
            <React.Fragment key={index}>
              <div className="flex flex-row max-h-72">
                <div className="flex flex-col gap-20">
                  <Button
                    className={`border-1 border-solid text-14 sm:text-16 font-medium px-20 py-5 hover:bg-quick-social hover:border-quick-social hover:text-white ${
                      activeStep === index
                        ? 'border-quick-social bg-quick-social text-white'
                        : 'border-black disabled:border-grey-300'
                    } `}
                    onClick={() => handleClick(index)}
                    disabled={
                      (!scheduledTime && label === 'Audience Segment') ||
                      (addedInterests?.length <= 0 && label === 'Choose Media') ||
                      (mediaFiles === null && label === 'Ad Copy & CTA Selection')
                    }
                  >
                    <Icon className="mr-5">{t('quickAds.groups')}</Icon>
                    <p className="text-center">{label}</p>
                  </Button>
                </div>
                {index < steps.length - 1 && (
                  <div className="w-36 sm:w-96 h-1 mt-20 mx-12 sm:mx-10 my-2 relative bg-grey-800 before:w-5 before:h-5 before:bg-black before:inline-block before:rounded-full before:absolute before:-mt-2 after:w-5 after:h-5 after:bg-black after:inline-block after:rounded-full after:absolute after:-mt-2 after:right-0" />
                )}
              </div>
            </React.Fragment>
          ))}
        </div>

        <div>
          {activeStep === 0 && (
            <Box className="max-w-786 w-full">
              <ChoosePlatform
                setActiveStep={setActiveStep}
                scheduledDate={scheduledDate}
                setScheduledDate={setScheduledDate}
                scheduledTime={scheduledTime}
                setScheduledTime={setScheduledTime}
                budgetType={budgetType}
                setBudgetType={setBudgetType}
                amount={amount}
                setAmount={setAmount}
                platformStates={platformStates}
                setPlatformStates={setPlatformStates}
                budgetAmount={budgetAmount}
                setBudgetAmount={setBudgetAmount}
                refreshIcon={refreshIcon}
                setRefreshIcon={setRefreshIcon}
                createAndUpdateAdsCampaign={createAndUpdateAdsCampaign}
                setFrom={setFrom}
                endDate={endDate}
                setEndDate={setEndDate}
                scheduleEndDate={scheduleEndDate}
                setScheduleEndDate={setScheduleEndDate}
                scheduleEndTime={scheduleEndTime}
                setScheduleEndTime={setScheduleEndTime}
              />
            </Box>
          )}
          {activeStep === 1 && (
            <Box className="max-w-[982px] w-full">
              <AudienceSegmentBudget
                setActiveStep={setActiveStep}
                addedInterests={addedInterests}
                setAddedInterests={setAddedInterests}
                addedAreas={addedAreas}
                setAddedAreas={setAddedAreas}
                timeRange={timeRange}
                setTimeRange={setTimeRange}
                selectedDays={selectedDays}
                setSelectedDays={setSelectedDays}
                ageRange={ageRange}
                setAgeRange={setAgeRange}
                createAndUpdateAdsCampaign={createAndUpdateAdsCampaign}
                gender={gender}
                setGender={setGender}
                setFrom={setFrom}
                budgetType={budgetType}
              />
            </Box>
          )}
          {activeStep === 2 && (
            <Box className="max-w-786 w-full">
              <ChooseMedia
                setActiveStep={setActiveStep}
                mediaFiles={mediaFiles}
                setMediaFiles={setMediaFiles}
                createAndUpdateAdsCampaign={createAndUpdateAdsCampaign}
                setFrom={setFrom}
              />
            </Box>
          )}
          {activeStep === 3 && (
            <Box>
              {/* <QuickCampaignsFeatures
              allFeaturesTrialDataId={allFeaturesTrialDataId}
              campaignTrialDone={trialStatus.campaign}
              surpriseMeCardData={surpriseMeCardData}
            /> */}
              <AdCopy
                createAndUpdateAdsCampaign={createAndUpdateAdsCampaign}
                mediaFiles={mediaFiles}
                data={data}
                selected={selected}
                setSelected={setSelected}
                headline={headline}
                setHeadline={setHeadline}
                primaryText={primaryText}
                setPrimaryText={setPrimaryText}
                CTA={CTA}
                setCTA={setCTA}
                frequency={frequency}
                setFrequency={setFrequency}
                setFrom={setFrom}
                openPreview={openPreview}
                setOpenPreview={setOpenPreview}
                preview={preview}
                setPreview={setPreview}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                instantFormTitle={instantFormTitle}
                setInstantFormTitle={setInstantFormTitle}
                instantHeadline={instantHeadline}
                setInstantHeadline={setInstantHeadline}
                instantSelected={instantSelected}
                setInstantSelected={setInstantSelected}
                instantCTA={instantCTA}
                setInstantCTA={setInstantCTA}
                instantForm={instantForm}
                setInstantForm={setInstantForm}
                mobileApp={mobileApp}
                setMobileApp={setMobileApp}
                appName={appName}
                setAppName={setAppName}
                phoneValue={phoneValue}
                setPhoneValue={setPhoneValue}
                website={website}
                setWebsite={setWebsite}
                publishAds={publishAds}
                refresh={refresh}
              />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
}
export default CreateAds;
