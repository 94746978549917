import Dashboards from './dashboard/Dashboards';
import AdsCampaign from './ads-campaign/AdsCampaign';
import AdsCampaignDetails from './ads-campaign/AdsCampaignDetails';
import CreateAds from './add-ads-campaign/create-ads';
import Leads from './ads-leads/Leads';

const quickAdsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'quick-ads/dashboard/:id?',
      element: <Dashboards />,
    },
    {
      path: 'quick-ads/ads-campaign/:id?',
      element: <AdsCampaign />,
    },
    {
      path: 'ads-campaign-details/:addId',
      element: <AdsCampaignDetails />,
    },
    {
      path: 'create-ads',
      element: <CreateAds />,
    },
    {
      path: 'quick-ads/ads-leads/:id?',
      element: <Leads />,
    },
  ],
};

export default quickAdsConfig;
