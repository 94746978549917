import React from 'react';

const ShowAdsPost = ({ open, onClose, image, position }) => {
  return (
    <>
      {open && (
        <div>
          <div
            style={{
              top: position.top,
              //   left: position.left,
              transform: 'translateX(178%)',
            }}
            aria-hidden
            onClick={onClose}
            className="arrow-bottom p-6 rounded-6 bg-[#E8E8E8] absolute z-40 w-150 h-150"
          >
            <img
              src={image}
              alt="Popup"
              style={{
                width: '150px',
                height: '120px',
                borderRadius: '4px',
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ShowAdsPost;
