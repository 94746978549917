import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import FuseLoading from '@fuse/core/FuseLoading';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import quickHub from '../../../../query/quickHub';
import { handleApiRequest } from '../../../../common/common';
import LabelOrBusinessDrawer from './LabelOrBusinessDrawer';
import NoData from '../../../matrices/noDataScreen';

const ReportMatrix = (props) => {
  const {
    selectLabelOrBusiness,
    groupType,
    setGroupType,
    setSelectLabelOrBusiness,
    setIsMatrix,
    selectCategory,
    customDates,
    setCustomDates,
    duration,
    setDuration,
    durationData,
  } = props;
  const matrixColor = ['#EBB32F', '#478AEF', '#0BA861'];
  const reviewDistributionColor = ['#DE3636', '#EBB32F', '#8B78DC', '#219EBC', '#0BA861'];
  const reportRef = useRef();
  const [reportMatrix, setReportMatrix] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openBusinessDrawer, setOpenBusinessDrawer] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [ratingMatrixData, setRatingMatrixData] = useState([
    {
      name: 'Average Ratings',
      data: [],
    },
  ]);
  const [reviewDistributionMatrixData, setReviewDistributionMatrixData] = useState([
    {
      name: '1 Star',
      data: [],
    },
    {
      name: '2 Star',
      data: [],
    },
    {
      name: '3 Star',
      data: [],
    },
    {
      name: '4 Star',
      data: [],
    },
    {
      name: '5 Star',
      data: [],
    },
  ]);
  const [reviewsMatrixData, setReviewsMatrixData] = useState([
    { name: 'TotalReviews', data: [] },
    { name: 'Negative', data: [] },
    { name: 'Neutral', data: [] },
    { name: 'Positive', data: [] },
  ]);

  const calculateStartDateAndEndDate = useCallback(() => {
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    const calculateDate = (offsetDays = 0, offsetMonths = 0) => {
      const today = new Date();
      const calculatedDate = new Date(today);
      if (offsetDays) calculatedDate.setDate(today.getDate() + offsetDays);
      if (offsetMonths) calculatedDate.setMonth(today.getMonth() + offsetMonths);
      return formatDate(calculatedDate);
    };
    switch (duration) {
      case 'custom':
        return {
          startDate: customDates?.from ? formatDate(new Date(customDates.from)) : null,
          endDate: customDates?.to ? formatDate(new Date(customDates.to)) : null,
        };
      case 'last7days':
        return {
          startDate: calculateDate(0),
          endDate: calculateDate(-7),
        };
      case 'lastMonth':
        return {
          startDate: calculateDate(0),
          endDate: calculateDate(0, -1),
        };
      default:
        return null;
    }
  }, [duration, customDates]);

  const fetchReport = useCallback(async () => {
    if (!openDrawer) {
      setLoading(true);
      try {
        const payload = {
          query: quickHub.getOnlineReputationReport,
          variables: {
            ...(groupType === 'business'
              ? {
                  userBusinessId: selectLabelOrBusiness?.map((business) => Number(business?.id)),
                }
              : {
                  labelId: selectLabelOrBusiness?.map((label) => Number(label?.id)),
                }),
            durationDate: duration && calculateStartDateAndEndDate(),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getOnlineReputationReport?.status === 200) {
          const data = result.getOnlineReputationReport?.data;
          setReportMatrix(data);
          const reviewCategories = [
            'numberOfReviews',
            'numberOfNegativeReviews',
            'numberOfNeutralReviews',
            'numberOfPositiveReviews',
          ];
          const reviewDistribution = [
            'oneStarReviewDistribution',
            'twoStarReviewDistribution',
            'threeStarReviewDistribution',
            'fourStarReviewDistribution',
            'fiveStarReviewDistribution',
          ];
          const reviewsData = reviewCategories?.map((category) => {
            return data?.map(
              (business) =>
                business.numberOfReviewByBusiness?.find((item) => item?.key === category)?.value ||
                0
            );
          });
          const reviewsDistributionData = reviewDistribution?.map((category) => {
            return data?.map(
              (business) =>
                business.reviewDistribution?.find((item) => item?.key === category)?.value || 0
            );
          });
          setReviewsMatrixData([
            { name: 'TotalReviews', data: reviewsData[0] },
            { name: 'Negative', data: reviewsData[1] },
            { name: 'Neutral', data: reviewsData[2] },
            { name: 'Positive', data: reviewsData[3] },
          ]);
          setReviewDistributionMatrixData([
            { name: '1 Star', data: reviewsDistributionData[0] },
            { name: '2 Star', data: reviewsDistributionData[1] },
            { name: '3 Star', data: reviewsDistributionData[2] },
            { name: '4 Star', data: reviewsDistributionData[3] },
            { name: '5 Star', data: reviewsDistributionData[4] },
          ]);
          setRatingMatrixData([
            {
              name: 'Average Ratings',
              data: data?.reduce((acc, rating) => {
                const values = rating?.starRatingByBusiness?.map((item) => item?.value) || [];
                return acc.concat(values);
              }, []),
            },
          ]);
        } else {
          console.error('Failed to fetch report:', result);
        }
      } catch (error) {
        console.error('Error fetching report:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [selectLabelOrBusiness, groupType, calculateStartDateAndEndDate, openDrawer, duration]);

  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  const BarChart = ({
    data,
    categories,
    color,
    tooltipCustom,
    columnSize,
    stackedValue,
    intersectValue,
  }) => {
    const chartOptions = {
      grid: {
        strokeDashArray: 3,
        show: true,
        forceNiceScale: false,
        padding: { bottom: 0 },
      },
      chart: {
        events: {
          mounted: (charts) => charts.windowResizeHandler(),
        },
        type: 'bar',
        stacked: stackedValue,
        parentHeightOffset: 0,
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          dataLabels: { position: 'right' },
          horizontal: false,
          borderRadius: 0,
          columnWidth: columnSize,
        },
      },
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'right',
        markers: { width: 12, height: 12, radius: 12 },
      },
      fill: { colors: color, opacity: 1 },
      colors: color,
      dataLabels: { enabled: false },
      xaxis: {
        axisBorder: { show: true, color: '#000', height: 1 },
        categories,
        show: true,
        axisTicks: { show: false },
        labels: { show: true, offsetY: 0 },
      },
      yaxis: {
        show: true,
        axisBorder: { show: true, color: '#000' },
        labels: {
          show: true,
          align: 'left',
          maxWidth: 350,
          position: 'left',
          offsetX: 0,
          style: { fontSize: '14px' },
        },
      },
      tooltip: {
        shared: false,
        intersect: intersectValue,
        custom: tooltipCustom,
      },
    };

    return (
      <ReactApexChart options={chartOptions} series={data} type="bar" height="400px" width="100%" />
    );
  };

  const Legend = ({ data, colors }) => (
    <div className="flex flex-wrap gap-8 xs:gap-40 items-center">
      {data.map((item, index) => (
        <div className="flex gap-4 items-center" key={index}>
          <div className="w-16 h-16 rounded-full" style={{ backgroundColor: colors[index] }} />
          <Typography className="text-14 sm:text-18 font-semibold">{item.name}</Typography>
        </div>
      ))}
    </div>
  );

  const handleDownload = () => {
    setRefreshIcon(true);
    if (reportRef.current) {
      html2canvas(reportRef.current)
        .then((canvas) => {
          /* eslint-disable new-cap */
          const pdf = new jsPDF('p', 'mm', 'a4');
          const imgData = canvas.toDataURL('image/png');
          pdf.addImage(imgData, 'PNG', 10, 10, 180, 160);
          pdf.save('report.pdf');
          setRefreshIcon(false);
        })
        .catch(() => {
          setRefreshIcon(false);
        });
    } else {
      setRefreshIcon(false);
      console.error('Invalid element provided as first argument');
    }
  };

  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };

  const handleDateChange = (key, value) => {
    setCustomDates((prev) => ({ ...prev, [key]: value }));
  };

  const handleResetGraph = () => {
    fetchReport();
    setOpenDialogue(false);
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="bg-gray-A500">
      <div className="sm:p-24 p-16">
        <div className="flex flex-wrap justify-between items-center">
          <Typography className="text-20 sm:text-28 font-bold">
            {t('navigation.reports')}
          </Typography>
          <div className="flex gap-12">
            <Button
              className="border w-160 border-solid bg-gray-A500 hover:bg-gray-A500 text-14 sm:text-16 font-semibold border-quick-hub text-quick-hub rounded-md"
              variant="contained"
              onClick={() => setOpenDrawer(true)}
            >
              <img className="w-28 h-28 pr-4" src="assets/images/icon/Editnew.svg" alt="" />
              {t('reports.editReport')}
            </Button>
            <Button
              className="border border-solid w-128 bg-gray-A500 hover:bg-gray-A500 text-14 sm:text-16 font-semibold border-quick-hub text-quick-hub rounded-md"
              variant="contained"
              onClick={() => setOpenDialogue(true)}
            >
              {' '}
              <img
                className="w-24 h-24 pr-4"
                src="assets/images/icon/generate_reviews.svg"
                alt=""
              />{' '}
              {t('reports.reset')}
            </Button>
          </div>
        </div>
        <div className="bg-white rounded-md border border-solid p-8 xs:p-16 mt-20">
          <div className="flex flex-wrap items-center justify-between pb-20">
            <h2 className="text-16 sm:text-24 font-bold mb-12">{selectCategory?.categoryName}</h2>
            {(reviewsMatrixData?.some((item) => item?.data?.length > 0) ||
              ratingMatrixData?.some((item) => item?.data?.length > 0) ||
              reviewDistributionMatrixData?.some((item) => item?.data?.length > 0)) && (
              <Button
                className="border border-solid w-144 bg-darkgreen-100 hover:bg-darkgreen-100 text-16 font-semibold border-quick-hub  rounded-md"
                variant="contained"
                onClick={handleDownload}
                disabled={refreshIcon}
              >
                <FileDownloadOutlinedIcon /> {t('qrCode.button.download')}
                {refreshIcon && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>
            )}
          </div>
          <div ref={reportRef}>
            <div className="bg-gray-A500 border border-solid rounded-md p-12">
              <div className="flex flex-wrap justify-between items-center">
                <Typography className="text-16 sm:text-20 font-semibold">
                  {t('reports.numberOfReviews')}
                </Typography>
                <Legend
                  data={reviewsMatrixData?.filter((item) => item?.name !== 'TotalReviews')}
                  colors={matrixColor}
                />
              </div>
              {reviewsMatrixData?.some((item) => item?.data?.length > 0) ? (
                <BarChart
                  data={reviewsMatrixData?.filter((item) => item?.name !== 'TotalReviews')}
                  categories={reportMatrix?.map((item) =>
                    groupType === 'business' ? item?.businessName : item?.label
                  )}
                  color={matrixColor}
                  tooltipCustom={({ series, dataPointIndex, w }) => {
                    const total = series?.map((s) => s[dataPointIndex])?.reduce((a, b) => a + b, 0);
                    const { seriesNames, colors } = w.globals;
                    return `
                      <div style="padding: 10px; font-size: 14px;">
                        <div style="font-weight: bold; margin-bottom: 8px;">
                          Total Reviews - <span style="color: #22C55E;">${total}</span>
                        </div>
                        ${series
                          ?.map(
                            (value, idx) => `
                          <div style="margin-bottom: 4px;">
                            <span style="color: ${colors[idx]}; font-weight: bold;">●</span>
                            ${seriesNames[idx]} - ${value[dataPointIndex]}
                          </div>
                        `
                          )
                          ?.join('')}
                      </div>
                    `;
                  }}
                  columnSize="10px"
                  stackedValue
                  intersectValue={false}
                />
              ) : (
                <NoData />
              )}
            </div>
            <div className="bg-gray-A500 border border-solid rounded-md mt-32 p-8">
              <div className="flex justify-between items-center">
                <Typography className="text-16 sm:text-20 font-semibold">
                  {t('reports.startRatingByBusiness')}
                </Typography>
              </div>
              {ratingMatrixData?.some((item) => item?.data?.length > 0) ? (
                <BarChart
                  data={ratingMatrixData}
                  categories={reportMatrix?.map((item) =>
                    groupType === 'business' ? item?.businessName : item?.label
                  )}
                  color={['#8B78DC']}
                  tooltipCustom={({ series, dataPointIndex }) => `
                    <div style="padding: 10px; font-size: 14px;">
                      <div style="font-weight: bold; margin-bottom: 8px;">
                        Average Rating
                      </div>
                      ${series
                        .map(
                          (value) => `
                        <div style="margin-bottom: 4px; display: flex; align-items: center; gap: 6px;">
                          <img src="assets/images/icon/Star.svg" alt="" style="width: 32px; height: 32px;" />
                          <span style="color:#0BA861; font-weight: bold; font-size: 32px">${value[dataPointIndex]}</span>
                        </div>
                      `
                        )
                        .join('')}
                    </div>
                  `}
                  columnSize="10px"
                  stackedValue
                  intersectValue={false}
                />
              ) : (
                <NoData />
              )}
            </div>
            <div className="bg-gray-A500 border border-solid rounded-md mt-32 p-8">
              <div className="flex flex-wrap justify-between items-center">
                <Typography className="text-16 sm:text-20 font-semibold">
                  {t('reports.reviewDistribution')}
                </Typography>
                <Legend data={reviewDistributionMatrixData} colors={reviewDistributionColor} />
              </div>
              {reviewDistributionMatrixData?.some((item) => item?.data?.length > 0) ? (
                <BarChart
                  stacked
                  data={reviewDistributionMatrixData}
                  categories={reportMatrix?.map((item) =>
                    groupType === 'business' ? item?.businessName : item?.label
                  )}
                  color={reviewDistributionColor}
                  tooltipCustom={({ series, seriesIndex, dataPointIndex, w }) => {
                    const value = series[seriesIndex][dataPointIndex];
                    return `
                      <div style="padding: 10px; font-size: 14px; border: 1px solid #ccc; border-radius: 5px; background: #fff;">
                        <div style="margin-bottom: 8px;">
                          <span style="font-weight: bold;">Rating(300)</span>
                        </div>
                        <div style="margin-bottom: 4px; display: flex; align-items: center; gap: 6px;">
                          <img src="assets/images/icon/Star.svg" alt="" style="width: 32px; height: 32px;" />
                          <span style="color:#0BA861; font-weight: bold; font-size: 32px">${value}</span>
                        </div>
                      </div>
                    `;
                  }}
                  columnSize="15px"
                  stackedValue={false}
                  intersectValue
                />
              ) : (
                <NoData />
              )}
            </div>
          </div>
        </div>
      </div>
      <Drawer className="" anchor="right" open={openDrawer} transitionDuration={700}>
        <div className="sm:p-24 p-16 sm:min-w-512">
          <Typography className="font-bold text-28">
            <Button
              className="min-w-16 w-16 hover:bg-transparent mr-8"
              onClick={() => setOpenDrawer(false)}
            >
              <ArrowBackIcon />
            </Button>
            {t('reports.editReport')}
          </Typography>
          <div className="border border-solid bg-white max-w-400 p-20 rounded-md my-20">
            <Typography className="text-16 font-semibold mb-12">
              {t('reports.selectDuration')}
            </Typography>
            <FormControl fullWidth>
              <Select
                value={duration}
                onChange={handleDurationChange}
                size="small"
                displayEmpty
                renderValue={(selected) => (
                  <span className="font-medium text-16">
                    {durationData?.find((item) => item?.value === selected)?.label ||
                      t('reports.selectDuration')}
                  </span>
                )}
              >
                {durationData?.map((item, index) => {
                  return (
                    <MenuItem value={item?.value} key={index}>
                      <FormControlLabel
                        value={item?.value}
                        control={<Radio checked={duration === item?.value} />}
                        label={<span className="font-medium text-16">{item?.label}</span>}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {duration === 'custom' && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box display="flex " justifyContent="space-between" gap={2} mt={4}>
                  <DatePicker
                    label={
                      <span className="font-semibold text-black text-18">{t('reports.from')}</span>
                    }
                    value={customDates.from}
                    onChange={(date) => handleDateChange('from', date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    label={
                      <span className="font-semibold text-black text-18">{t('reports.to')}</span>
                    }
                    value={customDates.to}
                    onChange={(date) => handleDateChange('to', date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </LocalizationProvider>
            )}
            <Typography className="my-20 text-16 font-semibold">
              {t('reports.selectGroup')}
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
                value={groupType}
                onChange={(e) => {
                  setGroupType(e.target.value);
                  setSelectLabelOrBusiness([]);
                }}
              >
                <FormControlLabel
                  value="business"
                  control={<Radio />}
                  label={<span className="font-semibold text-16">{t('reports.business')}</span>}
                />
                <FormControlLabel
                  value="label"
                  control={<Radio />}
                  label={<span className="font-semibold text-16">{t('reports.label')}</span>}
                />
              </RadioGroup>
            </FormControl>
            <Typography className="text-16 font-semibold mt-20">
              {groupType === 'business' ? t('reports.selectBusiness') : t('reports.selectLabel')}
            </Typography>
            <Button
              className="w-full rounded-md border hover:bg-transparent border-solid border-gray flex justify-between text-16 font-medium mt-20"
              variant="outlined"
              onClick={() => setOpenBusinessDrawer(true)}
            >
              {groupType === 'business' ? t('reports.selectBusiness') : t('reports.selectLabel')}{' '}
              <ArrowForwardIosIcon className="w-20" />
            </Button>
            <div className="mt-20">
              <Button
                className="w-full rounded-md text-white font-semibold text-16 bg-quick-hub hover:bg-quick-hub"
                variant="contained"
                disabled={selectLabelOrBusiness?.length === 0 || !duration}
                onClick={() => setOpenDrawer(false)}
              >
                {t('reports.createReport')}
              </Button>
            </div>
          </div>
        </div>
        <LabelOrBusinessDrawer
          openBusinessDrawer={openBusinessDrawer}
          setOpenBusinessDrawer={setOpenBusinessDrawer}
          setSelectLabelOrBusiness={setSelectLabelOrBusiness}
          selectLabelOrBusiness={selectLabelOrBusiness}
          setIsMatrix={setIsMatrix}
          groupType={groupType}
        />
      </Drawer>
      <Dialog
        open={openDialogue}
        aria-labelledby="delete-confirmation-dialog"
        classes={{
          paper: `m-24 p-28 text-center rounded-md`,
        }}
        PaperProps={{ style: { maxWidth: '460px' } }}
      >
        <IconButton
          className="absolute top-4 right-4"
          color="inherit"
          onClick={() => setOpenDialogue(false)}
        >
          <CloseIcon className="w-16 h-16 text-grey-700" />
        </IconButton>
        <div className="relative mt-32">
          <img
            src="assets/images/icon/reset.png"
            className="mx-auto mb-12"
            width="100"
            height="64"
            alt="..."
          />
        </div>
        <DialogTitle
          id="delete-confirmation-dialog"
          className="text-20 mb-20 p-0 font-bold sm:text-24"
        >
          {t('reports.resetGraphs')}
        </DialogTitle>
        <DialogContent className="p-0 mb-24">
          <div className="w-full flex justify-center items-center">
            <Typography className="text-14 sm:text-16 font-semibold text-black w-11/12">
              {t('reports.resetGraphMessage')}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions className="p-0 flex justify-between gap-14">
          <Button
            className="md:text-16 font-medium rounded-md w-full max-w-256"
            variant="outlined"
            color="secondary"
            onClick={() => setOpenDialogue(false)}
          >
            {t('common.cancel')}
          </Button>

          <Button
            className="md:text-16 font-medium rounded-md w-full max-w-256"
            variant="contained"
            color="secondary"
            onClick={() => handleResetGraph()}
          >
            {t('reports.reset')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReportMatrix;
