import {
  Button,
  FormControlLabel,
  Icon,
  Input,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import history from '@history';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import { useCallback, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useLocation } from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import MailIcon from '@mui/icons-material/Mail';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import businessData from '../../../query/business';
import {
  Message,
  capitalizeFirstLetter,
  getParamsId,
  handleApiRequest,
  handlePhoneNumberValidationCheck,
} from '../../../common/common';
import { storeCustomerList, storeSendSmsOrEmailDetails } from '../store/targetMessageSlice';
import { selectDashboardData } from '../../../../store/userSlice';
import reducer from '../store';
import userService from '../../../../services';
import AddContact from '../../waba/AddContact';

const SelectPlateFormType = () => {
  const [businessListData, setBusinessListData] = useState([]);
  const [limitAvailable, setLimitAvailable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [openCreateDialogue, setOpenCreateDialogue] = useState(false);
  const [contactList, setContactList] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [smsCount, setSmsCount] = useState({ sendCount: 0, totalAccessCount: 0 });
  const [selectedContact, setSelectedContact] = useState();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [errorMessage, setErrorMessage] = useState(true);
  const sendSmsOrEmailDetailsData = useSelector(
    ({ state }) => state.targetMessageSlice?.sendSmsOrEmailDetailsData
  );
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const loginUserData = userService.getUserData();

  const fetchCampignValidation = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      try {
        const payload = {
          query: businessData.campaignServiceValidation,
          variables: {
            campaignPlatformId: parseInt(sendSmsOrEmailDetailsData?.selectPlateFormType, 10),
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };

        const result = await handleApiRequest(payload);

        if (result?.campaignServiceValidation?.status === 200) {
          setLimitAvailable(result?.campaignServiceValidation);
          setSmsCount({
            sendCount: result?.campaignServiceValidation?.sendCount || 0,
            totalAccessCount: result?.campaignServiceValidation?.totalAccessCount || 0,
          });
        } else {
          console.error('Failed to fetch campaign validation data:', result);
        }
      } catch (error) {
        console.error('Error occurred during campaign validation:', error);
      }
    }
  }, [userCurrentBusinessDetails?.id, sendSmsOrEmailDetailsData?.selectPlateFormType]);

  useEffect(() => {
    fetchCampignValidation();
  }, [fetchCampignValidation]);
  useEffect(() => {
    const id = getParamsId();
    let targetMessageType = '';
    if (id) {
      targetMessageType =
        location?.pathname === `/invite-reviews/${id}` ? 'invite_reviews' : 'send_messages';
    } else {
      targetMessageType =
        location?.pathname === '/invite-reviews/' ? 'invite_reviews' : 'send_messages';
    }

    if (sendSmsOrEmailDetailsData?.targetMessageType !== targetMessageType) {
      dispatch(
        storeSendSmsOrEmailDetails({
          ...sendSmsOrEmailDetailsData,
          selectPlateFormType: 2,
          tabValue: 1,
          targetMessageType,
          businessId: 0,
          serviceType: 'individual_type',
        })
      );
    }
    if (sendSmsOrEmailDetailsData?.tabValue === 1) {
      dispatch(storeCustomerList({}));
    }
  }, [dispatch, location?.pathname, sendSmsOrEmailDetailsData, businessListData]);

  useEffect(() => {
    const fetchBusinessData = async () => {
      setLoading(true);
      const payload = {
        query: businessData.getBusinessBasedOnUserId,
      };
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.getBusinessBasedOnUserId?.status === 200) {
        setBusinessListData(result?.getBusinessBasedOnUserId?.data);
      }
    };
    fetchBusinessData();
  }, [dispatch]);

  const handleTabChange = async (event, newValue) => {
    dispatch(
      storeSendSmsOrEmailDetails({
        ...sendSmsOrEmailDetailsData,
        tabValue: newValue,
        serviceType: parseInt(newValue, 10) === 1 ? 'individual_type' : 'bulk_type',
      })
    );
  };

  const handlePlateFormChange = async (e) => {
    dispatch(
      storeSendSmsOrEmailDetails({
        ...sendSmsOrEmailDetailsData,
        selectPlateFormType: parseInt(e.target.value, 10),
      })
    );
  };

  const handleRedirect = () => {
    if (smsCount.sendCount >= 100) {
      const messageType =
        sendSmsOrEmailDetailsData?.selectPlateFormType === 2
          ? 'SMS'
          : sendSmsOrEmailDetailsData?.selectPlateFormType === 3
          ? 'WhatsApp'
          : 'Email';

      enqueueSnackbar(`Your ${messageType} limit has been exceeded.`, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    const data = {
      ...sendSmsOrEmailDetailsData,
      serviceType: 'individual_type',
      campaignPlatformId: parseInt(sendSmsOrEmailDetailsData?.selectPlateFormType, 10),
      businessId: parseInt(userCurrentBusinessDetails?.id, 10),
      reviewUrl: userCurrentBusinessDetails?.businessQRId,
      businessName: userCurrentBusinessDetails?.name,
    };
    dispatch(storeSendSmsOrEmailDetails(data));
    const id = getParamsId();
    if (sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews') {
      if (
        (parseInt(sendSmsOrEmailDetailsData.selectPlateFormType, 10) === 1 ||
          parseInt(sendSmsOrEmailDetailsData.selectPlateFormType, 10) === 2) &&
        id
      ) {
        history.push(`create-new-review/${id}`);
      } else if (
        (parseInt(sendSmsOrEmailDetailsData.selectPlateFormType, 10) === 1 ||
          parseInt(sendSmsOrEmailDetailsData.selectPlateFormType, 10) === 2) &&
        !id
      ) {
        history.push('create-new-review');
      } else if (parseInt(sendSmsOrEmailDetailsData.selectPlateFormType, 10) === 3 && id) {
        history.push(`SelectWhatsAppTemplate/${id}`);
      } else if (parseInt(sendSmsOrEmailDetailsData.selectPlateFormType, 10) === 3 && !id) {
        history.push('SelectWhatsAppTemplate/');
      }
    } else if (id) {
      history.push(`create-new-message/${id}`);
    } else {
      history.push('create-new-message');
    }
  };

  const handleNextRedirect = () => {
    const data = {
      ...sendSmsOrEmailDetailsData,
      serviceType: 'bulk_type',
      campaignPlatformId: parseInt(sendSmsOrEmailDetailsData?.selectPlateFormType, 10),
      businessId: parseInt(userCurrentBusinessDetails?.id, 10),
      reviewUrl: userCurrentBusinessDetails?.businessQRId,
      businessName: userCurrentBusinessDetails?.name,
    };
    dispatch(storeSendSmsOrEmailDetails(data));
    const id = getParamsId();
    if (sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews') {
      if (id) {
        history.push(`select-invite-review-customer/${id}`);
      } else {
        history.push(`select-invite-review-customer`);
      }
    } else if (id) {
      history.push(`select-send-message-customer/${id}`);
    } else {
      history.push('select-send-message-customer');
    }
  };

  const handleHistoryButton = () => {
    const id = getParamsId();
    if (sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews') {
      if (id) {
        history.push(`invite-review-history/${id}`);
      } else {
        history.push('invite-review-history');
      }
    } else if (id) {
      history.push(`send-message-history/${id}`);
    } else {
      history.push('send-message-history');
    }
  };

  const getSearchCampaignData = async (value) => {
    try {
      const payload = {
        query: businessData.searchCampaignContact,
        variables: {
          searchText: value,
          callFrom: 'whatsapp',
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.searchCampaignContact?.status === 200) {
        const data = result.searchCampaignContact?.data;
        const addUniqueId = data?.map((item) => ({ ...item, id: Math.random() * 100 }));
        setContactList(addUniqueId);
      }
    } catch (error) {
      console.log('Error fetching campaign data:', error);
    }
  };
  const handleSearchEvent = (event) => {
    const searchText = event.target.value;
    setSearchValue(searchText);
    getSearchCampaignData(searchText);
  };

  const clearSearch = () => {
    setSearchValue('');
    setContactList([]);
    getSearchCampaignData('');
  };

  const cancelDialogue = async () => {
    setOpenCreateDialogue(false);
  };

  const selectContact = (data) => {
    setSelectedContact(data);
    dispatch(
      storeSendSmsOrEmailDetails({
        ...sendSmsOrEmailDetailsData,
        firstName: data.name,
        lastName: data.name,
        email: data.email,
        phone: {
          dialCode: data.countryCode,
          phoneNumber: data.countryCode.concat(data.phoneNumber.toString()),
        },
      })
    );
  };

  const contactsRedirect = () => {
    const id = getParamsId();
    history.push(id ? `contacts/${id}` : 'contacts/');
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-24">
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold"
          >
            {sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews'
              ? t('inviteReviews.title')
              : t('sendMessage.title')}
          </Typography>
        </div>
        <div className="flex gap-24">
          <Button
            className="rounded px-20 text-darkgreen border border-solid px-40 hover:bg-transparent border-darkgreen"
            onClick={() => contactsRedirect()}
          >
            {t('inviteReviews.buttons.contacts')}
          </Button>
          <Button
            className="bg-darkgreen rounded px-40 text-white hover:bg-darkgreen"
            onClick={() => handleHistoryButton()}
          >
            {t('inviteReviews.buttons.history')}
          </Button>
        </div>
      </div>
      <div
        className={`bg-white px-20 md:px-36 py-20 rounded-md ${
          sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews' &&
          sendSmsOrEmailDetailsData?.serviceType === 'individual_type'
            ? 'lg:w-3/4'
            : 'lg:w-1/2'
        }  relative shadow`}
      >
        <Tabs
          value={parseInt(sendSmsOrEmailDetailsData?.tabValue, 10)}
          className="rounded-tl-md rounded-tr-md overflow-scroll"
          sx={{
            '& .MuiTabs-scroller': {
              overflow: 'scroll !important',
            },
          }}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            className="md:text-18 text-14 font-semibold px-5 md:px-16"
            value={1}
            label={t('inviteReviews.individualMessage')}
          />
          <Tab
            className="md:text-18 text-14 ml-24 font-semibold px-5 md:px-16"
            value={2}
            label={t('inviteReviews.bulkMessage')}
          />
        </Tabs>
        <div className="flex items-center my-20 justify-between">
          <Typography className="font-medium text-18">
            {t('inviteReviews.selectPlateForm')}
          </Typography>
          <Typography className="font-medium text-18 text-right mt-4">
            {sendSmsOrEmailDetailsData?.selectPlateFormType === 2
              ? `SMS: ${smsCount.sendCount} / ${smsCount.totalAccessCount}`
              : sendSmsOrEmailDetailsData?.selectPlateFormType === 3
              ? `WhatsApp: ${smsCount.sendCount} / ${smsCount.totalAccessCount}`
              : `Email: ${smsCount.sendCount} / ${smsCount.totalAccessCount}`}
          </Typography>
        </div>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={parseInt(sendSmsOrEmailDetailsData?.selectPlateFormType, 10)}
          onChange={handlePlateFormChange}
        >
          <div className="sm:flex justify-between gap-12 md:gap-24">
            <div className="border-2 border-[#707070] rounded-md p-10">
              <FormControlLabel value="2" control={<Radio />} label="SMS" />
              <Typography>{t('inviteReviews.smsMessage')}</Typography>
            </div>
            <div className="border-2 border-[#707070] rounded-md p-10 my-16 sm:my-0">
              <FormControlLabel value="1" control={<Radio />} label="Email" />
              <Typography>{t('inviteReviews.emailMessage')}</Typography>
            </div>
            {sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews' &&
              sendSmsOrEmailDetailsData?.serviceType === 'individual_type' && (
                <div className="border-2 border-[#707070] rounded-md p-10">
                  <FormControlLabel value="3" control={<Radio />} label="Whatsapp" />
                  <Typography>{t('inviteReviews.whatsappMessage')}</Typography>
                </div>
              )}
          </div>
        </RadioGroup>
        {sendSmsOrEmailDetailsData?.serviceType === 'individual_type' &&
          sendSmsOrEmailDetailsData?.selectPlateFormType === 3 && (
            <div className="border !border-gray mt-16 rounded px-8 items-center justify-center w-full">
              <Paper
                component={motion.div}
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                className="flex items-center w-full rounded-8 shadow-none"
              >
                <Input
                  placeholder={t('inviteReviews.searchMessage')}
                  className="flex flex-1 text-black text-14"
                  disableUnderline
                  name="searchText"
                  fullWidth
                  inputProps={{
                    'aria-label': 'Search',
                  }}
                  onChange={handleSearchEvent}
                  value={searchValue}
                />
                {searchValue ? (
                  <Icon
                    color="action"
                    className="cursor-pointer"
                    onClick={() => {
                      clearSearch();
                    }}
                  >
                    close
                  </Icon>
                ) : (
                  <Icon color="action">search</Icon>
                )}
              </Paper>
            </div>
          )}
        <div className="h-360">
          {searchValue ? (
            <div className="h-full">
              {contactList?.length > 0 ? (
                <div className="h-full shadow overflow-scroll">
                  {contactList?.map((item, i) => {
                    return (
                      <div
                        className={`px-12 pt-12 ${
                          selectedContact?.id === item.id && 'bg-darkgreen-100'
                        }`}
                        key={i}
                        onClick={() => selectContact(item)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            selectContact(item);
                          }
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <Typography className="font-semibold text-16">
                          {capitalizeFirstLetter(item.name)}
                        </Typography>
                        {item?.email && (
                          <Typography className="text-16 py-2">
                            <MailIcon className="font-semibold text-gray w-16" /> {item?.email}
                          </Typography>
                        )}
                        <Typography>
                          <AddIcCallIcon className="font-semibold text-gray text-16 w-16" />{' '}
                          {item.countryCode} {item.phoneNumber}
                        </Typography>
                        <hr className="mt-16" />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className=" h-full shadow mt-8 flex items-center justify-center">
                  <div className="text-center">
                    <Typography className="font-semibold text-20">
                      {t('inviteReviews.contactNotFound')}
                    </Typography>
                    <Typography className="py-12 sm:max-w-480">
                      {t('inviteReviews.contactNotFoundMessage')}
                    </Typography>
                    <Button
                      className="bg-darkgreen hover:bg-darkgreen text-white px-40 rounded-md"
                      onClick={() => setOpenCreateDialogue(true)}
                    >
                      {t('inviteReviews.addNewContact')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="mt-8">
                {parseInt(sendSmsOrEmailDetailsData?.tabValue, 10) === 1 && (
                  <div>
                    <div className="mt-12">
                      <Typography className="mt-12 mb-6">
                        {t('common.firstName')}
                        <span className=" text-2xl text-red-500">*</span>
                      </Typography>
                      <TextField
                        className="w-full"
                        size="small"
                        value={sendSmsOrEmailDetailsData?.firstName}
                        onChange={(e) =>
                          dispatch(
                            storeSendSmsOrEmailDetails({
                              ...sendSmsOrEmailDetailsData,
                              firstName: e.target.value,
                            })
                          )
                        }
                      />
                      <Typography className="mt-12 mb-6">
                        {t('common.lastName')}
                        <span className=" text-2xl text-red-500">*</span>
                      </Typography>
                      <TextField
                        className="w-full"
                        size="small"
                        value={sendSmsOrEmailDetailsData?.lastName}
                        onChange={(e) =>
                          dispatch(
                            storeSendSmsOrEmailDetails({
                              ...sendSmsOrEmailDetailsData,
                              lastName: e.target.value,
                            })
                          )
                        }
                      />
                      {sendSmsOrEmailDetailsData?.selectPlateFormType === 1 ? (
                        <>
                          <Typography className="mt-12 mb-6">
                            {t('common.email')}
                            <span className=" text-2xl text-red-500">*</span>
                          </Typography>
                          <TextField
                            className="w-full"
                            size="small"
                            value={sendSmsOrEmailDetailsData?.email}
                            onChange={(e) => {
                              const emailValue = e.target.value;
                              dispatch(
                                storeSendSmsOrEmailDetails({
                                  ...sendSmsOrEmailDetailsData,
                                  email: emailValue,
                                })
                              );
                              if (!Message.emailRegex.test(emailValue)) {
                                setIsEmailValid(true);
                                setEmailErrorMessage(
                                  t('signInPage.formField.email.validationMessages.validEmail')
                                );
                              } else {
                                setIsEmailValid(false);
                                setEmailErrorMessage();
                              }
                            }}
                          />
                          {isEmailValid && (
                            <Typography className="text-red pt-4">{emailErrorMessage}</Typography>
                          )}
                        </>
                      ) : (
                        <>
                          <Typography className="mt-12 mb-6">
                            {t('common.phone')}
                            <span className=" text-2xl text-red-500">*</span>
                          </Typography>
                          <PhoneInput
                            placeholder={t('signUpPage.formField.phoneNumber.placeHolder')}
                            inputStyle={{
                              width: '100%',
                              height: '42px',
                            }}
                            className="w-full"
                            name="phone_number"
                            required
                            country={
                              sendSmsOrEmailDetailsData?.phone?.countryCode ||
                              loginUserData?.isoCode?.toLowerCase()
                            }
                            enableSearch="true"
                            value={sendSmsOrEmailDetailsData?.phone?.phoneNumber}
                            onChange={(value, data) => {
                              const phoneNumber = value.replace(/[^0-9]/g, '');
                              dispatch(
                                storeSendSmsOrEmailDetails({
                                  ...sendSmsOrEmailDetailsData,
                                  phone: {
                                    ...data,
                                    phoneNumber,
                                  },
                                })
                              );
                              const validPhoneNumber = handlePhoneNumberValidationCheck(
                                value,
                                data
                              );
                              setErrorMessage(validPhoneNumber);
                            }}
                          />
                          {errorMessage === false &&
                            sendSmsOrEmailDetailsData?.phone?.phoneNumber.slice(
                              sendSmsOrEmailDetailsData?.phone?.dialCode?.length
                            )?.length > 0 && (
                              <p className="text-red mt-5 text-16">
                                {t('productSettings.InvalidPhoneNumber')}
                              </p>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                )}
                {sendSmsOrEmailDetailsData?.tabValue === 1 && (
                  <div className="text-center mt-28 mb-16">
                    <Button
                      className="bg-darkgreen rounded-md text-white hover:bg-darkgreen disabled:text-black disabled:bg-gray px-60"
                      onClick={() => handleRedirect()}
                      disabled={
                        !sendSmsOrEmailDetailsData?.firstName ||
                        !sendSmsOrEmailDetailsData?.lastName ||
                        (sendSmsOrEmailDetailsData?.selectPlateFormType === 1
                          ? !sendSmsOrEmailDetailsData?.email ||
                            !/\S+@\S+\.\S+/.test(sendSmsOrEmailDetailsData.email)
                          : !errorMessage ||
                            sendSmsOrEmailDetailsData?.phone?.phoneNumber?.slice(
                              sendSmsOrEmailDetailsData?.phone?.dialCode.length
                            ).length === 0)
                      }
                    >
                      {sendSmsOrEmailDetailsData?.selectPlateFormType === 1
                        ? t('inviteReviews.selectEmail')
                        : sendSmsOrEmailDetailsData?.selectPlateFormType === 2
                        ? t('inviteReviews.selectSms')
                        : t('inviteReviews.selectMessage')}
                    </Button>
                  </div>
                )}
                {parseInt(sendSmsOrEmailDetailsData?.tabValue, 10) === 2 && (
                  <div>
                    <div className="text-center mt-28 mb-16">
                      <Button
                        className="bg-darkgreen rounded-md text-white disabled:text-black disabled:bg-gray hover:bg-darkgreen px-60"
                        type="button"
                        onClick={() => handleNextRedirect()}
                      >
                        {t('signUpPage.buttons.next')}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {openCreateDialogue && (
        <AddContact
          openValue={openCreateDialogue}
          cancelCall={cancelDialogue}
          type="add"
          popUpMessage={t('waba.broadcast.contactList.addContact')}
          popUpButtonName={t('waba.broadcast.contactList.addContact')}
        />
      )}
    </div>
  );
};

export default withReducer('state', reducer)(SelectPlateFormType);
