import {
  Button,
  Checkbox,
  Drawer,
  Icon,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import history from '@history';
import { useEffect, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import FuseLoading from '@fuse/core/FuseLoading';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { City } from 'country-state-city';
import quickHub from '../../../../query/quickHub';
import { handleApiRequest } from '../../../../common/common';
import { getAllUserBusinessSubscription, getCountryData } from '../../../../../store/userSlice';
import queryData from '../../../../query/common';
import CheckPlanForAddNewBusiness from '../../../manage-business/checkPlanForAddNewBusiness/CheckPlanForAddNewBusiness';

const Businesses = (props) => {
  const { primaryDetailsScreen, activeTab } = props;
  const [searchText, setSearchText] = useState();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [businesses, setBusinesses] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [countryId, setCountryId] = useState();
  const [stateId, setStateId] = useState();
  const [loading, setLoading] = useState(true);
  const [labels, setLabels] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [stateData, setStateData] = useState([]);
  const countriesData = useSelector(getCountryData);
  const [page, setPage] = useState(1);
  const [openSelectProductDialog, setOpenSelectProductDialog] = useState(false);
  const [planAvailableDialog, setPlanAvailableDialog] = useState(false);
  const [userAvailablePlan, setUserAvailablePlan] = useState([]);
  const [city, setCity] = useState('');
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        if (!searchText && !countryId && !stateId && !stateData && !(selectedLabels?.length > 0)) {
          setLoading(true);
        }
        const payload = {
          query: quickHub.getAllBusinesses,
          variables: {
            pageNumber: page,
            pageSize: 10,
            labelIds: selectedLabels,
            searchText,
            countryId: Number(countryId),
            city,
            stateId: Number(stateId),
            state: stateId ? stateData?.find((state) => state?.id === stateId)?.name : '',
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getAllBusinesses?.status === 200) {
          setBusinesses(result.getAllBusinesses?.data);
          setTotalCount(result.getAllBusinesses?.totalCount);
        } else {
          enqueueSnackbar(result?.getAllBusinesses?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setLoading(false);
        console.error('Error in fetchManageLabels:', error);
      }
    };
    fetchBusinesses();
  }, [enqueueSnackbar, page, searchText, selectedLabels, countryId, stateId, stateData, city]);

  useEffect(() => {
    async function fetchData() {
      if (countryId) {
        const payload = {
          query: queryData.getStates,
          variables: {
            countryId: Number(countryId),
          },
        };
        const res = await handleApiRequest(payload);
        if (res?.getStates?.status === 200) {
          setStateData(res.getStates?.data);
        }
      }
    }
    fetchData();
  }, [countryId]);

  useEffect(() => {
    const fetchManageLabels = async () => {
      try {
        const payload = {
          query: quickHub.getAllBusinessesLabels,
        };
        const result = await handleApiRequest(payload);
        if (result?.getAllBusinessesLabels?.status === 200) {
          setLabels(result.getAllBusinessesLabels?.data);
        } else {
          enqueueSnackbar(result?.getAllBusinessesLabels?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error('Error in fetchManageLabels:', error);
      }
    };
    fetchManageLabels();
  }, [enqueueSnackbar]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedLabels(typeof value === 'string' ? value.split(',') : value);
  };

  const handleRemoveFilter = (data) => {
    setSelectedLabels((prevFilter) => {
      const filterExists = prevFilter?.some((filter) => filter === data?.id);
      if (filterExists) {
        return prevFilter.filter((filter) => filter !== data?.id);
      }
      return [...prevFilter, data?.id];
    });
  };

  const handleClearFilter = () => {
    setSelectedLabels([]);
    setCountryId();
    setStateId();
  };

  const handleAddBusiness = () => {
    const findAvailablePlan = userSubscriptionData?.filter((item) => !item?.userBusinessId);
    if (findAvailablePlan?.length > 0) {
      setUserAvailablePlan(findAvailablePlan);
      setPlanAvailableDialog(true);
    } else {
      setOpenSelectProductDialog(true);
    }
  };

  const handleRedirect = () => {
    history.push(activeTab?.url, {
      // selectBusiness: selectLabelOrBusiness || [],
      selectBusiness: [],
      activeTab,
    });
  };

  if (loading) {
    return <FuseLoading />;
  }
  return (
    <div className="bg-gray-A500 min-h-screen p-16 sm:p-24">
      {!primaryDetailsScreen && (
        <div className="flex items-center justify-between">
          <Typography className="text-20 sm:text-28 font-bold">
            {t('navigation.businesses')}
          </Typography>
          <Button
            className="bg-darkgreen text-white font-semibold rounded-md hover:bg-darkgreen px-4 text-12 xs:text-14 xs:px-20"
            onClick={() => handleAddBusiness()}
          >
            <AddIcon className="text-16 xs:text-24" />
            {t('manageBusiness.button.addBusiness')}
          </Button>
        </div>
      )}
      {(businesses?.length > 0 || searchText) && (
        <div className="flex flex-wrap items-center justify-between mt-16">
          <Typography className="font-semibold text-20">{t('businesses.yourBusiness')}</Typography>
          <div className="flex flex-wrap gap-16">
            {primaryDetailsScreen && (
              <Button
                className="rounded-md w-128 font-semibold"
                variant="contained"
                color="secondary"
                onClick={() => handleRedirect()}
              >
                {t('primaryDetails.bulkEdit')}
              </Button>
            )}
            <TextField
              placeholder={t('business.visitedBusiness.labels.searchBusinessName')}
              variant="outlined"
              fullWidth
              className="bg-white w-full sm:w-512 my-0"
              margin="normal"
              value={searchText}
              size="small"
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchText && (
                  <InputAdornment position="end">
                    <Icon
                      color="quickChat"
                      className="cursor-pointer text-20"
                      onClick={() => setSearchText('')}
                    >
                      close
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              className="rounded-md bg-white hover:bg-white border border-solid border-gray font-semibold text-16"
              variant="contained"
              onClick={() => setOpenDrawer(true)}
            >
              <FilterListIcon className="font-semibold mr-8" /> {t('reports.filter')}
            </Button>
          </div>
        </div>
      )}
      {selectedLabels?.length > 0 && (
        <div className="flex flex-wrap items-center gap-8 mt-16">
          {labels
            ?.filter((label) => selectedLabels?.includes(label?.id))
            ?.map((label, i) => {
              return (
                <div
                  className="flex gap-16 items-center border border-solid border-gray rounded-full px-16 py-8"
                  key={i}
                >
                  <div className="flex gap-6 items-center">
                    <div
                      className="w-14 h-14 rounded-full"
                      style={{ backgroundColor: label?.color }}
                    />
                    <Typography className="font-semibold text-14">
                      {label?.label} ({label?.businessAssociatedCount})
                    </Typography>
                  </div>
                  <Button
                    className="min-w-16 w-16 hover:bg-transparent p-0 h-16 min-h-16"
                    onClick={() => handleRemoveFilter(label)}
                  >
                    <CloseIcon />
                  </Button>
                </div>
              );
            })}
          <Button
            className="min-w-96 w-96 hover:bg-transparent text-red font-medium text-16 p-0 h-16 min-h-16"
            onClick={() => setSelectedLabels([])}
          >
            {t('businesses.clearFilters')}
          </Button>
        </div>
      )}
      {businesses?.length > 0 ? (
        <>
          <TableContainer component={Paper} className="rounded-md border-none shadow-0 mt-24">
            <Table className="">
              <TableHead>
                <TableRow>
                  <TableCell
                    className="font-semibold text-14 md:text-18 border-b-2 border-grey-300 w-112"
                    align="left"
                  >
                    {t('manageLabels.srNo')}
                  </TableCell>
                  <TableCell
                    className="font-semibold text-14 md:text-18 border-b-2 border-grey-300"
                    align="left"
                  >
                    {t('common.businessName')}
                  </TableCell>
                  <TableCell
                    className="font-semibold text-14 md:text-18 border-b-2 border-grey-300"
                    align="left"
                  >
                    {t('common.address')}
                  </TableCell>
                  <TableCell
                    className="font-semibold text-14 md:text-18 border-b-2 border-grey-300"
                    align="left"
                  >
                    {t('quickCampaignsContacts.contactNumber')}
                  </TableCell>
                  <TableCell
                    className="font-semibold text-14 md:text-18 border-b-2 border-grey-300"
                    align="left"
                  >
                    {t('reports.label')}
                  </TableCell>
                  {primaryDetailsScreen ? (
                    <TableCell
                      className="font-semibold text-14 md:text-18 border-b-2 border-grey-300"
                      align="left"
                    >
                      {activeTab?.label}
                    </TableCell>
                  ) : (
                    <TableCell
                      className="font-semi-bold text-14 md:text-18 border-b-2 border-grey-300"
                      align="right"
                    />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {businesses.map((business, index) => (
                  <TableRow
                    key={index}
                    className="hover:bg-quick-campaign-100 hover:bg-opacity-30 h-60"
                    onMouseEnter={() => setHoveredRow(index)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    <TableCell className="font-semibold text-16 md:text-16 border-b-2 border-grey-300 h-76 min-w-32 max-w-32">
                      {(page - 1) * 10 + index + 1}
                    </TableCell>
                    <TableCell className="font-semibold text-12 md:text-14 border-b-2 border-grey-300 max-w-144 min-w-144">
                      {business?.name}
                    </TableCell>
                    <TableCell className="font-semibold text-12 md:text-14 border-b-2 border-grey-300 min-w-320 max-w-320">
                      {business?.address || '-'}
                    </TableCell>
                    <TableCell className="font-semibold text-12 md:text-14 border-b-2 border-grey-300 min-w-160 max-w-160 sm:max-w-256 sm:min-w-256">
                      {business?.phoneNumber
                        ? `+${business?.phoneNumber?.countryCode} ${business?.phoneNumber?.phoneNumber}`
                        : '-'}
                    </TableCell>
                    <TableCell className="font-semibold text-12 md:text-14 border-b-2 border-grey-300 min-w-256">
                      <div className="flex gap-4 flex-wrap">
                        {business?.label?.length > 0
                          ? business?.label?.map((label, i) => {
                              return (
                                <span
                                  style={{ backgroundColor: label?.color }}
                                  className="rounded-full px-16 text-white font-semibold text-14 md:text-16"
                                  key={i}
                                >
                                  {label?.label}
                                </span>
                              );
                            })
                          : '-'}
                      </div>
                    </TableCell>
                    {primaryDetailsScreen ? (
                      <TableCell className="font-medium text-12 md:text-16 border-b-2 border-grey-300 min-w-128">
                        <Button
                          className="rounded-md"
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            history.push(activeTab?.url, {
                              selectBusiness: [business] || [],
                              activeTab,
                            });
                          }}
                        >
                          {activeTab?.buttonName}
                        </Button>
                      </TableCell>
                    ) : (
                      <TableCell className="font-medium text-12 md:text-16 border-b-2 border-grey-300 min-w-128">
                        {hoveredRow === index ? (
                          <div className="flex gap-12">
                            <Button
                              className="p-0 min-w-40 w-40 min-h-40 h-40 bg-quick-campaign-100 hover:bg-quick-campaign-100"
                              onClick={() => {
                                localStorage.setItem('selectedBusinessId', business?.id);
                                history.push('/editBusinessDetails/', { businessId: business?.id });
                              }}
                            >
                              <img src="assets/images/quick-hub/edit.png" alt="" />
                            </Button>
                          </div>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="px-28 sm:px-52 py-20 sm:py-32 bottom-0 right-0 w-full flex justify-end">
            <Pagination
              count={Math.ceil(totalCount / 10)}
              page={page}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
            />
          </div>
        </>
      ) : (
        <div className="text-center pt-160">
          <img className="m-auto" src="assets/images/business/businesses.png" alt="" />
          <Typography className="font-bold text-24 pt-12">
            {t('businesses.noBusinessAvailable')}
          </Typography>
          <Typography className="font-semibold text-16 pt-12">
            {t('businesses.noBusinessAvailableMessage')}
          </Typography>
          <Button
            className="bg-darkgreen text-white font-semibold mt-16 w-192 rounded-md hover:bg-darkgreen px-4 text-12 xs:text-14 xs:px-20"
            onClick={() => handleAddBusiness()}
          >
            <AddIcon className="text-16 xs:text-24" />
            {t('manageBusiness.button.addBusiness')}
          </Button>
        </div>
      )}
      <Drawer anchor="right" open={openDrawer} transitionDuration={700}>
        <div className="min-w-320 xs:min-w-400 flex flex-col h-full">
          <div className="flex items-center justify-between gap-12 p-16">
            <Typography className="font-bold text-16 sm:text-20">{t('reports.filter')}</Typography>
            <Button
              className="min-w-16 w-16 hover:bg-transparent"
              onClick={() => setOpenDrawer(false)}
            >
              <CloseIcon />
            </Button>
          </div>
          <hr />
          <div className="p-16 flex-grow overflow-y-auto">
            <Typography className="font-semibold text-16 pb-12">
              {t('businesses.selectLabel')}
            </Typography>
            <Select
              className="w-full"
              size="small"
              displayEmpty
              multiple
              value={selectedLabels}
              onChange={handleChange}
              renderValue={() => t('businesses.selectLabel')}
            >
              {labels?.map((label, i) => (
                <MenuItem key={i} value={label.id}>
                  <div className="flex justify-between items-center w-full">
                    <div className="flex gap-8 items-center">
                      <div
                        className="w-14 h-14 rounded-full"
                        style={{ backgroundColor: label?.color }}
                      />
                      {label.label}
                    </div>
                    <Checkbox checked={selectedLabels?.includes(label?.id)} />
                  </div>
                </MenuItem>
              ))}
            </Select>
            {selectedLabels?.length > 0 && (
              <div className="flex flex-wrap items-center gap-8 mt-12">
                {labels
                  ?.filter((label) => selectedLabels?.includes(label?.id))
                  ?.map((label, i) => {
                    return (
                      <div
                        className="flex gap-16 items-center border border-solid border-gray rounded-full px-16 py-8"
                        key={i}
                      >
                        <div className="flex gap-6 items-center">
                          <div
                            className="w-14 h-14 rounded-full"
                            style={{ backgroundColor: label?.color }}
                          />
                          <Typography className="font-semibold text-14">
                            {label?.label} ({label?.businessAssociatedCount})
                          </Typography>
                        </div>
                        <Button
                          className="min-w-16 w-16 hover:bg-transparent p-0 h-16 min-h-16"
                          onClick={() => handleRemoveFilter(label)}
                        >
                          <CloseIcon />
                        </Button>
                      </div>
                    );
                  })}
                <Button
                  className="min-w-96 w-96 text-red hover:bg-transparent p-0 h-16 min-h-16"
                  onClick={() => setSelectedLabels([])}
                >
                  {t('businesses.clearAll')}
                </Button>
              </div>
            )}
            <Typography className="font-semibold text-16 py-12">
              {t('listManagement.selectCountry')}
            </Typography>
            <Select
              className="w-full"
              size="small"
              displayEmpty
              value={countryId}
              onChange={(e) => setCountryId(e.target.value)}
              renderValue={() => {
                return countryId
                  ? countriesData?.find((country) => country?.id === countryId)?.name
                  : t('listManagement.selectCountry');
              }}
            >
              {countriesData?.map((country, i) => {
                return (
                  <MenuItem key={i} value={country?.id}>
                    {country?.name}
                  </MenuItem>
                );
              })}
            </Select>
            <Typography className="font-semibold text-16 py-12">
              {t('listManagement.selectState')}
            </Typography>
            <Select
              className="w-full"
              size="small"
              displayEmpty
              value={stateId}
              onChange={(e) => setStateId(e.target.value)}
              renderValue={() => {
                const selectedState = stateData?.find((state) => state?.id === stateId);
                return selectedState ? selectedState?.name : t('listManagement.selectState');
              }}
            >
              {stateData?.map((state, i) => {
                return (
                  <MenuItem key={i} value={state?.id}>
                    {state?.name}
                  </MenuItem>
                );
              })}
            </Select>
            <Typography className="font-semibold text-16 py-12">
              {t('businesses.selectCity')}
            </Typography>
            <Select
              className="w-full"
              size="small"
              displayEmpty
              value={city}
              renderValue={() => city || t('businesses.selectCity')}
              onChange={(e) => setCity(e.target.value)}
            >
              {City.getCitiesOfState(
                countriesData?.find((country) => country?.id === countryId)?.isoCode,
                stateData?.find((state) => state?.id === stateId)?.shortName
              )?.map((item, index) => {
                return (
                  <MenuItem value={item?.name} key={index}>
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="text-end p-16">
            <Button
              className="rounded-md text-quick-hub border w-144 font-semibold border-solid border-quick-hub mr-8 hover:bg-white"
              variant="outlined"
              onClick={() => handleClearFilter()}
            >
              {t('businesses.clearFilters')} <CloseIcon className="ml-4 text-quick-hub w-20" />
            </Button>
            <Button
              className="rounded-md text-white border border-solid w-128 font-semibold border-quick-hub bg-quick-hub hover:bg-quick-hub"
              variant="contained"
              onClick={() => setOpenDrawer(false)}
            >
              {t('reports.apply')}
            </Button>
          </div>
        </div>
      </Drawer>
      <CheckPlanForAddNewBusiness
        openSelectProductDialog={openSelectProductDialog}
        planAvailableDialog={planAvailableDialog}
        userAvailablePlan={userAvailablePlan}
        setOpenSelectProductDialog={setOpenSelectProductDialog}
        setPlanAvailableDialog={setPlanAvailableDialog}
        setUserAvailablePlan={setUserAvailablePlan}
      />
    </div>
  );
};

export default Businesses;
