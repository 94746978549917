import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FuseLoading from '@fuse/core/FuseLoading';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useSnackbar } from 'notistack';
import history from '@history';
import SearchIcon from '@mui/icons-material/Search';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import quickAdsQuery from 'src/app/main/query/quickAds';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  Checkbox,
} from '@mui/material';
import Pagination from 'src/app/component/table/Pagination';
import ShowAdsPost from '../showAdsPost';
import {
  formatDateWithTimeZone,
  handleApiRequest,
  numberFormateOnTimeZone,
} from '../../../common/common';
import SelectObjective from '../add-ads-campaign/select-objective';

const filterList = ['DRAFT', 'COMPLETED', 'ACTIVE', 'PAUSED', 'SCHEDULED'];

function AdsCampaign() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState();
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [ADSdata, setADSData] = useState();
  const [filterIds, setFilterIds] = useState([]);
  const [openSelectObjective, setOpenSelectObjective] = useState(false);
  const [popupData, setPopupData] = useState({
    isOpen: false,
    image: '',
    position: {},
  });

  useEffect(() => {
    const fetchAdsCampaign = async () => {
      try {
        setLoading(true);
        const payload = {
          query: quickAdsQuery.getAdsCampaign,
          variables: {
            quickHubAdsAccountId: 11,
            pageNumber: page,
            pageSize,
            campaignStatusFilter: selectedFilters,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getAdsCampaign?.status === 200) {
          setADSData(result?.getAdsCampaign?.data);
          setTotalCount(result?.getAdsCampaign?.totalCount);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchAdsCampaign();
  }, [page, pageSize, selectedFilters]);

  const handleChangePage = (event, value) => {
    setPage(value + 1);
  };

  const handleChangeRowsPerPage = (event, value) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const handleSelectFilter = (filter) => {
    setSelectedFilters(filter);
    setPage(1);
    setShowFilterOption(false);
  };

  const filteredContacts = useMemo(() => {
    setPage(1);
    if (!ADSdata?.length) return [];
    const searchText = searchTerm?.toLowerCase() || '';
    return ADSdata?.filter((data) => {
      if (!data?.name) return false;
      const ip = data?.name?.toLowerCase();
      return ip?.includes(searchText);
    });
  }, [ADSdata, searchTerm]);

  if (loading) {
    return <FuseLoading />;
  }

  const handleClosePopup = () => {
    setPopupData({ isOpen: false, image: '', position: {} });
  };

  const viewPost = (event, image) => {
    const rect = event.target.getBoundingClientRect();
    console.log('rect', rect);
    setPopupData({
      isOpen: true,
      image,
      position: {
        top: rect.top - 210,
        left: rect.left + rect.width / 5,
      },
    });
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  return (
    <div className="p-20 lg:p-24 h-full">
      <div>
        <div className="flex  flex-col md:flex-row w-full items-start md:items-center justify-between mb-20">
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="text-20 md:text-28 font-bold mb-16 md:mb-0"
          >
            {t(`quickAds.adsCampaign`)}
          </Typography>
          <div className="flex items-start sm:items-center flex-col sm:flex-row gap-10 sm:gap-0 md:w-auto w-full md:justify-normal justify-between quick-social">
            <div className="flex sm:w-auto w-full">
              <TextField
                placeholder="Search"
                variant="outlined"
                margin="normal"
                value={searchTerm}
                className="bg-gray-A500 border-none rounded-sm border-grey-A500 m-0 max-h-40 sm:w-320 w-full"
                onChange={(e) => setSearchTerm(e?.target?.value)}
                InputProps={{
                  endAdornment: searchTerm ? (
                    <InputAdornment position="end">
                      <Icon
                        color="quickSocial"
                        className="cursor-pointer text-20"
                        onClick={handleClearSearch}
                      >
                        {t(`quickAds.close`)}
                      </Icon>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="relative">
              <Button
                className="rounded-md w-28 min-w-28 p-20 h-24 min-h-24 mx-16"
                color="quickSocial"
                variant="contained"
                onClick={() => setShowFilterOption((prev) => !prev)}
              >
                <FilterListIcon />
              </Button>
              <div className="quick-social">
                {showFilterOption && (
                  <div className="absolute z-10 top-60 right-16 bg-gray-A500 min-w-320 w-full rounded-md shadow p-14">
                    <div className="flex items-center justify-between mb-12">
                      <h3 className="text-20 font-semibold">
                        {' '}
                        {t(`users.UserList.Filter.mainBtn`)}
                      </h3>
                      <Icon
                        className="cursor-pointer text-18"
                        aria-hidden
                        onClick={() => setShowFilterOption(false)}
                      >
                        {t(`quickAds.close`)}
                      </Icon>
                    </div>
                    {filterList &&
                      filterList?.map((list, index) => (
                        <div key={index} className="flex items-center justify-between mt-10">
                          <Typography className="text-16 text-grey-800 font-medium capitalize">
                            {list.charAt(0).toUpperCase() + list.slice(1).toLowerCase()}
                          </Typography>
                          <Checkbox
                            className="p-0 text-grey-300 rounded-md"
                            checked={selectedFilters?.includes(list)}
                            onChange={() => handleSelectFilter(list)}
                          />
                        </div>
                      ))}
                    {/* <div className="flex items-center justify-between mt-10">
                      <Typography className="text-16 text-grey-800 font-medium">
                        {t(`appWebsite.home.column.active`)}
                      </Typography>
                      <Checkbox
                        className="p-0 text-grey-300 rounded-md"
                        // checked={filterIds.some(
                        //   (filterId) => filterId?.key === item?.key
                        // )}
                        // onChange={() => handleSelectFilter(item)}
                      />
                    </div> */}
                    <div className="flex mt-16 gap-12 justify-end">
                      <Button
                        className="rounded-md py-0 max-w-128 w-full text-16 font-medium min-h-32 bg-quick-social-100"
                        size="small"
                        variant="outlined"
                        color="quickSocial"
                        type="button"
                        onClick={() => {
                          setSelectedFilters(null);
                          setShowFilterOption(false);
                        }}
                      >
                        {t(`quickCampaign.campaign.clearFilter`)}
                        <Icon className="text-16">{t(`quickAds.close`)}</Icon>
                      </Button>
                      {/* <Button
                        className="rounded-md py-0 max-w-128 w-full text-16 font-medium min-h-32"
                        size="small"
                        variant="contained"
                        color="quickSocial"
                        type="button"
                      >
                        {t('quickChat.apply')}
                      </Button> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <Button
                className="rounded-md h-40 min-h-40 text-18 font-medium"
                color="quickSocial"
                variant="contained"
                onClick={() => setOpenSelectObjective(true)}
              >
                <Icon>{t(`quickAds.add`)}</Icon> {t(`quickCampaign.campaign.createCampaign`)}
              </Button>
            </div>
          </div>
        </div>
        {filteredContacts && filteredContacts?.length > 0 ? (
          <TableContainer
            component={Paper}
            className="rounded-md border-1 border-solid border-grey-A500 shadow"
          >
            <Table>
              <TableHead className="border-b-2 border-grey-400">
                <TableRow>
                  <TableCell className="font-bold text-18">
                    {t('quickCampaign.campaign.srNo')}
                  </TableCell>
                  <TableCell className="font-bold text-18">{t('quickAds.dateCreated')}</TableCell>
                  <TableCell className="font-bold  text-18">{t('quickSocialPost.post')}</TableCell>
                  <TableCell className="font-bold text-18">
                    {t('quickCampaign.campaign.campaignName')}
                  </TableCell>
                  <TableCell className="font-bold  text-18">
                    {t('listManagement.startDate')}
                  </TableCell>
                  <TableCell className="font-bold  text-18">
                    {t('listManagement.endDate')}
                  </TableCell>
                  <TableCell className="font-bold  text-18">{t('common.status')}</TableCell>
                  <TableCell className="font-bold  text-18">
                    {t('quickSocial.impressions')}
                  </TableCell>
                  <TableCell className="font-bold  text-18">{t('quickAds.clicks')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredContacts &&
                  filteredContacts?.length > 0 &&
                  filteredContacts?.map((data, index) => (
                    <TableRow
                      key={index}
                      className="cursor-pointer hover:bg-quick-social-100 hover:bg-opacity-30 border-b-2"
                    >
                      <TableCell className="font-medium text-16 p-12">{index + 1}</TableCell>
                      <TableCell className="font-medium text-16 p-12">
                        {formatDateWithTimeZone(data?.cts, 'MMM DD, YYYY')}
                      </TableCell>
                      <TableCell className="font-medium text-12 md:text-16 p-12">
                        <img
                          src="assets/images/quick-hub/quick-ads/post.png"
                          onClick={(e) => viewPost(e, data.mediaUrl)}
                          aria-hidden
                          alt="..."
                          width={24}
                          height={24}
                        />
                      </TableCell>

                      <TableCell className="font-medium text-12 md:text-16 p-12">
                        <span
                          onClick={() =>
                            history.push(`/ads-campaign-details/${data.id}`, {
                              ADSdata: data,
                            })
                          }
                          aria-hidden
                        >
                          {data?.name}
                        </span>
                      </TableCell>
                      <TableCell className="font-medium text-16 p-12">
                        {formatDateWithTimeZone(data?.scheduleDate, 'MMM DD, YYYY')}
                      </TableCell>
                      <TableCell className="font-medium text-12 md:text-16 p-12">
                        {formatDateWithTimeZone(data?.endDate, 'MMM DD, YYYY')}
                      </TableCell>
                      <TableCell className="p-12 border-b-0">
                        <span
                          className={`${
                            data?.status === 'SCHEDULED'
                              ? 'bg-quick-social-100 text-quick-social border-1 border-quick-social'
                              : data?.status === 'ACTIVE'
                              ? 'bg-green-100 text-quick-review border-1 border-quick-review'
                              : data?.status === 'COMPLETED'
                              ? 'bg-[#f1cdea] text-[#C837AB] border-1 border-[#C837AB]'
                              : 'bg-yellow-100 text-quick-chat border-1 border-quick-chat'
                          } rounded-full text-14 font-medium text-center px-10 py-5`}
                        >
                          {data?.status}
                        </span>
                      </TableCell>
                      <TableCell className="font-medium text-12 md:text-16 p-12">
                        {data?.impression > 0 ? numberFormateOnTimeZone(data?.impression) : '--'}
                      </TableCell>
                      <TableCell className="font-medium text-12 md:text-16 p-12">
                        {data?.click > 0 ? numberFormateOnTimeZone(data?.click) : '--'}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="flex flex-col items-center justify-center max-w-384 mx-auto custom-height">
            <img
              src="assets/images/quick-hub/quick-ads/no-ads.png"
              alt="No ads"
              className="h-320 w-320 mb-16"
            />
            <div className="text-28 font-bold mb-16">
              {t('quickCampaign.campaign.noCampaignYet')}
            </div>
            <p className="text-17 font-500 mb-16 text-gray-700 text-center">
              {t('quickAds.noAdsContent')}
            </p>
            <Button
              variant="outlined"
              color="quickSocial"
              size="medium"
              className="mb-16 rounded-md text-16 font-500 bg-quick-social-100 text-black"
            >
              {t('quickCampaign.campaign.createYourFirstCampaign')}
            </Button>
          </div>
        )}
      </div>
      <ShowAdsPost
        open={popupData?.isOpen}
        onClose={handleClosePopup}
        image={popupData?.image}
        position={popupData?.position}
      />
      {filteredContacts?.length > 10 && (
        <div className="bg-white px-52 py-32 bottom-0 right-0 w-full flex justify-end">
          <Pagination
            totalCount={totalCount}
            rowsPerPage={pageSize}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      )}
      <SelectObjective
        openValue={openSelectObjective}
        cancelCall={() => setOpenSelectObjective(false)}
      />
    </div>
  );
}
export default AdsCampaign;
