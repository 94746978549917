import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import history from '@history';
import { useLocation } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Toolbar,
  Icon,
} from '@mui/material';
import { formatDateWithTimeZone } from '../../../common/common';

function AdsCampaignDetails() {
  const { t } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { ADSdata } = location.state || {};

  if (loading) {
    return <FuseLoading />;
  }

  const campaignsLeads = [
    {
      id: 1,
      LeadName: 'Wright Brothers',
      email: 'demo@yopmail.com',
      phone: '123456890',
      countryCode: '+91',
    },
    {
      id: 2,
      LeadName: 'Wright Brothers',
      email: 'demo@yopmail.com',
      phone: '8141359856',
      countryCode: '+91',
    },
    {
      id: 3,
      LeadName: 'Wright Brothers',
      email: 'demo@yopmail.com',
      phone: '9648561805',
      countryCode: '+91',
    },
    {
      id: 4,
      LeadName: 'bright school',
      email: 'demo@yopmail.com',
      phone: '123456890',
      countryCode: '+91',
    },
    {
      id: 5,
      LeadName: 'Wright Brothers',
      email: 'demo@yopmail.com',
      phone: '123456890',
      countryCode: '+91',
    },
    {
      id: 6,
      LeadName: 'Wright Brothers',
      email: 'demo@yopmail.com',
      phone: '123456890',
      countryCode: '+91',
    },
    {
      id: 7,
      LeadName: 'Wright Brothers',
      email: 'demo@yopmail.com',
      phone: '123456890',
      countryCode: '+91',
    },
  ];

  const firstMetricsData = [
    {
      id: 1,
      icon: '👁️',
      value: '1205',
      label: 'Impressions',
    },
    {
      id: 2,
      icon: '📈',
      value: '9876',
      label: 'Total Reach',
    },
    {
      id: 3,
      icon: '📊',
      value: '76.5%',
      label: 'Engagement Rate',
    },
    {
      id: 4,
      icon: '🖱️',
      value: '3450',
      label: 'Clicks',
    },
  ];

  return (
    <div className="p-20 lg:p-24 h-full">
      <div>
        <Toolbar className="px-0 font-semibold min-h-fit mb-10">
          <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
            {t(`quickAds.west`)} :
          </Icon>
          <Typography
            variant="subtitle1"
            color="inherit"
            className="flex-1 px-12 font-bold text-18"
          >
            {t(`quickCampaign.campaign.title`)}
          </Typography>
        </Toolbar>

        <div className="grid md:grid grid-cols-5">
          <div className="sm:col-span-2">
            <div className="sm:flex w-full items-center justify-between mb-20">
              <Typography
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="text-20 md:text-28 font-bold mb-16 md:mb-0"
              >
                {t(`quickCampaign.campaign.campaignDetails`)}
              </Typography>
            </div>
            <div className="xl:flex mb-28">
              <img className="max-w-256 mr-20 mb-20 xl:mb-0" src={ADSdata.mediaUrl} alt="..." />
              <div className="block">
                <div>
                  <span className="block text-20 font-semibold mb-12">
                    {ADSdata?.name
                      ?.split(' ')
                      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      ?.join(' ')}
                  </span>
                  <div>
                    <span className="text-18 font-medium block text-grey-700 leading-7">
                      {t(`quickAds.campaignObjective`)} :
                    </span>
                    <span className="text-18 font-medium block text-black leading-7 mb-20">
                      {ADSdata?.campaignOverview?.objective}
                    </span>
                  </div>
                </div>
                <div className="flex items-center justify-between mb-20">
                  <span
                    className={`${
                      ADSdata?.status === 'SCHEDULED'
                        ? 'bg-quick-social-100 text-quick-social border-1 border-quick-social'
                        : ADSdata?.status === 'ACTIVE'
                        ? 'bg-green-100 text-quick-review border-1 border-quick-review'
                        : ADSdata?.status === 'COMPLETED'
                        ? 'bg-[#f1cdea] text-[#C837AB] border-1 border-[#C837AB]'
                        : 'bg-yellow-100 text-quick-chat border-1 border-quick-chat'
                    } inline-block rounded-md font-medium text-16 text-center py-3 px-10`}
                  >
                    {ADSdata?.status}
                  </span>
                  <span>
                    {ADSdata?.shareOnFacebook && (
                      <img
                        src="assets/images/quick-hub/quick-ads/facebook.png"
                        alt="Facebook"
                        className="w-28 h-28 inline-block ml-4"
                      />
                    )}
                    {ADSdata?.shareOnInstagram && (
                      <img
                        src="assets/images/quick-hub/quick-ads/instagram.png"
                        alt="instagram"
                        className="w-28 h-28 inline-block ml-4"
                      />
                    )}
                  </span>
                </div>
                <div className="text-18 font-medium">
                  <span className="bg-quick-social-100 w-32 h-32 inline-block pt-3 align-middle rounded-full text-center mr-10">
                    <Icon className="mx-auto text-grey-800 ">{t(`quickAds.dateRange`)} </Icon>
                  </span>
                  {formatDateWithTimeZone(ADSdata?.scheduleDate, 'MMM DD, YYYY')} to{' '}
                  {formatDateWithTimeZone(ADSdata?.endDate, 'MMM DD, YYYY')}
                </div>
              </div>
            </div>
            <div>
              <div className="text-18 font-semibold  mb-20">
                <div className="inline-block">
                  <span className=" inline-block align-middle text-grey-700">
                    {t(`quickAds.ageGroup`)}
                  </span>
                  <span className="inline-block align-middle mx-4">:</span>
                  <span className="inline-block align-middle text-black">
                    {ADSdata?.campaignOverview?.minAge} - {ADSdata?.campaignOverview?.maxAge}{' '}
                    {t(`quickAds.years`)}
                  </span>
                </div>
                <div className="inline-block lg:ml-48 ml-20">
                  <span className=" inline-block align-middle text-grey-700">
                    {t(`quickAds.gender`)}
                  </span>
                  <span className="inline-block align-middle mx-4">:</span>
                  <span className="inline-block align-middle text-black">
                    {ADSdata?.campaignOverview?.genderSelected}
                  </span>
                </div>
              </div>
            </div>
            <div className="text-18 font-semibold  mb-20">
              <div className="block">
                <span className=" inline-block align-middle text-grey-700">
                  {t(`business.searchBusiness.labels.location`)}
                </span>
                <span className="inline-block align-middle mx-4">:</span>
                <span className="inline-block align-middle text-black">
                  {ADSdata?.campaignOverview?.geoLocation?.countries?.map((item, index) => (
                    <div key={index}>
                      {' '}
                      {index > 0 && <span>/</span>}
                      {item}
                    </div>
                  ))}
                </span>
              </div>
            </div>
            <div className="text-18 font-semibold  mb-20">
              <div className="block">
                <span className=" inline-block align-middle text-grey-700">
                  {t(`quickAds.interest`)}
                </span>
                <span className="inline-block align-middle mx-4">:</span>
                <span className="inline-block align-middle text-black">
                  {ADSdata?.campaignOverview?.interest?.map((item, index) => (
                    <div key={index}>{item?.name ? item.name : '-'}</div>
                  ))}{' '}
                </span>
              </div>
            </div>

            <TableContainer
              component={Paper}
              className="rounded-md border-1 border-solid border-grey-A500 shadow"
            >
              <div className="flex items-center justify-between mt-10 mb-5 px-16">
                <h3 className="text-18 font-medium">
                  Campaign Leads
                  <span className="ml-10 px-6 inline-block border-1 border-grey-400 rounded-full min-w-48 py-3 text-14 font-medium text-center">
                    {campaignsLeads?.length}
                  </span>
                </h3>
                <Button
                  className="text-16 font-medium px-0 hover:bg-transparent hover:text-black"
                  variant="text"
                  color="quickSocial"
                >
                  See All <Icon className="text-14 ml-3">arrow_forward_ios</Icon>
                </Button>
              </div>

              <Table>
                <TableHead className="border-b-2 border-grey-400">
                  <TableRow>
                    <TableCell className="font-bold xl:text-18 pr-0">Sr No</TableCell>
                    <TableCell className="font-bold  xl:text-18">Lead Name</TableCell>
                    <TableCell className="font-bold xl:text-18">Email Address</TableCell>
                    <TableCell className="font-bold  xl:text-18">Phone</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {campaignsLeads &&
                    campaignsLeads?.map((data, index) => (
                      <TableRow
                        key={index}
                        className="cursor-pointer hover:bg-quick-social-100 hover:bg-opacity-30 border-b-2"
                      >
                        <TableCell className="font-medium xl:text-16 p-12 pr-0">
                          {index + 1}
                        </TableCell>
                        <TableCell className="font-medium xl:text-16 p-12">
                          {data?.LeadName}
                        </TableCell>
                        <TableCell className="font-medium xl:text-16 p-12">{data?.email}</TableCell>

                        <TableCell className="font-medium text-16 p-12">
                          {data?.countryCode} {data?.phone}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="sm:col-span-3 border-l-2 border-grey-400 pl-28 ml-28">
            <div className="sm:flex w-full items-center justify-between mb-20">
              <Typography className="text-20 md:text-28 font-bold mb-16 md:mb-0">
                Campaign Metrics
              </Typography>
            </div>
            <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 md:gap-20 gap-10 mb-24 bg-white rounded-md p-24 border-1 border-solid border-grey-A500 shadow">
              {firstMetricsData &&
                firstMetricsData?.length > 0 &&
                firstMetricsData?.map((metricsValue, i) => (
                  <div key={i}>
                    <div className="flex items-center">
                      <img
                        src="https://app-uat.quickreviews.click/assets/images/icon/todayConversation.svg"
                        width={50}
                        height={50}
                        className="h-auto max-w-full mr-10"
                        alt="..."
                      />
                      <div>
                        <span className="block font-semibold text-28 mb-3">
                          {metricsValue?.value}
                        </span>
                        <span className="block font-medium text-18 text-grey-600">
                          {metricsValue?.label}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className="grid xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 grid-cols-1 md:gap-20 gap-10 mb-24">
              <div className="bg-white rounded-md p-24 border-1 border-solid border-grey-A500 shadow">
                cfd
              </div>
              <div className="bg-white rounded-md p-24 border-1 border-solid border-grey-A500 shadow">
                cfd
              </div>
            </div>
            <div className="bg-white rounded-md p-24 border-1 border-solid border-grey-A500 shadow">
              Top Channel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdsCampaignDetails;
