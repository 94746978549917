import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import CampaignDetails from './CampaignDetails';
import SelectContacts from './SelectContacts';
import businessData from '../../../query/business';
import { generateRandomColor, handleApiRequest } from '../../../common/common';

const CreateCampaign = () => {
  const { state } = useLocation();
  const [createCampaignDetails, setCreateCampaignDetails] = useState({
    campaignPlatformId:
      state?.template?.campaignPlatformId ||
      state?.campaign_services_standard_messages?.htmlSubject ||
      '',
    htmlSubject:
      state?.template?.htmlSubject || state?.campaign_services_standard_messages?.htmlSubject || '',
    htmlTemplate:
      state?.template?.htmlTemplate ||
      state?.campaign_services_standard_messages?.htmlTemplate ||
      '',
    id: state?.template?.id || state?.campaign_services_standard_messages?.id,
    messages:
      state?.template?.messages || state?.campaign_services_standard_messages?.messages || '',
    title: state?.template?.title || state?.campaign_services_standard_messages?.title || '',
    platForm: state?.platForm || state?.campaign_platform?.id,
  });
  const [campaignName, setCampaignName] = useState(state?.campaignTitle || '');
  const [stepsCount, setStepsCount] = useState(
    state?.campaign_services_queue?.length > 0 ? 3 : state?.campaignTitle ? 2 : 1
  );
  const [selectedContact, setSelectContact] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contactsData, setContactsData] = useState([]);
  const [csvContactData, setCsvContactData] = useState([]);
  const [page, setPage] = useState(1);
  const [csvPage, setCsvPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [csvTotalCount, setCsvTotalCount] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [tabValue, setTabValue] = useState(state?.csvIds?.length > 0 ? 2 : 1);

  const steps = [
    {
      name: t('quickCampaign.campaign.campaignDetails'),
      imageUrl: 'assets/images/business/details.png',
    },
    {
      name: t('quickCampaign.campaign.selectContacts'),
      imageUrl: 'assets/images/business/contacts.png',
    },
    {
      name: t('quickCampaign.campaign.postSchedule'),
      imageUrl: 'assets/images/business/post.png',
    },
  ];

  const fetchContactData = useCallback(async () => {
    try {
      setLoading(true);
      const payload = {
        query: businessData.getUserCampaignContact,
        variables: {
          callFrom: 'campaign',
          defaultSave: false,
          pageNumber: page,
          userBusinessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getUserCampaignContact?.status === 200) {
        const allContacts = result.getUserCampaignContact?.data?.map((item) => ({
          ...item,
          userProfile: generateRandomColor(),
        }));
        const matchedContacts = allContacts?.filter((contact) =>
          state?.campaign_services_queue?.some((item) => item.phoneNumber === contact.phoneNumber)
        );

        setTotalCount(result?.getUserCampaignContact?.totalCount);
        setContactsData(allContacts);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  }, [userCurrentBusinessDetails?.id, page, state?.campaign_services_queue]);

  const fetchCsvData = useCallback(async () => {
    try {
      setLoading(true);
      const payload = {
        query: businessData.getUserCSV,
        variables: {
          callFrom: 'campaign',
          defaultSave: false,
          pageNumber: csvPage,
          userBusinessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getUserCSV?.status === 200) {
        const matchedContacts = result?.getUserCSV?.data?.userCSVData?.filter((contact) =>
          state?.csvIds?.some((item) => item.phoneNumber === contact.phoneNumber)
        );

        setCsvTotalCount(result.getUserCSV?.totalCount);
        setCsvContactData(result.getUserCSV?.data?.userCSVData);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  }, [userCurrentBusinessDetails?.id, csvPage, state]);

  return (
    <div className="p-24">
      <Typography className="font-bold text-24">
        {t('quickCampaign.campaign.createCampaign')}
      </Typography>
      <div className="flex justify-between items-center mt-20 p-10 sm:p-20 bg-white text-center rounded-md">
        {steps?.map((item, index) => {
          return (
            <>
              <Typography
                className={`w-586 font-semibold flex items-center flex-col md:flex-row gap-8 text-16 ${
                  index < stepsCount ? 'text-quick-campaign' : ''
                }`}
                key={index}
              >
                <img
                  src={item?.imageUrl}
                  alt=""
                  style={{
                    filter: index < stepsCount ? undefined : 'brightness(0) invert(0)',
                  }}
                />{' '}
                <span className="text-10 sm:text-16">{item?.name}</span>
              </Typography>
              {index < steps.length - 1 && (
                <hr
                  className={`border h-2 w-full border-solid ${
                    index + 1 < stepsCount ? '!border-quick-campaign' : ''
                  } mx-16`}
                />
              )}
            </>
          );
        })}
      </div>
      <div className="md:grid grid-cols-4 gap-24">
        {(stepsCount === 1 || stepsCount === 3) && (
          <div className="col-span-2 w-full">
            <CampaignDetails
              createCampaignDetails={createCampaignDetails}
              setCreateCampaignDetails={setCreateCampaignDetails}
              campaignName={campaignName}
              setCampaignName={setCampaignName}
              stepsCount={stepsCount}
              businessDetails={userCurrentBusinessDetails}
              setStepsCount={setStepsCount}
              selectedContact={selectedContact}
              state={state}
              tabValue={tabValue}
              contactsData={contactsData}
              csvContactData={csvContactData}
            />
          </div>
        )}
        {(stepsCount === 2 || stepsCount === 3) && (
          <div
            className={`${
              stepsCount === 2 ? 'col-span-3' : 'col-span-2'
            } item-center-center w-full`}
          >
            <Typography className="font-semibold text-16 py-20">
              {t('quickCampaign.campaign.selectContacts')}
            </Typography>
            <div className="bg-white">
              <SelectContacts
                createCampaignDetails={createCampaignDetails}
                selectedContact={selectedContact}
                campaignName={campaignName}
                setSelectContact={setSelectContact}
                stepsCount={stepsCount}
                businessDetails={userCurrentBusinessDetails}
                setStepsCount={setStepsCount}
                fetchContactData={fetchContactData}
                csvContactData={csvContactData}
                contactsData={contactsData}
                state={state}
                fetchCsvData={fetchCsvData}
                page={page}
                setPage={setPage}
                csvPage={csvPage}
                setCsvPage={setCsvPage}
                totalCount={totalCount}
                csvTotalCount={csvTotalCount}
                setTabValue={setTabValue}
                tabValue={tabValue}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateCampaign;
