import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { getAllUserBusinessSubscription, selectDashboardData } from 'src/app/store/userSlice';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { capitalize } from 'lodash';
import { useSnackbar } from 'notistack';
import history from '@history';
import userService from '../../../services';
import './exploreFeatures.css';
import businessData from '../../query/business';
import { handleApiRequest, handlePhoneNumberValidationCheck } from '../../common/common';
import quickPostQuery from '../../query/quickPost';

const QuickCampaignsFeatures = ({
  surpriseMeCardData,
  allFeaturesTrialDataId,
  campaignTrialDone,
}) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const loginUserData = userService?.getUserData();
  const params = useParams();
  const [tabValue, setTabValue] = useState('1');
  const [platformId, setPlatformId] = useState(2);
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState({});
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [isEmailValid, setIsEmailValid] = useState([]);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);

  const { enqueueSnackbar } = useSnackbar();
  const [userData, setUserData] = useState([
    {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
    },
  ]);

  const transformUserSubscriptionData = () => {
    return userSubscriptionData
      ?.filter((item) => item?.userBusinessId === userCurrentBusinessDetails?.id)
      .reduce((acc, item) => {
        const addToAcc = (details, key, isFreeTrial) => {
          details?.forEach((detail) => {
            if (detail[key]) {
              acc.push({ name: detail[key], isFreeTrial });
            }
          });
        };
        if (item?.name !== 'quick bundle' && item?.name !== 'quick combo') {
          acc.push({ name: item?.name, isFreeTrial: item?.isFreeTrial });
        }
        addToAcc(item?.comboDetails, 'name', item?.isFreeTrial);
        addToAcc(item?.packageBundleDetails, 'name', item?.isFreeTrial);
        return acc;
      }, []);
  };

  useEffect(() => {
    async function fetchData() {
      setRefreshIcon(true);
      const payload = {
        query: businessData.getCampaignStandardMessages,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          messagesBusinessTypeId: 15,
          campaignPlatformId: parseInt(platformId, 10),
          serviceType: 'invite_reviews',
          callFrom: 'surpriseMe',
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.getCampaignStandardMessages?.status === 200) {
        const data = result?.getCampaignStandardMessages?.data;
        const reviewScannerUrl = `${userCurrentBusinessDetails?.businessQRId}`;
        const updatedArray = data?.map((value) => {
          return {
            ...value,
            htmlTemplate: value?.htmlTemplate?.replace('[Review Link]', reviewScannerUrl),
          };
        });
        surpriseMeCardData();
        setSelectedMessage(updatedArray[0]);
        setMessages(updatedArray);
      }
    }
    fetchData();
  }, [userCurrentBusinessDetails, tabValue, platformId, surpriseMeCardData]);

  const handleRedirect = async () => {
    const productData = transformUserSubscriptionData();
    const formatText = (text, item) =>
      text
        ?.replace(/\[Customers Name\]/g, `${item?.firstName} ${item?.lastName}`)
        ?.replace(/\[Business Name\]/g, `${userCurrentBusinessDetails?.name}`)
        ?.replace(/\[Review Link\]/g, userCurrentBusinessDetails?.businessQRId);

    const generateQueue = () =>
      userData?.map((item) => {
        const baseData = {
          phoneNumber: Number(item.phoneNumber?.phoneNumber),
          countryCode: `+${item?.phoneNumber?.dialCode}`,
          name: `${item?.firstName} ${item?.lastName}`,
        };
        if (Number(tabValue) === 2) {
          return {
            email: item?.email,
            htmlSubject: selectedMessage?.htmlSubject
              ?.replace('[Business Name]', userCurrentBusinessDetails?.name)
              .replace('[Customers Name]', `${item?.firstName} ${item?.lastName}`),
            htmlTemplate: formatText(selectedMessage?.htmlTemplate, item),
          };
        }
        if (Number(tabValue) === 1) {
          return {
            ...baseData,
            title: formatText(selectedMessage?.messages, item),
          };
        }
        if (Number(tabValue) === 3) {
          return baseData;
        }
        return null;
      });
    try {
      const payload = {
        query:
          tabValue === 3
            ? businessData.sendCampaignWhatsAppMessagesV1
            : businessData.sendCampaignMessagesV1,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
          campaignPlatformId: Number(platformId),
          isSchedule: false,
          campaignServicesStandardMessageId: Number(selectedMessage?.id),
          isFreeTrial: productData?.find((item) => item.name === 'Quick Campaigns')?.isFreeTrial,
          queue: generateQueue(),
        },
      };
      const res = await handleApiRequest(payload);
      const result =
        tabValue === 3 ? res?.sendCampaignWhatsAppMessagesV1 : res?.sendCampaignMessagesV1;

      if (result?.status === 200) {
        enqueueSnackbar(result?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setUserData([
          {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
          },
        ]);
      } else {
        enqueueSnackbar(result?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error in createCampaign:', error);
    }
  };

  const handleEmailChange = (e, i) => {
    const emailValue = e.target.value;
    const updatedUserData = [...userData];
    updatedUserData[i] = {
      ...updatedUserData[i],
      email: emailValue,
    };
    setUserData(updatedUserData);
    setIsEmailValid((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[i] = /\S+@\S+\.\S+/.test(emailValue);
      return newErrors;
    });
  };

  const handlePhoneChange = (event, data, i) => {
    const updatedUserData = [...userData];
    updatedUserData[i] = {
      ...updatedUserData[i],
      phoneNumber: { ...data, phoneNumber: event?.slice(data?.dialCode?.length) },
    };
    setUserData(updatedUserData);
  };

  const addMoreReceiver = () => {
    setUserData([
      ...userData,
      {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
      },
    ]);
  };

  const isUserDataValid = () => {
    return userData.map((user, i) => {
      const isNameValid = user.firstName.trim() && user.lastName.trim();
      const isContactValid =
        Number(tabValue) === 2
          ? user.email && isEmailValid[i]
          : user.phoneNumber?.phoneNumber?.length > 0 && errorMessage[i];
      return isNameValid && isContactValid;
    });
  };

  return (
    <div>
      <div className="sm:flex gap-24">
        <div className="md:min-w-400 md:max-w-400 sm:max-w-288 p-14 bg-white">
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            className="mb-20"
            value={tabValue}
            onChange={(e) => {
              setTabValue(e?.target?.value);
              setPlatformId(e?.target?.value === '1' ? 2 : e?.target?.value === '2' ? 1 : 3);
            }}
          >
            <div className="grid grid-cols-3 border-1 border-solid border-darkgreen rounded-3xl text-center">
              <FormControlLabel
                className={`rounded-l-3xl p-10 m-0 relative text-center block ${
                  tabValue === '1' ? 'bg-darkgreen text-white' : ''
                }`}
                value="1"
                control={<Radio className="p-0 absolute right-12 top-12 w-24 h-24 opacity-0" />}
                label={
                  <span className="block text-center text-16 font-medium">
                    {t('dashboard.exploreFeatures.campaigns.sms')}
                  </span>
                }
              />

              <FormControlLabel
                className={`rounded-0 border-x-1 border-darkgreen p-10 m-0 relative text-center block ${
                  tabValue === '2' ? 'bg-darkgreen text-white' : ''
                }`}
                value="2"
                control={<Radio className="p-0 absolute right-12 top-12 w-24 h-24 opacity-0" />}
                label={
                  <span className="block text-center text-16 font-medium">{t('common.email')}</span>
                }
              />

              <FormControlLabel
                className={`rounded-r-3xl p-10 m-0 relative text-center block ${
                  tabValue === '3' ? 'bg-darkgreen text-white' : ''
                }`}
                value="3"
                control={<Radio className="p-0 absolute right-12 top-12 w-24 h-24 opacity-0" />}
                label={
                  <span className="block text-center text-16 font-medium">
                    {t('dashboard.exploreFeatures.campaigns.whatsapp')}
                  </span>
                }
              />
            </div>
          </RadioGroup>
          {messages && messages?.length > 0 && (
            <div className="max-h-screen overflow-y-auto">
              <h2 className="font-semibold text-20 mt-20 mb-12">
                {t('dashboard.exploreFeatures.campaigns.template')}
              </h2>

              <div>
                <div>
                  {refreshIcon ? (
                    <CircularProgress size={28} className="text-darkgreen text-center mx-auto" />
                  ) : (
                    <>
                      {messages?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            className={`bg-gray-A500 p-16 rounded-md mb-16 last:mb-0 border-1 border-solid ${
                              item?.id === selectedMessage?.id
                                ? 'border-darkgreen'
                                : 'border-gray-A500'
                            }`}
                            onClick={() => setSelectedMessage(item)}
                            aria-hidden
                          >
                            <Typography className="text-14 text-black pb-4">
                              {item?.title}
                            </Typography>
                            <div role="button" tabIndex={0}>
                              {tabValue === '2' ? (
                                <div className="max-h-[400px] overflow-scroll w-full">
                                  <Typography className="font-bold pb-16">
                                    {t('inviteReviews.subject')}: {item?.htmlSubject}
                                  </Typography>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item?.htmlTemplate,
                                    }}
                                  />
                                </div>
                              ) : tabValue === '3' ? (
                                <div>
                                  <p
                                    className="w-full templeteCss"
                                    dangerouslySetInnerHTML={{
                                      __html: item?.htmlTemplate,
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="h-[100px] overflow-scroll w-full">
                                  <Typography>{item?.messages}</Typography>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-full bg-white md:px-0 px-14 py-28 mt-14 sm:mt-0">
          <div className="max-w-400 mx-auto">
            <div>
              <h2 className="text-20 font-semibold mb-16 ">
                {t('dashboard.exploreFeatures.campaigns.template')}
              </h2>
              <div className="bg-gray-A500 p-12 text-black">
                {tabValue === '2' ? (
                  <div className="max-h-[400px] overflow-scroll w-full">
                    <Typography className="font-bold pb-16">
                      {t('inviteReviews.subject')}: {selectedMessage?.htmlSubject}
                    </Typography>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: selectedMessage?.htmlTemplate,
                      }}
                    />
                  </div>
                ) : tabValue === '3' ? (
                  <div>
                    <p
                      className="w-full templeteCss"
                      dangerouslySetInnerHTML={{
                        __html: selectedMessage?.htmlTemplate,
                      }}
                    />
                  </div>
                ) : (
                  <div className="h-[100px] overflow-scroll w-full">
                    <Typography>{selectedMessage?.messages}</Typography>
                  </div>
                )}
              </div>
              <div className="max-h-screen overflow-y-auto">
                {userData?.length > 0 &&
                  userData?.map((item, i) => {
                    return (
                      <div className="mt-16">
                        <div className="flex items-start md:items-center flex-col md:flex-row justify-between">
                          <h2 className="text-20 font-semibold">
                            {t('dashboard.exploreFeatures.campaigns.receiverDetails')} {i + 1}
                          </h2>
                          <Button
                            className="font-semibold text-14 hover:bg-transparent p-0"
                            color="secondary"
                            onClick={() => addMoreReceiver()}
                          >
                            <AddIcon />{' '}
                            {t('dashboard.exploreFeatures.campaigns.addMoreReceiverDetails')}
                          </Button>
                        </div>
                        <div className="mt-12">
                          <Typography className="mt-12 mb-6">{t('common.firstName')}</Typography>
                          <TextField
                            className="w-full"
                            size="small"
                            value={item?.firstName}
                            onChange={(e) => {
                              const updatedUserData = [...userData];
                              updatedUserData[i] = {
                                ...updatedUserData[i],
                                firstName: e.target.value,
                              };
                              setUserData(updatedUserData);
                            }}
                          />
                          <Typography className="mt-12 mb-6">{t('common.lastName')}</Typography>
                          <TextField
                            className="w-full"
                            size="small"
                            value={item?.lastName}
                            onChange={(e) => {
                              const updatedUserData = [...userData];
                              updatedUserData[i] = {
                                ...updatedUserData[i],
                                lastName: e.target.value,
                              };
                              setUserData(updatedUserData);
                            }}
                          />
                          {tabValue === '2' ? (
                            <>
                              <Typography className="mt-12 mb-6">{t('common.email')}</Typography>
                              <TextField
                                className="w-full"
                                size="small"
                                value={item?.email}
                                onChange={(e) => handleEmailChange(e, i)}
                                error={item?.email && !isEmailValid[i]}
                                helperText={
                                  item?.email &&
                                  !isEmailValid[i] &&
                                  t('dashboard.exploreFeatures.campaigns.inValidEmailAddress')
                                }
                              />
                            </>
                          ) : (
                            <>
                              <Typography className="mt-12 mb-6">{t('common.phone')}</Typography>
                              <PhoneInput
                                placeholder={t(
                                  'manageBusiness.addBusinessSteps.addBusiness.form.phone.placeHolder'
                                )}
                                inputStyle={{
                                  width: '100%',
                                  height: '37px',
                                }}
                                className="w-full"
                                name="phone_number"
                                required
                                value={
                                  item?.phoneNumber?.dialCode?.concat(
                                    item?.phoneNumber?.phoneNumber
                                  ) || loginUserData?.phoneNumber?.countryCode
                                }
                                size="small"
                                country={
                                  loginUserData?.isoCode?.toLowerCase() ||
                                  loginUserData?.phoneNumber?.isoCode?.toLowerCase()
                                }
                                enableSearch="true"
                                onChange={(value, data) => {
                                  handlePhoneChange(value, data, i);
                                  const validPhoneNumber = handlePhoneNumberValidationCheck(
                                    value,
                                    data
                                  );
                                  setErrorMessage((prevErrors) => {
                                    const newErrors = [...prevErrors];
                                    newErrors[i] = validPhoneNumber;
                                    return newErrors;
                                  });
                                }}
                              />
                              {!errorMessage[i] &&
                                item?.phoneNumber?.dialCode?.concat(item?.phoneNumber?.phoneNumber)
                                  ?.length > 0 && (
                                  <p className="text-red mt-5 text-16">
                                    {t('productSettings.InvalidPhoneNumber')}
                                  </p>
                                )}
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="flex flex-col sm:flex-row justify-center w-full gap-20 mt-28">
                <Button
                  className="md:text-18 px-36 sm:px-60 font-semibold disabled:text-black rounded-md"
                  variant="outlined"
                  color="secondary"
                  type="button"
                  onClick={() =>
                    history.push(`/business/dashboard/${params?.id ? params?.id : ''}`)
                  }
                >
                  {t('manageBusiness.button.finish')}
                </Button>
                <Button
                  className={`bg-darkgreen rounded-md text-white hover:bg-darkgreen disabled:text-black disabled:bg-gray ${
                    tabValue === '3' ? 'px-40' : 'px-60'
                  }`}
                  onClick={() => handleRedirect()}
                  disabled={!isUserDataValid().every((status) => status)}
                >
                  {tabValue === '2'
                    ? `${t('dashboard.exploreFeatures.campaigns.sendEmail')}`
                    : tabValue === '3'
                    ? `${t('dashboard.exploreFeatures.campaigns.sendMessage')}`
                    : `${t('dashboard.exploreFeatures.campaigns.sendSms')}`}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickCampaignsFeatures;
