import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import { handleApiRequest, handlePhoneNumberValidationCheck } from 'src/app/main/common/common';
import userService from 'src/app/services';
import quickAdsQuery from 'src/app/main/query/quickAds';
import { Search } from '@mui/icons-material';
import PreviewPopUp from './PreviewPopUp';
import InstantFormPopUp from './InstantFormPopUp';

const defaultValues = {
  firstName: '',
  phone_number: '',
  mail: '',
};

const AdCopy = ({
  mediaFiles,
  data,
  headline,
  setHeadline,
  primaryText,
  setPrimaryText,
  selected,
  setSelected,
  CTA,
  setCTA,
  frequency,
  setFrequency,
  createAndUpdateAdsCampaign,
  instantFormTitle,
  setInstantFormTitle,
  instantHeadline,
  setInstantHeadline,
  instantSelected,
  setInstantSelected,
  instantCTA,
  setInstantCTA,
  preview,
  setPreview,
  activeTab,
  setActiveTab,
  instantForm,
  setInstantForm,
  refresh,
  mobileApp,
  setMobileApp,
  appName,
  setAppName,
  phoneValue,
  setPhoneValue,
  website,
  setWebsite,
  publishAds,
}) => {
  const { t } = useTranslation();
  const loginUserData = userService.getUserData();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [destination, setDestination] = useState();
  const [number, setNumber] = useState();
  const [phone, setPhone] = useState();
  const [whatsApp, setWhatsApp] = useState();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(true);

  useEffect(() => {
    const getQuickAdsObjectiveDestination = async () => {
      try {
        const payload = {
          query: quickAdsQuery.getQuickAdsObjectiveDestination,
          variables: {
            objectiveDestinationIds: data?.objectiveDestinationIds,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getQuickAdsObjectiveDestination?.status === 200) {
          setDestination(result?.getQuickAdsObjectiveDestination?.data);
        }
      } catch {
        console.error('Error fetching quick ads objective destination:');
      }
    };
    if (data?.objectiveDestinationIds) {
      getQuickAdsObjectiveDestination();
    }
  }, [data?.objectiveDestinationIds, error]);

  const validateUrl = (value) => {
    const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\w.-]*)*\/?$/;
    return urlRegex?.test(value);
  };

  const handleWebsiteChange = ({ target: { value } }) => {
    setWebsite(value);
    setError(value && !validateUrl(value));
  };

  const handleChange = (event, newValue) => {
    setFrequency(newValue);
  };

  const schema = yup.object().shape({
    phone_number: yup.string().required(t('signInPage.enterPhoneNumber')),
    mail: yup.string().email(t('common.enterAValidEmail')).trim(),
  });

  const { formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;
  const handlePhoneChange = (event, dataTwo, value, formattedValue) => {
    setPhoneValue(event);
    setPhone({
      ...dataTwo,
      phoneNumber: Number(event.slice(dataTwo?.dialCode?.length)),
    });
    setValue('phone_number', event.slice(dataTwo?.dialCode?.length), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <div className="quick-social">
      <div className="bg-white lg:w-1/2 w-full p-10">
        <h1 className="font-600 mb-10 mt-0 text-14 lg:text-18 bg-white p-10 ">
          {t('quickAds.adCopy&CTASelection')}
        </h1>
        <div className="flex gap-28 mb-20">
          {mediaFiles && (
            <img
              src={URL.createObjectURL(mediaFiles[0])}
              alt="preview"
              className="max-h-256 max-w-256"
            />
          )}
          <div className="w-full">
            <div className="mb-20 ">
              <h3 className="text-18 font-semibold mb-10">{t('quickAds.headline')}</h3>
              <TextField
                className="w-full border-grey-300 bg-gray-A500"
                value={headline}
                size="medium"
                onChange={(e) => setHeadline(e.target.value)}
                placeholder="Enter Campaign Name"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#f5f6fa',
                    },
                    '&:hover fieldset': {
                      borderColor: '#219EBC',
                    },
                  },
                  '& .MuiInputBase-input': {
                    fontWeight: '600',
                    lineHeight: '22px',
                    fontSize: 16,
                  },
                }}
              />
            </div>
            <div className="mb-20">
              <h3 className="text-18 font-semibold mb-10">{t('quickAds.primaryText')}</h3>
              <TextField
                className="w-full border-grey-300 bg-gray-A500"
                value={primaryText}
                size="medium"
                rows={3}
                multiline
                onChange={(e) => setPrimaryText(e.target.value)}
                placeholder="Enter Campaign Name"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#f5f6fa',
                    },
                    '&:hover fieldset': {
                      borderColor: '#219EBC',
                    },
                  },
                  '& .MuiInputBase-input': {
                    fontWeight: '600',
                    lineHeight: '22px',
                    fontSize: 16,
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="mb-20">
          <h3 className="text-18 font-semibold mb-10">{t('quickAds.selectCTA')}</h3>
          <Select
            className="w-full border-grey-300 bg-gray-A500"
            value={CTA}
            onChange={(e) => setCTA(e.target.value)}
            displayEmpty
            size="medium"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#f5f6fa',
                },
                '&:hover fieldset': {
                  borderColor: '#219EBC',
                },
              },
              '& .MuiSelect-select': {
                fontWeight: '600',
                lineHeight: '22px',
                fontSize: 16,
              },
            }}
          >
            <MenuItem value="" disabled>
              {t('quickAds.select')}
            </MenuItem>
            <MenuItem value="LEARN_MORE">{t('plan.learnMore')}</MenuItem>
            <MenuItem value="SHOP_NOW"> {t('quickAds.shopNow')} </MenuItem>
            <MenuItem value="SIGN_UP">{t('quickAds.signUp')}</MenuItem>
          </Select>
        </div>
        <>
          <div className="border-2 border-black rounded-md py-12 px-20 mb-14">
            <h1 className="text-18 font-500"> {t('quickAds.setDestination')}</h1>
            <p className="text-16 font-500 text-gray">{t('quickAds.setDestinationContent')}</p>
            <RadioGroup
              row
              value={selected}
              onChange={(e) => {
                setSelected(e.target.value);
                if (e.target.value === '6') {
                  setInstantForm(true);
                }
              }}
              className="mb-16"
            >
              <div>
                {destination?.some((des) => des?.id !== 7) &&
                  destination?.map((redirect, index) => (
                    <div key={index}>
                      <div>
                        <FormControlLabel
                          value={redirect?.id}
                          control={<Radio />}
                          label={
                            <div className="flex items-center gap-6 justify-between w-full">
                              <>
                                <img
                                  src={redirect?.icon}
                                  alt=" "
                                  className="mr-2 max-h-20 max-w-20"
                                />{' '}
                                <p className="text-16 font-500">{redirect?.name}</p>
                              </>
                              <div>
                                {instantHeadline?.length > 1 &&
                                  instantSelected?.length > 1 &&
                                  instantFormTitle !== '' &&
                                  redirect?.name === 'Instant Lead Form' && (
                                    <Button
                                      className="md:text-16 font-medium rounded-md px-28 bg-quick-social-100"
                                      variant="outlined"
                                      color="quickSocial"
                                      size="small"
                                      onClick={() => setInstantForm(true)}
                                    >
                                      {t('common.edit')}
                                    </Button>
                                  )}
                              </div>
                            </div>
                          }
                          className="text-18 w-full"
                        />{' '}
                      </div>
                      {Number(selected) === 1 && redirect?.name === 'Website' && (
                        <>
                          <TextField
                            type="url"
                            placeholder="Search"
                            variant="outlined"
                            margin="normal"
                            value={website}
                            error={error}
                            helperText={error ? t('quickAds.numberValidation') : ''}
                            className="bg-gray-A500 border-none rounded-sm border-grey-A500 m-0 max-h-40 sm:w-320 w-full mb-20"
                            onChange={handleWebsiteChange}
                            InputProps={{
                              endAdornment: website && (
                                <InputAdornment position="end">
                                  <DeleteOutlineIcon
                                    className="text-20 text-black cursor-pointer "
                                    onClick={() => setWebsite('')}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      )}

                      {Number(selected) === 3 && redirect?.name === 'Calls' && (
                        <>
                          <PhoneInput
                            placeholder={t('common.phoneNumber')}
                            inputStyle={{
                              width: '100%',
                              height: '37px',
                            }}
                            className="w-full"
                            name="phone_number"
                            required
                            error={!!errors.phone_number}
                            helperText={errors?.phone_number?.message}
                            value={phoneValue}
                            size="small"
                            country={loginUserData?.isoCode?.toLowerCase()}
                            enableSearch="true"
                            onChange={(value, dataOne) => {
                              handlePhoneChange(value, dataOne);
                              const validPhoneNumber = handlePhoneNumberValidationCheck(
                                value,
                                dataOne
                              );
                              setErrorMessage(validPhoneNumber);
                            }}
                          />
                        </>
                      )}

                      {Number(selected) === 2 && redirect?.name === 'Whatsapp' && (
                        <>
                          <TextField
                            type="number"
                            placeholder={t('common.phoneNumber')}
                            variant="outlined"
                            margin="normal"
                            value={whatsApp}
                            className="bg-gray-A500 border-none rounded-sm border-grey-A500 m-0 max-h-40 sm:w-320 w-full"
                            onChange={(e) => setWhatsApp(e?.target?.value)}
                            InputProps={{
                              endAdornment: whatsApp && (
                                <InputAdornment position="end">
                                  <DeleteOutlineIcon
                                    className="text-20 text-black cursor-pointer "
                                    onClick={() => setNumber()}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      )}
                    </div>
                  ))}
              </div>
            </RadioGroup>
            {destination?.some((des) => des?.id === 7) && (
              <div>
                <div>
                  <h3 className="text-18 font-semibold mb-10">{t('quickAds.mobileApp')}</h3>
                  <div className="w-full">
                    <Select
                      className="w-full border-grey-300 bg-gray-A500"
                      value={mobileApp}
                      onChange={(e) => setMobileApp(e.target.value)}
                      displayEmpty
                      size="small"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#9B9B9B',
                          },
                          '&:hover fieldset': {
                            borderColor: '#219EBC',
                          },
                        },
                        '& .MuiSelect-select': {
                          fontWeight: '600',
                          lineHeight: '22px',
                          fontSize: 16,
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        {t('quickAds.select')}
                      </MenuItem>
                      <MenuItem value="APP_STORE">{t('quickAds.appStore')}</MenuItem>
                      <MenuItem value="PLAY_Store"> {t('quickAds.playStore')} </MenuItem>
                    </Select>
                  </div>
                </div>
                <>
                  {' '}
                  <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
                    {t('quickAds.appName')}
                  </Typography>
                  <Box display="flex" alignItems="center" gap={2} mt={2}>
                    <TextField
                      value={appName}
                      className="bg-gray-A500"
                      onChange={(e) => setAppName(e.target.value)}
                      variant="outlined"
                      placeholder={t('quickAds.searchTargetedAreas')}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <IconButton className="p-0">
                            <Search />
                          </IconButton>
                        ),
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#9B9B9B',
                          },
                          '&:hover fieldset': {
                            borderColor: '#219EBC',
                          },
                        },
                        '& .MuiSelect-select': {
                          fontWeight: '500',
                          lineHeight: '22px',
                          fontSize: 14,
                        },
                      }}
                      fullWidth
                    />
                  </Box>
                </>
              </div>
            )}
          </div>
          {data?.name === 'Awareness' && (
            <div className="p-10 mb-32">
              <h1 className="text-18 font-500"> {t('quickAds.adFrequencyCap')} </h1>
              <p className="text-16 font-500 text-gray">{t('quickAds.adFrequencyCapContent')}</p>
              <div className="relative">
                <Slider
                  value={frequency}
                  onChange={handleChange}
                  min={1}
                  max={5}
                  step={1}
                  marks={[
                    { value: 1, label: '01' },
                    { value: 2, label: '02' },
                    { value: 3, label: '03' },
                    { value: 4, label: '04' },
                    { value: 5, label: '05' },
                  ]}
                  valueLabelDisplay="on"
                  classes={{
                    thumb: 'bg-quick-social',
                    track: 'bg-quick-social',
                    rail: 'bg-gray-300',
                  }}
                />
              </div>
            </div>
          )}
        </>
        <div className="flex justify-between items-center">
          <Button
            className="md:text-16 font-medium rounded-md px-28 disabled:bg-gray"
            variant="outlined"
            color="quickSocial"
            onClick={() => createAndUpdateAdsCampaign('Draft')}
          >
            {t('quickAds.saveDraft')}
            {refreshIcon && (
              <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
            )}
          </Button>
          <div className="flex items-center justify-end gap-10">
            <Button
              className="md:text-16 font-medium rounded-md px-28 disabled:bg-gray  disabled:text-white"
              variant="outlined"
              color="quickSocial"
              disabled={!CTA || !primaryText || !headline || refresh}
              onClick={() => {
                createAndUpdateAdsCampaign('preview');
              }}
            >
              {t('promotion.preview')}
              {refresh && (
                <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
              )}
            </Button>
            <Button
              className="md:text-16 font-medium rounded-md px-28 disabled:bg-gray disabled:text-white"
              variant="contained"
              color="quickSocial"
              onClick={() => publishAds()}
              disabled={!CTA || !primaryText || !headline || refreshIcon || refresh}
            >
              {t('appWebsites.users.publish')}
              {refreshIcon && (
                <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
              )}
            </Button>
          </div>
        </div>
      </div>
      {preview && (
        <PreviewPopUp
          open={preview}
          onClose={() => setPreview(null)}
          Image={preview}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}
      {instantForm && (
        <InstantFormPopUp
          open={instantForm}
          onClose={() => setInstantForm(false)}
          instantFormTitle={instantFormTitle}
          setInstantFormTitle={setInstantFormTitle}
          instantHeadline={instantHeadline}
          setInstantHeadline={setInstantHeadline}
          instantSelected={instantSelected}
          setInstantSelected={setInstantSelected}
          instantCTA={instantCTA}
          setInstantCTA={setInstantCTA}
          createAndUpdateAdsCampaign={createAndUpdateAdsCampaign}
        />
      )}
    </div>
  );
};

export default AdCopy;
