import {
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import history from '@history';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import quickHub from '../../../query/quickHub';
import userService from '../../../../services';
import { duration, formatDateWithTimeZone, handleApiRequest } from '../../../common/common';
import { getAllUserBusinessSubscription, selectDashboardData } from '../../../../store/userSlice';

const SelectPlan = () => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const loginUserData = userService.getUserData();
  const [productData, setProductData] = useState([]);
  const [isCheckOut, setIsCheckOut] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState('subscription');
  const [packageIds, setPackageIds] = useState([]);
  const [packageIntervalType, setPackageIntervalType] = useState('month');
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [comboDetails, setComboDetails] = useState();
  const [loading, setLoading] = useState(false);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const { enqueueSnackbar } = useSnackbar();
  const countryId = Number(loginUserData?.countryId);

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      setPackageIds(location.state?.packageBundle || []);
      setPackageIntervalType(location.state?.intervalType || 'month');
      setPaymentDetails(location.state?.paymentDetails || 'subscription');
      if (location?.state?.specialOffer) {
        setIsCheckOut(true);
        setPackageIds(location?.state?.packageBundle);
      }
    }
  }, [location.state]);

  const handleCheckOutNextStep = useCallback(async () => {
    try {
      setRefreshIcon(true);

      const stripeIds = productData
        ?.filter((item) => packageIds?.includes(item.packageTypeId))
        ?.map((item) => item.stripePriceId);

      if (countryId !== 2 && (!stripeIds || stripeIds.length === 0)) {
        setRefreshIcon(false);
        return;
      }

      const payload = {
        query: quickHub.getCombo,
        variables: {
          countryId,
          interval: packageIntervalType,
          ...(packageIds?.length > 1 && countryId === 2
            ? { packageTypeId: packageIds }
            : countryId === 2 && {
                packageId: productData?.find((item) => item.packageTypeId === packageIds[0])?.id,
              }),
          ...(countryId !== 2 && { stripeIds }),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.getCombo?.status === 200) {
        setIsCheckOut(true);
        setComboDetails(result?.getCombo?.data);
      } else {
        enqueueSnackbar(result?.getCombo?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      setRefreshIcon(false);
    }
  }, [enqueueSnackbar, countryId, packageIds, packageIntervalType, productData]);

  useEffect(() => {
    if (location?.state?.specialOffer && isCheckOut && packageIds.length > 0) {
      handleCheckOutNextStep();
    }
  }, [isCheckOut, packageIds, location?.state?.specialOffer, handleCheckOutNextStep]);
  const loadScript = (src) =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => reject(new Error('Razorpay SDK failed to load.'));
      document.body.appendChild(script);
    });
  const transformUserSubscriptionData = (data) => {
    return data?.reduce((acc, item) => {
      const addToAcc = (details, key) => {
        details?.forEach((detail) => {
          if (detail?.[key]) {
            acc.push({ name: detail?.[key] });
          }
        });
      };
      if (item?.name !== 'quick bundle' && item?.name !== 'quick combo') {
        acc.push({ name: item?.name });
      }
      addToAcc(item?.comboDetails, 'name');
      addToAcc(item?.packageBundleDetails, 'name');
      return acc;
    }, []);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const payload = {
          query: quickHub.getQuickHubProducts,
          variables: {
            countryId,
            packageInterval: packageIntervalType,
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getQuickHubProducts?.status === 200) {
          const data = result.getQuickHubProducts?.data;
          const findBusinessPackageDetails = userSubscriptionData?.filter(
            (item) => item?.userBusinessId === userCurrentBusinessDetails?.id
          );
          const filterPurchaseOrNot = findBusinessPackageDetails?.filter((item) => {
            const isValidPaymentType =
              (item.isOneTimePayment || item.isSubscription) &&
              !(item.isOneTimePayment && item.isSubscription);
            const isNotExpired = new Date(item.expireDate) > new Date();

            return isValidPaymentType && isNotExpired;
          });
          const products = transformUserSubscriptionData(filterPurchaseOrNot);
          const filterExistingPackage = data?.filter(
            (item) => !products?.some((value) => value.name === item.name)
          );
          setProductData(location.state?.isBusinessAdded ? filterExistingPackage : data || []);
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching QuickHub products:', error);
      }
    };
    if (packageIntervalType && countryId) {
      fetchData();
    }
  }, [
    countryId,
    packageIntervalType,
    userSubscriptionData,
    userCurrentBusinessDetails?.id,
    location.state,
  ]);

  const handleSelectProduct = (id) => {
    setPackageIds((prevPackageIds) =>
      prevPackageIds.includes(id)
        ? prevPackageIds.filter((packageId) => packageId !== id)
        : [...prevPackageIds, id]
    );
  };
  console.log('loginUserData', loginUserData);
  const handleCheckOut = async () => {
    handleCheckOutNextStep();
  };
  const handlePay = async () => {
    setRefreshIcon(true);
    try {
      let payload = {};
      if (countryId === 2 && paymentDetails === 'oneTimePayment') {
        payload = {
          query: quickHub.redirectPortalForRazorpayOneTimePayment,
          variables: {
            amount: comboDetails?.offerAmount || comboDetails?.amount,
            billingCycle: duration.find((value) => value.interval === packageIntervalType)
              ?.oneTimeInterval,
            isCombo: false,
            packageId: comboDetails?.packageId,
            ...(location.state?.isBusinessAdded && {
              userBusinessId: Number(userCurrentBusinessDetails?.id),
            }),
          },
        };
      } else if (paymentDetails === 'subscription') {
        payload = {
          query: quickHub.redirectPortalForSubscription,
          variables: {
            countryId,
            ...(countryId === 2
              ? {
                  priceId: comboDetails?.razorpayPlanId,
                }
              : {
                  stripePriceIds: productData
                    ?.filter((item) => packageIds?.includes(item.packageTypeId))
                    .map((item) => item.stripePriceId),
                }),
            ...(location.state?.isBusinessAdded && {
              userBusinessId: Number(userCurrentBusinessDetails?.id),
            }),
          },
        };
      }
      const result = await handleApiRequest(payload);
      // setRefreshIcon(false);
      const response =
        countryId === 2 && paymentDetails === 'oneTimePayment'
          ? result?.redirectPortalForRazorpayOneTimePayment
          : result?.redirectPortalForSubscription;
      if (response?.status === 200) {
        if (countryId === 2 && paymentDetails === 'subscription') {
          const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

          if (!res) {
            console.log('Razorpay SDK failed to load. Are you online?');
            return;
          }
          setRefreshIcon(false);
          const options = {
            key: process.env.REACT_APP_RAZORPAY_PUBLIC_KEY,
            subscription_id: response?.data?.razorpaySubscriptionId,
            name: 'Quick Hub',
            prefill: {
              name: loginUserData?.fullName,
              email: loginUserData?.email,
              contact: loginUserData?.phoneNumber?.phoneNumber,
            },
            theme: {
              color: '#0BA861',
            },
            handler: (responseRaz) => {
              if (responseRaz) {
                setRefreshIcon(true);
                enqueueSnackbar(
                  'Thank you for purchasing the plan! Your subscription will be active in 10 minutes.',
                  {
                    variant: 'success',
                    autoHideDuration: 2000,
                  }
                );
                history.push('/mySubscription');
              }
            },
            timeout: 600,
            modal: {
              confirm_close: true,
              ondismiss: async (reason) => {
                setRefreshIcon(false);
                enqueueSnackbar(
                  'Your payment was not completed. Please try again to continue with your purchase',
                  {
                    variant: 'error',
                    autoHideDuration: 2000,
                  }
                );
              },
            },
          };
          const razorpayInstance = new window.Razorpay(options);
          razorpayInstance.open();
        } else {
          const redirectUrl = response?.data?.url;
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            throw new Error('Redirect URL not found');
          }
        }
      } else {
        enqueueSnackbar(response?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
        throw new Error('Payment request failed');
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error during payment processing:', error);
    }
  };

  const clickOnCheckFeature = (data) => {
    const { packageTypeId } = data || {};

    const routesMap = {
      7: 'quick-reviews',
      9: 'quick-chat',
      8: 'quick-social',
      10: 'quick-campaign',
    };

    const route = routesMap[packageTypeId];
    history.push(`${route}/description/`, { isRedirectNotNeed: true });
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="">
      <div className="md:flex w-full justify-between">
        <div className="p-28 w-full">
          {productData?.filter((item) => !packageIds?.includes(item.packageTypeId)).length > 0 && (
            <Typography className="font-bold text-24">
              {t('selectProduct.SelectYourProduct')}
            </Typography>
          )}
          <div className="mt-20">
            {productData?.length > 0 &&
              productData
                ?.filter((item) => !packageIds?.includes(item.packageTypeId))
                .map((item, index) => {
                  return (
                    <div
                      className="border flex gap-16 items-center !border-gray-A800 border-solid !max-w-[600px] bg-white p-8 rounded-md mb-8"
                      key={index}
                    >
                      <div>
                        <img className="w-48 mx-20" src={item?.image} alt="" />
                      </div>
                      <div className="w-full">
                        <div className="flex justify-between h-32">
                          <Typography className="font-bold text-16">{item?.name}</Typography>
                          <Typography className="mb-12 align-middle capitalize flex flex-col text-end">
                            {item?.offerAmount && (
                              <h3 className="text-16 font-bold mb-0">
                                <span>{countryId === 2 ? '₹' : '$'}</span>
                                {item?.offerAmount}/{item?.packageInterval?.replace(/_/g, ' ')}
                              </h3>
                            )}
                          </Typography>
                        </div>
                        <div className="flex justify-between w-full">
                          <Typography className="max-w-320 font-semibold text-12">
                            {item?.description}
                          </Typography>
                          <h3
                            className={`block ${
                              item?.offerAmount
                                ? 'line-through text-grey-500 font-medium text-14'
                                : 'text-16 font-bold'
                            }`}
                          >
                            <span>{countryId === 2 ? '₹' : '$'}</span>
                            {item?.amount}/{item?.packageInterval?.replace(/_/g, ' ')}
                          </h3>
                        </div>
                        <div className="flex justify-between w-full">
                          <Button
                            className="text-darkgreen p-0 h-16 max-h-16 min-h-32 hover:bg-transparent font-bold"
                            onClick={() => clickOnCheckFeature(item)}
                          >
                            {t('plan.checkFeatures')} <KeyboardArrowRightIcon />
                          </Button>
                          <Checkbox
                            onChange={() => handleSelectProduct(item?.packageTypeId)}
                            checked={packageIds?.some(
                              (packageId) => packageId === item.packageTypeId
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
        {!isCheckOut ? (
          <div className="p-28 border !border-gray-A900 rounded-md w-full lg:!max-w-[466px] border-t-0 min-h-[calc(100vh-65px)]">
            <Typography className="font-bold text-24">{t('plan.selectProducts')}</Typography>
            {location.state?.isBusinessAdded && userCurrentBusinessDetails && (
              <div className="flex bg-white p-12 gap-12 items-center my-20">
                <img
                  className="w-40 h-40 rounded-md"
                  src={
                    userCurrentBusinessDetails?.profileURL ||
                    'assets/images/business/businessprofile.png'
                  }
                  alt=""
                />
                <div>
                  <Typography className="font-semibold">
                    {userCurrentBusinessDetails?.name}
                  </Typography>
                  <Typography>{userCurrentBusinessDetails?.address}</Typography>
                </div>
              </div>
            )}
            {countryId === 2 && (
              <div className="my-16 w-full border border-darkgreen flex justify-between rounded-full items-center">
                <Button
                  className={`text-center w-full font-semibold flex justify-center items-center ${
                    paymentDetails === 'subscription'
                      ? 'bg-darkgreen hover:bg-darkgreen text-white'
                      : 'bg-white hover:bg-white text-darkgreen'
                  } ${countryId === 2 ? 'rounded-r-0' : ''}`}
                  onClick={() => {
                    setPaymentDetails('subscription');
                    setPackageIntervalType('month');
                  }}
                >
                  {t('users.UserList.Filter.subscription')}
                </Button>
                <Button
                  className={`text-center w-full rounded-l-0 font-semibold flex justify-center items-center ${
                    paymentDetails === 'oneTimePayment'
                      ? 'bg-darkgreen hover:bg-darkgreen text-white'
                      : 'bg-white hover:bg-white text-darkgreen'
                  }`}
                  onClick={() => setPaymentDetails('oneTimePayment')}
                >
                  {t('plan.oneTimePayment')}
                </Button>
              </div>
            )}
            {paymentDetails === 'subscription' && (
              <div className="text-center">
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={packageIntervalType}
                    onChange={(e) => setPackageIntervalType(e.target.value)}
                  >
                    <FormControlLabel value="month" control={<Radio />} label={t('plan.monthly')} />
                    <FormControlLabel value="year" control={<Radio />} label={t('plan.yearly')} />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
            {paymentDetails === 'oneTimePayment' && (
              <div className="flex gap-12 my-24">
                {duration.map((item, i) => {
                  return (
                    <Button
                      className={`w-full text-center py-8 px-12 font-medium text-12 rounded-md ${
                        packageIntervalType === item.interval
                          ? 'bg-darkgreen-100 text-darkgreen'
                          : 'bg-white'
                      }`}
                      onClick={() => setPackageIntervalType(item.interval)}
                      key={i}
                    >
                      {item.value}
                    </Button>
                  );
                })}
              </div>
            )}
            {productData?.length > 0 &&
              productData
                ?.filter((item) => packageIds?.includes(item.packageTypeId))
                .map((item, i) => {
                  return (
                    <div
                      className="mt-16 flex gap-24 items-center bg-darkgreen-100 p-8 rounded-md"
                      key={i}
                    >
                      <div className="px-4">
                        <img className="w-60 h-48" src={item?.image} alt="" />
                      </div>
                      <div className="w-full">
                        <div className="flex justify-between">
                          <Typography className="font-bold text-16">{item?.name}</Typography>
                          <Typography>
                            {item?.offerAmount && (
                              <Typography className="text-16 font-bold mb-0">
                                <span>{loginUserData?.countryId === 2 ? '₹' : '$'}</span>
                                {item?.offerAmount}/{item?.packageInterval?.replace(/_/g, ' ')}
                              </Typography>
                            )}
                          </Typography>
                        </div>
                        <div className="flex justify-between">
                          <Typography className="text-12 font-semibold py-4">
                            {duration
                              .find((value) => value.interval === item?.packageInterval)
                              ?.value?.toLowerCase()}{' '}
                            {t('agencyBusiness.clients.plan')}
                          </Typography>
                          <Typography
                            className={`block ${
                              item?.offerAmount
                                ? 'line-through text-grey-500 font-medium text-14'
                                : 'text-16 font-bold'
                            }`}
                          >
                            <span>{loginUserData?.countryId === 2 ? '₹' : '$'}</span>
                            {item?.amount}/{item?.packageInterval?.replace(/_/g, ' ')}
                          </Typography>
                        </div>
                        <div className="flex justify-between items-center h-20">
                          <div>
                            <Typography className="font-semibold text-14">
                              {countryId === 2 ? '₹' : '$'} {item?.offerAmount || item?.amount}{' '}
                              {t('plan.payFor')}{' '}
                              {duration
                                .find((value) => value.interval === item?.packageInterval)
                                ?.value?.toLowerCase()}
                            </Typography>
                          </div>
                          <Checkbox
                            onChange={() => handleSelectProduct(item?.packageTypeId)}
                            checked={packageIds.some(
                              (packageId) => packageId === item.packageTypeId
                            )}
                          />
                        </div>
                        <Button
                          className="text-darkgreen p-0 h-16 max-h-16 min-h-32 hover:bg-transparent font-bold"
                          onClick={() => clickOnCheckFeature(item)}
                        >
                          {t('plan.checkFeatures')} <KeyboardArrowRightIcon />
                        </Button>
                      </div>
                    </div>
                  );
                })}
            {packageIds?.length !== 0 && productData?.length > 0 && (
              <div className="text-center flex justify-center items-center m-auto mt-24 w-3/4">
                <Button
                  className="bg-darkgreen text-white font-bold hover:bg-darkgreen disabled:bg-gray rounded-md w-full"
                  onClick={() => handleCheckOut()}
                  disabled={refreshIcon || packageIds?.length === 0}
                >
                  {t('plan.checkOut')}
                  {refreshIcon && (
                    <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
                  )}
                </Button>
              </div>
            )}
          </div>
        ) : (
          <Drawer className="" anchor="right" open={isCheckOut}>
            <div className="bg-gray-A500 p-28 max-w-[466px] min-w-[466px] h-full rounded-md">
              <div className="flex items-center justify-between w-full">
                <Typography className="font-bold text-24">{t('plan.checkOut')}</Typography>
                <Button
                  className="w-32 min-w-32 hover:bg-transparent"
                  onClick={() => setIsCheckOut(false)}
                >
                  <CloseIcon />
                </Button>
              </div>
              {location.state?.isBusinessAdded && userCurrentBusinessDetails && (
                <div className="flex bg-white p-12 gap-12 items-center my-20">
                  <img
                    className="w-40 h-40 rounded-md"
                    src={
                      userCurrentBusinessDetails?.profileURL ||
                      'assets/images/business/businessprofile.png'
                    }
                    alt=""
                  />
                  <div>
                    <Typography className="font-semibold">
                      {userCurrentBusinessDetails?.name}
                    </Typography>
                    <Typography>{userCurrentBusinessDetails?.address}</Typography>
                  </div>
                </div>
              )}
              <div className="bg-white p-12 rounded-md mt-16">
                <Typography className="font-semibold">{t('plan.selectedProducts')}</Typography>
                {productData
                  ?.filter((item) => packageIds?.includes(item.packageTypeId))
                  .map((item, index) => {
                    return (
                      <div className="border px-16 py-8 rounded-md mt-12" key={index}>
                        <div className="flex justify-between">
                          <Typography className="font-semibold">{item.name}</Typography>
                          {item?.offerAmount && (
                            <Typography className="text-16 font-bold mb-0">
                              <span>{loginUserData?.countryId === 2 ? '₹' : '$'}</span>
                              {item?.offerAmount}/{item?.packageInterval?.replace(/_/g, ' ')}
                            </Typography>
                          )}
                        </div>
                        <div className="flex justify-between">
                          <Typography className="text-14">
                            {countryId === 2 ? '₹' : '$'} {item?.offerAmount || item?.amount}{' '}
                            {t('plan.payFor')}{' '}
                            {duration
                              .find((value) => value.interval === item?.packageInterval)
                              ?.value?.toLowerCase()}
                          </Typography>
                          <Typography
                            className={`block ${
                              item?.offerAmount
                                ? 'line-through text-grey-500 font-medium text-14'
                                : 'text-16 font-bold'
                            }`}
                          >
                            <span>{loginUserData?.countryId === 2 ? '₹' : '$'}</span>
                            {item?.amount}/{item?.packageInterval?.replace(/_/g, ' ')}
                          </Typography>
                        </div>
                      </div>
                    );
                  })}
                {/* {comboDetails?.isOffer && (
                  <Typography className="bg-yellow-A800 text-center rounded-md mt-12 py-8">
                    {t('plan.grateOfferMessage')} {countryId === 2 ? '₹' : '$'}
                    {Number(comboDetails?.amount) - Number(comboDetails?.offerAmount)}
                  </Typography>
                )} */}
              </div>
              <div className="bg-white p-12 rounded-md mt-16">
                <Typography className="font-semibold">{t('plan.billingDetails')}</Typography>
                <hr className="border border-dashed !border-gray my-12" />
                <div className="flex justify-between">
                  <Typography className="text-gray">{t('plan.billingCycle')}</Typography>
                  <Typography className="font-semibold">
                    {paymentDetails === 'oneTimePayment'
                      ? t('plan.oneTime')
                      : packageIntervalType === 'year'
                      ? t('plan.annually')
                      : t('plan.monthly')}
                  </Typography>
                </div>
                <div className="flex justify-between my-8">
                  <Typography className="text-gray">{t('plan.startDate')}</Typography>
                  <Typography className="font-semibold">
                    {formatDateWithTimeZone(comboDetails?.startDate, 'MMM DD, YYYY')}
                  </Typography>
                </div>
                {paymentDetails === 'subscription' && (
                  <div className="flex justify-between">
                    <Typography className="text-gray">{t('common.nextRecurringDate')}</Typography>
                    <Typography className="font-semibold">
                      {formatDateWithTimeZone(comboDetails?.expireDate, 'MMM DD, YYYY')}
                    </Typography>
                  </div>
                )}
                {comboDetails?.isOffer && (
                  <Typography className="bg-[#e9f3ff] rounded-md mt-12 px-6 py-16 border border-dashed border-[#2b8cff]">
                    {t('common.note')}: {t('plan.after')}{' '}
                    {duration
                      .find((value) => value.interval === packageIntervalType)
                      ?.value?.toLowerCase()}
                    , {t('plan.noteMessage')} {countryId === 2 ? '₹' : '$'}{' '}
                    {comboDetails?.offerAmount || comboDetails?.amount}
                  </Typography>
                )}
              </div>
              <div className="bg-white p-12 rounded-md mt-16">
                <Typography className="font-semibold">{t('plan.orderSummary')}</Typography>
                <hr className="border border-dashed !border-gray my-12" />
                <div className="flex justify-between">
                  <Typography className="text-gray">{t('plan.originalAmount')}</Typography>
                  <Typography className="font-semibold">
                    {countryId === 2 ? '₹' : '$'} {comboDetails?.amount}
                  </Typography>
                </div>
                <div className="flex justify-between my-8">
                  <Typography className="text-gray">{t('plan.discountAmount')}</Typography>
                  <Typography className="font-semibold">
                    - {countryId === 2 ? '₹' : '$'} {comboDetails?.discount}
                  </Typography>
                </div>

                <div className="flex justify-between my-8">
                  <Typography className="text-gray">{t('plan.estimatedTax')}</Typography>
                  <Typography className="font-semibold">
                    {countryId === 2 ? '₹' : '$'} {comboDetails?.tax}
                  </Typography>
                </div>
                <div className="flex justify-between">
                  <Typography className="text-gray">{t('plan.totalAmount')}</Typography>
                  <Typography className="font-semibold">
                    {countryId === 2 ? '₹' : '$'}{' '}
                    {comboDetails?.offerAmount || comboDetails?.amount}
                  </Typography>
                </div>
                {/* {comboDetails?.isOffer && (
                  <Typography className="bg-darkgreen-A100 opacity-60 font-medium text-center rounded-md mt-12 py-8">
                    {t('plan.youSaved')} {countryId === 2 ? '₹' : '$'}{' '}
                    {Number(comboDetails?.amount) - Number(comboDetails?.offerAmount)}{' '}
                    {t('plan.timePeriodMessage')}{' '}
                    {duration
                      .find((value) => value.interval === packageIntervalType)
                      ?.value?.toLowerCase()}
                  </Typography>
                )} */}
              </div>
              <div className="text-center m-auto mt-24">
                {!refreshIcon && (
                  <Button
                    className="mb-16 font-bold disabled:bg-gray rounded-md mx-auto w-full max-w-288"
                    onClick={() => handlePay()}
                    color="secondary"
                    variant="contained"
                    disabled={refreshIcon}
                  >
                    {t('plan.pay')} {countryId === 2 ? '₹' : '$'}{' '}
                    {comboDetails?.offerAmount || comboDetails?.amount}
                    {refreshIcon && (
                      <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
                    )}
                  </Button>
                )}
                {refreshIcon && (
                  <div className="payment-loader block">
                    <div className="pad">
                      <div className="chip" />
                      <div className="line line1" />
                      <div className="line line2" />
                    </div>
                    <div className="loader-text">Please wait...</div>
                  </div>
                )}
              </div>
            </div>
          </Drawer>
        )}
      </div>
    </div>
  );
};

export default SelectPlan;
