import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import Table from '@mui/material/Table';
import Select from '@mui/material/Select';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { motion } from 'framer-motion';
import moment from 'moment';
import FuseLoading from '@fuse/core/FuseLoading';
import history from '@history';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import selectedBusinessData from '../../query/selectedBusiness';
import {
  getDemoBusinessWithDemoUserDetail,
  resetSelectedBusiness,
  getUserBusinessStatus,
  fetchDemoBusinessData,
  getSalesPersonBusinessType,
} from './store/selectedBusinessSlice';
import reducer from './store';
import { formatDateWithTimeZone, handleApiRequest } from '../../common/common';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiAutocomplete-tag': {
      backgroundColor: '#0BA861',
      color: 'white',
      borderRadius: '8px',
    },
    '& .MuiChip-deleteIcon': {
      color: 'white',
    },
  },
}));

function SelectedBusiness() {
  const classes = useStyles();
  const defaultValues = {
    sliderName: '',
    des: '',
    buttonName: '',
    urlValue: '',
    packageType: '',
  };

  const dayArray = [
    { id: 1, day: 1 },
    { id: 2, day: 2 },
    { id: 3, day: 3 },
    { id: 4, day: 4 },
    { id: 5, day: 5 },
    { id: 6, day: 6 },
    { id: 7, day: 7 },
  ];

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const setupInProgressLabel = t('business.selectedBusiness.menu.setupInProgress');
  const readyToDemoLabel = t('business.selectedBusiness.menu.readyToDemo');
  const pendingLabel = t('business.selectedBusiness.menu.pending');
  const successLabel = t('business.selectedBusiness.menu.success');
  const [timeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const selectedBusinessList = useSelector(
    ({ selectedBusinessReducer }) =>
      selectedBusinessReducer?.selectedBusiness?.getDemoBusinessWithDemoUserDetailData?.data
        ?.getDemoBusinessWithDemoUserDetail?.data || ''
  );

  const totalUser = useSelector(
    ({ selectedBusinessReducer }) =>
      selectedBusinessReducer?.selectedBusiness?.getDemoBusinessWithDemoUserDetailData?.data
        ?.getDemoBusinessWithDemoUserDetail?.total_count || ''
  );

  const salesUserSelectedBusiness = useSelector(
    ({ selectedBusinessReducer }) =>
      selectedBusinessReducer?.selectedBusiness?.getSalesPersonBusinessTypeData?.data
        ?.getSalesPersonBusinessType?.data || ''
  );

  const getBusinessStatus = useSelector(
    ({ selectedBusinessReducer }) =>
      selectedBusinessReducer?.selectedBusiness?.getUserBusinessStatusData?.data
        ?.getUserBusinessStatus?.data
  );

  const fetchDemoBusinessDataStatus = useSelector(
    ({ selectedBusinessReducer }) =>
      selectedBusinessReducer?.selectedBusiness?.fetchDemoBusinessDataData?.data
        ?.fetchDemoBusinessData
  );

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [processLoading, setProcessLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [selected, setSelected] = useState([]);
  const [statusValue, setStatusValue] = useState('all');
  const [processBusinessId, setProcessBusinessId] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedBusinessType, setSelectedBusinessType] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedBusinessListData, setSelectedBusinessListData] = useState([selectedBusinessList]);
  const [orderNow, setOrderNow] = useState('desc');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('cts');
  const [extendUserId, setExtendUserId] = useState();
  const [selectExtendDays, setSelectExtendDays] = useState();
  const businessTypesSet = new Set();
  Object.values(salesUserSelectedBusiness).forEach((business) => {
    if (business?.bType) {
      businessTypesSet.add(business.bType);
    }
  });
  const businessTypes = Array.from(businessTypesSet);

  useEffect(() => {
    let unmounted = false;
    const payload = {
      query: selectedBusinessData.getDemoBusinessWithDemoUserDetail,
      variables: {
        searchText: '',
        pageNumber: 1,
        pageSize: 10,
        isVisited: false,
        sortBy: 'cts',
        sortOrder: 'desc',
      },
    };
    dispatch(getDemoBusinessWithDemoUserDetail(payload));

    const payloadData = {
      query: selectedBusinessData.getSalesPersonBusinessType,
    };
    dispatch(getSalesPersonBusinessType(payloadData));

    return () => {
      unmounted = true;
      dispatch(resetSelectedBusiness());
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        query: selectedBusinessData.getUserBusinessStatus,
      };
      await dispatch(getUserBusinessStatus(payload));
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (selectedBusinessList || selectedBusinessList === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [selectedBusinessList]);

  useEffect(() => {
    if (fetchDemoBusinessDataStatus && fetchDemoBusinessDataStatus.status === 400) {
      enqueueSnackbar(fetchDemoBusinessDataStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setProcessLoading(false);
      closeSnackbar(3000);
    } else if (fetchDemoBusinessDataStatus && fetchDemoBusinessDataStatus.status === 200) {
      if (
        fetchDemoBusinessDataStatus &&
        fetchDemoBusinessDataStatus?.message === 'Business has no reviews'
      ) {
        enqueueSnackbar(fetchDemoBusinessDataStatus.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(fetchDemoBusinessDataStatus.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      setProcessLoading(false);
      closeSnackbar(3000);
    }
  }, [dispatch, fetchDemoBusinessDataStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (selectedBusinessList && selectedBusinessList.length) {
      setSelectedBusinessListData(selectedBusinessList);
    } else {
      setSelectedBusinessListData();
    }
  }, [selectedBusinessList]);

  function commonGetData(searchValues = '') {
    setSearchLoading(true);
    const payload = {
      query: selectedBusinessData.getDemoBusinessWithDemoUserDetail,
      variables: {
        searchText: searchValues,
        pageNumber: 1,
        pageSize: rowsPerPage,
        businessType: selectedBusinessType,
        isVisited: false,
      },
    };
    if (statusValue !== 'all') {
      if (statusValue === 'bookmark') {
        payload.variables.isBookmark = true;
      } else {
        payload.variables.status = statusValue;
      }
    }

    setTimeout(() => {
      setSearchLoading(false);
    }, 800);
    dispatch(getDemoBusinessWithDemoUserDetail(payload));
  }

  function handleSearchEvent(event) {
    setPage(1);
    const searchText = event.target.value;
    setSearchValue(searchText);
    if (searchText?.length > 3) {
      commonGetData(searchText);
    } else if (searchText?.length === 3) {
      commonGetData();
    }
  }

  const handleSorting = async (event) => {
    const sortColumnName = event;
    setOrderBy(sortColumnName);
    if (order === 'asc') {
      const sorted = [...selectedBusinessListData].sort((sortTypeA, sortTypeB) =>
        sortTypeA.col > sortTypeB.col ? 1 : 1
      );
      setSelectedBusinessListData(sorted);
      setOrderNow('asc');
      setOrder('desc');
    }
    if (order === 'desc') {
      const sorted = [...selectedBusinessListData].sort((sortTypeA, sortTypeB) =>
        sortTypeA.col < sortTypeB.col ? 1 : 1
      );
      setSelectedBusinessListData(sorted);
      setOrderNow('desc');
      setOrder('asc');
    }
    const payload = {
      query: selectedBusinessData.getDemoBusinessWithDemoUserDetail,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: page,
        searchText: searchValue,
        sortBy: sortColumnName,
        sortOrder: order,
      },
    };
    dispatch(getDemoBusinessWithDemoUserDetail(payload));
  };

  const clearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  function handleChangePage(event, value) {
    setPage(value + 1);
    setLoadingPage(true);
    const payload = {
      query: selectedBusinessData.getDemoBusinessWithDemoUserDetail,
      variables: {
        searchText: searchValue,
        pageNumber: value + 1,
        pageSize: rowsPerPage,
        businessType: selectedBusinessType,
        isVisited: false,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    if (statusValue !== 'all') {
      if (statusValue === 'bookmark') {
        payload.variables.isBookmark = true;
      } else {
        payload.variables.status = statusValue;
      }
    }
    dispatch(async (dispatchValue) => {
      await dispatchValue(getDemoBusinessWithDemoUserDetail(payload));
      setLoadingPage(false);
    });
  }

  function handleChangeRowsPerPage(event) {
    setLoadingPage(true);
    const targetStatus = event.target.value;
    setRowsPerPage(targetStatus);
    setPage(1);
    const payload = {
      query: selectedBusinessData.getDemoBusinessWithDemoUserDetail,
      variables: {
        searchText: searchValue,
        pageNumber: 1,
        pageSize: targetStatus,
        businessType: selectedBusinessType,
        isVisited: false,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    if (statusValue !== 'all') {
      if (statusValue === 'bookmark') {
        payload.variables.isBookmark = true;
      } else {
        payload.variables.status = statusValue;
      }
    }
    dispatch(async (dispatchValue) => {
      await dispatchValue(getDemoBusinessWithDemoUserDetail(payload));
      setLoadingPage(false);
    });
  }
  function handleBusinessTypeChange(newValue) {
    setSelectedBusinessType(newValue);
    const payload = {
      query: selectedBusinessData.getDemoBusinessWithDemoUserDetail,
      variables: {
        pageNumber: 1,
        pageSize: rowsPerPage,
        businessType: newValue,
        isVisited: false,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    dispatch(getDemoBusinessWithDemoUserDetail(payload));
  }

  function handleStatusDropdownChange(event) {
    const statusData = event.target.value;
    setPage(1);
    setStatusValue(statusData);
    setLoading(true);
    const payloadData = {
      query: selectedBusinessData.getDemoBusinessWithDemoUserDetail,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: 1,
        searchText: searchValue,
        businessType: selectedBusinessType,
        isVisited: false,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    if (statusData !== 'all') {
      if (statusData === 'bookmark') {
        payloadData.variables.isBookmark = true;
      } else {
        payloadData.variables.status = statusData;
      }
    }

    dispatch(getDemoBusinessWithDemoUserDetail(payloadData));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  function handleProcess(businessId) {
    setProcessLoading(true);
    const payloadData = {
      query: selectedBusinessData.fetchDemoBusinessData,
      variables: {
        accessToken: window.localStorage.getItem('jwt_access_token'),
        businessId,
      },
    };
    dispatch(fetchDemoBusinessData(payloadData));
  }
  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    if (numSelected > 0) {
      return (
        <Toolbar className="absolute left-0 right-0 -top-80 bg-black text-white z-10 rounded-md">
          {numSelected > 0 ? (
            <Typography
              sx={{ flex: '1 1 100%' }}
              color="inherit"
              variant="subtitle1"
              component="div"
              className="text-white"
            >
              {numSelected} Selected
            </Typography>
          ) : (
            ''
          )}

          {numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton className="text-white">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )}
        </Toolbar>
      );
    }
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = selectedBusinessList?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead className="bg-grey-50 rounded-md hidden md:table-header-group">
        <TableRow>
          {/* <TableCell padding="checkbox" className="rounded-tl-md">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all User',
              }}
            />
          </TableCell> */}
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon> {t('appWebsites.users.column.srNo')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('common.businessName')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('business.searchBusiness.column.userName')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel
              direction={order}
              onClick={() => {
                handleSorting('cts');
              }}
            >
              {t('business.selectedBusiness.column.date')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('common.status')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('business.searchBusiness.column.validity')}
            </TableSortLabel>
          </TableCell>
          {/* <TableCell className="text-16 font-medium rounded-tr-md">
            <TableSortLabel hideSortIcon />
          </TableCell> */}
          <TableCell className="text-16 font-medium rounded-tr-md">
            <TableSortLabel hideSortIcon>{t('common.action')}</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  if (loading) {
    return <FuseLoading />;
  }

  function redirectBusinessDetailsRoutes(id) {
    return history.push({
      pathname: `/business-details/${id}`,
    });
  }

  const handleOpenDialogue = (id) => {
    setExtendUserId(id);
    setIsDialogOpen(true);
  };

  const handleSelectExtendDays = (e) => {
    setSelectExtendDays(e.target.value);
  };

  const handleClearExtendDays = () => {
    setIsDialogOpen(false);
    setExtendUserId();
    setSelectExtendDays(0);
  };

  const handleSubmitExtendDays = async () => {
    const payload = {
      query: selectedBusinessData.extendDemoAccountValidity,
      variables: {
        extendDay: selectExtendDays,
        userId: extendUserId,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.extendDemoAccountValidity?.status === 200) {
      enqueueSnackbar(result?.extendDemoAccountValidity?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      handleClearExtendDays();
      commonGetData();
    }
    if (result?.extendDemoAccountValidity?.status === 400) {
      enqueueSnackbar(result?.extendDemoAccountValidity?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <div className="p-20 lg:p-24">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-grey-300 border-t-darkgreen" />
        </div>
      )}
      <div className="md:flex flex-1 w-full items-center justify-between md:mb-36 mb-20 top-page">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
          >
            {t('business.selectedBusiness.title')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-0 md:px-12">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full md:max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder={t('business.visitedBusiness.labels.searchBusinessName')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon
                color="action"
                className="cursor-pointer"
                onClick={() => {
                  clearSearch();
                }}
              >
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div className="items-center justify-end md:flex my-10 md:my-0 sm:inline-block sm:w-1/2 md:w-auto align-middle">
          <Autocomplete
            className="rounded-full border border-darkgreen h-40 bg-white text-16 w-full min-w-192 leading-5 shadow-none md:m-6"
            id="business-type-autocomplete"
            options={businessTypes}
            value={selectedBusinessType}
            sx={{
              '& .MuiFilledInput-root': { pt: '0px', border: 'none' },
              '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
            }}
            size="small"
            onChange={(event, newValue) => handleBusinessTypeChange(newValue)}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Business" variant="outlined" fullWidth />
            )}
          />
        </div>

        <div className="items-center justify-end md:flex sm:inline-block sm:pl-10 md:pl-0 sm:w-1/2 md:w-auto align-middle">
          <Select
            className="rounded-full border border-darkgreen h-40 bg-white text-16 w-full min-w-192 leading-5 shadow-none"
            variant="filled"
            sx={{ '& .MuiFilledInput-input': { pt: '8px' } }}
            value={statusValue}
            fullWidth
            onChange={handleStatusDropdownChange}
            IconComponent={() => (
              <Icon className="text-20 align-middle absolute right-14 text-black ml-2">
                filter_list
              </Icon>
            )}
          >
            <MenuItem value="all" className="capitalize">
              {t('menuItems.all')}
            </MenuItem>
            {getBusinessStatus?.map((item) => {
              if (item.status === 'in_progress' || item.status === 'reachable') {
                return (
                  <MenuItem key={item.id} value={item.status} className="capitalize">
                    {item.status === 'in_progress' &&
                      t('business.selectedBusiness.menu.setupInProgress')}
                    {item.status === 'reachable' && t('business.selectedBusiness.menu.readyToDemo')}
                  </MenuItem>
                );
              }
              return null;
            })}
            <MenuItem value="bookmark" className="capitalize">
              {t('menuItems.bookmark')}
            </MenuItem>
          </Select>
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow">
        {searchLoading ? (
          <div color="textSecondary" className="text-16 py-12 text-center font-semibold">
            {t('business.searchBusiness.labels.loading')}
          </div>
        ) : (
          <>
            {selectedBusinessList?.length > 0 && (
              <>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                  <Table className="w-full" aria-labelledby="tableTitle" size="medium">
                    <EnhancedTableHead
                      numSelected={selected.length}
                      onSelectAllClick={handleSelectAllClick}
                      rowCount={selectedBusinessList.length}
                    />
                    <TableBody className="block md:table-row-group">
                      {selectedBusinessListData &&
                        selectedBusinessListData?.length > 0 &&
                        selectedBusinessListData?.map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              className="border border-t-2 border-t-grey-100 md:table-row"
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              {/* <TableCell
                            padding="checkbox"
                            onClick={(event) => handleClick(event, row.id)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell> */}
                              <TableCell
                                className="text-14 color-black font-semibold block md:table-cell py-5 pt-16 md:py-16"
                                align="left"
                              >
                                {(page - 1) * rowsPerPage + index + 1}
                              </TableCell>
                              <TableCell
                                className="text-14 color-black font-semibold md:w-[15%] block md:table-cell py-5 md:py-16"
                                align="left"
                              >
                                {row?.name}
                              </TableCell>
                              <TableCell
                                className={`text-14 color-black font-semibold md:w-[12%] w-full block md:table-cell break-words whitespace-break-spaces ${
                                  !row?.users?.userName ? 'py-0' : 'py-5 md:py-16'
                                }`}
                                align="left"
                              >
                                <span className="inline-block align-middle md:hidden mr-10 font-bold text-darkgreen">
                                  {t('business.visitedBusiness.column.userName')}:
                                </span>
                                {row?.users?.userName}
                              </TableCell>
                              <TableCell
                                className="text-14 color-black font-semibold block md:table-cell py-5 md:py-16"
                                align="left md:w-[20%]"
                              >
                                {formatDateWithTimeZone(row?.cts, 'MMM DD, YYYY')}
                              </TableCell>
                              <TableCell
                                className={`text-14 color-black font-semibold block md:table-cell py-5 md:py-16 ${
                                  row?.user_business_review_platforms?.[0]?.user_businesses_status
                                    ?.status === 'pending'
                                    ? 'text-orange'
                                    : row?.user_business_review_platforms?.[0]
                                        ?.user_businesses_status?.status === 'reachable'
                                    ? 'text-darkgreen-500'
                                    : row?.user_business_review_platforms?.[0]
                                        ?.user_businesses_status?.status === 'in_progress'
                                    ? 'text-orange'
                                    : row?.user_business_review_platforms?.[0]
                                        ?.user_businesses_status?.status === 'success'
                                    ? 'text-darkgreen-500'
                                    : ''
                                }`}
                                align="left"
                              >
                                <span className="inline-block align-middle md:hidden mr-10 font-bold text-darkgreen">
                                  {t('common.status')}:
                                </span>
                                {row?.user_business_review_platforms?.[0]?.user_businesses_status
                                  ?.status === 'pending'
                                  ? setupInProgressLabel
                                  : row?.user_business_review_platforms?.[0]?.user_businesses_status
                                      ?.status === 'reachable'
                                  ? readyToDemoLabel
                                  : row?.user_business_review_platforms?.[0]?.user_businesses_status
                                      ?.status === 'in_progress'
                                  ? setupInProgressLabel
                                  : row?.user_business_review_platforms?.[0]?.user_businesses_status
                                      ?.status === 'success'
                                  ? successLabel
                                  : ''}
                              </TableCell>
                              <TableCell
                                className="text-14 color-black font-semibold  md:w-[14%] block md:table-cell py-5 md:py-16"
                                align=""
                              >
                                <div className="flex gap-4 items-center">
                                  {row?.user_business_review_platforms?.[0]?.user_businesses_status
                                    ?.status !== 'in_progress' &&
                                    row?.user_business_review_platforms?.[0]?.user_businesses_status
                                      ?.status !== 'pending' && (
                                      <>
                                        <span className="inline-block align-middle md:hidden mr-10 font-bold text-darkgreen">
                                          {t('business.searchBusiness.column.validity')}:
                                        </span>
                                        <Typography className="text-14 font-semibold mr-10">
                                          {' '}
                                          {row?.validity} {t('common.days')}
                                        </Typography>
                                        <Button
                                          className="bg-[#006B5A45] hover:bg-[#006B5A45] px-20 rounded text-darkgreen text-14 font-medium"
                                          onClick={() => handleOpenDialogue(row.users?.id)}
                                          size="small"
                                        >
                                          {' '}
                                          {t('business.searchBusiness.labels.extend')}{' '}
                                        </Button>
                                      </>
                                    )}
                                </div>
                              </TableCell>
                              <TableCell
                                className="text-14 color-black sm:w-144 sm:min-w-144 font-semibold px-16 block md:table-cell py-5 pb-16 md:py-16 md:pl-10"
                                align=""
                              >
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className="md:text-14 sm:text-12 sm:px-5 px-16 h-28 py-2 bg-green-100 text-green font-semibold rounded hover:text-white disabled:bg-gry-400 inline-block"
                                  aria-label="view"
                                  onClick={() => redirectBusinessDetailsRoutes(row.id)}
                                  type="button"
                                  size="small"
                                >
                                  {t('common.viewDetails')}
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {selectedBusinessList?.length === 0 && searchValue !== '' && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full text-center mx-auto"
              >
                <div>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                    className="text-16 py-12 font-semibold max-w-640"
                  >
                    {t('users.userDetails.messages.noBusiness')}
                  </Typography>
                </div>
              </motion.div>
            )}
          </>
        )}
      </div>

      {!searchLoading &&
        selectedBusinessList &&
        selectedBusinessList.length === 0 &&
        searchValue === '' && (
          <>
            {statusValue === 'all' ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full text-center mx-auto h-fit"
              >
                <div>
                  <img
                    src="/assets/images/sales-icon/selected_business.png"
                    className="img-fluid mx-auto max-w-216 pb-40 lg:pt-96"
                    alt="..."
                  />
                  <h5 className="block text-24 font-bold text-black">
                    {t('business.selectedBusiness.labels.doNotHaveSelectedBusiness')}
                  </h5>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                    className="text-16 py-12 font-semibold max-w-640"
                  >
                    {t('business.selectedBusiness.labels.selectedBusinessMessage')}
                  </Typography>
                </div>
              </motion.div>
            ) : statusValue === 'in_progress' ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full text-center mx-auto h-fit"
              >
                <div>
                  <img
                    src="/assets/images/sales-icon/in_Progress.png"
                    className="img-fluid mx-auto max-w-216 pb-40 lg:pt-96"
                    alt="..."
                  />
                  <h5 className="block text-24 font-bold text-black">
                    {t('business.selectedBusiness.labels.notHaveSelectedBusinessIn_Progress')}
                  </h5>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                    className="text-16 py-12 font-semibold max-w-640"
                  >
                    {t('business.selectedBusiness.labels.selectedBusinessIn_ProgressMessage')}
                  </Typography>
                </div>
              </motion.div>
            ) : statusValue === 'reachable' ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full text-center mx-auto h-fit"
              >
                <div>
                  <img
                    src="/assets/images/sales-icon/ready_to_demo.png"
                    className="img-fluid mx-auto max-w-216 pb-40 lg:pt-96"
                    alt="..."
                  />
                  <h5 className="block text-24 font-bold text-black">
                    {t('business.selectedBusiness.labels.notHaveSelectedBusinessReady_to_demo')}
                  </h5>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                    className="text-16 py-12 font-semibold max-w-640"
                  >
                    {t('business.selectedBusiness.labels.selectedBusinessReady_to_demoMessage')}
                  </Typography>
                </div>
              </motion.div>
            ) : statusValue === 'bookmark' ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full text-center mx-auto h-fit"
              >
                <div>
                  <img
                    src="/assets/images/sales-icon/bookmark.png"
                    className="img-fluid mx-auto max-w-216 pb-40 lg:pt-96"
                    alt="..."
                  />
                  <h5 className="block text-24 font-bold text-black">
                    {t('business.selectedBusiness.labels.notHaveBookmarked')}
                  </h5>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                    className="text-16 py-12 font-semibold max-w-640"
                  >
                    {t('business.selectedBusiness.labels.notHaveBookmarkedDesc')}
                  </Typography>
                </div>
              </motion.div>
            ) : (
              ''
            )}
          </>
        )}

      {selectedBusinessList.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalUser}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <Dialog
        classes={{
          root: classes.root,
          paper:
            'm-24 p-28 business-type-dropdown border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md',
        }}
        open={isDialogOpen}
        onClose={() => {
          handleClearExtendDays();
        }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-20">
          <Icon
            onClick={() => {
              handleClearExtendDays();
            }}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            {t('appPromotionSlider.cancel')}
          </Icon>
          <Typography className="text-20 font-semibold text-black border-b-2 border-dashed border-grey-500 w-full pb-10">
            {t('business.searchBusiness.labels.extendValidity')}
          </Typography>
        </DialogTitle>
        <DialogContent className={`${classes.attDialog} p-0 mb-8 business-type-dropdown-content`}>
          <div>
            <div className="w-full">
              <Typography className="text-16 font-medium text-grey-900">
                {t('business.searchBusiness.labels.selectDays')}
              </Typography>
            </div>
            <div className="w-full pt-6">
              <Select
                className="h-[20px]"
                displayEmpty
                value={selectExtendDays || 0}
                id="packageType"
                onChange={handleSelectExtendDays}
                required
                fullWidth
              >
                <MenuItem value={0} disabled>
                  {t('business.searchBusiness.labels.selectDays')}
                </MenuItem>
                {dayArray?.length > 0 &&
                  dayArray.map((item, i) => {
                    return (
                      <MenuItem value={item.id}>
                        {item.day} {i === 0 ? t('common.day') : t('common.days')}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="p-0 m-auto mt-40">
          <Button
            className="md:text-18 px-40 font-semibold bg-white hover:bg-white text-darkgreen border border-solid border-darkgreen rounded-md"
            variant="contained"
            color="primary"
            type="button"
            onClick={() => handleClearExtendDays()}
          >
            {t('common.cancel')}
          </Button>
          <Button
            className="md:text-18 px-36 font-semibold disabled:bg-gray disabled:text-white hover:bg-darkgreen disabled:text-black rounded-md"
            variant="contained"
            color="secondary"
            type="button"
            disabled={!selectExtendDays}
            onClick={() => handleSubmitExtendDays()}
          >
            {t('business.searchBusiness.labels.extendValidity')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withReducer('selectedBusinessReducer', reducer)(SelectedBusiness);
