import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import history from '@history';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import { Search, Info } from '@mui/icons-material';
import PlaceIcon from '@mui/icons-material/Place';
import {
  Button,
  Typography,
  CircularProgress,
  TextField,
  Switch,
  Box,
  FormControlLabel,
  Checkbox,
  Slider,
  FormGroup,
  Chip,
  IconButton,
} from '@mui/material';

function AudienceSegmentBudget({ setActiveStep }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [ageRange, setAgeRange] = useState([20, 38]);
  const [selectedDays, setSelectedDays] = useState([1, 4, 5]);
  const [timeRange, setTimeRange] = useState([7, 18]);
  const [interestName, setInterestName] = useState('');
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [addedAreas, setAddedAreas] = useState(['Nanded City, Pune', 'Khadakwasla, Sinhgad Road']);
  const [addedInterests, setAddedInterests] = useState([
    'Business Professionals',
    'Travel & Adventure',
    'Gaming',
    'Music Lovers',
  ]);

  const suggestedInterests = [
    'Business Professionals',
    'Travel & Adventure',
    'Gaming',
    'Music Lovers',
    'Job Seekers',
  ];

  const totalDay = [
    {
      label: 'S',
      value: 1,
    },
    {
      label: 'M',
      value: 2,
    },
    {
      label: 'T',
      value: 3,
    },
    {
      label: 'W',
      value: 4,
    },
    {
      label: 'T',
      value: 5,
    },
    {
      label: 'F',
      value: 6,
    },
    {
      label: 'S',
      value: 7,
    },
  ];

  if (loading) {
    return <FuseLoading />;
  }

  const handleAgeRangeChange = (event, newValue) => {
    setAgeRange(newValue);
  };

  const handleDayClick = (day) => {
    setSelectedDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };

  const handleToggleDays = () => {
    if (selectedDays.length === totalDay.length) {
      setSelectedDays([]);
    } else {
      setSelectedDays(totalDay.map((day) => day.value));
    }
  };

  const handleToggleTime = () => {
    if (timeRange[0] === 1 && timeRange[1] === 24) {
      setTimeRange([7, 18]);
    } else {
      setTimeRange([1, 24]);
    }
  };

  const handleTimeChange = (event, newValue) => {
    setTimeRange(newValue);
  };

  return (
    <div className="quick-social">
      <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 md:gap-20 gap-10 mb-24">
        <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
          <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">Audience</Typography>
          <FormGroup className="flex flex-row">
            <FormControlLabel
              className="mr-68  text-18"
              control={<Checkbox />}
              label={<span className="text-18 text-black font-medium">All</span>}
            />
            <FormControlLabel
              className="mr-68"
              control={<Checkbox />}
              label={<span className="text-18 text-black font-medium">Male</span>}
            />
            <FormControlLabel
              control={<Checkbox />}
              label={<span className="text-18 text-black font-medium">Female</span>}
            />
          </FormGroup>
        </div>

        <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
          <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
            Audience{' '}
            <span className="text-16 font-medium text-quick-social">{`${ageRange[0]} - ${ageRange[1]} years`}</span>
          </Typography>
          <Slider
            value={ageRange}
            onChange={handleAgeRangeChange}
            valueLabelDisplay="auto"
            min={18}
            max={65}
            sx={{
              color: '#219EBC',
              paddingBottom: '0',
            }}
          />
          <Box display="flex" justifyContent="space-between">
            <Typography className="text-16 font-medium">18</Typography>
            <Typography className="text-16 font-medium">65</Typography>
          </Box>
        </div>
      </div>
      <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
        <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
          Target Areas
        </Typography>
        <Box display="flex" alignItems="center" gap={2} mt={2}>
          <TextField
            variant="outlined"
            placeholder="Search Targeted Areas"
            InputProps={{
              endAdornment: (
                <IconButton className="p-0">
                  <Search />
                </IconButton>
              ),
            }}
            fullWidth
          />
        </Box>
        <Box
          mt={2}
          height="150px"
          bgcolor="#f5f5f5"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>Map Placeholder</Typography>
        </Box>

        <Typography className="font-semibold mb-16 mt-0 text-14 lg:text-18 mt-20">
          Added Areas
        </Typography>
        <div className="flex items-center gap-x-7">
          {addedAreas.map((area, index) => (
            <Chip
              key={index}
              className="rounded-md text-black text-14 font-semibold"
              variant="outlined"
              label={area}
              avatar={<PlaceIcon style={{ fontSize: 14, color: '#f71959', width: 16 }} />}
              onDelete={() => setAddedAreas((prev) => prev.filter((_, i) => i !== index))}
            />
          ))}
        </div>
      </div>

      <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 md:gap-20 gap-10 mb-24">
        <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
          <div className="flex items-center justify-between mb-10">
            <Typography className="font-semibold mb-0 mt-0 text-14 lg:text-18">Days</Typography>
            <span>
              <span className="inline-block text-16 font-medium">All Days</span>
              <Switch
                checked={selectedDays.length === totalDay.length}
                className="P-0 -mr-12"
                onChange={() => handleToggleDays()}
                color="quickSocial"
              />
            </span>
          </div>
          <div className="flex items-center justify-between">
            {totalDay?.map((day, i) => (
              <Button
                key={i}
                className={`rounded-md w-44 h-36 min-w-44 min-h-36 p-0 inline-block font-semibold ${
                  selectedDays.includes(day?.value)
                    ? 'bg-quick-social text-white'
                    : 'bg-white border-gray-A500 text-black'
                }`}
                color="quickSocial"
                variant={selectedDays.includes(day?.value) ? 'contained' : 'outlined'}
                onClick={() => handleDayClick(day?.value)}
              >
                {day?.label}
              </Button>
            ))}
          </div>
        </div>

        <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
          <div className="flex items-center justify-between mb-10">
            <Typography className="font-semibold mb-0 mt-0 text-14 lg:text-18">
              Time{' '}
              <span className="text-16 font-medium text-quick-social ml-40">{`${timeRange[0]} to ${timeRange[1]}`}</span>
            </Typography>
            <span>
              <span className="inline-block text-16 font-medium">Whole Days</span>
              <Switch
                checked={timeRange[0] === 1 && timeRange[1] === 24}
                className="P-0 -mr-12"
                onChange={() => handleToggleTime()}
                color="quickSocial"
              />
            </span>
          </div>

          <Slider
            value={timeRange}
            onChange={handleTimeChange}
            min={1}
            max={24}
            valueLabelDisplay="auto"
            sx={{
              color: '#219EBC',
              paddingBottom: '0',
            }}
          />
          <Box display="flex" justifyContent="space-between">
            <Typography className="text-16 font-medium">12am</Typography>
            <Typography className="text-16 font-medium">11:59pm</Typography>
          </Box>
        </div>
      </div>
      <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
        <Typography className="font-semibold mb-16 mt-0 text-14 lg:text-18">
          Interest & Behaviors
        </Typography>
        <div className="relative mb-12">
          <TextField
            className="w-full"
            value={interestName}
            size="medium"
            placeholder="(ex: Fitness, Food)"
            onChange={(e) => setInterestName(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#b7b7b7',
                },
                '&:hover fieldset': {
                  borderColor: '#219EBC',
                },
              },
              '& .MuiInputBase-input': {
                fontWeight: '600',
                lineHeight: '22px',
                fontSize: 16,
              },
            }}
          />
          <Button
            size="small"
            color="quickSocial"
            onClick={() => {
              setAddedInterests((prev) =>
                prev.includes(interestName) ? prev : [...prev, interestName]
              );
              setInterestName('');
            }}
            variant="contained"
            className="rounded-md text-18 font-medium min-h-32 h-32 absolute right-12 top-12"
            disabled={!interestName}
          >
            + Add
          </Button>
        </div>
        <div className="flex items-center gap-x-7 border-b-1 border-solid border-grey-300 pb-12 mb-12">
          <span className="inline-block text-16 font-medium text-grey-600">Suggested :</span>
          {suggestedInterests.map((interest) => (
            <Chip
              key={interest}
              color="quickSocial"
              className="bg-quick-social-100 text-16 font-medium text-black"
              variant="outlined"
              label={interest}
              onClick={() =>
                setAddedInterests((prev) => (prev.includes(interest) ? prev : [...prev, interest]))
              }
            />
          ))}
        </div>
        <Typography className="font-medium mb-10 mt-0 text-14 lg:text-18">
          Added Interest & Behaviors
        </Typography>
        <div>
          <div className="flex items-center gap-x-7">
            {addedInterests.map((interest, index) => (
              <Chip
                key={index}
                label={interest}
                color="quickSocial"
                className="bg-quick-social-200 text-16 font-medium text-black"
                variant="outlined"
                onDelete={() => setAddedInterests((prev) => prev.filter((_, i) => i !== index))}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <Button
          className="md:text-16 font-medium rounded-md px-28 disabled:bg-gray"
          variant="outlined"
          color="quickSocial"
          //   onClick={() => ()}
          // disabled={}
        >
          Save Draft
          {refreshIcon && (
            <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
          )}
        </Button>
        <Button
          className="md:text-16 font-medium rounded-md px-28 disabled:bg-gray"
          variant="contained"
          color="quickSocial"
          onClick={() => setActiveStep(2)}
          disabled={refreshIcon}
        >
          Proceed
          {refreshIcon && (
            <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
          )}
        </Button>
      </div>
    </div>
  );
}
export default AudienceSegmentBudget;
