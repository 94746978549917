import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Icon,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import history from '@history';
import * as yup from 'yup';
import { t } from 'i18next';
import InputLabel from '@mui/material/InputLabel';
import { useSelector } from 'react-redux';

import _ from '@lodash';
import { useSnackbar } from 'notistack';
import { handleApiRequest } from 'src/app/main/common/common';
import quickAdsQuery from 'src/app/main/query/quick-ads';

const SelectObjective = (props) => {
  const navigate = useNavigate();
  const { openValue, cancelCall } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(openValue);
  const [campaignObjectives, setCampaignObjectives] = useState([]);
  const [objective, setObjective] = useState({});

  console.log('Objective', objective);

  useEffect(() => {
    const fetchDashboard = async () => {
      const payload = {
        query: quickAdsQuery.getQuickAdsObjective,
      };
      const result = await handleApiRequest(payload);
      if (result?.getQuickAdsObjective?.status === 200) {
        setCampaignObjectives(result?.getQuickAdsObjective?.data);
      }
    };
    fetchDashboard();
  }, []);

  const handleClose = () => {
    setDialogOpen(false);
    cancelCall();
  };

  return (
    <Dialog
      classes={{
        paper: 'm-24 rounded-md',
      }}
      open={openValue}
      onKeyDown={(event) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className="p-0 border-b-2 border-solid text-gray-A500 p-20">
        <Icon
          onClick={() => handleClose()}
          className="text-24 absolute top-20 right-20 font-normal cursor-pointer text-black hover:text-gray-700 focus:text-gray-700"
        >
          cancel
        </Icon>
        <Typography className="text-20 font-semibold text-black w-full">
          Campaign Objective
        </Typography>
      </DialogTitle>
      <div className="p-20">
        <h3 className="text-18 font-semibold mb-10">Select Campaign Objective</h3>
        {campaignObjectives &&
          campaignObjectives?.length > 0 &&
          campaignObjectives?.map((item, index) => {
            return (
              <div
                key={index}
                className={`block mt-10 border-2 border-solid  px-20 py-16 shadow rounded-md cursor-pointer ${
                  objective?.id === item?.id ? 'border-quick-social' : 'border-gray-A500'
                }`}
                onClick={() => {
                  setObjective(item);
                }}
                aria-hidden
              >
                <div className="flex items-center">
                  <img className="w-56 h-56" src={item?.icon} alt={item?.name} />
                  <div className="ml-16">
                    <Typography className="text-18 font-medium">{item?.name}</Typography>
                    <Typography className="text-14 font-medium text-grey-600">
                      {item?.description}
                    </Typography>
                  </div>
                </div>
              </div>
            );
          })}
        <div className="text-center">
          <Button
            disabled={!objective?.id}
            onClick={() => history.push('/create-ads', { data: objective })}
            className="rounded-md mt-16"
            color="quickSocial"
            variant="contained"
          >
            Next
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default SelectObjective;
