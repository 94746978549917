const getAdsCampaign = `query ($quickHubAdsAccountId: Int  $campaignStatusFilter: String
$pageNumber: Int
$pageSize: Int $searchText: String){
    getAdsCampaign(quickHubAdsAccountId:$quickHubAdsAccountId campaignStatusFilter:$campaignStatusFilter pageNumber:$pageNumber pageSize:$pageSize searchText: $searchText) {
      status
      message
      totalCount
      data {
      ads{
        id
        cts
        uts
        name
        status
        title
        description
        quickHubAdsAccountId
        quickHubAdsObjectiveId
        quickHubAdsAudienceSegment
        cta
        destinationId
        destinationDetails {
          link
          phoneNumber
          appId
          appStoreUrl
          adsInstantFormId
        }
        adFreqCap
        budget {
          budgetType
          totalBudget
          bidAmount
        }
        scheduleDate
        endDate
        mediaUrl
        shareOnFacebook
        shareOnInstagram
        campaignOverview {
          objective
          genderSelected
          maxAge
          minAge
          geoLocation {
            countries
            cities {
              key
              name
            }
          }
          interest {
            id
            name
          }
        }
        adsSchedule {
          days
          start_minute
          end_minute
        }}
      }
    }
  }`;

const getAdsConnectApis = `query ($businessId:Int)
  { getAdsConnectApis (businessId:$businessId){
        message,
        status,
        data 
          {
            name
            title
            isConnect
            bgImage
            buttonName
          }
      }}`;

const verifyMetaAdsCode = `mutation ($accessToken: String!) {
        verifyMetaAdsCode(
          accessToken: $accessToken
        ) {
          status
          message
          data
          errorMessage
          errorCode
        }
      }`;

const checkInstagramConnectivity = `mutation ($accessToken: String! $pageId: String!) {
        checkInstagramConnectivity(
          pageId:$pageId
          accessToken: $accessToken
        ) {
          status
          message
          isConnected
          errorMessage
          errorCode
        }
      }`;

const saveMetaAdsDetails = `mutation ($data: String!){
  saveMetaAdsDetails(data:$data){
  status
  message
  }
  }`;

const getQuickAdsObjective = `query {
    getQuickAdsObjective {
      status
      message
      data {
        id
        name
        value
        icon
        description
        objectiveDestinationIds
      }
    }
  }`;

const getQuickAdsObjectiveDestination = `query ($objectiveDestinationIds: [Int!]!) {
  getQuickAdsObjectiveDestination(objectiveDestinationIds: $objectiveDestinationIds) {
    status
    message
    data {
      id
      name
      icon
    }
  }
}`;

const searchAdsTargetingInterest = `query ($searchText: String!
$userBusinessId: Int!) {
  searchAdsTargetingInterest(searchText:$searchText, userBusinessId: $userBusinessId) {
    status
    message
     data {
      interests{
        id
        name
        type
      }
       behaviors{
        id
        name
        type
      }
    }
  }
}`;

const searchAdsGeolocation = `query ($searchText: String!
$userBusinessId: Int!){
  searchAdsGeolocation(searchText:$searchText, userBusinessId:$userBusinessId){
    status
    message
    data {
      key
      name
      type
      country_code
      country_name
      region
    }
  }
}`;

const createAndUpdateAdsCampaign = `mutation ($userBusinessId: Int!, $adDraftId: Int, $campaign: QuickAdsCampaign, $adSets: QuickAdsSet, $adCreative: QuickAdsCreative) {
  createAndUpdateAdsCampaign(
    userBusinessId: $userBusinessId
    adDraftId: $adDraftId
    campaign: $campaign
    adSets: $adSets
    adCreative: $adCreative
  ) {
    status
    message
    errorMessage
    data {
      adDraftId
    }
  }
}`;

const getAdsPreview = `query ($adsCampaignId: Int!
$viewFor: String!) {
  getAdsPreview(adsCampaignId:$adsCampaignId , viewFor:$viewFor){
    status
    message
    data {
      previewUrl
      adFormat
    }
  }
}`;

const publishAds = `mutation ($adsCampaignId: Int!) {
  publishAds(adsCampaignId: $adsCampaignId) {
    status
    message
  }
}`;

const quickAdsQuery = {
  getAdsCampaign,
  getAdsConnectApis,
  verifyMetaAdsCode,
  checkInstagramConnectivity,
  saveMetaAdsDetails,
  getQuickAdsObjective,
  searchAdsTargetingInterest,
  searchAdsGeolocation,
  createAndUpdateAdsCampaign,
  getQuickAdsObjectiveDestination,
  getAdsPreview,
  publishAds,
};
export default quickAdsQuery;
