import { Typography } from '@mui/material';
import withReducer from 'app/store/withReducer';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from '@lodash';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import Icon from '@mui/material/Icon';
import { useEffect, useState } from 'react';
import history from '@history';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import ReactSelect from 'react-select';
import reducer from './store';
import UserService from '../../../services/userService';
import cmsData from '../../query/cms';
import {
  getUserTemplateBasicDetails,
  updateUserTemplateBasicDetails,
  resetCmsWebsite,
} from './store/cmsWebsiteSlice';

function BusinessInformation() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /**
   * Form Validation Schema
   */

  const templateDetails = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.getUserTemplateBasicDetailsData?.data
        ?.getUserTemplateBasicDetails?.data?.[0] || ''
  );
  const addAboutInformationStatus = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.updateUserTemplateBasicDetailsData?.data
        ?.updateUserTemplateBasicDetails || ''
  );

  const defaultValues = {
    businessName: '',
    businessEmail: '',
    businessAddress: '',
    phone_number: '',
  };

  const schema = yup.object().shape({
    businessName: yup
      .string()
      .max(
        40,
        t('appWebsite.businessInformation.formField.businessName.validationMessages.maxLength')
      ),
    businessEmail: yup
      .string()
      .email(
        t('appWebsite.businessInformation.formField.businessEmail.validationMessages.validEmail')
      )
      .trim(),
    phone_number: yup.string(),
    businessAddress: yup
      .string()
      .max(
        200,
        t('appWebsite.businessInformation.formField.businessAddress.validationMessages.maxLength')
      ),
  });

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const [loading, setLoading] = useState(true);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [phoneValue, setPhoneValue] = useState();
  const [phone, setPhone] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneCountryCode, setPhoneCountryCode] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [imageUpload, setImageUpload] = useState();
  const [imagePreviewName, setImagePreviewName] = useState();
  const [noImgMsg, setNoImgMsg] = useState('');
  const [imageUploadSecondLogo, setImageUploadSecondLogo] = useState();
  const [imagePreviewNameSecondLogo, setImagePreviewNameSecondLogo] = useState();
  const [noImgMsgSecondLogo, setNoImgMsgSecondLogo] = useState('');
  const [phoneIsoCode, setPhoneIsoCode] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const loginUserData = UserService.getUserData();
  const [businessList, setBusinessList] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const userDomainRegisterId = parseInt(loginUserData?.domainRegisterId, 10);
  const isValidDomainRegisterId = loginUserData?.domainRegisterId;
  const [physicalBusinessTypeList, setPhysicalBusinessTypeList] = useState([]);

  const handleSelectChange = (newSelectedOption) => {
    setSelectedOption(newSelectedOption);
  };

  useEffect(() => {
    if (isValidDomainRegisterId) {
      const payload = {
        query: cmsData.getUserTemplateBasicDetails,
        variables: { domainRegisterId: userDomainRegisterId },
      };
      dispatch(getUserTemplateBasicDetails(payload));
    }

    return () => {
      dispatch(resetCmsWebsite());
    };
  }, [dispatch, userDomainRegisterId, isValidDomainRegisterId]);

  useEffect(() => {
    if (localStorage.getItem('physicalBusinessType')) {
      setPhysicalBusinessTypeList(JSON.parse(localStorage?.getItem('physicalBusinessType')));
    }
  }, []);

  useEffect(() => {
    if (physicalBusinessTypeList?.length > 0) {
      const businessTypeData = physicalBusinessTypeList?.map((idx) => ({
        value: idx.id,
        label: idx.bType,
      }));
      setBusinessList(businessTypeData);
    }
  }, [physicalBusinessTypeList]);

  useEffect(() => {
    setSelectedOption(
      {
        value: templateDetails?.business_types?.id,
        label: templateDetails?.business_types?.bType,
      } || null
    );
  }, [templateDetails]);

  useEffect(() => {
    setPhoneValue(`${templateDetails?.phone?.countryCode} ${templateDetails?.phone?.phoneNumber}`);
  }, [templateDetails?.phone?.countryCode, templateDetails?.phone?.phoneNumber]);

  useEffect(() => {
    if (isValidDomainRegisterId) {
      if (templateDetails || templateDetails === null) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }
  }, [templateDetails, isValidDomainRegisterId]);

  useEffect(() => {
    if (addAboutInformationStatus && addAboutInformationStatus.status === 400) {
      enqueueSnackbar(addAboutInformationStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      closeSnackbar(3000);
    } else if (addAboutInformationStatus && addAboutInformationStatus.status === 200) {
      enqueueSnackbar(addAboutInformationStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      setIsEdit(false);
      closeSnackbar(3000);
      reset();
      setImagePreviewNameSecondLogo();
      setImagePreviewName();
      setImagePreviewNameSecondLogo();
      setImageUpload();
    }
  }, [reset, dispatch, addAboutInformationStatus, enqueueSnackbar, closeSnackbar]);

  if (loading) {
    return <FuseLoading />;
  }

  const onSubmit = async (data) => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: cmsData.updateUserTemplateBasicDetails,
        variables: {
          businessName: data.businessName,
          businessTypeId: parseInt(selectedOption.value, 10),
          email: data.businessEmail,
          countryCode: phone?.dialCode
            ? parseInt(phone?.dialCode, 10)
            : parseInt(phoneCountryCode, 10),
          isoCode: phone?.countryCode ? phone?.countryCode : phoneIsoCode,
          phoneNumber,
          address: data.businessAddress,
          googleMapLink: data.googleMapEmbedLink,
          domainRegisterId: userDomainRegisterId,
          themeId: parseInt(templateDetails?.webTemplateId, 10),
          logo: imageUpload,
          lightLogo: imageUploadSecondLogo,
        },
      };
      await dispatch(updateUserTemplateBasicDetails(payload));

      const payloadAbout = {
        query: cmsData.getUserTemplateBasicDetails,
        variables: { domainRegisterId: userDomainRegisterId },
      };
      dispatch(getUserTemplateBasicDetails(payloadAbout));
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  function handleFileChange(e) {
    const file = e.target.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();

    reader?.readAsBinaryString(file);

    reader.onload = async () => {
      // setImagePreview(`data:${file.type};base64,${btoa(reader.result)}`);
      setImageUpload(file);
      setImagePreviewName(file?.name);
    };
    setNoImgMsg('');
  }

  function handleDrop(e) {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = async () => {
      setImageUpload(file);
      setImagePreviewName(file?.name);
    };
    setNoImgMsg('');
  }

  function handleFileChangeSecondLogo(e) {
    const file = e.target.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();

    reader?.readAsBinaryString(file);

    reader.onload = async () => {
      setImageUploadSecondLogo(file);
      setImagePreviewNameSecondLogo(file?.name);
    };
    setNoImgMsgSecondLogo('');
  }

  function handleDropSecondLogo(e) {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = async () => {
      setImageUploadSecondLogo(file);
      setImagePreviewNameSecondLogo(file?.name);
    };
    setNoImgMsgSecondLogo('');
  }

  function handlePhoneChange(event, data, value, formattedValue) {
    setPhoneNumber(event.slice(data.dialCode.length));
    setPhoneValue(event);
    setPhone(data);
    setValue('phone_number', event.slice(data.dialCode.length), {
      shouldDirty: true,
      shouldValidate: true,
    });
  }
  function redirectUserRoutes() {
    return history.push({
      pathname: `/app-website`,
    });
  }
  return (
    <div className="p-20 lg:p-24 h-full">
      {isValidDomainRegisterId ? (
        <>
          <div className="items-center mb-36">
            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="text-16 md:text-24 font-bold mb-20"
            >
              {t('appWebsite.businessInformation.title')}
            </Typography>
            <>
              {isEdit && (
                <div className="inline-block items-center w-auto float-right">
                  <Button
                    className="text-18 px-15 font-semibold"
                    variant="outlined"
                    color="secondary"
                    aria-label="Edit"
                    onClick={(e) => setIsEdit(false)}
                  >
                    {t('common.cancel')}
                  </Button>
                </div>
              )}
            </>
          </div>
          {!templateDetails || isEdit ? (
            <div className="bg-white rounded-md relative shadow p-24 max-w-640">
              <form
                name="registerForm"
                noValidate
                className="w-full mt-32"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div>
                  <div className="">
                    <div className="block z-0">
                      <InputLabel
                        htmlFor="businessName"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.businessInformation.formField.businessName.name')}*
                      </InputLabel>
                      <Controller
                        name="businessName"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            sx={{
                              '& .MuiInputBase-input': {
                                background: 'white',
                              },
                            }}
                            type="text"
                            size="small"
                            value={undefined}
                            defaultValue={templateDetails?.businessName}
                            error={!!errors.businessName}
                            helperText={errors?.businessName?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div className="block">
                      <InputLabel
                        htmlFor="businesstype"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.businessInformation.formField.businesstype.name')}*
                      </InputLabel>
                      <ReactSelect
                        value={selectedOption}
                        onChange={handleSelectChange}
                        options={businessList}
                        placeholder="Select a business..."
                      />
                    </div>
                    <div className="block mt-24">
                      <InputLabel
                        htmlFor="businessEmail"
                        className="text-16 z-0 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.businessInformation.formField.businessEmail.name')}*
                      </InputLabel>
                      <Controller
                        name="businessEmail"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            sx={{
                              '& .MuiInputBase-input': {
                                background: 'white',
                              },
                            }}
                            type="text"
                            size="small"
                            value={undefined}
                            error={!!errors.businessEmail}
                            defaultValue={templateDetails?.email}
                            helperText={errors?.businessEmail?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div className="block mb-24">
                      <InputLabel
                        htmlFor="phone_number"
                        className="text-16 z-0 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.businessInformation.formField.businessPhone.name')}*
                      </InputLabel>
                      <PhoneInput
                        placeholder={t('signUpPage.formField.phoneNumber.placeHolder')}
                        inputStyle={{
                          width: '100%',
                          height: '40px',
                        }}
                        className="w-full business-phone"
                        name="phone_number"
                        required
                        error={!!errors.phone_number}
                        defaultValue={templateDetails?.phone}
                        helperText={errors?.phone_number?.message}
                        value={phoneValue}
                        country="us"
                        enableSearch="true"
                        onChange={handlePhoneChange}
                      />
                      <p className="text-red ml-12 top-5 relative text-md">
                        {errors?.phone_number?.message}
                      </p>
                    </div>
                    <div className="block">
                      <InputLabel
                        htmlFor="businessAddress"
                        className="text-16 z-0 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.businessInformation.formField.businessAddress.name')}*
                      </InputLabel>
                      <Controller
                        name="businessAddress"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            sx={{
                              '& .MuiInputBase-input': {
                                background: 'white',
                              },
                            }}
                            type="text"
                            size="small"
                            value={undefined}
                            defaultValue={templateDetails?.address}
                            error={!!errors.businessAddress}
                            multiline
                            minRows={4}
                            helperText={errors?.businessAddress?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div className="block">
                      <InputLabel
                        htmlFor="googleMapEmbedLink"
                        className="text-16 z-0 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.businessInformation.formField.googleMapEmbedLink.name')}*
                      </InputLabel>
                      <Controller
                        name="googleMapEmbedLink"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            sx={{
                              '& .MuiInputBase-input': {
                                background: 'white',
                              },
                            }}
                            type="text"
                            size="small"
                            multiline
                            minRows={4}
                            defaultValue={templateDetails?.googleMapLink}
                            value={undefined}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <motion.div className="block mb-24">
                      <InputLabel
                        htmlFor="businessEmail"
                        className="text-16 z-0 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.businessInformation.formField.companyLogo.name')}
                      </InputLabel>
                      <Paper
                        className="relative w-full h-full rounded-8 shadow-none border-1 border-dashed border-darkgreen p-12 bg-white"
                        role="button"
                        onDrop={handleDrop}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        <div className="inline-block">
                          {/* <img
                           className="w-2/4 block rounded max-h-96"
                           name="logo"
                           src={imagePreview}
                           alt="logo"
                         /> */}
                          <label htmlFor="button-file">
                            <input
                              accept="image/*"
                              className="hidden"
                              id="button-file"
                              type="file"
                              onChange={handleFileChange}
                            />
                            <Button
                              size="small"
                              className="rounded block bg-darkgreen-100 text-darkgreen text-14 font-medium hover:bg-darkgreen hover:text-white"
                              variant="contained"
                              color="secondary"
                              component="span"
                            >
                              <Icon color="action material-symbols-outlined align-middle">
                                upload
                              </Icon>{' '}
                              {t('reviewPlatform.buttons.uploadReviewIcon')}
                            </Button>
                          </label>
                        </div>
                        <div className="inline-block ml-16 font-medium">
                          {imagePreviewName ||
                            `${t('appWebsite.businessInformation.formField.OrDropImage')}`}
                        </div>
                        {noImgMsg && <span className="text-red block mt-16">{noImgMsg}</span>}
                      </Paper>
                    </motion.div>
                    <motion.div className="block">
                      <InputLabel
                        htmlFor="whiteLogo"
                        className="text-16 z-0 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.businessInformation.formField.companyLogoWhite.name')}
                      </InputLabel>
                      <Paper
                        className="relative w-full h-full rounded-8 shadow-none border-1 border-dashed border-darkgreen p-12 bg-white"
                        role="button"
                        onDrop={handleDropSecondLogo}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        <div className="inline-block">
                          {/* <img
                           className="w-2/4 block rounded max-h-96"
                           name="logo"
                           src={imagePreview}
                           alt="logo"
                         /> */}
                          <label htmlFor="button-fileSecondLogo">
                            <input
                              accept="image/*"
                              className="hidden"
                              id="button-fileSecondLogo"
                              type="file"
                              onChange={handleFileChangeSecondLogo}
                            />
                            <Button
                              size="small"
                              className="rounded block bg-darkgreen-100 text-darkgreen text-14 font-medium"
                              variant="contained"
                              color="secondary"
                              component="span"
                            >
                              <Icon color="action material-symbols-outlined align-middle">
                                upload
                              </Icon>{' '}
                              {t('reviewPlatform.buttons.uploadReviewIcon')}
                            </Button>
                          </label>
                        </div>
                        <div className="inline-block ml-16 font-medium">
                          {imagePreviewNameSecondLogo ||
                            `${t('appWebsite.businessInformation.formField.OrDropImage')}`}
                        </div>
                        {noImgMsgSecondLogo && (
                          <span className="text-red block mt-16">{noImgMsgSecondLogo}</span>
                        )}
                      </Paper>
                    </motion.div>
                  </div>
                </div>
                <div className="text-center">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="mt-24 mx-auto md:px-80 md:text-18 font-semibold disabled:text-black rounded-md"
                    aria-label="Register"
                    disabled={refreshIcon}
                    type="submit"
                    size="small"
                  >
                    {t('common.save')}{' '}
                    {refreshIcon && (
                      <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                    )}
                  </Button>
                </div>
              </form>
            </div>
          ) : templateDetails !== null ? (
            <div className="bg-white rounded-md relative shadow p-24">
              <>
                {!isEdit ? (
                  <div className="inline-block items-center w-auto float-right">
                    <Button
                      className="text-18 px-15 font-semibold"
                      variant="outlined"
                      color="secondary"
                      aria-label="Edit"
                      onClick={(e) => setIsEdit(true)}
                    >
                      <Icon>edit</Icon>
                      {t('common.edit')}
                    </Button>
                  </div>
                ) : (
                  ''
                )}
              </>
              <div className="mb-24">
                <span className="text-16 font-medium block break-words text-grey-600 mb-6">
                  {t('appWebsite.businessInformation.formField.businessName.name')}
                </span>
                <span className="text-16 font-semibold block break-words">
                  {templateDetails?.businessName ? templateDetails?.businessName : '-'}
                </span>
              </div>
              <div className="mb-24">
                <span className="text-16 font-medium block break-words text-grey-600 mb-6">
                  {t('appWebsite.businessInformation.formField.businesstype.name')}
                </span>
                <span className="text-16 font-semibold block break-words">
                  {templateDetails?.business_types?.bType
                    ? templateDetails?.business_types?.bType
                    : '-'}
                </span>
              </div>
              <div className="mb-24">
                <span className="text-16 font-medium block text-grey-600 mb-6">
                  {t('appWebsite.businessInformation.formField.businessEmail.name')}
                </span>
                <span className="text-16 font-semibold block break-words">
                  {templateDetails?.email ? templateDetails?.email : '-'}
                </span>
              </div>
              <div className="mb-24">
                <span className="text-16 font-medium block text-grey-600 mb-6">
                  {t('appWebsite.businessInformation.formField.businessPhone.name')}
                </span>
                <span className="text-16 font-semibold block break-words">
                  {templateDetails?.phone?.phoneNumber
                    ? `+${templateDetails?.phone?.countryCode} ${templateDetails?.phone?.phoneNumber}`
                    : '-'}
                </span>
              </div>
              <div className="mb-24">
                <span className="text-16 font-medium block text-grey-600 mb-6 break-words">
                  {t('appWebsite.businessInformation.formField.businessAddress.name')}
                </span>
                <span className="text-16 font-semibold block break-words">
                  {templateDetails?.address ? templateDetails?.address : '-'}
                </span>
              </div>
              <div className="mb-24">
                <span className="text-16 font-medium block text-grey-600 mb-6">
                  {t('appWebsite.businessInformation.formField.googleMapEmbedLink.name')}
                </span>
                <span className="text-16 font-semibold block break-words">
                  {templateDetails?.googleMapLink ? templateDetails?.googleMapLink : '-'}
                </span>
              </div>
              {templateDetails?.logo && (
                <div>
                  <span className="text-16 font-medium block text-grey-600 mb-12">
                    {t('appWebsite.businessInformation.formField.companyLogoEdit.name')}
                  </span>
                  <span className="text-16 font-semibold block">
                    <img
                      src={templateDetails?.logo}
                      className="img-fluid max-h-56 rounded-md"
                      alt="trial_accounts"
                    />
                  </span>
                </div>
              )}
              {templateDetails?.lightLogo && (
                <div>
                  <span className="text-16 font-medium block text-grey-600 mb-12">
                    {t('appWebsite.businessInformation.formField.companyLightLogoEdit.name')}
                  </span>
                  <span className="text-16 font-semibold block">
                    <img
                      src={templateDetails?.lightLogo}
                      className="img-fluid max-h-56 rounded-md"
                      alt="trial_accounts"
                    />
                  </span>
                </div>
              )}
            </div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.1 } }}
              className="flex flex-1 items-center justify-center h-full"
            >
              <Typography color="textSecondary" variant="h5" className="text-16 py-12">
                {t('business.selectedBusinessDetail.validationMessage.noBusinessDetails')}
              </Typography>
            </motion.div>
          )}
        </>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.1 } }}
          className="flex flex-1 items-center justify-center h-full"
        >
          <div>
            <img
              src="/assets/images/business/register.png"
              className="img-fluid mx-auto max-w-160 pb-40"
              alt="..."
            />
            <h5 className="block text-20 font-bold text-black text-center">
              {t('business.validationMessage.domainRegistration')}
            </h5>
            <Typography
              color="textPrimary"
              variant="h5"
              className="text-14 py-12 max-w-640 text-center mx-auto"
            >
              {t('business.validationMessage.desc')}
              <br />
              {t('business.validationMessage.shortDesc')}
            </Typography>
            <div className="text-center">
              <Button
                variant="contained"
                color="secondary"
                className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                aria-label="Search"
                type="button"
                size="medium"
                onClick={() => redirectUserRoutes()}
              >
                {t('business.validationMessage.button')}
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default withReducer('cmsWebsiteReducer', reducer)(BusinessInformation);
