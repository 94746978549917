import { t } from 'i18next';
import {
  Button,
  Icon,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Checkbox from '@mui/material/Checkbox';
import EmojiPicker from 'emoji-picker-react';
import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FilterListIcon from '@mui/icons-material/FilterList';
import { publicIpv4 } from 'public-ip';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import { connectChatSocket } from '../../../../socket';
import quickChatQuery from '../../query/quickChat';
import {
  capitalizeFirstLetter,
  formatDateWithTimeZone,
  handleApiRequest,
} from '../../common/common';
import { selectDashboardData } from '../../../store/userSlice';

const ChatInbox = () => {
  const location = useLocation();
  const chatScroll = useRef(null);
  const chatContainerRef = useRef(null);
  const [timeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [messageInput, setMessageInput] = useState('');
  const [selectedContact, setSelectedContact] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [getChatRoom, setGetChatRoom] = useState([]);
  const [selectContactMessageList, setSelectContactMessageList] = useState([]);
  const [subscribeChannelByBusinessUserStatus, setSubscribeChannelByBusinessUserStatus] =
    useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [quickChatDetails, setQuickChatDetails] = useState({});
  const [isConnected, setIsConnected] = useState(false);
  const ipAddress = publicIpv4();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [currPage, setCurrPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [prevPage, setPrevPage] = useState(0);
  const [wasLastList, setWasLastList] = useState(false);
  const currentTime = formatDateWithTimeZone(moment(), 'h:mm A');
  const { enqueueSnackbar } = useSnackbar();
  const chatSocket = connectChatSocket();
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [statusList, setStatusList] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterIds, setFilterIds] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const [status, setStatus] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [fromDashboard, setFromDashboard] = useState(location.state?.contact);
  const [isHumanInteraction, setIsHumanInteraction] = useState(false);

  useEffect(() => {
    if (Array.isArray(statusList)) {
      const selectedStatus = statusList?.find(
        (state) => state?.value === selectedContact?.chatResolveStatus
      );
      if (selectedStatus) {
        setStatus(selectedStatus?.value);
      }
    }
  }, [selectedContact?.chatResolveStatus, statusList]);

  useEffect(() => {
    const fetchAgentSupportStatus = async () => {
      if (userCurrentBusinessDetails?.id && !userCurrentBusinessDetails?.isOnlineBusiness) {
        const payloadData = {
          query: quickChatQuery.getQuickChat,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        if (
          resultData?.getQuickChat?.status === 200 &&
          resultData?.getQuickChat?.message !== 'Not found.'
        ) {
          setQuickChatDetails(resultData?.getQuickChat?.data);
        }
      }
    };
    fetchAgentSupportStatus();
  }, [userCurrentBusinessDetails]);

  const whatAppUserDetails = useCallback(async () => {
    try {
      if (selectedContact) {
        const previousScrollHeight = chatContainerRef.current?.scrollHeight;

        const payload = {
          query: quickChatQuery.getAllQuickChatRoomMessageUsingQuickChatId,
          variables: {
            quickChatRoomId: selectedContact?.quickChatRoomMessages?.quickChatRoomId,
            sortBy: 'cts',
            sortOrder: 'desc',
            pageNumber: currPage,
            pageSize: 20,
          },
        };
        const res = await handleApiRequest(payload);
        if (res?.getAllQuickChatRoomMessageUsingQuickChatId?.status === 200) {
          setHasMore(true);
          if (!res?.getAllQuickChatRoomMessageUsingQuickChatId?.data?.length) {
            setWasLastList(true);
            setHasMore(false);
            return;
          }
          setPrevPage(currPage);
          setSelectContactMessageList([
            ...(res?.getAllQuickChatRoomMessageUsingQuickChatId?.data.reverse() || []),
            ...selectContactMessageList,
          ]);

          setTimeout(() => {
            const newScrollHeight = chatContainerRef.current?.scrollHeight;
            if (chatContainerRef.current) {
              chatContainerRef.current.scrollTop += newScrollHeight - previousScrollHeight;
            }
          }, 0);

          const removeUnread = {
            quickChatRoomId: selectedContact?.quickChatRoomMessages?.quickChatRoomId,
          };
          chatSocket.emit('clientMessageSeenByBusinessOwner', removeUnread, (ack) => {
            console.log('Event emitted, acknowledgment:', ack);
          });

          setGetChatRoom((prevContacts) => {
            const contactExist = prevContacts?.some((contacts) => {
              return contacts?.ipAddress === selectedContact?.ipAddress;
            });
            if (!contactExist) {
              return null;
            }
            return prevContacts.map((contacts) =>
              contacts?.ipAddress === selectedContact?.ipAddress
                ? {
                    ...contacts,
                    unReadCount: 0,
                  }
                : contacts
            );
          });
        }
        setFromDashboard({});
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [selectedContact, currPage, selectContactMessageList, chatSocket]);

  const fetchQuickChatRoom = useCallback(
    async (data) => {
      if (!quickChatDetails?.id) return;
      try {
        setPageLoading(true);
        const payload = {
          query: quickChatQuery.getAllQuickChatWithLastMessage,
          variables: {
            quickChatId: quickChatDetails?.id,
            status: data,
          },
        };
        const res = await handleApiRequest(payload);
        setPageLoading(false);
        if (res?.getAllQuickChatWithLastMessage?.status === 200) {
          setGetChatRoom(res?.getAllQuickChatWithLastMessage?.data);
        } else {
          setGetChatRoom([]);
        }
      } catch (error) {
        setPageLoading(false);
        console.error('An error occurred:', error);
      }
    },
    [quickChatDetails?.id]
  );

  useEffect(() => {
    fetchQuickChatRoom();
  }, [fetchQuickChatRoom]);

  useEffect(() => {
    if (quickChatDetails?.id) {
      const handleChat = async () => {
        try {
          const payload = {
            query: quickChatQuery.getQuickChatHumanInteraction,
            variables: {
              quickChatId: Number(quickChatDetails?.id),
            },
          };
          const result = await handleApiRequest(payload);
          if (result?.getQuickChatHumanInteraction?.status === 200) {
            setIsHumanInteraction(result?.getQuickChatHumanInteraction?.data?.isHumanInteraction);
          }
        } catch (err) {
          console.error('Error fetching data:', err);
        }
      };
      handleChat();
    }
  }, [quickChatDetails?.id]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id && quickChatDetails?.id) {
      const handleConnect = async () => {
        try {
          const resolvedIpAddress = await publicIpv4();

          const subscribeChatData = JSON.stringify({
            userBusinessesId: userCurrentBusinessDetails?.id,
            ipAddress: resolvedIpAddress,
            quickChatId: quickChatDetails?.id,
          });
          chatSocket.emit('subscribeChannelByBusinessUser', subscribeChatData, (ack) => {
            console.log('Event emitted, acknowledgment:', ack);
          });

          await setIsConnected(true);
        } catch (error) {
          console.error('Error fetching IP address or connecting to chat:', error);
          setIsConnected(false);
        }
      };
      if (!subscribeChannelByBusinessUserStatus) {
        handleConnect();
        setSubscribeChannelByBusinessUserStatus(true);
      }

      const handleDisconnect = () => {
        setIsConnected(false);
      };

      const handleChatMessage = (event) => {
        if (
          event?.eventType === 'sendClientMessageToBusinessUser' ||
          event?.eventType === 'newClient'
        ) {
          if (
            event?.data?.quickChatRoomId ===
              selectedContact?.quickChatRoomMessages?.quickChatRoomId ||
            event?.data?.ipAddress === selectedContact?.quickChatRoomMessages?.ipAddress
          ) {
            setSelectContactMessageList((prevMessages) => [
              ...prevMessages,
              {
                ...event?.data,
              },
            ]);
            chatScroll.current?.scrollIntoView({ behavior: 'smooth' });
          }
          setGetChatRoom((prevContacts) => {
            const contactExists = prevContacts.some((contact) => {
              return (
                contact?.quickChatRoomMessages?.quickChatRoomId === event?.data?.quickChatRoomId ||
                contact?.ipAddress === event?.data?.ipAddress
              );
            });
            let updatedContacts;

            if (!contactExists) {
              updatedContacts = [
                {
                  ...event?.data,
                  cts: Number(event?.data?.cts) * 1000,
                  unRead: 1,
                },
                ...prevContacts,
              ];
            } else {
              updatedContacts = prevContacts.map((contact) => {
                return contact?.quickChatRoomMessages?.quickChatRoomId ===
                  event?.data?.quickChatRoomId || contact?.ipAddress === event?.data?.ipAddress
                  ? {
                      ...contact,
                      quickChatRoomMessages: { ...event?.data },
                      cts: Number(event?.data?.cts) * 1000,
                      unReadCount:
                        event?.data?.quickChatRoomId ===
                          selectedContact?.quickChatRoomMessages?.quickChatRoomId ||
                        event?.data?.ipAddress === selectedContact?.quickChatRoomMessages?.ipAddress
                          ? contact?.unReadCount
                          : (contact?.unReadCount || 0) + 1,
                    }
                  : contact;
              });
            }
            return updatedContacts.sort((a, b) => b.cts - a.cts);
          });
        }

        if (event?.eventType === 'messageStatusToBusinessOwner') {
          setGetChatRoom((prevContacts) => {
            const updatedContacts = prevContacts?.map((contact) =>
              contact?.quickChatRoomMessages?.quickChatRoomId === event?.data?.quickChatRoomId
                ? {
                    ...contact,
                    quickChatRoomMessages: {
                      ...contact.quickChatRoomMessages,
                      status: event?.data?.status,
                    },
                  }
                : contact
            );
            return updatedContacts;
          });
        }

        if (event?.eventType === 'clientCurrentStatus') {
          setGetChatRoom((prevContacts) => {
            const contactExists = prevContacts?.some((contact) => {
              return contact?.ipAddress === event?.data?.ipAddress;
            });
            if (!contactExists) {
              return null;
            }
            const updatedContacts = prevContacts.map((contact) =>
              contact?.ipAddress === event?.data?.ipAddress
                ? {
                    ...contact,
                    ...event?.data,
                  }
                : contact
            );
            return updatedContacts.sort((a, b) => b.cts - a.cts);
          });
        }
      };

      chatSocket.on('connect', handleConnect);
      chatSocket.on('disconnect', handleDisconnect);
      chatSocket.on('QUICK_CHAT_MESSAGES', handleChatMessage);
      return () => {
        chatSocket.off('connect', handleConnect);
        chatSocket.off('disconnect', handleDisconnect);
        chatSocket.off('QUICK_CHAT_MESSAGES', handleChatMessage);
      };
    }
    return undefined;
  }, [
    currentTime,
    getChatRoom,
    ipAddress,
    quickChatDetails?.id,
    userCurrentBusinessDetails?.id,
    selectedContact?.quickChatRoomMessages?.quickChatRoomId,
    chatSocket,
    selectedContact?.quickChatRoomMessages?.ipAddress,
    subscribeChannelByBusinessUserStatus,
  ]);

  useEffect(() => {
    if (selectedContact) {
      if (!wasLastList && prevPage !== currPage) {
        whatAppUserDetails();
      }
    }
  }, [
    selectedContact,
    currPage,
    wasLastList,
    prevPage,
    selectContactMessageList,
    whatAppUserDetails,
    hasMore,
  ]);

  const onScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = chatContainerRef.current;
      if (scrollTop <= 4 && !wasLastList && hasMore) {
        setHasMore(false);
        setCurrPage((prevPageNumber) => prevPageNumber + 1);
      }
    }
  };

  // useEffect(() => {
  //   if (chatScroll.current) {
  //     chatScroll.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [selectContactMessageList]);

  const handleContactClick = (contact) => {
    setSelectedContact(contact);
    setSelectContactMessageList([]);
    setCurrPage(1);
    setPrevPage(0);
    setHasMore(false);
  };

  useEffect(() => {
    if (fromDashboard?.quickChatRoomMessages?.quickChatRoomId) {
      handleContactClick(fromDashboard);
    }
  }, [fromDashboard]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage('text');
    }
  };
  chatSocket.on('error', (error) => {
    console.error('Socket error:', error);
  });
  const handleSendMessage = async () => {
    if (!messageInput || messageInput.trim() === '') {
      return;
    }
    setMessageInput('');

    if (isHumanInteraction) {
      const newMessage = {
        message: messageInput,
        cts: moment().tz(timeZone).toISOString(),
        status: 'sent',
        messageType: 'sender',
      };

      setSelectContactMessageList((prevList) => [...prevList, newMessage]);
    }

    try {
      const payload = {
        query: quickChatQuery.sendQuickChatMessageByOwner,
        variables: {
          quickChatRoomId: selectedContact?.quickChatRoomMessages?.quickChatRoomId,
          message: messageInput,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.sendQuickChatMessageByOwner?.status === 400) {
        enqueueSnackbar(result?.sendQuickChatMessageByOwner?.message, {
          variant: 'warning',
          autoHideDuration: 2000,
        });
        return;
      }
      setTimeout(() => {
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      }, 0);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const formattedDate = (timestamp) => {
    const date = moment(timestamp).tz(timeZone);
    const today = moment().tz(timeZone);
    const yesterday = moment().tz(timeZone).subtract(1, 'days');

    if (date.isSame(today, 'day')) {
      return date?.tz(timeZone)?.format('h:mm A');
    }
    if (date.isSame(yesterday, 'day')) {
      return 'Yesterday';
    }
    return date.format('MMM DD, YYYY');
  };

  useEffect(() => {
    try {
      const getStatusList = async () => {
        const payload = {
          query: quickChatQuery.getChatRoomResolveStatus,
        };
        const result = await handleApiRequest(payload);
        if (result?.getChatRoomResolveStatus?.status === 200) {
          setStatusList(result?.getChatRoomResolveStatus?.data);
        }
      };
      getStatusList();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, []);

  const updateStatus = useCallback(
    async (update) => {
      try {
        const payload = {
          query: quickChatQuery.updateQuickChatResolveStatus,
          variables: {
            quickChatRoomId: selectedContact?.id,
            status: update,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.updateQuickChatResolveStatus?.status === 200) {
          fetchQuickChatRoom();
          enqueueSnackbar(result?.updateQuickChatResolveStatus?.message, {
            variant: 'success',
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(result?.updateQuickChatResolveStatus?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },
    [enqueueSnackbar, fetchQuickChatRoom, selectedContact?.id]
  );

  const handleClearSearch = async () => {
    await setSearchTerm('');
  };

  const handleSelectFilter = (selectedItem) => {
    const updatedFilterIds = filterIds.some((filterId) => filterId?.key === selectedItem?.key)
      ? filterIds.filter((filterId) => filterId?.key !== selectedItem?.key)
      : [...filterIds, selectedItem];
    setFilterIds(updatedFilterIds);
    const selectedKeys = updatedFilterIds?.map((item) => item?.key);
    setFilteredData(selectedKeys);
  };

  const applyFilters = () => {
    setShowFilterOption(false);
    fetchQuickChatRoom(filteredData);
  };

  const changeReviewPlatForm = (value) => {
    setStatus(value);
    const selectedStatus = statusList?.find((state) => state?.value === value);
    if (selectedStatus) {
      updateStatus(selectedStatus?.key);
    }
  };

  const filteredContacts = useMemo(() => {
    if (!getChatRoom?.length) return [];
    const searchText = searchTerm?.toLowerCase() || '';
    return getChatRoom?.filter((contact) => {
      if (!contact?.ipAddress) return false;
      const ip = contact?.ipAddress?.toLowerCase();
      return ip?.includes(searchText);
    });
  }, [getChatRoom, searchTerm]);

  if (pageLoading) {
    return <FuseLoading />;
  }

  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      const date = formatDateWithTimeZone(message.cts, 'YYYY-MM-DD');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  };

  const renderMessageStatusIcon = (messageType, MessageStatus) => {
    if (messageType !== 'sender') return null;

    switch (MessageStatus) {
      case 'send':
        return <Icon className="text-14">done</Icon>;
      case 'delivered':
        return <DoneAllIcon className="text-14 ml-3" />;
      case 'read':
        return <DoneAllIcon className="text-blue text-14 ml-3" />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="lg:p-24 p-16">
        {userCurrentBusinessDetails?.isOnlineBusiness ? (
          <div className="h-2xl w-full flex flex-col justify-center items-center">
            <div className="w-512 flex flex-col gap-20 items-center">
              <img className="max-w-96" src="/assets/images/icon/chatUnavailable.png" alt="" />
              <p className="text-24 text-center font-600">{t('quickChat.chatbotForOnline')}</p>
            </div>
          </div>
        ) : getChatRoom ? (
          <div className="bg-white rounded-md border-1 border-solid border-grey-300 quick-chat  h-full overflow-y-scroll">
            <div className="flex flex-col h-full md:flex-row sm:items-start items-center">
              {/* Left Sidebar */}
              <div className="w-full md:w-1/4">
                <div className="border-b-1 border-solid border-grey-300 h-80 sm:h-64 flex justify-between align-middle w-full px-16 relative gap-5">
                  <div className=" flex justify-between w-full ">
                    <TextField
                      placeholder="Search IP address"
                      variant="outlined"
                      margin="normal"
                      value={searchTerm}
                      className="bg-gray-A500 w-full border-none rounded-sm border-gray-A500"
                      onChange={(e) => setSearchTerm(e?.target?.value)}
                      InputProps={{
                        endAdornment: searchTerm ? (
                          <InputAdornment position="end">
                            <Icon
                              color="quickChat"
                              className="cursor-pointer text-20"
                              onClick={handleClearSearch}
                            >
                              close
                            </Icon>
                          </InputAdornment>
                        ) : (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="flex items-center justify-center relative">
                    <Button
                      className={`bg-gray-A500 mt-9 ${
                        filterIds?.length > 0 ? 'text-quick-review' : 'border-gray-500'
                      } rounded-md w-28 min-w-28 p-20 h-24 min-h-24 hover:bg-white`}
                      onClick={() => setShowFilterOption((prev) => !prev)}
                    >
                      <FilterListIcon
                        className={`${
                          filterIds?.length > 0 ? 'text-quick-review' : 'text-gray-800'
                        } opacity-100`}
                      />
                      {filterIds?.length > 0 && (
                        <Typography className="absolute -right-8 -top-10 text-12 font-semibold bg-quick-review text-white rounded-full h-16 w-16 flex items-center justify-center">
                          {filterIds?.length}
                        </Typography>
                      )}
                    </Button>
                    <div>
                      {showFilterOption && (
                        <div className="absolute z-10 top-48 right-0 bg-white max-w-192 rounded-md shadow-2">
                          {statusList?.length > 0 &&
                            statusList.map((item, i) => {
                              return (
                                <div key={i} className="flex items-center mt-16 px-7">
                                  <Checkbox
                                    className="p-0 text-grey-300"
                                    checked={filterIds.some(
                                      (filterId) => filterId.key === item.key
                                    )}
                                    onChange={() => handleSelectFilter(item)}
                                  />
                                  <Typography className="text-14 text-grey-800 ml-10 font-medium">
                                    {item?.value}
                                  </Typography>
                                </div>
                              );
                            })}
                          <div className="flex border-t-2 p-10 mt-10 gap-12 border-grey-200">
                            <Button
                              className="rounded-md py-0 px-16"
                              size="small"
                              variant="outlined"
                              color="quickChat"
                              type="button"
                              onClick={() => setShowFilterOption(false)}
                            >
                              {t('quickChat.cancel')}
                            </Button>
                            <Button
                              className="rounded-md py-0 px-24"
                              size="small"
                              variant="contained"
                              color="quickChat"
                              type="button"
                              onClick={applyFilters}
                            >
                              {t('quickChat.apply')}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="p-10 max-h-[580px] overflow-auto">
                  {filteredContacts && filteredContacts?.length > 0 && getChatRoom?.length > 0 ? (
                    <>
                      {filteredContacts?.map((contact, index) => (
                        <div
                          key={index}
                          className={`mb-10 cursor-pointer hover:border-quick-chat border-1 border-solid  rounded-md p-10 ${
                            selectedContact?.id === contact?.id
                              ? 'border-quick-chat'
                              : 'bg-white border-grey-300'
                          }`}
                          aria-hidden
                          onClick={() => handleContactClick(contact)}
                        >
                          <div className="flex items-center">
                            <img
                              src="/assets/images/business/profile.png"
                              className="img-fluid w-full sm:max-w-48 max-w-36 mr-10"
                              alt="..."
                            />

                            <div className="overflow-hidden w-full">
                              <div className="flex items-center justify-between">
                                <h3 className="text-14 sm:text-16 font-semibold w-full max-w-96 overflow-hidden whitespace-nowrap text-ellipsis">
                                  {contact?.ipAddress}
                                </h3>

                                <div className="text-12 font-semibold w-full max-w-160 flex justify-end items-center text-right gap-4 capitalize">
                                  <span
                                    className={`inline-block w-7 h-7 rounded-full ${
                                      contact?.chatResolveStatus === 'Resolved by human' ||
                                      contact?.chatResolveStatus === 'Resolved by bot'
                                        ? 'bg-darkgreen'
                                        : contact?.chatResolveStatus === 'Unresolved by bot' ||
                                          contact?.chatResolveStatus === 'Unresolved by human'
                                        ? 'bg-red'
                                        : ''
                                    }`}
                                  />
                                  <span
                                    className={`inline-block  ${
                                      contact?.chatResolveStatus === 'Unresolved by bot' ||
                                      contact?.chatResolveStatus === 'Unresolved by human'
                                        ? 'text-red'
                                        : contact?.chatResolveStatus === 'Resolved by human' ||
                                          contact?.chatResolveStatus === 'Resolved by bot'
                                        ? 'text-darkgreen'
                                        : ''
                                    }`}
                                  >
                                    {contact?.chatResolveStatus}
                                  </span>
                                </div>
                              </div>
                              <div className="flex items-center justify-between">
                                <span
                                  className="font-medium truncate text-14 h-20  mt-4 mb-4 text-grey-600"
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: contact?.quickChatRoomMessages?.message,
                                  }}
                                />
                              </div>

                              <div className="flex items-center justify-between">
                                <span className="text-12 font-semibold text-left">
                                  {formattedDate(contact?.quickChatRoomMessages?.cts)}
                                </span>

                                {contact?.unReadCount > 0 ? (
                                  <span className="text-center bg-green-100 text-green-800 text-xs font-medium w-16 h-16 pt-1 rounded-full dark:bg-green-900 dark:text-green-300">
                                    {contact?.unReadCount}
                                  </span>
                                ) : (
                                  renderMessageStatusIcon(
                                    contact?.quickChatRoomMessages?.messageType,
                                    contact?.quickChatRoomMessages?.status
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <Typography className="text-center font-medium">
                      {t('waba.inbox.noResult')}
                    </Typography>
                  )}
                </div>
              </div>

              <div
                className={`flex-grow relative mt-20 sm:mt-0 h-full w-full md:w-3/5 md:${
                  selectedContact
                    ? ' bg-white border-grey-300'
                    : ' flex h-full items-center justify-center bg-gray-A300'
                } border-x-1 border-solid`}
              >
                {selectedContact ? (
                  <>
                    <div className="border-b-1 border-solid border-grey-300 h-80 sm:h-64 grid align-middle w-full px-16">
                      <div className="flex items-center">
                        <img
                          src="/assets/images/business/profile.png"
                          className="img-fluid w-full sm:max-w-40 max-w-36 mr-10"
                          alt="..."
                        />

                        <div className="flex sm:items-center items-start gap-10 flex-col sm:flex-row justify-start sm:justify-between w-full">
                          <h3 className="text-14 sm:text-16 font-bold">
                            {selectedContact?.ipAddress}
                          </h3>
                          <div className="flex gap-28 w-full justify-end">
                            <Select
                              className="rounded-md h-40 bg-gray-A500 text-16 w-full sm:w-256 leading-5  capitalize"
                              variant="filled"
                              sx={{
                                '& .MuiFilledInput-input': {
                                  pt: '8px',
                                  zIndex: '10',
                                },
                              }}
                              value={status}
                              fullWidth
                              onChange={(e) => changeReviewPlatForm(e?.target?.value)}
                              onOpen={() => setIsMenuOpen(true)}
                              onClose={() => setIsMenuOpen(false)}
                              IconComponent={() => (
                                <ExpandMoreOutlinedIcon
                                  className={`text-28 align-middle absolute right-6 text-black ml-2 transition-transform duration-1500 ${
                                    isMenuOpen ? 'rotate-180' : ''
                                  }`}
                                />
                              )}
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    borderRadius: '2px',
                                    marginTop: '1px',
                                    boxShadow: 'none',

                                    '& .MuiList-root': {
                                      paddingTop: 0,
                                      paddingBottom: 0,
                                    },
                                  },
                                },
                              }}
                            >
                              {statusList &&
                                statusList
                                  ?.slice()
                                  .sort((a, b) => b.defaultReview - a.defaultReview)
                                  .map((review, i) => (
                                    <MenuItem
                                      className="capitalize hover:bg-white bg-gray-A500"
                                      value={review?.value}
                                      key={i}
                                    >
                                      <div className="flex gap-8">
                                        <div
                                          className={`w-7 h-7 rounded-full justify-center items-center flex mt-7 ${
                                            review?.key === 'resolveByBot' ||
                                            review?.key === 'resolveByHuman'
                                              ? 'bg-darkgreen'
                                              : 'bg-red-500'
                                          }`}
                                        >
                                          {' '}
                                        </div>
                                        <Typography>
                                          {review?.value && capitalizeFirstLetter(review?.value)}
                                        </Typography>
                                      </div>
                                    </MenuItem>
                                  ))}
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="h-[600px] sm:inbox-height flex flex-col bg-gray-A300 relative">
                      <div
                        className="flex-grow overflow-auto p-20 pb-0"
                        onScroll={onScroll}
                        ref={chatContainerRef}
                      >
                        {Object.entries(groupMessagesByDate(selectContactMessageList)).map(
                          ([date, messages], index) => (
                            <div key={index}>
                              <div className="text-center">
                                <div className="text-center text-grey-600 text-12 mb-12 bg-white inline-block mx-auto rounded-md shadow px-10 py-4 font-medium">
                                  {moment(date).tz(timeZone).calendar(null, {
                                    sameDay: '[Today]',
                                    lastDay: '[Yesterday]',
                                    lastWeek: 'dddd',
                                    sameElse: 'MMM DD, YYYY',
                                  })}
                                </div>
                              </div>
                              {messages.map((message, idx) => (
                                <div className="mb-12 block" key={idx}>
                                  <div
                                    className={`rounded-md mb-4 inline-block px-8 py-8 text-14 font-semibold ${
                                      message?.messageType === 'sender'
                                        ? 'self-end text-right float-right bg-quick-chat text-white'
                                        : 'self-start text-left bg-white'
                                    }`}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: message?.message,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={`text-12 text-gray-700 font-medium clear-both     ${
                                      message?.messageType === 'sender' ? 'text-right' : 'text-left'
                                    }`}
                                  >
                                    {formatDateWithTimeZone(message.cts, 'hh:mm A')}

                                    {message?.messageType === 'sender' &&
                                      message?.status === 'send' && (
                                        <Icon className="text-14">done</Icon>
                                      )}
                                    {message?.messageType === 'sender' &&
                                      message?.status === 'delivered' && (
                                        <DoneAllIcon className="text-14 ml-3" />
                                      )}
                                    {message?.messageType === 'sender' &&
                                      message?.status === 'read' && (
                                        <DoneAllIcon className="text-blue text-14 ml-3" />
                                      )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )
                        )}
                        <div ref={chatScroll} className="h-32" />
                      </div>
                      <div className="mt-4 flex bg-white items-center px-20">
                        <input
                          type="text"
                          className="flex-grow p-2 border-none rounded-none h-60"
                          value={messageInput}
                          onChange={(e) => setMessageInput(e.target.value)}
                          placeholder={t('waba.inbox.typeMessage')}
                          onKeyPress={handleKeyPress}
                        />
                        <div>
                          <span
                            aria-hidden
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                            className="bg-gray-A500 rounded-md w-28 h-28 inline-block text-center pt-4 align-middle mx-10 cursor-pointer"
                          >
                            <Icon className="text-20">emoji_emotions</Icon>
                          </span>
                          {showEmojiPicker && (
                            <div className="absolute bottom-60 right-16">
                              <EmojiPicker
                                onEmojiClick={(emojiObject) => {
                                  setMessageInput((prevMsg) => prevMsg + emojiObject.emoji);
                                  setShowEmojiPicker(false);
                                }}
                              />
                            </div>
                          )}

                          <Button
                            className="rounded-md md:px-24"
                            onClick={() => handleSendMessage()}
                            size="small"
                            variant="contained"
                            color="quickChat"
                            type="button"
                          >
                            {t('quickChat.createBot.send')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="h-full flex items-center justify-center">
                    <div className="mx-auto p-40 md:p-0 text-center">
                      <img
                        src="/assets/images/business/no_chat.png"
                        className="img-fluid mx-auto mb-48 max-w-192"
                        alt="..."
                      />
                      <Typography className="block text-24 font-semibold mb-12">
                        {t('quickChat.createBot.noChat')}
                      </Typography>
                      <Typography className="block font-medium text-16 text-grey-600 mb-28 max-w-256 mx-auto">
                        {t('quickChat.createBot.noChatInfo')}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="h-screen chat-height flex items-center justify-center">
            <div className="mx-auto text-center">
              <img
                src="/assets/images/business/no_chat.png"
                className="img-fluid mx-auto mb-48 max-w-192"
                alt="..."
              />
              <Typography className="block text-24 font-semibold mb-12">
                {t('quickChat.createBot.noChatStart')}
              </Typography>
              <Typography className="block font-medium text-16 text-grey-600 mb-28 max-w-480 mx-auto">
                {t('quickChat.createBot.noChatStartDesc')}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatInbox;
