const getAdsCampaign = `query ($quickHubAdsAccountId: Int  $campaignStatusFilter: String
$pageNumber: Int
$pageSize: Int $searchText: String){
    getAdsCampaign(quickHubAdsAccountId:$quickHubAdsAccountId campaignStatusFilter:$campaignStatusFilter pageNumber:$pageNumber pageSize:$pageSize searchText: $searchText) {
      status
      message
      totalCount
      data {
        id
        cts
        uts
        name
        status
        title
        description
        quickHubAdsAccountId
        quickHubAdsObjectiveId
        quickHubAdsAudienceSegment
        cta
        destinationId
        destinationDetails {
          link
          phoneNumber
          appId
          appStoreUrl
          adsInstantFormId
        }
        adFreqCap
        budget {
          budgetType
          totalBudget
          bidAmount
        }
        scheduleDate
        endDate
        mediaUrl
        shareOnFacebook
        shareOnInstagram
        campaignOverview {
          objective
          genderSelected
          maxAge
          minAge
          geoLocation {
            countries
            cities {
              key
              name
            }
          }
          interest {
            id
            name
          }
        }
        adsSchedule {
          days
          start_minute
          end_minute
        }
      }
    }
  }`;

const getAdsConnectApis = `query ($businessId:Int)
  { getAdsConnectApis (businessId:$businessId){
        message,
        status,
        data 
          {
            name
            title
            isConnect
            bgImage
            buttonName
          }
      }}`;

const verifyMetaAdsCode = `mutation ($accessToken: String!) {
        verifyMetaAdsCode(
          accessToken: $accessToken
        ) {
          status
          message
          data
          errorMessage
          errorCode
        }
      }`;

const checkInstagramConnectivity = `mutation ($accessToken: String! $pageId: String!) {
        checkInstagramConnectivity(
          pageId:$pageId
          accessToken: $accessToken
        ) {
          status
          message
          isConnected
          errorMessage
          errorCode
        }
      }`;

const saveMetaAdsDetails = `mutation ($data: String!){
  saveMetaAdsDetails(data:$data){
  status
  message
  }
  }`;

const quickAdsQuery = {
  getAdsCampaign,
  getAdsConnectApis,
  verifyMetaAdsCode,
  checkInstagramConnectivity,
  saveMetaAdsDetails,
};
export default quickAdsQuery;
