import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { motion } from 'framer-motion';
import './Matrices.css';
import { selectDashboardData } from 'src/app/store/userSlice';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import Masonry from '@mui/lab/Masonry';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { CircularProgress } from '@mui/material';
import { initializeApp } from 'firebase/app';
import ReceivedReviews from './receivedReviews';
import ReviewsOverview from './ReviewsOverview';
import ReviewSentimentChart from './reviewSentimentChart';
import ReviewDistribution from './reviewDistribution';
import ReviewVelocity from './reviewVelocity';
import AttributeComparison from './attributeComparison';
import CustomersSay from './customersSay';
import CompetitorBenchmarking from './competitorBenchmarking';
import RatingTrendTracker from './ratingTrendTracker';
import CustomerSatisfactionScore from './customerSatisfactionScore';
import { capitalizeFirstLetter, handleApiRequest, reviewFormate } from '../../common/common';
import metricsData from '../../query/metrics';
import manageBusinessData from '../../query/manageBusiness';
import ReviewsOnGoogleVsYelp from './reviewOnGoogleVsYelp';
import QuickReviewsCount from './QuickReviewsCount';
import ReviewSentimentOverTime from './ReviewSentimentOverTime';
import AttributesSentiment from './AttributesSentiment';
import NetPromoterScore from './NetPromoterScore';
import ReviewCloud from './ReviewCloud';
import ReviewsHeatMap from './ReviewsHeatMap';
import RepliedVsNotReplied from './RepliedVsNotReplied';
import TextVsNoText from './TextVsNoText';

function Matrices() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const { t } = useTranslation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [reviewPlatformInfo, setReviewPlatformInfo] = useState({});
  const [selectPlateForm, setSelectPlateForm] = useState();
  const [reviewPlaceId, setReviewPlaceId] = useState(null);
  const [reviewSentiment, setReviewSentiment] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [pageLoading, setPageLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [metricsIconInfo, setMetricsIconInfo] = useState(null);
  const [googleAndYelpAvailable, setGoogleVsYelpAvailable] = useState(false);
  const [analysisPercentage, setAnalysisPercentage] = useState(100);

  useEffect(() => {
    if (selectPlateForm === 'website') {
      setAnalysisPercentage(100);
      return undefined;
    }
    const platForm = userCurrentBusinessDetails?.reviewplatform.find(
      (platform) => platform?.reviewPlatformId === selectPlateForm
    );
    if (!platForm) {
      return undefined;
    }

    const docRef = doc(
      db,
      `${platForm?.name}-${process.env.REACT_APP_CONFIG}`,
      String(userCurrentBusinessDetails?.id)
    );
    const unsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const analysisPercentages = docSnapshot.data()?.status;
          setAnalysisPercentage(analysisPercentages ?? 0);
        } else {
          setAnalysisPercentage(100);
        }
      },
      (error) => {
        console.error('Error fetching document:', error);
      }
    );
    return () => unsubscribe();
  }, [db, userCurrentBusinessDetails, selectPlateForm]);

  useEffect(() => {
    const idsAvailable = [2, 4].every((id) =>
      userCurrentBusinessDetails?.reviewplatform.some(
        (platform) => platform.reviewPlatformId === id
      )
    );
    setGoogleVsYelpAvailable(idsAvailable);
  }, [userCurrentBusinessDetails?.reviewplatform]);

  const fetchProducts = useCallback(
    async (plateFormId) => {
      if (userCurrentBusinessDetails?.id) {
        setPageLoading(true);
        const payload = {
          query:
            parseInt(plateFormId, 10) === 5
              ? manageBusinessData.getAmazonProduct
              : parseInt(plateFormId, 10) === 6 && manageBusinessData.getFlipkartProduct,
          variables: {
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        setPageLoading(false);
        const res =
          parseInt(plateFormId, 10) === 5
            ? result?.getAmazonProduct
            : parseInt(plateFormId, 10) === 6 && result?.getFlipkartProduct;
        if (res?.status === 200) {
          setProducts(res?.data);
          const product = res?.data[0];
          if (product) {
            setSelectedProduct(product);
            setReviewPlaceId(product?.placeId);
          } else {
            setSelectedProduct('');
            setReviewPlaceId('');
          }
        } else {
          enqueueSnackbar(res?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    },
    [userCurrentBusinessDetails?.id, enqueueSnackbar]
  );
  useEffect(() => {
    const getMetricsInfoIconData = async () => {
      try {
        const payload = {
          query: metricsData.getMetricsInfoIconData,
        };
        const res = await handleApiRequest(payload);
        if (res?.getMetricsInfoIconData?.status === 200) {
          setMetricsIconInfo(res?.getMetricsInfoIconData?.data?.info ?? null);
        }
      } catch (error) {
        console.error('Error fetching metrics info icon data:', error);
      }
    };
    getMetricsInfoIconData();
  }, []);

  useEffect(() => {
    if (userCurrentBusinessDetails?.isOnlineBusiness) {
      const sortData = userCurrentBusinessDetails?.reviewplatform
        ?.slice()
        ?.sort((a, b) => a.id - b.id);
      setReviewPlatformInfo(sortData[0]);
      setSelectPlateForm(sortData[0]?.reviewPlatformId);
      fetchProducts(sortData[0]?.reviewPlatformId);
    }
  }, [
    fetchProducts,
    userCurrentBusinessDetails?.isOnlineBusiness,
    userCurrentBusinessDetails?.reviewplatform,
  ]);

  useEffect(() => {
    const fetchDashboard = async () => {
      setPageLoading(true);
      const payload = {
        query: metricsData.getReviewSentimentPeriodsData,
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getReviewSentimentPeriodsData?.status === 200) {
        await setReviewSentiment(result?.getReviewSentimentPeriodsData?.data || []);
      } else {
        enqueueSnackbar(result?.getReviewSentimentPeriodsData?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    };
    fetchDashboard();
  }, [enqueueSnackbar]);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!userCurrentBusinessDetails?.isOnlineBusiness) {
      if (!selectPlateForm) {
        const reviewPlatformValue = userCurrentBusinessDetails?.reviewplatform?.find(
          (item) => item.defaultReview === true
        );
        setReviewPlatformInfo(reviewPlatformValue);
        setSelectPlateForm(reviewPlatformValue?.reviewPlatformId);
        setReviewPlaceId(reviewPlatformValue?.placeId);
      } else {
        const reviewPlatformValue = userCurrentBusinessDetails?.reviewplatform?.find(
          (item) => item.reviewPlatformId === selectPlateForm
        );
        setReviewPlatformInfo(reviewPlatformValue);
        setReviewPlaceId(reviewPlatformValue?.placeId);
      }
    }
  }, [
    selectPlateForm,
    userCurrentBusinessDetails,
    reviewPlatformInfo,
    userCurrentBusinessDetails?.isOnlineBusiness,
  ]);

  function handlePlatformChange(event) {
    const plateFormID = event?.target?.value;
    const reviewPlatformInfoValue = userCurrentBusinessDetails?.reviewplatform?.find(
      (item) => item.reviewPlatformId === plateFormID
    );
    setReviewPlatformInfo(reviewPlatformInfoValue);
    setSelectPlateForm(plateFormID);
    setReviewPlaceId(reviewPlatformInfoValue?.placeId);
    if (
      userCurrentBusinessDetails?.isOnlineBusiness &&
      (parseInt(plateFormID, 10) === 5 || parseInt(plateFormID, 10) === 6)
    ) {
      fetchProducts(plateFormID);
    }
  }

  const handleSelectProduct = (id) => {
    const findProduct = products?.find((product) => product.id === id);
    setSelectedProduct(findProduct);
    setReviewPlaceId(findProduct?.placeId);
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  function formatRating(value) {
    return value >= 1000 ? `${(value / 1000).toFixed(1)}k` : value;
  }

  return (
    <>
      {pageLoading ? (
        <FuseLoading />
      ) : (
        <div className="lg:p-24 p-16  h-full l-padding">
          <div className="sm:flex w-full items-center justify-between mb-16 lg:mb-20">
            <div className="items-center justify-between sm:mb-0 mb-16">
              <Typography
                component={motion.span}
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="sm:flex text-20 md:text-28 font-bold"
              >
                {t('quickHubDashboard.matrices')}
              </Typography>
            </div>
            <div className="flex gap-28 flex-col md:flex-row">
              <Select
                className="inline-block bg-white text-black capitalize border-none font-semibold w-full md:w-224"
                id="country"
                value={parseInt(selectPlateForm, 10)}
                size="small"
                displayEmpty
                sx={{
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                    border: 'none',
                  },
                }}
                onChange={(e) => handlePlatformChange(e)}
              >
                {!userCurrentBusinessDetails?.isOnlineBusiness &&
                  userCurrentBusinessDetails?.reviewplatform?.map((option, i) => (
                    <MenuItem key={i} value={option?.reviewPlatformId} className="capitalize">
                      <img className="w-20 mr-10" src={option?.logo} alt="" /> {option.name}
                    </MenuItem>
                  ))}
                {userCurrentBusinessDetails?.isOnlineBusiness &&
                  userCurrentBusinessDetails?.reviewplatform
                    ?.slice()
                    ?.sort((a, b) => a.id - b.id)
                    .map((item, index) => {
                      return (
                        <MenuItem className="capitalize" value={item?.reviewPlatformId} key={index}>
                          <div className="flex gap-8">
                            <img className="w-20" src={item?.logo} alt="" />{' '}
                            <Typography>
                              {' '}
                              {item?.name && capitalizeFirstLetter(item.name)}
                            </Typography>
                          </div>
                        </MenuItem>
                      );
                    })}
              </Select>
              {userCurrentBusinessDetails?.isOnlineBusiness &&
                products?.length > 0 &&
                parseInt(reviewPlatformInfo?.reviewPlatformId, 10) !== 7 && (
                  <Select
                    className="inline-block bg-white text-black capitalize border-none pt-0 truncate font-semibold w-full md:w-224 relative"
                    id="country"
                    value={parseInt(selectedProduct?.id, 10)}
                    size="small"
                    displayEmpty
                    renderValue={() => selectedProduct?.genericName?.slice(0, 28)}
                    sx={{
                      '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                        border: 'none',
                        maxWidth: '320px',
                      },
                    }}
                    onChange={(e) => handleSelectProduct(e.target.value)}
                  >
                    {products?.map((product, i) => (
                      <MenuItem
                        key={i}
                        value={product?.id}
                        className="capitalize whitespace-normal"
                      >
                        <div className="w-full max-w-360">{product.genericName}</div>
                      </MenuItem>
                    ))}
                  </Select>
                )}
            </div>
          </div>
          {analysisPercentage !== 100 ? (
            <div className="sm:w-1/2 h-4 mb-4 mt-20">
              <div className="flex justify-between items-center px-8">
                <Typography className="text-16 font-semibold flex items-center gap-8">
                  <CircularProgress className="text-quick-review" size={20} />{' '}
                  {t('metrics.dataCalculating')}
                </Typography>
                <Typography className="text-16 font-semibold">{analysisPercentage}%</Typography>
              </div>
              <div className="relative w-full h-16 rounded-full bg-[#D9D9D9] my-12">
                <div
                  className="absolute top-0 left-0 h-full rounded-full bg-quick-review"
                  style={{ width: `${analysisPercentage}%` }}
                />
              </div>
              <Typography className="text-16 font-semibold pl-8">
                {t('metrics.dataCalculatingMessage')}
              </Typography>
            </div>
          ) : (
            <>
              <div className="grid gap-12 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                <div className="bg-white rounded-md relative shadow md:p-20 p-7">
                  <h2 className="text-15 font-bold mb-12 break-all capitalize">
                    {userCurrentBusinessDetails?.name}
                  </h2>
                  <Typography className="text-16 font-medium mb-16">
                    {userCurrentBusinessDetails?.address || userCurrentBusinessDetails?.address2}
                  </Typography>
                  <img
                    className="w-full max-h-288 rounded-md"
                    src={
                      userCurrentBusinessDetails?.profileURL ||
                      'assets/images/business/businessprofile.png'
                    }
                    alt="businessprofile.png"
                  />
                </div>

                <div className="bg-white rounded-md shadow md:p-20 p-7 flex flex-col justify-between">
                  <div className="flex-1 bg-gray-50 mb-6 rounded-md items-center flex">
                    <div className="mx-auto text-center">
                      <h3 className="text-14 font-semibold">
                        {t('quickHubDashboard.totalReview')}
                      </h3>
                      {!userCurrentBusinessDetails?.isOnlineBusiness ||
                      parseInt(reviewPlatformInfo?.reviewPlatformId, 10) === 7 ? (
                        <span className="text-36 font-semibold">
                          {formatRating(
                            reviewPlatformInfo?.reviewPlatformId === 3
                              ? reviewPlatformInfo?.aggregateData?.aggregateTotalReview
                              : reviewPlatformInfo?.totalReview
                          )}
                        </span>
                      ) : (
                        <span className="text-36 font-semibold">
                          {formatRating(selectedProduct?.totalReviews) || 0}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex-1 bg-gray-50 mt-6 rounded-md items-center flex">
                    <div className="mx-auto text-center">
                      <h3 className="text-16 font-semibold">{t('metrics.averageRating')}</h3>
                      {!userCurrentBusinessDetails?.isOnlineBusiness ||
                      parseInt(reviewPlatformInfo?.reviewPlatformId, 10) === 7 ? (
                        <span className="text-36 font-semibold">
                          {reviewPlatformInfo?.reviewPlatformId === 3
                            ? reviewPlatformInfo?.aggregateData?.aggregateAverageRating
                            : reviewPlatformInfo?.totalRatingStars}
                        </span>
                      ) : (
                        <span className="text-36 font-semibold">{selectedProduct?.rating}</span>
                      )}
                      {!userCurrentBusinessDetails?.isOnlineBusiness ||
                      parseInt(reviewPlatformInfo?.reviewPlatformId, 10) === 7 ? (
                        <span className="block">
                          {reviewFormate(
                            reviewPlatformInfo?.reviewPlatformId === 3
                              ? reviewPlatformInfo?.aggregateData?.aggregateAverageRating
                              : reviewPlatformInfo?.totalRatingStars
                          )}
                        </span>
                      ) : (
                        <span className="block">{reviewFormate(selectedProduct?.rating)}</span>
                      )}
                    </div>
                  </div>
                </div>

                {parseInt(reviewPlatformInfo?.reviewPlatformId, 10) !== 5 &&
                  parseInt(reviewPlatformInfo?.reviewPlatformId, 10) !== 6 && (
                    <div className="bg-white rounded-md relative shadow md:p-20 p-7 sm:grid-cols-2 lg:grid-cols-3">
                      <div className="flex flex-row">
                        <h3 className="text-15 font-bold mb-16 items-center">
                          {t('metrics.receivedReview')}
                        </h3>
                        <Tooltip
                          title={metricsIconInfo?.receivedReview}
                          arrow
                          classes={{
                            tooltip: 'bg-quick-review',
                            arrow: 'text-quick-review',
                          }}
                        >
                          <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">
                            info
                          </Icon>
                        </Tooltip>
                      </div>
                      <ReceivedReviews
                        reviewSentiment={reviewSentiment}
                        reviewPlatformId={selectPlateForm}
                        useArea="metrics"
                      />
                    </div>
                  )}
              </div>
              <Masonry
                columns={{ xs: 1, sm: 2, md: 3 }}
                className="mt-8 gap-x-4 gap-y-0 md:gap-y-8"
              >
                <div className="bg-white rounded-md relative shadow md:p-20 p-20">
                  <div className="flex items-center mb-16">
                    <h3 className="text-15 font-bold">{t('metrics.reviewSentiment')}</h3>
                    <Tooltip
                      title={metricsIconInfo?.reviewSentiment}
                      arrow
                      classes={{
                        tooltip: 'bg-quick-review',
                        arrow: 'text-quick-review',
                      }}
                    >
                      <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">info</Icon>
                    </Tooltip>
                  </div>
                  <ReviewSentimentChart
                    reviewSentiment={reviewSentiment}
                    reviewPlatformId={selectPlateForm}
                    placeId={reviewPlaceId}
                    totalReview={
                      !userCurrentBusinessDetails?.isOnlineBusiness ||
                      parseInt(reviewPlatformInfo?.reviewPlatformId, 10) === 7
                        ? reviewPlatformInfo?.reviewPlatformId === 3
                          ? reviewPlatformInfo?.aggregateData?.aggregateTotalReview
                          : reviewPlatformInfo?.totalReview
                        : selectedProduct?.totalReviews
                    }
                    sizes="91%"
                    dataLabel="false"
                    useArea="metrics"
                  />
                </div>
                <div className="bg-white rounded-md relative shadow md:p-20 p-7">
                  <div className="flex items-center mb-16">
                    <h3 className="text-15 font-bold">{t('metrics.reviewDistribution')}</h3>
                    <Tooltip
                      title={metricsIconInfo?.reviewDistribution}
                      arrow
                      classes={{
                        tooltip: 'bg-quick-review',
                        arrow: 'text-quick-review',
                      }}
                    >
                      <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">info</Icon>
                    </Tooltip>
                  </div>
                  <ReviewDistribution
                    reviewPlatformId={selectPlateForm}
                    placeId={reviewPlaceId}
                    totalReview={
                      !userCurrentBusinessDetails?.isOnlineBusiness ||
                      parseInt(reviewPlatformInfo?.reviewPlatformId, 10) === 7
                        ? reviewPlatformInfo?.reviewPlatformId === 3
                          ? reviewPlatformInfo?.aggregateData?.aggregateTotalReview
                          : reviewPlatformInfo?.totalReview
                        : selectedProduct?.totalReviews
                    }
                    averageRating={
                      !userCurrentBusinessDetails?.isOnlineBusiness ||
                      parseInt(reviewPlatformInfo?.reviewPlatformId, 10) === 7
                        ? reviewPlatformInfo?.reviewPlatformId === 3
                          ? reviewPlatformInfo?.aggregateData?.aggregateAverageRating
                          : reviewPlatformInfo?.totalRatingStars
                        : selectedProduct?.rating
                    }
                    color={['#FFBD23']}
                    categories={['5 Stars', '4 Stars', '3 Stars', '2 Stars', '1 Stars']}
                    useArea="metrics"
                  />
                </div>
                {Number(reviewPlatformInfo?.reviewPlatformId) === 2 && (
                  <div className="bg-white rounded-md relative shadow md:p-20 p-7">
                    <div className="flex items-center mb-16">
                      {' '}
                      <h3 className="text-15 font-bold">{t('metrics.reviewVelocity')}</h3>{' '}
                      <Tooltip
                        title={metricsIconInfo?.reviewVelocity}
                        arrow
                        classes={{
                          tooltip: 'bg-quick-review',
                          arrow: 'text-quick-review',
                        }}
                      >
                        <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">
                          info
                        </Icon>
                      </Tooltip>
                    </div>
                    <ReviewVelocity />
                  </div>
                )}
                <div className="bg-white rounded-md relative shadow md:p-20 p-7">
                  <div className="flex items-center mb-16">
                    <h3 className="text-15 font-bold">{t('metrics.customerSatisfactionScore')}</h3>
                    <Tooltip
                      title={metricsIconInfo?.customerSatisfactionScore}
                      arrow
                      classes={{
                        tooltip: 'bg-quick-review',
                        arrow: 'text-quick-review',
                      }}
                    >
                      <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">info</Icon>
                    </Tooltip>
                  </div>
                  <CustomerSatisfactionScore
                    reviewPlatformId={selectPlateForm}
                    placeId={reviewPlaceId}
                  />
                </div>
                <div className="bg-white rounded-md relative shadow md:p-20 p-7">
                  <div className="flex items-center mb-16">
                    <h3 className="text-15 font-bold">{t('metrics.attributeComparison')}</h3>
                    <Tooltip
                      title={metricsIconInfo?.attributeComparison}
                      arrow
                      classes={{
                        tooltip: 'bg-quick-review',
                        arrow: 'text-quick-review',
                      }}
                    >
                      <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">info</Icon>
                    </Tooltip>
                  </div>
                  <AttributeComparison
                    reviewSentiment={reviewSentiment}
                    reviewPlatformId={selectPlateForm}
                    placeId={reviewPlaceId}
                  />
                </div>
                {!userCurrentBusinessDetails?.isOnlineBusiness && (
                  <div className="bg-white rounded-md relative shadow md:p-20 p-7">
                    <div className="flex items-center mb-16">
                      {' '}
                      <h3 className="text-15 font-bold">{t('metrics.customersSay')}</h3>
                      <Tooltip
                        title={metricsIconInfo?.customerSay}
                        arrow
                        classes={{
                          tooltip: 'bg-quick-review',
                          arrow: 'text-quick-review',
                        }}
                      >
                        <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">
                          info
                        </Icon>
                      </Tooltip>
                    </div>
                    <CustomersSay userCurrentBusinessDetails={userCurrentBusinessDetails} />
                  </div>
                )}
                {!userCurrentBusinessDetails?.isOnlineBusiness && (
                  <div className="bg-white rounded-md relative shadow md:p-20 p-7">
                    <div className="flex items-center mb-16">
                      <h3 className="text-15 font-bold">{t('metrics.ratingTrendTracker')}</h3>
                      <Tooltip
                        title={metricsIconInfo?.reviewTrends}
                        arrow
                        classes={{
                          tooltip: 'bg-quick-review',
                          arrow: 'text-quick-review',
                        }}
                      >
                        <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">
                          info
                        </Icon>
                      </Tooltip>
                    </div>
                    <RatingTrendTracker
                      reviewSentiment={reviewSentiment}
                      reviewPlatformId={selectPlateForm}
                    />
                  </div>
                )}
                {Number(reviewPlatformInfo?.reviewPlatformId) === 2 && (
                  <div className="bg-white rounded-md relative shadow p-10">
                    <CompetitorBenchmarking
                      reviewSentiment={reviewSentiment}
                      reviewPlatformId={selectPlateForm}
                      title={metricsIconInfo?.competitorBenchmarking}
                    />
                  </div>
                )}
                {googleAndYelpAvailable && (
                  <div className="bg-white rounded-md relative shadow md:p-20 p-7">
                    {' '}
                    <div className="flex items-center mb-16">
                      <h3 className="text-18 font-bold">{t('metrics.reviewsOnGoogleVsYelp')}</h3>
                      <Tooltip
                        title={metricsIconInfo?.googleVsYelp}
                        arrow
                        classes={{
                          tooltip: 'bg-quick-review',
                          arrow: 'text-quick-review',
                        }}
                      >
                        <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">
                          info
                        </Icon>
                      </Tooltip>
                    </div>
                    <ReviewsOnGoogleVsYelp
                      reviewSentiment={reviewSentiment}
                      reviewPlatformId={selectPlateForm}
                    />
                  </div>
                )}
                <div className="bg-white rounded-md relative shadow md:p-20 p-7">
                  <div className="flex items-center mb-16">
                    <h3 className="text-15 font-bold">{t('metrics.quickReviewsCount')}</h3>
                    <Tooltip
                      title={metricsIconInfo?.quickReviewCount}
                      arrow
                      classes={{
                        tooltip: 'bg-quick-review',
                        arrow: 'text-quick-review',
                      }}
                    >
                      <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">info</Icon>
                    </Tooltip>
                  </div>
                  <QuickReviewsCount
                    reviewSentiment={reviewSentiment}
                    reviewPlatformId={selectPlateForm}
                    placeId={reviewPlaceId}
                  />
                </div>
                <div className="bg-white rounded-md relative shadow md:p-20 p-7">
                  <div className="flex items-center mb-16">
                    <h3 className="text-15 font-bold">{t('metrics.netPromoterScore')}</h3>
                    <Tooltip
                      title={metricsIconInfo?.netPromoterScore}
                      arrow
                      classes={{
                        tooltip: 'bg-quick-review',
                        arrow: 'text-quick-review',
                      }}
                    >
                      <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">info</Icon>
                    </Tooltip>
                  </div>
                  <NetPromoterScore
                    reviewSentiment={reviewSentiment}
                    reviewPlatformId={selectPlateForm}
                    placeId={reviewPlaceId}
                  />
                </div>
                {!userCurrentBusinessDetails?.isOnlineBusiness && (
                  <div className="bg-white rounded-md relative shadow md:p-20 p-7">
                    <div className="flex items-center mb-16">
                      <h3 className="text-15 font-bold">{t('metrics.reviewSentimentOverTime')}</h3>
                      <Tooltip
                        title={metricsIconInfo?.reviewSentimentOverTime}
                        arrow
                        classes={{
                          tooltip: 'bg-quick-review',
                          arrow: 'text-quick-review',
                        }}
                      >
                        <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">
                          info
                        </Icon>
                      </Tooltip>
                    </div>
                    <ReviewSentimentOverTime
                      reviewSentiment={reviewSentiment}
                      reviewPlatformId={selectPlateForm}
                      placeId={reviewPlaceId}
                    />
                  </div>
                )}
                {!userCurrentBusinessDetails?.isOnlineBusiness && (
                  <div className="bg-white rounded-md relative shadow md:p-20 p-7">
                    <div className="flex items-center mb-16">
                      <h3 className="text-15 font-bold">{t('metrics.attributesSentiment')}</h3>
                      <Tooltip
                        title={metricsIconInfo?.attributeSentiments}
                        arrow
                        classes={{
                          tooltip: 'bg-quick-review',
                          arrow: 'text-quick-review',
                        }}
                      >
                        <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">
                          info
                        </Icon>
                      </Tooltip>
                    </div>
                    <AttributesSentiment reviewPlatformId={selectPlateForm} />
                  </div>
                )}
                {Number(reviewPlatformInfo?.reviewPlatformId) !== 7 && (
                  <div className="bg-white rounded-md relative shadow md:p-20 p-7">
                    <div className="flex items-center mb-16">
                      <h3 className="text-15 font-bold">{t('metrics.repliedVsNoReplied')}</h3>
                      <Tooltip
                        title={metricsIconInfo?.repliedVsNotReplied}
                        arrow
                        classes={{
                          tooltip: 'bg-quick-review',
                          arrow: 'text-quick-review',
                        }}
                      >
                        <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">
                          info
                        </Icon>
                      </Tooltip>
                    </div>
                    <RepliedVsNotReplied
                      reviewSentiment={reviewSentiment}
                      reviewPlatformId={selectPlateForm}
                      placeId={reviewPlaceId}
                      totalReview={
                        !userCurrentBusinessDetails?.isOnlineBusiness ||
                        parseInt(reviewPlatformInfo?.reviewPlatformId, 10) === 7
                          ? reviewPlatformInfo?.reviewPlatformId === 3
                            ? reviewPlatformInfo?.aggregateData?.aggregateTotalReview
                            : reviewPlatformInfo?.totalReview
                          : selectedProduct?.totalReviews
                      }
                      sizes="91%"
                      dataLabel="false"
                    />
                  </div>
                )}
                {Number(reviewPlatformInfo?.reviewPlatformId) !== 7 && (
                  <div className="bg-white rounded-md relative shadow md:p-20 p-7">
                    <div className="flex items-center mb-16">
                      <h3 className="text-15 font-bold">{t('metrics.textVsNoText')}</h3>
                      <Tooltip
                        title={metricsIconInfo?.textVsNoText}
                        arrow
                        classes={{
                          tooltip: 'bg-quick-review',
                          arrow: 'text-quick-review',
                        }}
                      >
                        <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">
                          info
                        </Icon>
                      </Tooltip>
                    </div>
                    <TextVsNoText
                      reviewSentiment={reviewSentiment}
                      reviewPlatformId={selectPlateForm}
                      placeId={reviewPlaceId}
                      totalReview={
                        !userCurrentBusinessDetails?.isOnlineBusiness ||
                        parseInt(reviewPlatformInfo?.reviewPlatformId, 10) === 7
                          ? reviewPlatformInfo?.reviewPlatformId === 3
                            ? reviewPlatformInfo?.aggregateData?.aggregateTotalReview
                            : reviewPlatformInfo?.totalReview
                          : selectedProduct?.totalReviews
                      }
                      sizes="91%"
                      dataLabel="false"
                    />
                  </div>
                )}
              </Masonry>
              {!userCurrentBusinessDetails?.isOnlineBusiness && (
                <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-12 mt-12">
                  <div className="bg-white rounded-md relative shadow md:p-20 p-7 w-full">
                    <div className="flex items-center mb-16">
                      <h3 className="text-15 font-bold">{t('metrics.reviewsCloud')}</h3>
                      <Tooltip
                        title={metricsIconInfo?.reviewsCloud}
                        arrow
                        classes={{
                          tooltip: 'bg-quick-review',
                          arrow: 'text-quick-review',
                        }}
                      >
                        <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">
                          info
                        </Icon>
                      </Tooltip>
                    </div>
                    <ReviewCloud
                      reviewSentiment={reviewSentiment}
                      reviewPlatformId={selectPlateForm}
                      placeId={reviewPlaceId}
                    />
                  </div>

                  <div className="bg-white rounded-md relative shadow md:p-20 p-7 w-full">
                    <ReviewsOverview
                      reviewSentiment={reviewSentiment}
                      reviewPlatformId={selectPlateForm}
                      metricsIconInfo={metricsIconInfo?.reviewsOverview}
                    />
                  </div>
                </div>
              )}
              {!userCurrentBusinessDetails?.isOnlineBusiness && (
                <div className="bg-white rounded-md relative shadow md:p-20 p-7 w-full mt-12">
                  <div className="flex items-center mb-16">
                    <h3 className="text-15 font-bold">{t('metrics.heatmap')}</h3>
                    <Tooltip
                      title={metricsIconInfo?.reviewsHeatmap}
                      arrow
                      classes={{
                        tooltip: 'bg-quick-review',
                        arrow: 'text-quick-review',
                      }}
                    >
                      <Icon className="text-quick-review text-20 align-middle mt-4 ml-4">info</Icon>
                    </Tooltip>
                  </div>
                  <ReviewsHeatMap reviewPlatformId={selectPlateForm} />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
export default Matrices;
