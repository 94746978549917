import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import history from '@history';
import FuseLoading from '@fuse/core/FuseLoading';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Toolbar,
  Icon,
  Box,
} from '@mui/material';
import ChoosePlatform from './choose-platform';
import AudienceSegmentBudget from './audienceSegment-budget';
import ChooseMedia from './choose-media';

function CreateAds() {
  const location = useLocation;
  const { data } = location.state;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [allFeaturesTrialDataId, setAllFeaturesTrialDataId] = useState();
  const [trialStatus, setTrialStatus] = useState({
    social: false,
    chat: false,
    campaign: false,
  });

  const steps = [
    'Choose Platform & Schedule',
    'Audience Segment & Budget',
    'Choose Media',
    'Ad Copy & CTA Selection',
  ];

  if (loading) {
    return <FuseLoading />;
  }

  const handleClick = (index) => {
    setActiveStep(index);
  };

  return (
    <div className="p-16 lg:p-24 h-full">
      <div className="sm:flex justify-between lg:mb-20 mb-16">
        <h1 className=" md:text-28 text-20 font-semibold mb-16 sm:mb-0 flex items-center">
          Create Campaign
        </h1>
      </div>
      <div>
        <div className="flex mb-32">
          {steps.map((label, index) => (
            <React.Fragment key={index}>
              <div className="flex flex-row max-h-72">
                <div className="flex flex-col gap-20">
                  <Button
                    className={`border-2 border-solid text-14 sm:text-16 font-medium px-20 py-5 hover:bg-quick-social hover:border-quick-social hover:text-white ${
                      activeStep === index
                        ? 'border-quick-social bg-quick-social text-white'
                        : 'border-gray-300'
                    } `}
                    onClick={() => handleClick(index)}
                  >
                    <Icon className="mr-5">groups</Icon>
                    <p className="text-center">{label}</p>
                  </Button>
                </div>
                {index < steps.length - 1 && (
                  <div className="w-36 sm:w-96 h-1 mt-20 mx-12 sm:mx-10 my-2 relative bg-grey-800 before:w-5 before:h-5 before:bg-black before:inline-block before:rounded-full before:absolute before:-mt-2 after:w-5 after:h-5 after:bg-black after:inline-block after:rounded-full after:absolute after:-mt-2 after:right-0" />
                )}
              </div>
            </React.Fragment>
          ))}
        </div>

        <div>
          {activeStep === 0 && (
            <Box className="max-w-786 w-full">
              <ChoosePlatform setActiveStep={setActiveStep} />
            </Box>
          )}
          {activeStep === 1 && (
            <Box className="max-w-[982px] w-full">
              <AudienceSegmentBudget setActiveStep={setActiveStep} />
            </Box>
          )}
          {activeStep === 2 && (
            <Box className="max-w-786 w-full">
              <ChooseMedia setActiveStep={setActiveStep} />
            </Box>
          )}
          {activeStep === 3 && (
            <Box>
              {/* <QuickCampaignsFeatures
              allFeaturesTrialDataId={allFeaturesTrialDataId}
              campaignTrialDone={trialStatus.campaign}
              surpriseMeCardData={surpriseMeCardData}
            /> */}
              step 4
            </Box>
          )}
        </div>
      </div>
    </div>
  );
}
export default CreateAds;
