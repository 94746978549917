import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import Pagination from 'src/app/component/table/Pagination';
import { selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import { Button, Collapse } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Delete, Edit } from '@mui/icons-material';
import quickChatQuery from '../../query/quickChat';
import { handleApiRequest } from '../../common/common';
import manageBusinessData from '../../query/manageBusiness';
import BotResponsesAddNew from './BotResponsesAddNew';
import Loading from '../manage-business/Loading';

const BotResponses = (props) => {
  const {
    getQuestionResponseData,
    setPageSize,
    setPageNumber,
    pageSize,
    pageNumber,
    getQRData,
    totalCount,
    isLoading,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [showBotResponse, setShowBotResponse] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [activeIndex, setActiveIndex] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [openDialogueForDelete, setOpenDialogueForDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({ question: '', response: '' });
  const [editIndex, setEditIndex] = useState(null);

  const onClose = () => {
    setIsEdit(false);
    setEditData({ question: '', response: '' });
    setEditIndex(null);
    setShowBotResponse(false);
  };

  const handleSubmit = async ({ question, response }) => {
    try {
      const isEditMode = isEdit ? 'updateQuickChatBotResponses' : 'configureQuickChatBotResponses';
      const variables = isEdit
        ? {
            questionId: parseInt(getQRData[editIndex]?.id, 10),
            question,
            response,
          }
        : {
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            question,
            response,
          };
      const payload = {
        query: manageBusinessData[isEditMode],
        variables,
      };
      const result = await handleApiRequest(payload);
      const status = result?.[isEditMode]?.status;
      const message = result?.[isEditMode]?.message;
      if (status === 200) {
        getQuestionResponseData();
        setActiveIndex(null);
        enqueueSnackbar(message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    } finally {
      onClose();
    }
  };

  const handleDeleteClick = (index) => {
    setDeleteIndex(index);
    setOpenDialogueForDelete(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (deleteIndex !== null) {
        const payload = {
          query: manageBusinessData.deleteQuickChatConfigQuestion,
          variables: {
            questionId: parseInt(getQRData[deleteIndex]?.id, 10),
          },
        };

        const result = await handleApiRequest(payload);
        if (result?.deleteQuickChatConfigQuestion?.status === 200) {
          getQuestionResponseData();
          setActiveIndex(null);
          enqueueSnackbar(result?.deleteQuickChatConfigQuestion?.message, {
            variant: 'success',
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(result?.deleteQuickChatConfigQuestion?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      }
    } catch (error) {
      console.error('Error deleting data:', error);
    }
    setOpenDialogueForDelete(false);
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center bg-white  lg:py-200 md:px-144 sm:py-96 py-48 rounded-6">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <div className={`rounded-6 ${getQRData?.length <= 0 ? 'bg-white' : ''}`}>
        {!(isEdit || showBotResponse) && (
          <div className="flex justify-between mb-16">
            {getQRData?.length > 0 && (
              <div className="flex items-start xs:items-center w-full justify-between gap-10 xs:gap-0 flex-col xs:flex-row">
                <h1 className="xs:text-20 text-16 not-italic font-semibold">
                  {t('botResponses.configureBotResponses')}
                </h1>
                <Button
                  variant="contained"
                  color="quickChat"
                  size="small"
                  onClick={() => setShowBotResponse(true)}
                  className="rounded-md w-full xs:w-auto"
                >
                  {t('botResponses.addBotResponse')}
                </Button>
              </div>
            )}
          </div>
        )}
        <div>
          {isEdit || showBotResponse ? (
            <BotResponsesAddNew
              initialQuestion={editData?.question}
              initialAnswer={editData?.response}
              isEditing={isEdit}
              onClose={onClose}
              onSubmit={handleSubmit}
              cards={getQRData}
            />
          ) : (
            <>
              {getQRData?.length > 0 ? (
                <>
                  {getQRData?.map((item, index) => (
                    <div key={index} className=" bg-white px-24 rounded-6 mb-16">
                      <div
                        className="flex  justify-between items-center cursor-pointer"
                        onClick={() => toggleAccordion(index)}
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            toggleAccordion(index);
                          }
                        }}
                      >
                        <p className="lg:text-20 md:text-20 sm:text-16 text-12">{item?.question}</p>
                        <span className=" text-yellow-800 text-36">
                          {activeIndex === index ? '—' : '+'}
                        </span>
                      </div>

                      <Collapse in={activeIndex === index} timeout={700}>
                        <div className=" pb-16">
                          <p className="lg:text-16 md:text-16 sm:text-16 text-12 border-t-2 border-grey-600 text-gray-600 pt-16 mb-24">
                            {item?.response}
                          </p>
                          <div className="flex sm:flex-row flex-col">
                            <Button
                              variant="contained"
                              size="small"
                              className="rounded-4 lg:mr-24 md:mr-24 sm:mr-24 lg:mb-0 md:mb-0 sm:mb-0 mb-10"
                              onClick={() => {
                                setEditData({
                                  question: item?.question,
                                  response: item?.response,
                                });
                                setIsEdit(true);
                                setEditIndex(index);
                              }}
                            >
                              <Edit className="mr-5 h-16 w-16" />
                              {t('botResponses.editResponse')}
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              className="rounded-4"
                              onClick={() =>
                                handleDeleteClick(index, item?.question, item?.response)
                              }
                            >
                              <Delete className="mr-5 h-16 w-16" />
                              {t('botResponses.deleteResponse')}
                            </Button>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  ))}
                  <div className="mt-16">
                    <Pagination
                      totalCount={totalCount}
                      rowsPerPage={pageSize}
                      page={pageNumber}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
        {getQRData?.length === 0 && !isEdit && !showBotResponse && (
          <div className="text-center p-40 sm:p-120">
            <img
              src="/assets/images/icon/botNoResponse.png"
              alt="bot-bg"
              className="mx-auto mb-24"
            />
            <h3 className="lg:text-24 md:text-24 sm:text-24 text-20 font-semibold mb-16">
              {t('botResponses.noBotResponsesAdded')}
            </h3>
            <p className="lg:text-20 md:text-20 sm:text-20 text-15">
              {t('botResponses.noBotResponsesAddedPara').split('. ')[0]}.
            </p>
            <p className="lg:text-20 md:text-20 sm:text-20 text-15 mb-40">
              {t('botResponses.noBotResponsesAddedPara').split('. ')[1]}
            </p>
            <Button
              variant="contained"
              color="quickChat"
              size="large"
              onClick={() => setShowBotResponse(true)}
              className="rounded-md"
            >
              {t('botResponses.addBotResponse')}
            </Button>
          </div>
        )}
      </div>

      <ConformationDialogBox
        open={openDialogueForDelete}
        onClose={() => setOpenDialogueForDelete(false)}
        onDelete={() => handleConfirmDelete()}
        additionalInfo={t('botResponses.deletePara')}
        confirmationText={t('botResponses.areYouSure')}
        deleteImage="/assets/images/icon/trashBin.png"
        backgroundColor="quick-chat"
        textColor="quick-chat"
        buttonText={t('common.confirm')}
      />
    </div>
  );
};

export default BotResponses;
