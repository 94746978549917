const getQuickHubProducts = `
query($countryId: Int,$packageInterval: String,$packageTypeId: Int){
  getQuickHubProducts(countryId: $countryId, packageInterval: $packageInterval, packageTypeId: $packageTypeId){
    message
    status
    data{
      id
      name
      image
      description
      featureDetails
      packageIndex
      currency
      packageInterval
      packageBundle
      stripePriceId
      razorpayPlanId
      offerAmount
      packageTypeId
      monthlyAmount
      amount
      yearlyAmount
    }
  }
}
`;

const getSurpriseMeCard = `
query($isHomeScreen: Boolean!,$userBusinessId: Int!){
  getSurpriseMeCard(isHomeScreen: $isHomeScreen, userBusinessId: $userBusinessId){
    message
    status
    data{
      id
      button
      description
      buttonColor
      image
    }
  }
}
`;

const getQuickHubOverAllSummary = `
query($businessId: Int!){
  getQuickHubOverAllSummary(businessId: $businessId){
    message
    status
    data {
      overallSummary {
        key
        value
        icon
        backgroundColor
      }
    }
  }
}
`;

const quickHubOverAllFullReport = `
query($businessId: Int!){
  quickHubOverAllFullReport(businessId: $businessId){
    message
    status
    data{
      url
      timestamp
    }
  }
}
`;

const getSpecialOfferForUserBusiness = `
query($userBusinessId: Int!){
  getSpecialOfferForUserBusiness(userBusinessId: $userBusinessId){
    message
    status
    data {
      id
      backgroundColor
      name
      description
      icons
      button {
        name
        buttonColor
      }
      offerAmount
      packageInterval
      discount
      packageId
      packageBundle
      amount
      razorpayPlanId
      stripePriceId
    }
  }
}
`;

const getQuickHubProductSnapShotData = `
query($businessId: Int!){
  getQuickHubProductSnapShotData(businessId: $businessId){
    message
    status
    data {
      id
      type
      dataPoint {
       sharedPostCount
       totalEngagementsCount
       todayConversation
       todayReview
       totalConversation
       smsSent
       emailSent
       whatsAppSent
       totalReviews{
        count
        newReviews
       }
      totalReviewsRating{
        count
        totalReviewRatingsUpToGo
      }
      }
      pendingTask {
        totalProgressCount
        progressCount
        card {
          name
          button
          screen
          portalURL
          onlineIndex
          physicalIndex
          isOfflineSupport
          isOnlineSupport
        }
      }
      whatsNewCard
    }
  }
}
`;

const getCombo = `query ($countryId: Int!, $interval: String!, $packageId: Int, $packageTypeId: [Int!], $stripeIds: [String!]){
   getCombo(countryId: $countryId, interval: $interval, packageId: $packageId, packageTypeId: $packageTypeId, stripeIds: $stripeIds){
     message
     status
     data{
      amount
      discount
      expireDate
      isOffer
      name
      offerAmount
      packageId
      packagesName{
        id
        name
      }
      razorpayPlanId
      startDate
      stripeIds
      stripePriceId
      tax
     }
   }
  }`;

const redirectPortalForRazorpayOneTimePayment = `mutation ($amount: Float!, $billingCycle: String!, 
    $isCombo: Boolean!, $packageId: Int!, $userBusinessId: Int){
    redirectPortalForRazorpayOneTimePayment(
    amount: $amount,
    billingCycle: $billingCycle,
    isCombo: $isCombo,
    packageId: $packageId,
    userBusinessId: $userBusinessId
    ){
    status
    message
    data{
    url
    }
  }
}`;

const redirectPortalForSubscription = `mutation redirectPortalForSubscription($countryId: Int!, $priceId: String, 
    $stripePriceIds: [String!], $userBusinessId: Int){
    redirectPortalForSubscription(
    countryId: $countryId
    priceId: $priceId
    stripePriceIds: $stripePriceIds
    userBusinessId: $userBusinessId){
      message
      status
      data{
      url
      razorpaySubscriptionId      
    }
    }
  }`;

const getOverallRatingAndReview = `query getOverallRatingAndReview($businessId: Int, $domain: String, $pageNumber: Int, $pageSize: Int){
    getOverallRatingAndReview(businessId: $businessId, domain: $domain, pageNumber: $pageNumber, pageSize: $pageSize){
      message
      status
      data{
      review
      reviewText
      reviewBackgroundColor
      rating
      ratingText
      ratingBackgroundColor
      todayReviews
      todaysReviewText
      todaysReviewBackgroundColor
    }
      id
      uts
    }
  }`;

const getQuickCampaignDataPoint = `query getQuickCampaignDataPoint($businessId: Int){
    getQuickCampaignDataPoint(businessId: $businessId){
      message
      status
      data{
        key
        backgroundColor
        icon
        value
      }
    }
  }`;

const createAndEditUserBusinessesLabel = `mutation createAndEditUserBusinessesLabel($label : String!, $color: String!, $businessIdsToAdd: [Int!], $businessIdsToRemove: [Int!], $labelId: Int){
  createAndEditUserBusinessesLabel(
    label: $label
    color: $color
    businessIdsToAdd: $businessIdsToAdd
    businessIdsToRemove: $businessIdsToRemove
    labelId: $labelId
  ) {
    status
    message
  }
}`;

const getAllBusinessesLabels = `query ($pageSize: Int, $pageNumber: Int){
  getAllBusinessesLabels (pageSize: $pageSize , pageNumber: $pageNumber){
    status
    message
    totalCount
    data {
      id
      label
      color
      businessAssociatedCount
    }
  }
}`;

const deleteBusinessesLabel = `mutation ($labelId: Int!){
  deleteBusinessesLabel(labelId: $labelId) {
    status
    message
  }
}`;

const getAllBusinesses = `query ($businessName: String, $isGoogleConnected: Boolean, $dataMask: String, $labelIds: [Int!], $searchText: String, $city: String, $state: String, $stateId: Int, $countryId: Int, $pageSize: Int, $pageNumber:Int, $sortBy: String, $sortOrder: OrderDirection, $userBusinessId: Int) {
  getAllBusinesses(
    businessName: $businessName
    isGoogleConnected: $isGoogleConnected
    dataMask: $dataMask
    labelIds: $labelIds
    searchText: $searchText
    city: $city
    state: $state
    stateId: $stateId
    countryId: $countryId
    pageSize: $pageSize
    pageNumber: $pageNumber
    sortBy: $sortBy
    sortOrder: $sortOrder
    userBusinessId: $userBusinessId
  ) {
    status
    message
    totalCount
    data {
      id
      name
      address
      address2
      profileURL
      city
      googleBusinessAccountId
      quickHubAdsAccountId
      userId
      countries {
        name
      }
      states {
        name
      }
      phoneNumber {
        isoCode
        countryCode
        phoneNumber
      }
      label {
        id
        label
        color
      }
      viewData {
        regularHours
        activeServices
        activeAttributes
        isImagesAvailable
        isVideosAvailable
        isProfilePhotoAvailable
        isCoverPhotoAvailable
      }
    }
  }
}`;

const getOnlineReputationReport = `query ($labelId: [Int!], $userBusinessId: [Int!], $durationDate: durationDateDto){
  getOnlineReputationReport(
    labelId: $labelId,
    userBusinessId: $userBusinessId,
    durationDate: $durationDate
  ) {
    status
    message
    data {
      placeId
      businessName
      address
      label
      reviewDistribution {
        key
        value
      }
      starRatingByBusiness {
        key
        value
      }
      numberOfReviewByBusiness {
        key
        value
      }
    }
  }
}
`;

const quickHub = {
  getQuickHubProducts,
  getSurpriseMeCard,
  getQuickHubOverAllSummary,
  quickHubOverAllFullReport,
  getSpecialOfferForUserBusiness,
  getQuickHubProductSnapShotData,
  getCombo,
  redirectPortalForRazorpayOneTimePayment,
  redirectPortalForSubscription,
  getOverallRatingAndReview,
  getQuickCampaignDataPoint,
  createAndEditUserBusinessesLabel,
  getAllBusinessesLabels,
  deleteBusinessesLabel,
  getAllBusinesses,
  getOnlineReputationReport,
};

export default quickHub;
