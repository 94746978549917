import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import history from '@history';
import { selectDashboardData } from 'app/store/userSlice';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { findExpireDay } from '../common/common';

const CommonCard = (props) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [packageType, setPackageType] = useState('');
  const [content, setContent] = useState('');
  const {
    open,
    filteredData,
    productData,
    onDelete,
    refresh,
    backgroundColor,
    textColor,
    from,
    productId,
  } = props;
  const { t } = useTranslation();
  const filteredProductData = productData?.find((product) => product?.name === from);

  let productsId;
  switch (from) {
    case 'Quick Reviews':
      productsId = 7;
      break;
    case 'Quick Social':
      productsId = 8;
      break;
    case 'Quick Chat':
      productsId = 9;
      break;
    case 'Quick Campaigns':
      productsId = 10;
      break;
    default:
      productsId = null;
  }

  const handleGoBack = () => {
    console.log('Products ID: 1111', productsId);
    switch (productsId) {
      case 7:
        history.push('quick-reviews/description/');
        break;
      case 8:
        history.push('quick-social/description/');
        break;
      case 9:
        history.push('quick-chat/description/');
        break;
      case 10:
        history.push('quick-campaign/description/');
        break;
      default:
        console.warn('No matching product ID.');
        break;
    }
  };

  useEffect(() => {
    if (filteredProductData) {
      const product = filteredProductData;
      const expired = findExpireDay(product?.expireDate) < 0;
      if (product?.isFreeTrial && expired) {
        setPackageType(t('DashboardPopUp.freeTrail'));
        setContent(t('DashboardPopUp.freeTrailExp'));
      } else {
        setPackageType(t('users.UserList.Filter.subscription'));
        setContent(t('DashboardPopUp.subscriptionExp'));
      }
    }
  }, [filteredProductData, t]);

  return (
    <div
      className="relative bg-cover bg-center p-16"
      style={{
        backgroundImage: `url('${
          productsId === 8
            ? '/assets/images/icon/quickSocialBg.png'
            : productsId === 7
            ? '/assets/images/icon/quickReviewsBg.png'
            : productsId === 9
            ? '/assets/images/icon/quickChatBg.png'
            : productsId === 10
            ? '/assets/images/icon/quickCampaignsBg.png'
            : ''
        }')`,
      }}
    >
      <div className="relative flex flex-col gap-5">
        <img
          src="/assets/images/icon/crown.png"
          className="mx-auto lg:h-56 h-32 w-52 lg:w-88"
          alt="..."
        />
      </div>
      <DialogTitle
        id="delete-confirmation-dialog"
        className="lg:text-24 text-18 font-600 text-red text-center"
      >
        {t('quickHubDashboard.your')} {packageType} {t('DashboardPopUp.for')} {from}{' '}
        {t('DashboardPopUp.hasEnded')}
      </DialogTitle>
      <DialogContent className="p-0 mb-24">
        <div className="block w-full flex justify-center items-center text-center">
          <Typography className="lg:text-18 text-14 font-600 text-black w-11/12">
            {content}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions className="p-0 flex justify-between hover:bg: gap-14">
        <Button
          className="text-12 lg:text-16 mx-auto w-full font-medium rounded-md"
          variant="outlined"
          color={
            productsId === 8
              ? 'quickSocial'
              : productsId === 10
              ? 'quickCampaign'
              : productsId === 9
              ? 'quickChat'
              : productsId === 7
              ? 'quickReview'
              : ''
          }
          type="button"
          size="small"
          onClick={() => handleGoBack()}
        >
          {t('DashboardPopUp.explorePlan')}
        </Button>
        <Button
          className="text-12 lg:text-16 mx-auto w-full font-medium rounded-md"
          variant="contained"
          color={
            productsId === 8
              ? 'quickSocial'
              : productsId === 10
              ? 'quickCampaign'
              : productsId === 9
              ? 'quickChat'
              : productsId === 7
              ? 'quickReview'
              : ''
          }
          type="button"
          size="small"
          disabled={refresh}
          onClick={() => {
            history.push('selectProduct/', {
              packageBundle: [productsId],
              intervalType: 'month',
              paymentDetails: 'subscription',
              isBusinessAdded: !!userCurrentBusinessDetails?.id,
            });
          }}
        >
          {t('DashboardPopUp.renewNow')}
          {refresh && <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />}
        </Button>
      </DialogActions>
    </div>
  );
};

export default CommonCard;
