import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import userService from 'src/app/services';
import { useSelector } from 'react-redux';
import { selectDashboardData, getAllUserBusinessSubscription } from 'app/store/userSlice';
import history from '@history';
import FuseLoading from '@fuse/core/FuseLoading';
import { useLocation, useParams } from 'react-router-dom';
import planQuery from '../../query/plan';
import { findExpireDay, handleApiRequest } from '../../common/common';

const OffersAndPricing = () => {
  const [offerAndPricingData, setOfferAndPricingData] = useState({});
  const loginUserData = userService.getUserData();
  const [specialOffersData, setSpecialOffersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const specialOffersFetched = useRef(false);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const pricingFetched = useRef(false);
  const location = useLocation();
  const [tabValue, setTabValue] = useState(location?.state?.tabValue || 0);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const redirect = (type, id) => {
    try {
      if (type === 'offerAndPricing') {
        const routesMap = {
          7: 'quick-reviews',
          9: 'quick-chat',
          8: 'quick-social',
          10: 'quick-campaign',
          11: 'quick-ads',
        };
        const route = routesMap[id];
        history.push(`/${route}/description/`);
      }
    } catch (error) {
      console.error('Error during redirection:', error);
    }
  };

  const parseSubscriptionData = Array.isArray(userSubscriptionData)
    ? userSubscriptionData.flatMap((item) => {
        if (
          userCurrentBusinessDetails?.id
            ? findExpireDay(item?.expireDate) > 0 &&
              !item?.isFreeTrial &&
              item?.userBusinessId === userCurrentBusinessDetails?.id
            : findExpireDay(item?.expireDate) > 0 && !item?.isFreeTrial
        ) {
          return item?.comboDetails?.length > 1
            ? item.comboDetails?.map((detail) => detail?.id) || []
            : [item.packageTypeId].filter(Boolean);
        }
        return [];
      })
    : [];

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        if (specialOffersFetched.current) return;
        const payload = {
          query: planQuery.getSpecialOfferForUserBusiness,
          variables: {
            userBusinessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getSpecialOfferForUserBusiness?.status === 200) {
          setSpecialOffersData(result?.getSpecialOfferForUserBusiness?.data);
          specialOffersFetched.current = true;
        }
      } catch (error) {
        console.error('Error ', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        if (pricingFetched.current) return;
        const payload = {
          query: planQuery.getPackagesV2,
          variables: {
            callFromPortal: true,
            countryId: loginUserData?.countryId,
            packageInterval: 'month',
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getPackages?.status === 200) {
          const data = result?.getPackages?.data?.map((item) => ({
            ...item,
            featureInsideSection: JSON.parse(item?.featureInsideSection),
          }));
          setOfferAndPricingData(data);
          pricingFetched.current = true;
        }
      } catch (error) {
        console.error('Error ', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [loginUserData?.countryId]);

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-24">
      <div className="flex w-full flex-col">
        <div className="items-center justify-between mb-10">
          <Typography
            variant="subtitle1"
            color="inherit"
            className="flex-1 font-bold text-16 md:text-28"
          >
            {t('plan.offer&Pricing')}
          </Typography>
        </div>

        <div className="border-b-2 border-solid border-grey sm:flex justify-between items-center">
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => setTabValue(newValue)}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              className="sm:text-18 font-bold p-0 sm:mr-28 mr-14"
              value={0}
              label={t('quickHubDashboard.specialOffers')}
            />
            <Tab className="sm:text-18 font-bold p-0" value={1} label={t('products.title')} />
          </Tabs>
        </div>

        {tabValue === 0 && (
          <>
            {specialOffersData?.length > 0 ? (
              <div className="w-full mt-20 rounded-md ">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-14 h-auto">
                  {specialOffersData?.map((items, i) => (
                    <div className="rounded-md min-h-512 h-full" key={i}>
                      <div className="bg-white max-w-480 h-full rounded-8 border-1 border-gray-300 p-16 sm:p-20 flex flex-col gap-16">
                        <div className="text-20 font-800">
                          <div className="text-20 font-800">
                            {items?.packageBundle?.length === 4 && 'Quick Bundle'}
                            {items?.packageBundle?.length !== 4 && items?.name}
                            {items?.packageBundle?.length === 2 && ' of Two'}
                            {items?.packageBundle?.length === 3 && ' of Three'}
                          </div>
                        </div>
                        <div className="flex relative">
                          {items?.iconsAndNames &&
                            items?.iconsAndNames?.map((image, index) => (
                              <img
                                key={index}
                                className={`max-w-60 border-1 border-grey-300 rounded-full relative ${
                                  index !== 0 ? '-ml-12' : ''
                                }`}
                                src={image?.icon}
                                alt=""
                                style={{ zIndex: index }}
                              />
                            ))}
                        </div>
                        <div>
                          <div className="mb-12 align-middle capitalize">
                            {items?.offerAmount && (
                              <h3 className="text-28 font-bold mb-0">
                                <span>{loginUserData?.countryId === 2 ? '₹' : '$'}</span>
                                {items?.offerAmount}/{items?.packageInterval?.replace(/_/g, ' ')}
                              </h3>
                            )}
                            <h3
                              className={`block ${
                                items?.offerAmount
                                  ? 'line-through text-grey-500 font-medium text-16'
                                  : 'text-28 font-bold'
                              }`}
                            >
                              <span>{loginUserData?.countryId === 2 ? '₹' : '$'}</span>
                              {items?.amount}/{items?.packageInterval?.replace(/_/g, ' ')}
                            </h3>
                          </div>

                          <span className="flex">
                            <img
                              className="h-24 max-w-28 rounded-6 border-1 border-r-0 border-grey-300 bg-grey-100 p-5"
                              src="/assets/images/icon/discount.svg"
                              alt=""
                            />
                            <span className="text-blue-900 h-24 text-14 min-w-[180px] font-500 rounded-6 border-1 border-l-0 border-grey-300 bg-grey-100 px-5 ">
                              {t('plan.save')} {loginUserData?.countryId === 2 ? '₹' : '$'}
                              {items?.discount} {t('plan.onThis')} {items?.name}!
                            </span>
                          </span>
                        </div>
                        <p className="text-16 min-h-144 max-h-144 text-grey-700">
                          {items?.description}
                        </p>
                        <div className="flex flex-col justify-center">
                          <Button
                            variant="contained"
                            color="secondary"
                            className="md:text-16 font-medium disabled:text-black rounded-md sm:min-w-160"
                            aria-label="skip"
                            type="button"
                            onClick={() =>
                              history.push('selectProduct/', {
                                packageBundle: items?.packageBundle,
                                intervalType: items?.packageInterval,
                                isBusinessAdded: !!userCurrentBusinessDetails?.id,
                                specialOffer: true,
                              })
                            }
                          >
                            {items?.button?.name}
                          </Button>
                        </div>
                        <h3 className="font-600 text-16 text-start">{t('plan.whatsIncluded')}</h3>
                        <div className="flex flex-col gap-16">
                          {items?.icons &&
                            items?.iconsAndNames?.map((image, ind) => (
                              <div className="flex items-center gap-14" key={ind}>
                                <img
                                  className="max-w-28 border-1 border-grey-300 rounded-full"
                                  src={image?.icon}
                                  alt=""
                                />
                                <p className="font-500 text-14">{image?.name}</p>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex w-full justify-center pt-144 items-center">
                <div className="max-w-512 gap-12 flex flex-col items-center">
                  <img src="/assets/images/icon/specialOfferIcon.svg" className="w-160" alt="..." />
                  <Typography className="text-24 font-600 flex text-center ">
                    {t('manageBusiness.addBusinessSteps.businessLocation.offerNotAvailable')}
                  </Typography>
                  <Typography className="font-medium  text-14 flex text-center">
                    {t('manageBusiness.addBusinessSteps.businessLocation.noDataAvailableDesc')}
                  </Typography>
                  <Button
                    className="bg-darkgreen text-white px-20 mt-16 hover:bg-darkgreen font-medium rounded"
                    onClick={() => setTabValue(1)}
                  >
                    {t('plan.exploreProducts')}
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
        {tabValue === 1 && (
          <div className="w-full rounded-md mt-20">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-14 h-auto">
              {offerAndPricingData.length > 0 &&
                offerAndPricingData
                  .sort((a, b) => {
                    if (
                      parseSubscriptionData.includes(a?.packageTypeId) &&
                      !parseSubscriptionData.includes(b?.packageTypeId)
                    ) {
                      return 1;
                    }
                    if (
                      parseSubscriptionData.includes(b?.packageTypeId) &&
                      !parseSubscriptionData.includes(a?.packageTypeId)
                    ) {
                      return -1;
                    }
                    return 0;
                  })
                  .map((items) => (
                    <div className="rounded-md flex flex-col h-full">
                      <div className="bg-white max-w-480 rounded-8 p-24 border-1 border-gray-300 flex flex-col gap-16 flex-grow">
                        <span className="text-20 font-700 block">{items?.name}</span>
                        <img
                          className="max-w-80 rounded-full border-1 border-grey-300"
                          src={items?.image}
                          alt=""
                        />
                        <div className="mb-12 align-middle capitalize">
                          {items?.offerAmount && (
                            <h3 className="text-28 font-bold mb-0">
                              <span>{loginUserData?.countryId === 2 ? '₹' : '$'}</span>
                              {items?.offerAmount}/{items?.packageInterval?.replace(/_/g, ' ')}
                            </h3>
                          )}
                          <h3
                            className={`block ${
                              items?.offerAmount
                                ? 'line-through text-grey-500 font-medium text-16'
                                : 'text-28 font-bold'
                            }`}
                          >
                            <span>{loginUserData?.countryId === 2 ? '₹' : '$'}</span>
                            {items?.amount}/{items?.packageInterval?.replace(/_/g, ' ')}
                          </h3>
                        </div>
                        <p className="min-h-120 text-14 max-h-200">
                          {items?.featureInsideSection?.description}
                        </p>
                        <div className="flex flex-col gap-16 min-h-96 max-h-96">
                          <Button
                            variant="contained"
                            color="secondary"
                            className="md:text-16 font-medium disabled:text-black rounded-md sm:min-w-160"
                            aria-label="skip"
                            type="button"
                            disabled={parseSubscriptionData.includes(items?.packageTypeId)}
                            onClick={() =>
                              history.push('selectProduct/', {
                                packageBundle: [items?.packageTypeId],
                                intervalType: items?.packageInterval,
                                isBusinessAdded: !!userCurrentBusinessDetails?.id,
                              })
                            }
                          >
                            {parseSubscriptionData.includes(items?.packageTypeId)
                              ? t('userProfile.subscribed')
                              : t('common.subscribeNow')}
                          </Button>
                          {!parseSubscriptionData.includes(items?.packageTypeId) && (
                            <Button
                              variant="outlined"
                              color="secondary"
                              className="md:text-16 font-medium disabled:text-black rounded-md sm:min-w-160"
                              aria-label="skip"
                              type="button"
                              onClick={() => {
                                redirect('offerAndPricing', items?.packageTypeId);
                              }}
                            >
                              {t('plan.learnMore')}
                            </Button>
                          )}
                        </div>
                        <h3 className="font-600 text-16 text-start">
                          {items?.featureInsideSection?.title2 ||
                            items?.featureInsideSection?.title}
                        </h3>
                        <div>
                          {items?.featureInsideSection?.pricingDetails?.included?.map((item) => (
                            <div className="flex items-center pb-10">
                              <img
                                src="/assets/images/business/check_circle.svg"
                                className="img-fluid max-w-16 mr-16"
                                alt="..."
                              />
                              <p className="text-14 font-500 text-start">{item}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OffersAndPricing;
