import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import QuickReviewsConfig from 'src/app/main/business/quick-reviews/quickReviewsConfig';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import pagesConfigs from '../main/pages/pagesConfigs';
import adminConfigs from '../main/admin/adminConfigs';
import businessConfigs from '../main/business/businessConfigs';
import QrScannerConfig from '../main/qr-scanner/QrScannerConfig';
import PaymentConfig from '../main/payment/PaymentConfig';
import DeleteUserConfig from '../main/delete-user/DeleteUserConfig';
import ResetPasswordConfig from '../main/reset-password/ResetPasswordConfig';
import ForgotPasswordConfig from '../main/forgot-password/ForgotPasswordConfig';
import salesConfigs from '../main/sales/salesConfigs';
import UserConfig from '../main/user/UserConfig';
import agencyConfigs from '../main/agency/agencyConfigs';
import QuickChatConfig from '../main/quick-chat/QuickChatConfig';
import QuickHubConfig from '../main/business/quick-hub/quickHub.config';

const routeConfigs = [
  ...pagesConfigs,
  ...adminConfigs,
  ...businessConfigs,
  ...salesConfigs,
  ...agencyConfigs,
  ...QuickReviewsConfig,
  UserConfig,
  SignInConfig,
  SignUpConfig,
  QrScannerConfig,
  PaymentConfig,
  DeleteUserConfig,
  ResetPasswordConfig,
  ForgotPasswordConfig,
  QuickChatConfig,
  QuickHubConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '*',
    element: <Navigate to="pages/error/404" />,
  },
];

export default routes;
