import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { selectDashboardData } from 'src/app/store/userSlice';
import metricsData from '../../query/metrics';
import NoData from './noDataScreen';
import ReviewSentiment from './reviewSentiment';
import { formatDateWithTimeZone, getDataToServer } from '../../common/common';

function ReceivedReviews(props) {
  const { reviewSentiment, reviewPlatformId, useArea, setDateDetails } = props;
  const [reviewsSentimentBarData, setReviewsSentimentBarData] = useState();
  const [selectedReviewSentimentId, setSelectedReviewSentimentId] = useState(
    useArea !== 'dashboard' ? 7 : 3
  );
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [planStatus, setPlanStatus] = useState();

  useEffect(() => {
    if (useArea === 'dashboard') {
      setDateDetails(reviewsSentimentBarData);
    }
  }, [reviewsSentimentBarData, setDateDetails, useArea]);

  const [newGraphData, setNewGraphData] = useState([
    {
      name: 'Negative',
      data: [],
    },
    {
      name: 'Neutral',
      data: [],
    },
    {
      name: 'Positive',
      data: [],
    },
  ]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (reviewPlatformId) {
          const payload = {
            query: metricsData.getReviewsSentimentBarDataV1,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
              reviewPlatformId: parseInt(reviewPlatformId, 10),
              reviewsSentimentPeriodsId: parseInt(selectedReviewSentimentId, 10),
            },
          };
          const result = await getDataToServer(payload);
          setPlanStatus(result?.getReviewsSentimentBarDataV1?.status);
          const keyValueObject =
            result?.getReviewsSentimentBarDataV1?.data?.reviewsSentimentJsonV1?.map((item) => {
              const obj = {};
              item.forEach(({ key, value }) => {
                obj[key] = value;
              });
              return obj;
            });
          const sortData = keyValueObject?.sort((a, b) => b.reviewPeriod - a.reviewPeriod);
          setReviewsSentimentBarData(sortData || []);
          const updatedGraphData = [
            {
              name: 'Positive',
              data:
                sortData
                  ?.map((item) => item?.numberOfPositiveReviews)
                  ?.filter((value) => value !== undefined) || [],
            },
            {
              name: 'Neutral',
              data:
                sortData
                  ?.map((item) => item?.numberOfNeutralReviews)
                  ?.filter((value) => value !== undefined) || [],
            },
            {
              name: 'Negative',
              data:
                sortData
                  ?.map((item) => item?.numberOfNegativeReviews)
                  ?.filter((value) => value !== undefined) || [],
            },
          ];
          setNewGraphData(updatedGraphData);
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id, reviewPlatformId, selectedReviewSentimentId]);
  function getChartSimple(datas, business, index, color, types) {
    const chart = {
      series: datas,
      options: {
        grid: {
          strokeDashArray: 3,
          show: true,
          forceNiceScale: false,
          padding: {
            bottom: 0,
          },
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: true,
          parentHeightOffset: 0,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'right',
            },
            horizontal: false,
            borderRadius: 3,
            columnWidth: useArea === 'dashboard' ? '50px' : '25px',
          },
        },
        legend: {
          markers: {
            radius: 12,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        fill: {
          colors: color,
          opacity: 1,
        },
        colors: color,
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          categories: business,
          show: true,
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            offsetY: 0,
          },
        },
        yaxis: {
          show: true,
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            align: 'left',
            maxWidth: 350,
            position: 'left',
            offsetX: -15,
            style: {
              fontSize: '14px',
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
      },
    };

    return (
      <div
        id={`chart${index}`}
        className="flex flex-col flex-auto w-full"
        style={{ overflowX: 'auto', overflowY: 'hidden' }}
      >
        <ReactApexChart
          options={chart?.options}
          series={chart?.series}
          type={chart?.options?.chart?.type}
          height="300px"
          width={business.length > 8 ? `${business.length * 60}px` : '100%'}
        />
      </div>
    );
  }

  return (
    <>
      {((planStatus !== 403 && !userCurrentBusinessDetails?.isOnlineBusiness) ||
        parseInt(reviewPlatformId, 10) === 7) && (
        <ReviewSentiment
          reviewSentiment={reviewSentiment}
          sentimentId={[7, 3, 2]}
          onSelectSentiment={setSelectedReviewSentimentId}
        />
      )}
      {reviewsSentimentBarData?.length > 0 ? (
        <>
          <div>
            {reviewsSentimentBarData &&
              getChartSimple(
                newGraphData,
                reviewsSentimentBarData?.map((period) =>
                  selectedReviewSentimentId === 3
                    ? formatDateWithTimeZone(period?.reviewPeriod, 'MMM DD YYYY')?.split(',')[0]
                    : formatDateWithTimeZone(period?.reviewPeriod, 'MMM YYYY')?.split(',')[0]
                ),
                1,
                ['#F2B443', '#3E8CF3', '#67DF9D'],
                'bar'
              )}
          </div>
        </>
      ) : (
        <NoData />
      )}
    </>
  );
}

export default ReceivedReviews;
