import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import { Icon, Button, Typography } from '@mui/material';
import { publicIpv4 } from 'public-ip';
import FuseLoadingWhiteBg from '@fuse/core/FuseLoading/FuseLoadingWhiteBg';
import { useTranslation } from 'react-i18next';
import { getDecryptData, getEncryptedData, handleApiRequest } from '../common/common';
import quickHub from '../query/quickHub';
import queryData from '../query/common';

const QuickProducts = ({ onStartTrial }) => {
  const { t } = useTranslation();
  const ipBasedData = localStorage.getItem('ipData')
    ? JSON.parse(getDecryptData(localStorage.getItem('ipData')))
    : '';
  const [quickHubProductList, setQuickHubProductList] = useState([]);
  const [checkedState, setCheckedState] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!ipBasedData?.countryId) return;
    const fetchData = async () => {
      const payload = {
        query: quickHub.getQuickHubProducts,
        variables: {
          countryId: ipBasedData?.countryId,
          packageInterval: 'month',
        },
      };
      try {
        const result = await handleApiRequest(payload);
        setLoading(false);
        const products = result?.getQuickHubProducts?.data || [];
        setQuickHubProductList(products);
        setCheckedState(new Array(products.length).fill(false));
      } catch (error) {
        console.error('Failed to fetch QuickHub products:', error);
      }
    };

    fetchData();
  }, [ipBasedData?.countryId]);

  const handleOnChange = (position, product) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);

    if (updatedCheckedState[position]) {
      setSelectedProduct((prevSelected) => [...prevSelected, product]);
    } else {
      setSelectedProduct((prevSelected) => prevSelected.filter((pro) => pro.id !== product.id));
    }
  };

  const handleRemoveProduct = (product) => {
    const productIndex = quickHubProductList.findIndex((p) => p.id === product.id);

    setSelectedProduct((prevSelected) => prevSelected.filter((pro) => pro.id !== product.id));

    const updatedCheckedState = checkedState.map((item, index) =>
      index === productIndex ? false : item
    );
    setCheckedState(updatedCheckedState);
  };

  const handleStartTrial = () => {
    onStartTrial(selectedProduct);
  };

  if (loading) {
    return <FuseLoadingWhiteBg />;
  }

  return (
    <div className="bg-white mx-auto w-full">
      <div className="text-center flex flex-col md:flex-row gap-20">
        <div className="inline-block align-top">
          <Typography className="mt-10 mb-2 text-20 font-bold text-left text-black">
            {t('selectProduct.quickProducts')}
          </Typography>
          <Typography className="mb-20 text-12 font-normal text-start text-black">
            {t('selectProduct.unlockMessage')}
          </Typography>
          {quickHubProductList &&
            quickHubProductList?.length > 0 &&
            quickHubProductList?.map((product, index) => (
              <div
                key={index}
                className={`border rounded-lg p-4 mb-20 last:mb-0 w-full md:w-480 mx-auto px-20 py-12  ${
                  checkedState[index] ? 'bg-darkgreen-100' : 'bg-white'
                }`}
              >
                <div className="block">
                  <div className="flex w-full text-start items-center">
                    <div className="mr-12 w-60">
                      <img src={product?.image} alt={product?.name} className="h-48 w-48" />
                    </div>
                    <div className="w-full flex gap-4 flex-col">
                      <div className="flex items-center justify-between">
                        <h3 className="font-bold text-16">{product?.name}</h3>

                        <div className="justify-around flex flex-col capitalize text-right">
                          {product?.offerAmount && (
                            <h3 className="text-16 font-bold inline-block mb-0">
                              <span>{product?.currency === 'INR' ? '₹' : '$'}</span>
                              {product.offerAmount}/{product?.packageInterval.replace(/_/g, ' ')}
                            </h3>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center justify-between ">
                        <p className="text-12 font-semibold text-gray-500">
                          {product?.description}
                        </p>
                        <h3
                          className={`block ${
                            product?.offerAmount
                              ? 'line-through text-grey-500 font-medium inline-block text-14'
                              : 'text-16 font-bold'
                          }`}
                        >
                          <span>{product?.currency === 'INR' ? '₹' : '$'}</span>
                          {product.amount}/{product?.packageInterval.replace(/_/g, ' ')}
                        </h3>
                      </div>
                      <div className="flex justify-end">
                        <Checkbox
                          className="p-0"
                          checked={checkedState[index]}
                          onChange={() => handleOnChange(index, product)}
                          sx={{
                            color: '#BEBEBE',
                            borderRadius: '10px',
                            '&.Mui-checked': {
                              color: 'darkgreen',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {selectedProduct?.length > 0 && (
          <div className="w-full md:w-320 mx-auto mt-0 md:mt-80 border-1 rounded-lg p-12 sm:p-12 md:p-24 inline-block">
            <h2 className="md:text-20 text-16 font-bold mb-24 text-left mt-0">
              {selectedProduct?.length}{' '}
              {selectedProduct?.length === 1 ? 'Product selected' : 'Products selected'}
            </h2>
            {selectedProduct &&
              selectedProduct?.length > 0 &&
              selectedProduct?.map((product, index) => (
                <div
                  key={index}
                  className="bg bg-darkgreen-100 rounded-lg p-4 mb-16 last:mb-0 w-full mx-auto md:px-20 sm:px-10 py-8"
                >
                  <div className="flex w-full items-center">
                    <div className="mr-12 w-40">
                      <img src={product?.image} alt={product?.name} className="h-36 w-36" />
                    </div>
                    <div className="w-full">
                      <div className="flex items-center justify-between">
                        <h3 className="font-semibold text-14">{product?.name}</h3>
                        <span>
                          <Icon
                            className="text-16 cursor-pointer"
                            onClick={() => handleRemoveProduct(product)}
                          >
                            close
                          </Icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <span className="bg-[#FFF8DD] p-12 block mt-80">{t('selectProduct.note')}</span>
            <Button
              onClick={handleStartTrial}
              className="block rounded-md w-full mt-16"
              size="medium"
              variant="contained"
              color="secondary"
            >
              {t('selectProduct.startFreeTrial')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuickProducts;
