import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import history from '@history';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import { Button, Typography, CircularProgress, TextField, Switch, Box } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DatePicker,
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
} from '@mui/x-date-pickers';

function ChooseMedia({ setActiveStep }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [refreshIcon, setRefreshIcon] = useState(false);

  const apiKey = 'AAGbst3NNN8';

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="quick-social">
      <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
        <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
          Upload or Create Media
        </Typography>
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 md:gap-20 gap-10 mb-24">
          <div>
            {' '}
            <Button
              // onClick={handleRequestExport}
              className="bg-blue-500 text-white py-2 px-4 rounded"
            >
              Open Canva Editor
            </Button>{' '}
          </div>
          <div>Media </div>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <Button
          className="md:text-16 font-medium rounded-md px-28 disabled:bg-gray"
          variant="outlined"
          color="quickSocial"
          //   onClick={() => ()}
          // disabled={}
        >
          Save Draft
          {refreshIcon && (
            <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
          )}
        </Button>
        <Button
          className="md:text-16 font-medium rounded-md px-28 disabled:bg-gray"
          variant="contained"
          color="quickSocial"
          onClick={() => setActiveStep(3)}
          // disabled={
          // }
        >
          Proceed
          {refreshIcon && (
            <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
          )}
        </Button>
      </div>
    </div>
  );
}
export default ChooseMedia;
