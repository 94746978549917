import { useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, Icon, Typography } from '@mui/material';
import { t } from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  capitalizeFirstLetter,
  fetchBusinesses,
  handleApiRequest,
} from 'src/app/main/common/common';
import {
  selectDashboardData,
  setUserBusinesses,
  setUserCurrentBusinessDetailsValue,
} from '../../../../store/userSlice';
import manageBusinessData from '../../../query/manageBusiness';
import userData from '../../../query/user';
import SupportForPhysicalOrOnline from '../supportForPhysicalOrOnline/SupportForPhysicalOrOnline';

const ManageReviewSite = () => {
  const [openDialogue, setOpenDialogue] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [removePlatForm, setRemovePlatForm] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [reviewPlateForm, setReviewPlateForm] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const dispatch = useDispatch();
  useEffect(() => {
    const data = userCurrentBusinessDetails?.reviewplatform?.filter((item) => item.placeId);
    setReviewPlateForm(data);
  }, [userCurrentBusinessDetails?.reviewplatform]);

  const handleSelectPlatForm = (data) => {
    if (data.defaultReview) {
      enqueueSnackbar(t('manageBusinessSite.alertMessage'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else {
      setOpenDialogue(true);
      setRemovePlatForm(data);
    }
  };

  const handleDelete = () => {
    setOpenDialogue(false);
    setRemovePlatForm();
  };

  const handleRemoveReviewPlatForm = async () => {
    if (!removePlatForm?.defaultReview) {
      setRefreshIcon(true);
      const payload = {
        query: manageBusinessData.removeBusinessPlatform,
        variables: {
          useBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          userBusinessPlatformId: parseInt(removePlatForm?.reviewPlatformId, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.removeBusinessPlatform?.status === 200) {
        handleDelete();
        const userBusinesses = await fetchBusinesses();
        await localStorage.setItem('businesses', JSON.stringify(userBusinesses));
        await dispatch(setUserBusinesses(userBusinesses));
        await dispatch(
          setUserCurrentBusinessDetailsValue(
            userBusinesses?.find((item) => item?.id === userCurrentBusinessDetails?.id)
          )
        );
      } else {
        enqueueSnackbar(result?.removeBusinessPlatform?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } else {
      enqueueSnackbar(t('manageBusinessSite.removeWarningMessage'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      handleDelete();
    }
  };
  return (
    <div className="lg:p-24 p-16 sm:h-max ">
      {!userCurrentBusinessDetails?.isOnlineBusiness ? (
        <>
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex md:text-28 text-20 font-bold lg:mb-20 mb-16"
          >
            {t('manageBusinessSite.title')}
          </Typography>
          <div className="bg-white mt-36 h-full p-24 sm:p-44">
            <Typography className="font-semibold text-24 text-center">
              {t('manageBusinessSite.takeCareOnlineBusinessMsg')}
            </Typography>
            <Typography className="font-medium text-16 md:px-44 leading-loose mt-28 m-auto text-center">
              {t('manageBusinessSite.message')}
            </Typography>
            <div className="mt-28">
              {reviewPlateForm?.length > 0 ? (
                <>
                  {reviewPlateForm?.map((item, i) => {
                    return (
                      <div className="sm:w-3/4 w-full m-auto" key={i}>
                        <div className="flex justify-between mb-16 rounded-md p-12 sm:p-20 bg-gray-A300">
                          <div className="flex items-center gap-4 sm:gap-24">
                            <img className="w-24 sm:w-48" src={item?.logo} alt="" />
                            <Typography className="sm:text-16 text-12 font-semibold">
                              {item?.name && capitalizeFirstLetter(item.name)}
                            </Typography>
                          </div>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => handleSelectPlatForm(item)}
                            className="inline-block align-middle rounded-md sm:text-16 text-12 py-1 px-8 sm:px-20 font-semibold text-red hover:bg-red-A500 bg-red-A500 min-w-124 sm:min-w-160 min-h-20 sm:min-h-28"
                          >
                            <span>{t('manageBusiness.button.removePlatform')}</span>
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <Typography className="text-center font-semibold text-20">
                  {t('manageBusinessSite.noReview')}
                </Typography>
              )}
            </div>
          </div>
          <Dialog
            classes={{
              paper:
                'm-24 sm:p-28 p-12 business-type-dropdown border-t-8 border-solid border-t-red-A300 border-red-A300 rounded-md',
            }}
            open={openDialogue}
            fullWidth
            maxWidth="sm"
          >
            <Icon
              onClick={() => handleDelete()}
              className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
            <div className="flex justify-center items-center flex-col overflow-y-auto">
              <div className="w-full m-auto mt-24">
                <img
                  className="w-144 text-center m-auto"
                  src="/assets/images/icon/warning.png"
                  alt=""
                />
              </div>
              <Typography className="text-20 font-semibold text-black text-center w-full my-28">
                {t('manageBusinessSite.removeReviewPlatForm')}
              </Typography>
              <Typography className="text-14 max-w-480 m-auto font-semibold text-black text-center w-full pb-10">
                {t('manageBusinessSite.warningMsg')}
              </Typography>
              <DialogActions className="p-0 mt-20 justify-center flex-col sm:flex-row gap-10 w-full">
                <Button
                  className="md:text-16 font-medium hover:bg-white bg-white border border-solid !border-quick-review bg-white  text-quick-review rounded-lg w-full sm:w-auto sm:px-40 px-0"
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => handleDelete()}
                >
                  {t('manageBusiness.addBusinessSteps.staffMember.dialog.cancel')}
                </Button>
                <Button
                  className="md:text-16 font-medium disabled:bg-gray disabled:text-black hover:bg-red-A300 bg-red-A300 sm:px-24 px-0 rounded-lg w-full sm:w-auto"
                  variant="contained"
                  color="secondary"
                  size="small"
                  disabled={refreshIcon}
                  onClick={() => handleRemoveReviewPlatForm()}
                >
                  {t('manageBusinessSite.removePlatForm')}
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-quick-review absolute mx-auto" />
                  )}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </>
      ) : (
        <SupportForPhysicalOrOnline />
      )}
    </div>
  );
};

export default ManageReviewSite;
