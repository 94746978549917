import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { t } from 'i18next';
import { useState } from 'react';
import LabelOrBusinessDrawer from '../reports/LabelOrBusinessDrawer';

const CreateNewPost = () => {
  const [postType, setPostType] = useState('Offer');
  const [fileName, setFileName] = useState('');
  const [openBusinessDrawer, setOpenBusinessDrawer] = useState(false);
  const [selectLabelOrBusiness, setSelectLabelOrBusiness] = useState([]);

  return (
    <div className="bg-gray-A500 min-h-screen p-16 sm:p-24">
      <Typography className="font-bold text-16 sm:text-24">{t('posts.createNewPost')}</Typography>
      <div className="border border-solid rounded-md bg-white mt-12 sm:max-w-640">
        <div className="p-12 sm:p-20">
          <Typography>{t('posts.selectPostType')}</Typography>
          <FormControl className="mt-12">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              row
              value={postType}
              onChange={(e) => setPostType(e.target.value)}
            >
              <FormControlLabel
                className="border border-solid w-96 rounded-full ml-0 mr-12"
                value="Offer"
                control={<Radio />}
                label={<span className="font-medium text-16">{t('posts.offer')}</span>}
                labelPlacement="start"
              />
              <FormControlLabel
                className="border border-solid w-112 rounded-full ml-0 mr-12"
                value="Action"
                control={<Radio />}
                label={<span className="font-medium text-16">{t('posts.action')}</span>}
                labelPlacement="start"
              />
              <FormControlLabel
                className="border border-solid w-96 rounded-full ml-0 "
                value="Event"
                control={<Radio />}
                label={<span className="font-medium text-16">{t('posts.event')}</span>}
                labelPlacement="start"
              />
            </RadioGroup>
          </FormControl>
          <div className="">
            <Typography className="font-semibold text-18 py-16">
              {postType} {t('posts.postTypeTitle')}
            </Typography>
            <TextField
              className="bg-gray-A500 w-full"
              placeholder={`Enter ${postType} Title`}
              size="small"
            />
            <Typography className="font-semibold text-18 py-16">
              {postType} {t('posts.postDetails')}
            </Typography>
            <Paper
              className="relative w-full h-full rounded-8 shadow-none border-1 border-solid p-40 bg-gray-A500 text-center"
              role="button"
              // onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <span className="block text-center">
                <img
                  src="/assets/images/business/uploadImage.png"
                  className="mx-auto mb-12"
                  alt="..."
                />
              </span>
              <div className="block font-medium text-16">
                {fileName || t('posts.dragAndDropCsv')}
              </div>
              <span className="block font-medium text-16">{t('signInPage.messages.or')}</span>
              <div className="inline-block">
                <label htmlFor="button-file">
                  <input
                    accept="image/png, image/jpg, image/jpeg"
                    className="hidden"
                    id="button-file"
                    type="file"
                    // onChange={handleFileChange}
                  />
                  <Button
                    size="small"
                    className="rounded-md block text-14 font-semibold px-32 py-8 mt-6 w-auto"
                    variant="contained"
                    color="secondary"
                    component="span"
                  >
                    {t('posts.browse')}
                  </Button>
                </label>
              </div>
            </Paper>
            <Typography className="font-semibold text-18 py-16">
              {t('posts.startDateAndTime')}
            </Typography>
            <div className="flex justify-between items-center gap-16 w-full">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  className="h-40 w-full bg-gray-A500"
                  id="note"
                  variant="outlined"
                  required
                  fullWidth
                  disablePast
                  // format="MMM dd, yyyy"
                  // value={postDetails?.startDate}
                  // onChange={(data) => handleSelectStartDate(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: false,
                      }}
                      label={t('listManagement.selectDate')}
                    />
                  )}
                />
              </LocalizationProvider>
              <TimePicker
                className="h-40 w-full bg-gray-A500"
                required
                // value={postDetails?.startTime}
                // onChange={(data) => handleSelectStartTime(data)}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </div>
            <Typography className="font-semibold text-18 py-16">
              {t('posts.startDateAndTime')}
            </Typography>
            <div className="flex justify-between items-center gap-16 w-full">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  className="h-40 w-full bg-gray-A500"
                  id="note"
                  variant="outlined"
                  required
                  fullWidth
                  disablePast
                  // format="MMM dd, yyyy"
                  // value={postDetails?.startDate}
                  // onChange={(data) => handleSelectStartDate(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: false,
                      }}
                      label={t('listManagement.selectDate')}
                    />
                  )}
                />
              </LocalizationProvider>
              <TimePicker
                className="h-40 w-full bg-gray-A500"
                required
                // value={postDetails?.startTime}
                // onChange={(data) => handleSelectStartTime(data)}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </div>
            <Typography className="font-semibold text-18 py-16">
              {postType} {t('posts.postDetails')}
            </Typography>
            <TextField
              className="bg-gray-A500 w-full"
              placeholder={`Enter ${postType} Details`}
              multiline
              rows={5}
            />
            {postType === 'Offer' ? (
              <div className="flex justify-between items-center gap-16 w-full">
                <div className="w-full">
                  <Typography className="font-semibold text-18 py-16">
                    {t('posts.couponCode')}
                  </Typography>
                  <TextField
                    className="bg-gray-A500 w-full"
                    size="small"
                    placeholder={t('posts.enterCouponCode')}
                  />
                </div>
                <div className="w-full">
                  <Typography className="font-semibold text-18 py-16">
                    {t('listManagement.redeemLink')}
                  </Typography>
                  <TextField
                    className="bg-gray-A500 w-full"
                    size="small"
                    placeholder={t('posts.enterRedeemLink')}
                  />
                </div>
              </div>
            ) : (
              <div className="w-full">
                <Typography className="font-semibold text-18 py-16">
                  {t('posts.ctaButton')}
                </Typography>
                <Select
                  className="w-full bg-gray-A500"
                  size="small"
                  displayEmpty
                  renderValue={() => t('posts.selectCtaButton')}
                >
                  <MenuItem>Hello</MenuItem>
                </Select>
              </div>
            )}
            <Typography className="font-semibold text-18 py-16">
              {t('signUpPage.messages.termsAndCondition')}
            </Typography>
            <TextField
              className="bg-gray-A500 w-full"
              size="small"
              placeholder={t('posts.enterTermsAndConditions')}
              multiline
              rows={5}
            />
            {postType !== 'Offer' && (
              <>
                <Typography className="font-semibold text-18 py-16">
                  {t('posts.websiteLink')}
                </Typography>
                <TextField
                  className="bg-gray-A500 w-full"
                  size="small"
                  placeholder={t('posts.enterWebsiteLink')}
                />
              </>
            )}
          </div>
        </div>
        <hr className="my-8" />
        <div className="p-12 sm:p-20 flex justify-between items-center">
          <Typography className="font-semibold text-18">{t('reports.selectBusiness')}</Typography>
          <Button
            className="rounded-md w-112"
            variant="contained"
            color="secondary"
            onClick={() => setOpenBusinessDrawer(true)}
          >
            {t('posts.select')}
          </Button>
        </div>
      </div>
      <LabelOrBusinessDrawer
        openBusinessDrawer={openBusinessDrawer}
        setOpenBusinessDrawer={setOpenBusinessDrawer}
        setSelectLabelOrBusiness={setSelectLabelOrBusiness}
        selectLabelOrBusiness={selectLabelOrBusiness}
        // setIsMatrix={setIsMatrix}
        groupType="business"
      />
    </div>
  );
};

export default CreateNewPost;
