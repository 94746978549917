import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { t } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import quickPostQuery from '../../query/quickPost';
import { handleApiRequest } from '../../common/common';

const SharePost = ({
  url,
  title,
  image,
  open,
  onClose,
  dialogTitle,
  businessId,
  editableTexts,
  plateFormID,
}) => {
  const handleSavePost = async () => {
    onClose();
    try {
      const payloadData = {
        query: quickPostQuery.saveViewedPosts,
        variables: {
          userBusinessesId: Number(businessId),
          sharePostTemplateId: Number(plateFormID?.data?.id),
          editableText: [],
          ...(plateFormID?.reviewTemplate && {
            reviewText: {
              reviewPlatformTypeId: Number(plateFormID?.reviewTemplate.id),
              review: plateFormID?.reviewTemplate?.review || '',
              reviewRating: plateFormID?.reviewTemplate?.reviewRating || null,
              reviewerName: plateFormID?.reviewTemplate?.reviewerName || '',
            },
          }),
        },
      };
      const result = await handleApiRequest(payloadData);
      if (result?.saveViewedPosts?.status !== 200) {
        console.log('Error saving post');
      }
    } catch (error) {
      console.error('Error saving post:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" PaperProps={{ style: { width: '500px' } }}>
      <DialogTitle>
        {dialogTitle || `${t('createPost.sharePosts')}`}
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="absolute right-2 top-2 text-gray-500"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="flex space-x-4 mb-4">
          <FacebookShareButton url={image} onClick={() => handleSavePost()}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <TwitterShareButton url={image} title={title} onClick={() => handleSavePost()}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <LinkedinShareButton url={image} onClick={() => handleSavePost()}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <WhatsappShareButton url={image} title={title} onClick={() => handleSavePost()}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SharePost;
