import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import moment from 'moment';
import { getAllUserBusinessSubscription, selectDashboardData } from 'src/app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import { useParams, useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import history from '@history';
import { publicIpv4 } from 'public-ip';
import { connectSocket, connectChatSocket } from '../../../../socket';
import {
  handleApiRequest,
  handleWabaApiRequest,
  getDecryptData,
  findExpireDay,
  formatDateWithTimeZone,
  transformUserSubscriptionData,
  getFilteredData,
  addExpiryToProductArray,
  defaultProductArr,
} from '../../common/common';
import DashboardAlerts from './DashboardAlerts';
import wabaQuery from '../../query/waba';
import SharePost from '../quick-post/SharePost';
import quickChatQuery from '../../query/quickChat';
import BotStatus from './BotStatus';
import CommonPendingTask from '../../CommonComponent/CommonPendingTask';
import CommonFreeTrial from '../../CommonComponent/CommonFreeTrial';
import DashBoardPopUp from '../../CommonComponent/DashBoardPopUp';

function Dashboards() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [quickChatDetails, setQuickChatDetails] = useState({});
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [loading, setLoading] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [getChatRoom, setGetChatRoom] = useState([]);
  const [getChatRoomWp, setGetChatRoomWp] = useState([]);
  const [subscribeChannelByBusinessUserStatus, setSubscribeChannelByBusinessUserStatus] =
    useState(false);
  const [quickChatActiveData, setQuickChatActiveData] = useState({});
  const [shareCompOpen, setShareCompOpen] = useState(false);
  const [whatsAppPhoneNumberId, setWhatsAppPhoneNumberId] = useState('');
  const [isConnected, setIsConnected] = useState(false); // socket.connected
  const chatSocket = connectChatSocket();
  const socket = connectSocket();
  const [dashboardData, setDashboardData] = useState({
    quickChatData: null,
    quickChatCountAndInsightsMatrix: [],
  });
  const [productData, setProductData] = useState([]);
  const botLink = `${process.env.REACT_APP_PORTAL_URL}/quickchat/${quickChatDetails?.domain}`;

  const filteredData = userSubscriptionData?.filter(
    (subscription) => subscription?.userBusinessId === userCurrentBusinessDetails?.id
  );

  const shouldShowPopup = productData?.some(
    (item) => findExpireDay(item?.expireDate) < 0 && item?.name === 'Quick Chat'
  );

  useEffect(() => {
    const transformedData = transformUserSubscriptionData(
      userSubscriptionData,
      userCurrentBusinessDetails
    );
    if (!transformedData) return;

    const filterData = getFilteredData(transformedData);
    const updatedProducts = addExpiryToProductArray(filterData, defaultProductArr);

    setProductData((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify(updatedProducts)) {
        return updatedProducts;
      }
      return prev;
    });
  }, [userSubscriptionData, userCurrentBusinessDetails?.id, userCurrentBusinessDetails]);

  const [timeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const redirect = (type) => {
    const idPath = params?.id ? `/${params?.id}` : '/';
    switch (type) {
      case 'matrices':
        history.push(`quick-chat/matrices${idPath}`);
        break;
      case 'waba':
        history.push(`quick-chat/waba${idPath}`);
        break;
      case 'web-chatBot':
        history.push(`quick-chat/chat${idPath}`);
        break;
      case 'whatsapp-bot':
        history.push(`quick-chat/waba-inbox${idPath}`);
        break;
      default:
        console.warn('Unknown redirect type:', type);
    }
  };

  const fetchDashboardData = async (id) => {
    const fetchPayload = (query, variables) => ({
      query,
      variables: { ...variables },
    });

    const requests = [
      handleApiRequest(fetchPayload(quickChatQuery.getQuickChat, { businessId: Number(id) })).catch(
        (err) => ({
          error: err,
        })
      ),
      handleWabaApiRequest(
        fetchPayload(wabaQuery.getChatRoomWithLastMessage, {
          isBroadcast: false,
          isUnRead: false,
          userBusinessId: id,
        })
      ).catch((err) => ({
        error: err,
      })),
      handleApiRequest(
        fetchPayload(quickChatQuery.getQuickChatCountAndInsightsMatrix, {
          businessId: Number(id),
          botTypeId: 1,
        })
      ).catch((err) => ({
        error: err,
      })),
    ];

    try {
      setLoading(true);
      const results = await Promise.all(requests);

      const [quickChatData, chatRoomWithLastMessage, quickChatCountAndInsightsMatrix] = results.map(
        (result) => (result.error ? null : result)
      );

      setGetChatRoomWp(chatRoomWithLastMessage?.getChatRoomWithLastMessage?.data);

      setDashboardData({
        quickChatData: quickChatData?.getQuickChat?.data?.id || null,
        quickChatCountAndInsightsMatrix:
          quickChatCountAndInsightsMatrix?.getQuickChatCountAndInsightsMatrix?.data || [],
      });
    } catch (error) {
      console.error('Error fetching dashboard data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      fetchDashboardData(userCurrentBusinessDetails?.id);
    }
  }, [userCurrentBusinessDetails?.id]);
  const { quickChatData, quickChatCountAndInsightsMatrix } = dashboardData;

  useEffect(() => {
    const handleConnect = () => {
      setIsConnected(true);
    };

    const handleReceivedMessage = (message) => {
      if (message?.payload?.eventType === 'MESSAGE_FROM_CLIENT') {
        setGetChatRoomWp((prevContacts) => {
          const contactExists = prevContacts?.some(
            (contact) => contact?.waChatRoomId === message?.payload?.data?.waChatRoomId
          );

          let updatedContacts;

          if (!contactExists) {
            updatedContacts = [
              {
                ...message?.payload?.data,
                cts: Number(message?.payload?.data?.timestamp) * 1000,
                unRead: 1,
              },
              ...prevContacts,
            ];
          } else {
            updatedContacts = prevContacts.map((contact) =>
              contact.waChatRoomId === message?.payload?.data?.waChatRoomId
                ? {
                    ...contact,
                    ...message?.payload?.data,
                    cts: Number(message?.payload?.data?.timestamp) * 1000,
                    unRead: 1,
                  }
                : contact
            );
          }
          return updatedContacts.sort((a, b) => b.cts - a.cts);
        });
      }
    };
    const handleDisconnect = () => {
      setIsConnected(false);
    };

    socket.on('receivedMessage', handleReceivedMessage);
    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);

    return () => {
      socket.off('connect', handleConnect);
      socket.off('receivedMessage', handleReceivedMessage);
      socket.off('disconnect', handleDisconnect);
    };
  }, [socket]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id && quickChatDetails?.id) {
      const handleConnect = async () => {
        try {
          const resolvedIpAddress = await publicIpv4();

          const subscribeChatData = JSON.stringify({
            userBusinessesId: userCurrentBusinessDetails?.id,
            ipAddress: resolvedIpAddress,
            quickChatId: quickChatDetails?.id,
          });
          chatSocket.emit('subscribeChannelByBusinessUser', subscribeChatData, (ack) => {
            console.log('Event emitted, acknowledgment:', ack);
          });

          await setIsConnected(true);
        } catch (error) {
          console.error('Error fetching IP address or connecting to chat:', error);
          setIsConnected(false);
        }
      };
      if (!subscribeChannelByBusinessUserStatus) {
        handleConnect();
        setSubscribeChannelByBusinessUserStatus(true);
      }

      const handleDisconnect = () => {
        setIsConnected(false);
      };

      const handleChatMessage = (event) => {
        if (
          event?.eventType === 'sendClientMessageToBusinessUser' ||
          event?.eventType === 'newClient'
        ) {
          setGetChatRoom((prevContacts) => {
            const contactExists = prevContacts.some((contact) => {
              return (
                contact?.quickChatRoomMessages?.quickChatRoomId === event?.data?.quickChatRoomId ||
                contact?.ipAddress === event?.data?.ipAddress
              );
            });
            let updatedContacts;

            if (!contactExists) {
              updatedContacts = [
                {
                  ...event?.data,
                  cts: Number(event?.data?.cts) * 1000,
                  unRead: 1,
                },
                ...prevContacts,
              ];
            } else {
              updatedContacts = prevContacts.map((contact) => {
                return contact?.quickChatRoomMessages?.quickChatRoomId ===
                  event?.data?.quickChatRoomId || contact?.ipAddress === event?.data?.ipAddress
                  ? {
                      ...contact,
                      quickChatRoomMessages: { ...event?.data },
                      cts: Number(event?.data?.cts) * 1000,
                      unRead: 1,
                    }
                  : contact;
              });
            }
            return updatedContacts.sort((a, b) => b.cts - a.cts);
          });
        }
        if (event?.eventType === 'clientCurrentStatus') {
          setGetChatRoom((prevContacts) => {
            const contactExists = prevContacts?.some((contact) => {
              return contact?.ipAddress === event?.data?.ipAddress;
            });
            if (!contactExists) {
              return null;
            }
            const updatedContacts = prevContacts.map((contact) =>
              contact?.ipAddress === event?.data?.ipAddress
                ? {
                    ...contact,
                    ...event?.data,
                  }
                : contact
            );
            return updatedContacts.sort((a, b) => b.cts - a.cts);
          });
        }
      };

      chatSocket.on('connect', handleConnect);
      chatSocket.on('disconnect', handleDisconnect);
      chatSocket.on('QUICK_CHAT_MESSAGES', handleChatMessage);
      return () => {
        chatSocket.off('connect', handleConnect);
        chatSocket.off('disconnect', handleDisconnect);
        chatSocket.off('QUICK_CHAT_MESSAGES', handleChatMessage);
      };
    }
    return undefined;
  }, [
    getChatRoom,
    quickChatDetails?.id,
    userCurrentBusinessDetails?.id,
    chatSocket,
    subscribeChannelByBusinessUserStatus,
  ]);

  const fetchAgentSupportStatus = useCallback(async () => {
    try {
      setLoading(true);
      if (userCurrentBusinessDetails?.id && !userCurrentBusinessDetails?.isOnlineBusiness) {
        const payloadData = {
          query: quickChatQuery.getQuickChatDetails,
          variables: {
            businessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        setLoading(false);
        if (resultData?.getQuickChatDetails?.status === 200) {
          setQuickChatDetails(resultData?.getQuickChatDetails?.data);
          setQuickChatActiveData(resultData?.getQuickChatDetails?.data);
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setLoading(false);
    }
  }, [userCurrentBusinessDetails?.id, userCurrentBusinessDetails?.isOnlineBusiness]);

  useEffect(() => {
    fetchAgentSupportStatus();
  }, [fetchAgentSupportStatus]);

  useEffect(() => {
    setLoading(true);
    const whatAppUserChatRoom = async () => {
      try {
        const payload = {
          query: quickChatQuery.getAllQuickChatWithLastMessage,
          variables: {
            quickChatId: quickChatData,
          },
        };
        const res = await handleApiRequest(payload);
        setLoading(false);
        if (res?.getAllQuickChatWithLastMessage?.status === 200) {
          setGetChatRoom(res?.getAllQuickChatWithLastMessage?.data);
        } else {
          setGetChatRoom([]);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    setLoading(false);

    if (quickChatData && !userCurrentBusinessDetails?.isOnlineBusiness) {
      whatAppUserChatRoom();
    }
  }, [quickChatData, userCurrentBusinessDetails?.isOnlineBusiness]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userCurrentBusinessDetails?.id) {
        try {
          const payload = {
            query: wabaQuery.getUserDetail,
            variables: {
              quickReviewBusinessId: Number(userCurrentBusinessDetails?.id),
            },
          };
          const res = await handleWabaApiRequest(payload);
          if (res?.getUserDetail?.status === 200) {
            if (res?.getUserDetail?.data) {
              const decryptedData = JSON.parse(getDecryptData(res.getUserDetail.data));
              setWhatsAppPhoneNumberId(decryptedData?.whatsAppPhoneNumberId);
            }
          }
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }
    };
    fetchUserDetails();
  }, [userCurrentBusinessDetails?.id]);

  const handleContactClick = (contact, type) => {
    setSelectedContact(contact);
    navigate(type === 'chat' ? '/quick-chat/chat/' : '/quick-chat/waba-inbox/', {
      state: { contact },
    });
  };

  const handleCloseComp = () => {
    setShareCompOpen(false);
  };

  const formatTimestamp = (timestamp, isUnix = true) => {
    const date = isUnix
      ? moment.unix(timestamp / 1000).tz(timeZone)
      : moment(timestamp).tz(timeZone);
    const today = moment().tz(timeZone);
    const yesterday = moment().tz(timeZone).subtract(1, 'days');
    if (date.isSame(today, 'day')) {
      return formatDateWithTimeZone(date, 'h:mm A');
    }
    if (date.isSame(yesterday, 'day')) {
      return 'Yesterday';
    }
    return formatDateWithTimeZone(date, 'MMM DD, YYYY');
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="lg:p-24 p-16 h-full l-padding">
      <div className="flex flex-col gap-32">
        <CommonFreeTrial callFrom="quickChat" productName="Quick Chat" packageBundle={9} />
        <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-x-0 sm:gap-x-32 gap-y-28">
          <div className="col-span-2">
            <div className="flex flex-col items-center gap-32">
              <CommonPendingTask
                productType="quick_chat"
                bgColor="quick-chat"
                buttonColor="quickChat"
                videoImg="/assets/images/icon/playVideoIcon-chat.svg"
                botLink={botLink}
              />
              {quickChatCountAndInsightsMatrix &&
                Object?.keys(quickChatCountAndInsightsMatrix)?.length > 0 && (
                  <div className="p-10 sm:p-16 w-full rounded-md bg-white">
                    <div className="flex flex-col gap-14">
                      <div className="w-full flex items-center justify-between">
                        <h1 className="text-20 font-bold">{t('quickHubDashboard.matrices')}</h1>
                        <Button
                          className="text-quick-chat h-0 font-semibold cursor-pointer hover:bg-transparent text-16 md:text-20"
                          size="small"
                          onClick={() => redirect('matrices')}
                        >
                          {t('dashboard.dashboardSales.viewAll')}
                        </Button>
                      </div>
                      <div className="flex md:flex-row flex-col gap-20">
                        {quickChatCountAndInsightsMatrix
                          ?.filter((item) => item?.name !== `Total Query`)
                          ?.sort((a, b) => {
                            const order = {
                              "Today's Conversations": 1,
                              'Total Conversations': 2,
                              'Success Rate': 3,
                            };
                            return order[a?.name] - order[b?.name];
                          })
                          ?.map((itemData) => {
                            let imageSrc = '';
                            if (itemData?.name === "Today's Conversations") {
                              imageSrc = '/assets/images/icon/todayConversation.svg';
                            } else if (itemData?.name === 'Total Conversations') {
                              imageSrc = '/assets/images/icon/totalConversations.svg';
                            } else if (itemData?.name === 'Success Rate') {
                              imageSrc = '/assets/images/icon/successRate.svg';
                            }

                            return (
                              <div
                                key={itemData?.name}
                                className="w-full md:w-360 p-14 flex rounded-6 items-center border-2 border-grey-300 h-112 sm:h-144"
                              >
                                <div className="rounded-full mr-10 flex items-center justify-center">
                                  <img
                                    src={imageSrc}
                                    alt={itemData?.name}
                                    className="w-60 h-60 sm:w-80 sm:h-80"
                                  />
                                </div>
                                <div>
                                  <h1 className="text-28 sm:text-36 font-semibold">
                                    {itemData?.name === 'Success Rate'
                                      ? `${itemData?.count}%`
                                      : itemData?.count}
                                  </h1>
                                  <p className="text-14 sm:text-16 font-medium">{itemData?.name}</p>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}
              <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-28 w-full">
                {!userCurrentBusinessDetails?.isOnlineBusiness && (
                  <div className="bg-white rounded-md p-10 sm:p-20 w-full">
                    <p className="text-20 font-bold">{t('quickChat.chatbotDetail.webchatBot')}</p>
                    <div className="bg-white min-h-320 max-h-512 relative mt-16 w-full">
                      {getChatRoom?.length > 0 ? (
                        <>
                          {getChatRoom?.slice(0, 5).map((contact, index) => (
                            <div
                              key={index}
                              className={`mb-10 cursor-pointer hover:bg-quick-chat-100 border-1 border-solid  rounded-md p-10 ${
                                selectedContact?.id === contact?.id
                                  ? 'bg-quick-chat-100 border-quick-chat-100 border-l-3 border-l-solid border-l-quick-chat'
                                  : 'bg-white border-grey-300 pl-12'
                              }`}
                              aria-hidden
                              onClick={() => handleContactClick(contact, 'chat')}
                            >
                              <div className="flex items-center">
                                <img
                                  src="/assets/images/business/profile.png"
                                  className="img-fluid w-full max-w-48 mr-10"
                                  alt="..."
                                />

                                <div className="overflow-hidden w-full">
                                  <div className="flex items-center justify-between">
                                    <h3 className="text-16 font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                                      {contact?.ipAddress}
                                    </h3>
                                    <span className="text-12 font-semibold w-88 text-right">
                                      {formatTimestamp(contact?.quickChatRoomMessages?.uts, false)}
                                    </span>
                                  </div>
                                  <div className="flex items-center justify-between">
                                    <span
                                      className="font-medium truncate text-14 h-20 w-[400px] mt-4 mb-4 text-grey-600"
                                      style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: contact?.quickChatRoomMessages?.message,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="w-full flex justify-center items-center">
                            <Button
                              className="h-20 font-semibold cursor-pointer hover:bg-transparent text-16 md:text-20"
                              size="small"
                              onClick={() => redirect('web-chatBot')}
                            >
                              <p className="text-quick-chat text-center font-600 text-16 sm:text-20">
                                {t('quickChat.chatbotDetail.seeAllChats')}
                              </p>
                              <ChevronRightIcon className="text-quick-chat" />
                            </Button>
                          </div>
                        </>
                      ) : (
                        <div className="w-full h-400 flex gap-20 flex-col px-10 sm:px-40 justify-center items-center">
                          <img
                            src="/assets/images/icon/noConversationsAvailableImage.svg"
                            className="img-fluid"
                            alt="..."
                          />
                          <Typography className="text-center text-16 font-600">
                            {t('quickChat.createBot.noConversationsAvailableTitle')}
                          </Typography>
                          <Typography className="text-center text-14 font-500">
                            {t('quickChat.createBot.noConversationsAvailableDesc')}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="bg-white rounded-md p-10 sm:p-20 w-full">
                  <p className="text-20 font-bold">{t('quickChat.chatbotDetail.whatsappBot')}</p>
                  <div className="bg-white min-h-320 max-h-512 relative mt-16 w-full">
                    {whatsAppPhoneNumberId && getChatRoomWp?.length > 0 ? (
                      <>
                        {getChatRoomWp?.slice(0, 5).map((contact, index) => (
                          <div
                            key={index}
                            className={`mb-10 cursor-pointer hover:bg-quick-chat-100 border-1 border-solid rounded-md p-10 ${
                              selectedContact?.id === contact?.id
                                ? 'bg-quick-chat-100 border-quick-chat-100 border-l-3 border-l-solid border-l-quick-chat'
                                : 'bg-white border-grey-300 pl-12'
                            }`}
                            aria-hidden
                            onClick={() => handleContactClick(contact, 'wp')}
                          >
                            <div className="flex items-center">
                              <img
                                src="/assets/images/business/profile.png"
                                className="img-fluid w-full max-w-48 mr-10"
                                alt="..."
                              />

                              <div className="overflow-hidden w-full">
                                <div className="flex items-center justify-between">
                                  <h3 className="text-16 font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                                    {contact?.name}
                                  </h3>
                                  <span className="text-12 font-semibold w-88 text-right">
                                    {formatTimestamp(contact?.cts) ||
                                      formatTimestamp(Number(contact?.timestamp))}
                                  </span>
                                </div>
                                <div className="flex items-center justify-between">
                                  <span className="font-medium text-14 whitespace-nowrap overflow-hidden w-11/12 text-ellipsis mt-4 mb-4 block text-grey-600">
                                    {contact?.message}
                                  </span>
                                  <span
                                    className={`text-right bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300 ${
                                      contact?.unRead === 0 || !contact?.unRead ? 'hidden' : ''
                                    }`}
                                  >
                                    New
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="w-full flex justify-center items-center">
                          <Button
                            className="h-20 font-semibold cursor-pointer hover:bg-transparent text-16 md:text-20"
                            size="small"
                            onClick={() => redirect('whatsapp-bot')}
                          >
                            <p className="text-quick-chat text-center font-600 text-16 sm:text-20">
                              {t('quickChat.chatbotDetail.seeAllChats')}
                            </p>
                            <ChevronRightIcon className="text-quick-chat" />
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div className="w-full h-400 flex gap-20 flex-col  sm:px-40 justify-center items-center">
                        <img
                          src="/assets/images/icon/noConversationsAvailableImage.svg"
                          className="img-fluid"
                          alt="..."
                        />
                        <Typography className="text-center text-16 font-600">
                          {t('quickChat.createBot.noConversationsAvailableTitle')}
                        </Typography>
                        <Typography className="text-center text-14 font-500">
                          {t('quickChat.createBot.noConversationsAvailableDesc')}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-32">
            <DashboardAlerts />
            <BotStatus
              quickChatActiveData={quickChatDetails}
              setQuickChatActiveData={setQuickChatActiveData}
              whatsAppPhoneNumberData={whatsAppPhoneNumberId}
              callFrom="Dashboard"
            />
          </div>
        </div>
        {shareCompOpen && (
          <SharePost image={botLink} open={shareCompOpen} onClose={handleCloseComp} />
        )}
        {shouldShowPopup && (
          <DashBoardPopUp
            open={shouldShowPopup}
            filteredData={filteredData}
            productData={productData}
            from="Quick Chat"
            productId={9}
          />
        )}
      </div>
    </div>
  );
}
export default Dashboards;
