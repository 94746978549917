import {
  Button,
  CircularProgress,
  Drawer,
  Icon,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useSnackbar } from 'notistack';
import quickHub from '../../../../query/quickHub';
import { handleApiRequest } from '../../../../common/common';

const LabelOrBusinessDrawer = (props) => {
  const {
    openBusinessDrawer,
    setOpenBusinessDrawer,
    selectLabelOrBusiness,
    setSelectLabelOrBusiness,
    groupType,
  } = props;
  const [searchText, setSearchText] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [businesses, setBusinesses] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [labels, setLabels] = useState([]);
  const [selectFilter, setSelectFilter] = useState([]);
  const [showFilterList, setShowFilterList] = useState(false);
  const [page, setPage] = useState(1);

  const fetchBusiness = useCallback(async () => {
    try {
      const payload = {
        query: quickHub.getAllBusinesses,
        variables: {
          searchText,
          pageNumber: Number(page),
          pageSize: 6,
          labelIds: selectFilter?.length > 0 ? selectFilter?.map((filter) => filter?.id) : [],
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getAllBusinesses?.status === 200) {
        setBusinesses(result?.getAllBusinesses?.data);
        setTotalCount(result?.getAllBusinesses?.totalCount);
      } else {
        enqueueSnackbar(result?.getAllBusinesses?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error fetching businesses:', error);
    }
  }, [enqueueSnackbar, searchText, page, selectFilter]);

  const fetchManageLabels = useCallback(async () => {
    try {
      const payload = {
        query: quickHub.getAllBusinessesLabels,
        variables: {
          ...(groupType === 'label' && {
            pageNumber: Number(page),
          }),
          pageSize: 10,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getAllBusinessesLabels?.status === 200) {
        setLabels(result.getAllBusinessesLabels?.data);
        if (groupType === 'label') {
          setTotalCount(result.getAllBusinessesLabels?.totalCount);
        }
      } else {
        enqueueSnackbar(result?.createAndEditUserBusinessesLabel?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error in fetchManageLabels:', error);
    }
  }, [enqueueSnackbar, groupType, page]);

  useEffect(() => {
    setPage(1);
  }, [groupType]);

  useEffect(() => {
    if (groupType === 'business') {
      fetchBusiness();
    }
    fetchManageLabels();
  }, [fetchBusiness, fetchManageLabels, groupType]);

  const handleSelectLabelOrBusiness = (data) => {
    setSelectLabelOrBusiness((prevBusiness) => {
      const businessExists = prevBusiness?.some((business) => business?.id === data?.id);
      if (businessExists) {
        return prevBusiness.filter((business) => business.id !== data.id);
      }
      return [...prevBusiness, data];
    });
  };

  const handleSelectFilter = (data) => {
    setPage(1);
    setSelectFilter((prevFilter) => {
      const businessExists = prevFilter?.some((filter) => filter?.id === data?.id);
      if (businessExists) {
        return prevFilter.filter((filter) => filter?.id !== data?.id);
      }
      return [...prevFilter, data];
    });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleCancel = () => {
    setOpenBusinessDrawer(false);
    setSelectLabelOrBusiness([]);
  };

  return (
    <>
      <Drawer className="" anchor="right" open={openBusinessDrawer} transitionDuration={700}>
        <div className="md:max-w-[823px] md:min-w-[823px] p-16">
          <div className="flex items-center gap-12">
            <Button
              className="min-w-16 w-16 hover:bg-transparent"
              onClick={() => setOpenBusinessDrawer(false)}
            >
              <ArrowBackIcon />
            </Button>
            <Typography className="font-bold text-18">
              {groupType === 'business' ? t('reports.selectBusinesses') : t('reports.label')}
            </Typography>
          </div>
          <hr />
          <div className="mt-16">
            <Typography className="font-semibold text-18">
              {groupType === 'business' ? t('reports.searchBusiness') : t('reports.searchLabel')}
            </Typography>
            <div className="flex items-center gap-12 my-16 relative">
              <TextField
                placeholder={
                  groupType === 'business'
                    ? t('business.visitedBusiness.labels.searchBusinessName')
                    : t('reports.searchLabel')
                }
                variant="outlined"
                fullWidth
                className="bg-white w-full sm:w-512 my-0"
                margin="normal"
                value={searchText}
                size="small"
                onChange={(e) => setSearchText(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: searchText && (
                    <InputAdornment position="end">
                      <Icon
                        color="quickChat"
                        className="cursor-pointer text-20"
                        onClick={() => setSearchText('')}
                      >
                        close
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
              {groupType === 'business' && (
                <Button
                  className="rounded-md bg-quick-hub hover:bg-quick-hub text-white font-semibold text-16"
                  variant="contained"
                  onClick={() => setShowFilterList(true)}
                >
                  {t('reports.filter')} <FilterListIcon className="text-white font-bold ml-8" />
                </Button>
              )}
              {showFilterList && (
                <>
                  {labels?.length > 0 ? (
                    <div className="border border-solid z-10 absolute right-160 top-48 bg-white rounded-md w-256">
                      <div className="p-8">
                        {labels?.map((label, index) => {
                          return (
                            <div className="flex justify-between" key={index}>
                              <Typography className="flex items-center gap-4 font-semibold text-14">
                                <div
                                  className="w-14 h-14 rounded-full"
                                  style={{ backgroundColor: label?.color }}
                                />
                                {label.label}
                              </Typography>
                              <Checkbox
                                className=""
                                checked={selectFilter?.some((value) => value?.id === label?.id)}
                                onChange={() => handleSelectFilter(label)}
                              />
                            </div>
                          );
                        })}
                      </div>
                      <hr />
                      <div className="p-12 text-end">
                        <Button
                          className="border border-solid hover:bg-transparent border-quick-hub mr-8 text-quick-hub rounded-md font-semibold text-14 min-h-32 h-32 min-w-76 w-76"
                          onClick={() => {
                            setShowFilterList(false);
                            setSelectFilter([]);
                          }}
                        >
                          {t('common.cancel')}
                        </Button>
                        <Button
                          className="bg-quick-hub rounded-md text-white hover:bg-quick-hub font-semibold text-14 min-h-32 h-32 min-w-76 w-76"
                          variant="contained"
                          onClick={() => setShowFilterList(false)}
                        >
                          {t('reports.apply')}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="border border-solid absolute right-160 top-48 bg-white p-8 rounded-md w-256">
                      <Typography>{t('reports.noFilterAvailable')}</Typography>
                    </div>
                  )}
                </>
              )}
            </div>
            {businesses?.length > 0 && groupType === 'business' && (
              <div>
                <div className="bg-gray-A500 rounded-md">
                  {businesses?.map((business, index) => {
                    return (
                      <div key={index}>
                        <div className="flex justify-between items-center px-12 py-10">
                          <div className="flex gap-16 items-center">
                            <img
                              className="max-w-128 min-w-128 h-76 rounded-md"
                              src={
                                business?.profileURL ||
                                '/assets/images/business/businessprofile.png'
                              }
                              alt=""
                            />
                            <div>
                              <Typography className="font-bold text-16">
                                {business?.name}
                              </Typography>
                              <Typography className="font-semibold text-14 max-w-400">
                                {business?.address}
                              </Typography>
                              <div className="flex gap-12 flex-wrap mt-4">
                                {business?.label?.map((item, i) => {
                                  return (
                                    <div key={i}>
                                      <span
                                        style={{ backgroundColor: item?.color }}
                                        className="rounded-full px-16 text-white font-semibold text-16"
                                      >
                                        {item?.label}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <Checkbox
                            className=""
                            checked={selectLabelOrBusiness?.some(
                              (value) => value?.id === business?.id
                            )}
                            onChange={() => handleSelectLabelOrBusiness(business)}
                          />
                        </div>
                        <hr className="border !border-gray" />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {labels?.length > 0 && groupType === 'label' && (
              <div>
                {labels?.map((label, index) => {
                  return (
                    <div key={index}>
                      <div className="flex justify-between items-center px-12 py-10">
                        <Typography>
                          <span
                            style={{ backgroundColor: label?.color }}
                            className="rounded-full px-16 text-white font-semibold text-16"
                          >
                            {label?.label}
                          </span>
                        </Typography>
                        <Checkbox
                          className=""
                          checked={selectLabelOrBusiness?.some((value) => value?.id === label?.id)}
                          onChange={() => handleSelectLabelOrBusiness(label)}
                        />
                      </div>
                      <hr className="border !border-gray-200" />
                    </div>
                  );
                })}
              </div>
            )}
            <div className="px-28 sm:px-52 py-20 sm:py-32 bottom-0 right-0 w-full flex justify-end">
              <Pagination
                count={Math.ceil(totalCount / (groupType === 'business' ? 6 : 10))}
                page={page}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
              />
            </div>
            <div className="text-end mt-16">
              <Button
                className="rounded-md w-160 mr-16 text-quick-hub font-bold text-16"
                variant="outlined"
                onClick={() => handleCancel()}
              >
                {t('common.cancel')}
              </Button>
              <Button
                className="rounded-md w-160 bg-quick-hub hover:bg-quick-hub text-white font-bold text-16"
                variant="contained"
                disabled={selectLabelOrBusiness?.length === 0}
                onClick={() => setOpenBusinessDrawer(false)}
              >
                {t('common.save')}
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default LabelOrBusinessDrawer;
