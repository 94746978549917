import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import history from '@history';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import { Button, Typography, CircularProgress, TextField, Switch, Box } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DatePicker,
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
} from '@mui/x-date-pickers';

function ChoosePlatform({ setActiveStep }) {
  const platforms = [
    {
      id: 1,
      name: 'Facebook',
      logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg',
      enabled: true,
    },
    {
      id: 2,
      name: 'Instagram',
      logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png',
      enabled: true,
    },
  ];

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [scheduledDate, setScheduledDate] = useState();
  const [scheduledTime, setScheduledTime] = useState('');
  const [refreshIcon, setRefreshIcon] = useState(false);

  const [campaignName, setCampaignName] = useState('');
  const [platformStates, setPlatformStates] = useState(platforms);

  if (loading) {
    return <FuseLoading />;
  }

  const handleToggle = (id) => {
    const updatedPlatforms = platformStates.map((platform) =>
      platform.id === id ? { ...platform, enabled: !platform.enabled } : platform
    );
    setPlatformStates(updatedPlatforms);
  };

  const isDateError = () => {
    if (!scheduledDate) return false;

    const today = new Date();
    const selectedDate = new Date(scheduledDate);

    return selectedDate < today.setHours(0, 0, 0, 0); // If the selected date is before today
  };

  const isTimeError = () => {
    if (!scheduledDate || !scheduledTime) return false;

    const now = new Date();
    const selectedDate = new Date(scheduledDate);

    if (
      selectedDate.getFullYear() === now.getFullYear() &&
      selectedDate.getMonth() === now.getMonth() &&
      selectedDate.getDate() === now.getDate()
    ) {
      const selectedTime = new Date();
      selectedTime.setHours(scheduledTime.getHours(), scheduledTime.getMinutes(), 0, 0);

      return selectedTime < now;
    }

    return false;
  };

  return (
    <div className="quick-social">
      <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
        <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
          {t('quickCampaign.campaign.campaignName')}
        </Typography>
        <TextField
          className="w-full bg-gray-A500"
          value={campaignName}
          size="medium"
          onChange={(e) => setCampaignName(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#f5f6fa',
              },
              '&:hover fieldset': {
                borderColor: '#219EBC',
              },
            },
            '& .MuiInputBase-input': {
              fontWeight: '600',
              lineHeight: '22px',
              fontSize: 16,
            },
          }}
          placeholder="Enter Campaign Name"
        />
      </div>

      <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
        <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
          Choose Platform
        </Typography>
        {platformStates.map((platform) => (
          <Box
            key={platform.id}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="p-16 mb-12 rounded-md bg-gray-A500 h-56"
          >
            <Box display="flex" alignItems="center">
              <img src={platform.logo} alt={platform.name} height={24} width={24} />
              <Typography className="ml-8 text-18 font-semibold">{platform.name}</Typography>
            </Box>
            <Switch
              checked={platform.enabled}
              onChange={() => handleToggle(platform.id)}
              color="secondary"
            />
          </Box>
        ))}
      </div>

      <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
        <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
          Schedule Campaign
        </Typography>

        <div className=" items-center justify-between gap-16">
          <div className="mb-20">
            <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
              {t('sendMessage.selectDate')}
            </Typography>
            <div className="mt-4">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  className="w-full select-date bg-gray-A500 rounded-md"
                  id="note"
                  variant="contained"
                  fullWidth
                  disablePast
                  value={scheduledDate}
                  format="MMM dd, yyyy"
                  onChange={(date) => setScheduledDate(date)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#f5f6fa',
                      },
                      '&:hover fieldset': {
                        borderColor: '#219EBC',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#219EBC',
                      },
                    },
                    '& .MuiSvgIcon-root': {
                      color: '#219EBC',
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
            {isDateError() && (
              <Typography className="text-red-500 text-sm mt-2">
                Please select a valid date.
              </Typography>
            )}
          </div>
          <div className="">
            <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
              {t('sendMessage.selectTime')}
            </Typography>
            <div className="mt-4">
              <TimePicker
                className="w-full select-date bg-gray-A500 rounded-md"
                id="time"
                onChange={(newTime) => setScheduledTime(newTime)}
                size="small"
                required
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#f5f6fa',
                    },
                    '&:hover fieldset': {
                      borderColor: '#219EBC',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#219EBC',
                    },
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#219EBC',
                  },
                }}
              />
            </div>
            {isTimeError() && (
              <Typography className="text-red-500 text-sm mt-2">
                Please select a valid time. The time cannot be in the past.
              </Typography>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <Button
          className="md:text-16 font-medium rounded-md px-28 disabled:bg-gray"
          variant="outlined"
          color="quickSocial"
          //   onClick={() => ()}
          disabled={isDateError() || isTimeError()}
        >
          Save Draft
          {refreshIcon && (
            <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
          )}
        </Button>
        <Button
          className="md:text-16 font-medium rounded-md px-28 disabled:bg-gray"
          variant="contained"
          color="quickSocial"
          onClick={() => setActiveStep(1)}
          disabled={
            !scheduledDate ||
            !scheduledTime ||
            isDateError() ||
            isTimeError() ||
            !campaignName ||
            refreshIcon
          }
        >
          Proceed
          {refreshIcon && (
            <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
          )}
        </Button>
      </div>
    </div>
  );
}
export default ChoosePlatform;
