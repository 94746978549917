import { getAllUserBusinessSubscription, selectDashboardData } from 'app/store/userSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import history from '@history';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { findExpireDay } from '../common/common';

const CommonFreeTrial = (props) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const params = useParams();

  const isFreeTrialLength =
    userSubscriptionData &&
    userSubscriptionData?.filter(
      (subscriptionData) =>
        (props.callFrom === 'QuickHubDashboard'
          ? subscriptionData?.userBusinessId === userCurrentBusinessDetails?.id
          : true) && subscriptionData?.isFreeTrial
    ).length === 1;

  const subscriptionDataSettings = {
    dots: false,
    infinite: !isFreeTrialLength,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    userSubscriptionData &&
    userSubscriptionData.length > 0 &&
    userSubscriptionData.some(
      (subscriptionData) =>
        (props.callFrom === 'QuickHubDashboard'
          ? subscriptionData?.userBusinessId === userCurrentBusinessDetails?.id
          : true) && subscriptionData.isFreeTrial
    ) &&
    (props.callFrom === 'Layout1' || props.callFrom === 'QuickHubDashboard' ? (
      <div className="bg-[#FFE4E1] mb-20 sm:text-18 text-14 rounded-6 font-semibold p-10">
        <Slider {...subscriptionDataSettings}>
          {userSubscriptionData
            ?.filter((subscriptionData) => subscriptionData?.isFreeTrial)
            ?.map((subscriptionData, i) => (
              <div key={i}>
                {findExpireDay(subscriptionData?.expireDate) > 0 ? (
                  <div className="w-full flex justify-center relative top-3">
                    <div className="items-center flex justify-between">
                      <img
                        className="block rounded max-w-38 mr-16"
                        name="logo"
                        src="/assets/images/icon/bellIconNotification.png"
                        alt="bell notification icon"
                      />
                      <p className="mr-28 sm:mr-48">
                        {t('quickHubDashboard.your')} {subscriptionData?.name}{' '}
                        {subscriptionData?.comboDetails?.length > 0
                          ? `[${subscriptionData?.comboDetails
                              .map((item) => item?.name)
                              .join(', ')}]`
                          : ''}{' '}
                        {t('quickHubDashboard.freeTrialExpire')}{' '}
                        {findExpireDay(subscriptionData?.expireDate)} {t('common.days')}.
                      </p>
                      <Button
                        className="text-14 font-medium bg-[#D07062] text-white hover:bg-red-300 rounded-12 px-16"
                        variant="contained"
                        size="small"
                        type="submit"
                        onClick={() => {
                          const packageBundle =
                            subscriptionData?.comboDetails?.length > 0
                              ? subscriptionData?.comboDetails?.map((item) => item?.id)
                              : [subscriptionData?.packageTypeId] || [];
                          const intervalType = subscriptionData?.packageInterval;
                          const paymentDetails =
                            subscriptionData?.packageInterval === 'month' ||
                            subscriptionData?.packageInterval === 'year'
                              ? 'subscription'
                              : 'oneTimePayment';

                          history.push(
                            params?.id ? `/selectProduct/${params.id}` : '/selectProduct/',
                            {
                              packageBundle,
                              intervalType,
                              paymentDetails,
                              isBusinessAdded: true,
                            }
                          );
                        }}
                      >
                        {t('quickHubDashboard.upgradeNow')}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="w-full min-h-40 max-h-40 flex items-center justify-center">
                    <div className="w-384 sm:w-586 items-center flex justify-center">
                      <p>
                        {t('quickHubDashboard.your')} {subscriptionData?.name}{' '}
                        {t('quickHubDashboard.planExpired')}.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </Slider>
      </div>
    ) : (
      userSubscriptionData &&
      userSubscriptionData
        .filter((subscriptionData) =>
          subscriptionData?.comboDetails?.length > 0
            ? subscriptionData?.comboDetails.some((item) => item?.name === props.productName)
            : subscriptionData?.name === props.productName
        )
        .map(
          (subscriptionData, i) =>
            subscriptionData?.userBusinessId === userCurrentBusinessDetails?.id &&
            subscriptionData?.isFreeTrial === true && (
              <div key={i} className="bg-[#FFE4E1] text-16 rounded-6 font-600 p-16">
                <div>
                  {findExpireDay(subscriptionData?.expireDate) > 0 ? (
                    <div className="w-full flex justify-center">
                      <div className="w-384 sm:w-640 items-center flex">
                        <img
                          className="block rounded max-w-28 sm:max-w-32"
                          name="logo"
                          src="/assets/images/icon/reviewNotificationIcon.png"
                          alt="bell notification icon"
                        />
                        <p className="ml-10 mr-16 sm:ml-16 sm:mr-32 text-14 sm:text-16 ">
                          {t('quickHubDashboard.your')}{' '}
                          {subscriptionData?.comboDetails?.length > 0
                            ? 'Quick Chat'
                            : subscriptionData?.name}{' '}
                          {t('quickHubDashboard.freeTrialExpire')}{' '}
                          {findExpireDay(subscriptionData?.expireDate)} {t('common.days')}.
                        </p>
                        <Button
                          className="text-12 md:text-14 font-semibold bg-[#D24811] text-white hover:bg-red-500 rounded-12"
                          variant="contained"
                          size="small"
                          type="submit"
                          onClick={() => {
                            history.push(
                              params?.id ? `/selectProduct/${params.id}` : '/selectProduct/',
                              {
                                packageBundle: [props?.packageBundle],
                                intervalType: subscriptionData?.packageInterval,
                                isBusinessAdded: true,
                              }
                            );
                          }}
                        >
                          {t('quickHubDashboard.upgradeNow')}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full min-h-40 max-h-40 flex items-center justify-center">
                      <div className="w-384 sm:w-586 items-center flex justify-center">
                        <p>
                          {t('quickHubDashboard.your')} {props?.productName}{' '}
                          {t('quickHubDashboard.planExpired')}.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )
        )
    ))
  );
};

export default CommonFreeTrial;
