import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import CreateLabels from './CreateLabels';
import quickHub from '../../../query/quickHub';
import { handleApiRequest } from '../../../common/common';
import ConformationDialogBox from '../../../CommonComponent/ConformationDialogBox';

const ManageLabels = () => {
  const [labels, setLabels] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [labelId, setLabelId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [editLabels, setEditLabels] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();

  const fetchManageLabels = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        query: quickHub.getAllBusinessesLabels,
        variables: {
          pageNumber: page,
          pageSize: 10,
        },
      };
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.getAllBusinessesLabels?.status === 200) {
        setLabels(result.getAllBusinessesLabels?.data);
        setTotalCount(result.getAllBusinessesLabels?.totalCount);
      } else {
        enqueueSnackbar(result?.getAllBusinessesLabels?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.error('Error in fetchManageLabels:', error);
    }
  }, [enqueueSnackbar, page]);

  useEffect(() => {
    fetchManageLabels();
  }, [fetchManageLabels]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const deleteLabel = async () => {
    const payload = {
      query: quickHub.deleteBusinessesLabel,
      variables: {
        labelId: Number(labelId),
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.deleteBusinessesLabel?.status === 200) {
      fetchManageLabels();
      setOpenDialogue(false);
      enqueueSnackbar(result?.deleteBusinessesLabel?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(result?.deleteBusinessesLabel?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleDeleteClosePopUp = () => {
    setOpenDialogue(false);
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="bg-gray-A500 h-full p-16 sm:p-24 ">
      <div className="flex flex-wrap items-center justify-between">
        <Typography className="text-20 sm:text-28 font-bold">{t('manageLabels.title')}</Typography>
        <Button
          className="rounded-md"
          variant="contained bg-quick-hub mt-8 sm:mt-0 hover:bg-quick-hub text-white font-semibold text-16 md:text-18"
          onClick={() => setOpenDrawer(true)}
        >
          <AddIcon />
          {t('manageLabels.createNewLabel')}
        </Button>
      </div>
      {labels?.length > 0 ? (
        <div className="mt-16">
          <TableContainer component={Paper} className="rounded-md border-none shadow-0 ">
            <Table className="">
              <TableHead>
                <TableRow>
                  <TableCell
                    className="font-semibold text-14 md:text-18 border-b-2 border-grey-300 w-112"
                    align="left"
                  >
                    {t('manageLabels.srNo')}
                  </TableCell>
                  <TableCell
                    className="font-semibold text-14 md:text-18 border-b-2 border-grey-300"
                    align="left"
                  >
                    {t('manageLabels.labelName')}
                  </TableCell>
                  <TableCell
                    className="font-semibold text-14 md:text-18 border-b-2 border-grey-300"
                    align="left"
                  >
                    {t('manageLabels.noOfBusiness')}
                  </TableCell>
                  <TableCell
                    className="font-semi-bold text-14 md:text-18 border-b-2 border-grey-300"
                    align="right"
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {labels?.map((data, index) => (
                  <TableRow
                    key={data.id}
                    className="hover:bg-quick-campaign-100 hover:bg-opacity-30 h-60"
                    onMouseEnter={() => setHoveredRow(index)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    <TableCell className="font-semibold text-16 md:text-16 border-b-2 border-grey-300 h-76 min-w-32 max-w-32">
                      {(page - 1) * 10 + index + 1}
                    </TableCell>
                    <TableCell className="font-semibold text-16 md:text-16 border-b-2 border-grey-300 h-76 min-w-320 max-w-320">
                      <span
                        style={{ backgroundColor: data?.color }}
                        className="rounded-full px-16 text-white font-semibold text-16"
                      >
                        {data?.label}
                      </span>
                    </TableCell>
                    <TableCell className="font-semibold text-16 md:text-16 border-b-2 border-grey-300 h-76 min-w-320 max-w-320">
                      {data?.businessAssociatedCount}
                    </TableCell>
                    <TableCell className="font-medium text-12 md:text-16 border-b-2 border-grey-300 h-76 min-w-128">
                      {hoveredRow === index ? (
                        <div className="flex gap-12">
                          <Button
                            className="p-0 min-w-40 w-40 min-h-40 h-40 bg-quick-campaign-100 hover:bg-quick-campaign-100"
                            onClick={() => {
                              setEditLabels(data);
                              setOpenDrawer(true);
                              setLabelId(data?.id);
                            }}
                          >
                            <img src="assets/images/quick-hub/edit.png" alt="" />
                          </Button>
                          <Button
                            className="p-0 min-w-40 w-40 min-h-40 h-40 bg-quick-campaign-100 hover:bg-quick-campaign-100"
                            onClick={() => {
                              setLabelId(data?.id);
                              setOpenDialogue(true);
                            }}
                          >
                            <img src="assets/images/quick-hub/delete.png" alt="" />
                          </Button>
                        </div>
                      ) : (
                        <div />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="bg-white px-28 sm:px-52 py-20 sm:py-32 bottom-0 right-0 w-full flex justify-end">
            <Pagination
              count={Math.ceil(totalCount / 10)}
              page={page}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      ) : (
        <div className="text-center">
          <img className="m-auto w-200 pt-192" src="assets/images/business/labels.png" alt="" />
          <Typography className="font-semibold text-14 md:text-24 pt-20 pb-10">
            {t('manageLabels.noLabelsCreated')}
          </Typography>
          <Typography className="text-16 font-medium pb-24">
            {t('manageLabels.addLabelsMessage')}
          </Typography>
          <Button
            className="rounded-md"
            variant="contained bg-quick-hub hover:bg-quick-hub text-white py-20 font-semibold text-16 md:text-18"
            onClick={() => setOpenDrawer(true)}
          >
            <AddIcon />
            {t('manageLabels.createNewLabel')}
          </Button>
        </div>
      )}
      <CreateLabels
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        fetchManageLabels={fetchManageLabels}
        editLabels={editLabels}
        setEditLabels={setEditLabels}
      />
      <ConformationDialogBox
        open={openDialogue}
        onClose={handleDeleteClosePopUp}
        additionalInfo={t('manageLabels.deleteMessage')}
        onDelete={deleteLabel}
        confirmationText={t('quickCampaignsContacts.confirmMessage')}
        deleteImage="/assets/images/icon/cancelPlan.png"
        backgroundColor="quick-hub"
        textColor="quick-hub"
        buttonText={t('common.delete')}
      />
    </div>
  );
};

export default ManageLabels;
