import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Menu, MenuItem, Tabs, Tab, Button } from '@mui/material';
import {
  getAllUserBusinessSubscription,
  selectDashboardData,
  setUserSubscriptionData,
} from 'src/app/store/userSlice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import UserService from 'src/app/services';
import { useSnackbar } from 'notistack';
import history from '@history';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import FuseLoading from '@fuse/core/FuseLoading';
import planQuery from 'src/app/main/query/plan';
import { t } from 'i18next';
import Pagination from 'src/app/component/table/Pagination';
import { useParams } from 'react-router-dom';
import {
  findExpireDay,
  formatDateWithTimeZone,
  getDecryptData,
  getEncryptedData,
  handleApiRequest,
} from '../../../common/common';
import common from '../../../query/common';

const loginUserData = UserService.getUserData();

const MySubscription = () => {
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const dispatch = useDispatch();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const countryId = Number(loginUserData?.countryId);
  const PRODUCT_NAMES = process.env.REACT_APP_PRODUCT_NAMES;
  const [filteredSubscriptionData, setFilteredSubscriptionData] = useState();
  const [getPaymentHistory, setGetPaymentHistory] = useState('');
  const [total, setTotal] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const [isOpenBundle, setIsOpenBundle] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [cancelledSubscriptions, setCancelledSubscriptions] = useState({});
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const [paymentId, setPaymentId] = useState();

  useEffect(() => {
    if (userSubscriptionData && userCurrentBusinessDetails?.id) {
      const filteredData = userSubscriptionData?.filter(
        (subscription) => subscription?.userBusinessId === userCurrentBusinessDetails?.id
      );
      setFilteredSubscriptionData(filteredData);
    } else {
      setFilteredSubscriptionData(userSubscriptionData);
    }
  }, [userSubscriptionData, userCurrentBusinessDetails?.id]);

  const getSubscriptionData = useCallback(async () => {
    try {
      setIsLoading(true);
      const payload = {
        query: common.getAllUserBusinessSubscription,
      };
      const result = await handleApiRequest(payload);
      if (result?.getAllUserBusinessSubscription?.status === 200) {
        dispatch(
          setUserSubscriptionData(
            JSON.parse(getDecryptData(result?.getAllUserBusinessSubscription?.data))
          )
        );
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  const fetchAllData = useCallback(async () => {
    try {
      setIsLoading(true);
      if (countryId === 2) {
        const invoicePayload = {
          query: planQuery.getInvoiceHistoryForRazorpay,
          variables: {
            orderBy: 'asc',
            pageSize: rowsPerPage,
            ...(userCurrentBusinessDetails?.id && {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            }),
          },
          pageNumber: page,
        };
        const invoiceResult = await handleApiRequest(invoicePayload);
        if (invoiceResult?.getInvoiceHistoryForRazorpay?.status === 200) {
          setGetPaymentHistory(invoiceResult?.getInvoiceHistoryForRazorpay?.data);
          setTotal(invoiceResult?.getInvoiceHistoryForRazorpay?.totalCount);
        }
      } else {
        const paymentHistoryPayload = {
          query: planQuery.getPaymentHistory,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            orderBy: 'asc',
            pageSize: rowsPerPage,
          },
        };
        const paymentHistoryResult = await handleApiRequest(paymentHistoryPayload);
        if (paymentHistoryResult?.getPaymentHistory?.status === 200) {
          setGetPaymentHistory(paymentHistoryResult?.getPaymentHistory?.data);
          setTotal(paymentHistoryResult?.getPaymentHistory?.totalCount);
        }
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  }, [countryId, page, rowsPerPage, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (selectedTab === 1) {
      fetchAllData();
    } else if (selectedTab === 0) {
      getSubscriptionData();
    }
  }, [selectedTab, fetchAllData, getSubscriptionData]);

  const handleCancelSubscription = async () => {
    setOpen(false);
    setCancelledSubscriptions((prev) => ({
      ...prev,
      [id]: true,
    }));
    if (countryId === 2) {
      const payloadData = {
        query: planQuery.cancelSubscriptionForRazorpay,

        variables: {
          data: getEncryptedData(
            JSON.stringify({
              razorpaySubscriptionId: paymentId,
            })
          ),
        },
      };
      const result = await handleApiRequest(payloadData);
      if (result?.cancelSubscriptionForRazorpay?.status === 200) {
        getSubscriptionData();
        enqueueSnackbar(result?.cancelSubscriptionForRazorpay?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(result?.cancelSubscriptionForRazorpay?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } else {
      const payloadData = {
        query: planQuery.cancelSubscription,

        variables: {
          data: getEncryptedData(
            JSON.stringify({
              stripeSubscriptionId: paymentId,
            })
          ),
        },
      };
      const result = await handleApiRequest(payloadData);
      if (result?.cancelSubscription?.status === 200) {
        getSubscriptionData();
        enqueueSnackbar('Success', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar('error', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }
  };

  const handleClickA = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenDropdownIndex(index);
  };

  const handleCloseA = () => {
    setAnchorEl(null);
    setOpenDropdownIndex(null);
  };

  const handleClickBundle = (index) => {
    setIsOpenBundle((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleClosePopUp = () => {
    setOpen(false);
  };
  const handlePopUp = (packageId, payment) => {
    setOpen(true);
    setId(packageId);
    setPaymentId(payment);
  };

  const handleDownloadInvoice = async (transactionId) => {
    if (loginUserData?.countryId === 2) {
      window.open(transactionId, '_blank');
    } else {
      const payloadInvoice = {
        query: planQuery.getPaymentInvoice,
        variables: {
          id: transactionId,
        },
      };
      try {
        const result = await handleApiRequest(payloadInvoice);

        if (result?.getPaymentInvoice?.status === 200) {
          window.open(result?.getPaymentInvoice?.data?.hosted_invoice_url, '_blank');
        } else {
          console.error('Hosted invoice URL not found in API response.');
        }
      } catch (error) {
        console.error('Error fetching payment invoice:', error);
      }
    }
  };

  const reversedHistory = [...getPaymentHistory].reverse();

  if (isLoading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-24 ">
      <div className="mb-16">
        <h1 className=" text-30 font-bold">{t('plan.mySubscriptionData.mySubscription')}</h1>
      </div>
      <div className="  border-solid border-grey  flex flex-col justify-between  ">
        <div className=" border-solid border-grey  sm:flex justify-between items-center  border-b-2 ">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              className="sm:text-18 font-bold mr-20 text-black p-0"
              value={0}
              label={t('plan.mySubscriptionData.activePlan')}
            />
            <Tab
              label={t('plan.mySubscriptionData.paymentHistory')}
              className="sm:text-18 font-bold p-0 text-black"
              value={1}
            />
          </Tabs>
        </div>
        <div className="py-20">
          <div>
            {selectedTab === 0 && (
              <div className="w-full">
                {filteredSubscriptionData?.length > 0 ? (
                  filteredSubscriptionData
                    ?.slice()
                    ?.reverse()
                    ?.map((subscription, index) => (
                      <div
                        className={`my-16 justify-between rounded-md bg-white max-w-screen-md flex-col sm:flex-row border-grey-300 border-1 ${
                          isOpenBundle ? 'h-auto' : ''
                        }`}
                        key={index}
                      >
                        {subscription?.isFreeTrial &&
                          (findExpireDay(subscription?.expireDate) > 0 ? (
                            <p className="w-full h-15 bg-amber-50 text-14 text-grey-700 font-medium pl-5 p-4">
                              {t('plan.mySubscriptionData.freeTrailWillExpireIn')}
                              {findExpireDay(subscription?.expireDate)}
                              {t('plan.mySubscriptionData.days')}
                            </p>
                          ) : (
                            <p className="w-full h-15 bg-amber-50 text-14 text-grey-700 font-medium pl-5 p-4">
                              {t('quickHubDashboard.freeTrialExpired')}.
                            </p>
                          ))}
                        <div className="flex flex-col xs:flex-row items-start xs:items-center justify-center xs:justify-between p-14 relative">
                          <div className="gap-10 flex items-center bg-white rounded-md">
                            <div>
                              {subscription?.name?.toLowerCase() === 'quick ads' && (
                                <img
                                  src="assets/images/quick-hub/quick-ads-icon.png"
                                  alt="Quick Chat"
                                  className="bg-white w-80 h-80"
                                />
                              )}
                              {subscription?.name?.toLowerCase() === 'quick reviews' && (
                                <img
                                  src="assets/images/quick-hub/quick-reviews-icon.png"
                                  alt="Quick Review"
                                  className="bg-white w-80 h-80"
                                />
                              )}
                              {subscription?.name?.toLowerCase() === 'quick social' && (
                                <img
                                  src="assets/images/quick-hub/quick-social-icon.png"
                                  alt="Quick Social"
                                  className="bg-white w-80 h-80"
                                />
                              )}
                              {subscription?.name?.toLowerCase() === 'quick chat' && (
                                <img
                                  src="assets/images/quick-hub/quick-chat-icon.png"
                                  alt="Quick Chat"
                                  className="bg-white w-80 h-80"
                                />
                              )}
                              {(subscription?.name?.toLowerCase() === 'quick bundle' ||
                                subscription?.name?.toLowerCase() === 'quick combo') && (
                                <img
                                  src="/assets/images/quick-hub/quick-hub.png"
                                  alt="Quick Bundle"
                                  className=" bg-white  w-80 h-80"
                                />
                              )}
                              {subscription?.name?.toLowerCase() === 'quick campaigns' && (
                                <img
                                  src="assets/images/quick-hub/quick-campaign-icon.png"
                                  alt="Quick Campaigns"
                                  className="bg-white w-80 h-80"
                                />
                              )}
                            </div>
                            <div>
                              <div className="flex text-left items-center">
                                <span className="text-left block mb-6 font-semibold text-20 tracking-normal text-black">
                                  {subscription?.name.replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )}
                                </span>
                                {(subscription?.name?.toLowerCase() === 'quick bundle' ||
                                  subscription?.name?.toLowerCase() === 'quick combo') && (
                                  <div className="ml-2 font-Quick">
                                    <div className="w-full">
                                      <IconButton
                                        className="p-0"
                                        onClick={() => handleClickBundle(index)}
                                      >
                                        {isOpenBundle === index ? (
                                          <ExpandLessIcon />
                                        ) : (
                                          <ExpandMoreIcon />
                                        )}
                                      </IconButton>
                                      {isOpenBundle === index && (
                                        <div className="ml-64 left-0 right-14 bg-grey-300 w-100 h-auto z-20 rounded-8 absolute p-7">
                                          {subscription?.comboDetails?.length > 0 ? (
                                            subscription?.comboDetails?.map((item, idx) => (
                                              <div
                                                key={idx}
                                                className="p-4 flex font-medium justify-between border-b-1 border-b-grey-400 last:border-b-0"
                                              >
                                                <p>{item?.name}</p>
                                              </div>
                                            ))
                                          ) : (
                                            <div className="text-center">
                                              {t('plan.mySubscriptionData.noDataAvailable')}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <p
                                className={`text-left font-medium relative ${
                                  findExpireDay(subscription?.expireDate) <= 0
                                    ? 'text-red-500'
                                    : 'text-black'
                                }`}
                              >
                                {(!isOpen || subscription?.name !== 'Quick Bundle') && (
                                  <>
                                    {findExpireDay(subscription?.expireDate) <= 0
                                      ? t('plan.mySubscriptionData.expiredOn')
                                      : subscription?.isFreeTrial ||
                                        subscription?.isOneTimePayment ||
                                        (subscription?.isFreeTrial === false &&
                                          subscription?.isOneTimePayment === false &&
                                          subscription?.isSubscription === false)
                                      ? t('plan.mySubscriptionData.expiresOn')
                                      : subscription?.isSubscription &&
                                        t('plan.mySubscriptionData.nextRenewOn')}
                                    {subscription?.expireDate ? (
                                      <span>
                                        {formatDateWithTimeZone(
                                          subscription?.expireDate,
                                          'MMM DD, YYYY'
                                        )}
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="item-center pt-10 xs:text-right">
                            <div className="justify-around capitalize xs:text-right">
                              {subscription?.offerAmount && (
                                <h3 className="text-20 font-medium mb-0">
                                  <span>{Number(countryId) === 2 ? '₹' : '$'}</span>
                                  {subscription.offerAmount}/
                                  {subscription?.packageInterval.replace(/_/g, ' ')}
                                </h3>
                              )}
                              <h3
                                className={`block ${
                                  subscription?.offerAmount
                                    ? 'line-through text-grey-500 font-medium text-16'
                                    : 'text-20 font-medium'
                                }`}
                              >
                                <span>{Number(countryId) === 2 ? '₹' : '$'}</span>
                                {subscription.amount}/
                                {subscription?.packageInterval.replace(/_/g, ' ')}
                              </h3>
                            </div>
                            <div className="px-2 py-8">
                              {subscription?.isOneTimePayment &&
                                findExpireDay(subscription?.expireDate) > 0 &&
                                null}
                              {subscription?.isSubscription &&
                                findExpireDay(subscription?.expireDate) > 0 && (
                                  <a
                                    href="*"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handlePopUp(
                                        subscription?.packageId,
                                        subscription?.stripeSubscriptionId ||
                                          subscription?.razorpaySubscriptionId
                                      );
                                    }}
                                    className="text-14 font-medium text-black px-20 text-center"
                                    aria-label="skip"
                                    style={{
                                      color: 'black',
                                      display: cancelledSubscriptions[subscription?.packageId]
                                        ? 'none'
                                        : 'block',
                                    }}
                                  >
                                    {t('plan.mySubscriptionData.cancelPlan')}
                                  </a>
                                )}
                              {(subscription?.isFreeTrial ||
                                findExpireDay(subscription?.expireDate) <= 0) && (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  size="small"
                                  className="text-14 font-medium disabled:text-black rounded-md px-20"
                                  aria-label="skip"
                                  onClick={(event) => {
                                    event.preventDefault();

                                    const packageBundle =
                                      subscription?.comboDetails?.length > 0
                                        ? subscription?.comboDetails
                                            .map((item) => {
                                              if (PRODUCT_NAMES?.includes(item?.name)) {
                                                return item?.id;
                                              }
                                              return null;
                                            })
                                            .filter(Boolean)
                                        : [subscription?.packageTypeId];

                                    history.push(
                                      params?.id
                                        ? `/selectProduct/${params.id}`
                                        : '/selectProduct/',
                                      {
                                        packageBundle,
                                        intervalType: subscription?.packageInterval,
                                        paymentDetails: 'subscription',
                                        isBusinessAdded: !!userCurrentBusinessDetails?.id,
                                      }
                                    );
                                  }}
                                  type="button"
                                >
                                  {t('common.subscribeNow')}
                                </Button>
                              )}
                              {!subscription?.isSubscription &&
                                findExpireDay(subscription?.expireDate) > 0 &&
                                (subscription?.razorpaySubscriptionId ||
                                  subscription?.stripeSubscriptionId) && (
                                  <p className="text-14 font-medium text-black rounded-md px-20 text-center">
                                    {t('plan.mySubscriptionData.planCancelled')}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  <h1 className="text-center text-black">
                    {t('plan.mySubscriptionData.noDataAvailable')}
                  </h1>
                )}
              </div>
            )}
            <ConformationDialogBox
              open={open}
              onClose={handleClosePopUp}
              onDelete={() => handleCancelSubscription()}
              confirmationText="Are You Sure You Want to Cancel This Plan?"
              deleteImage="/assets/images/icon/cancelPlan.png"
              backgroundColor="darkgreen"
              textColor="darkgreen"
              buttonText={t('plan.mySubscriptionData.cancelPlan')}
            />
          </div>
          {selectedTab === 1 && (
            <div className="w-full">
              {getPaymentHistory?.length > 0 ? (
                <div className="overflow-y-auto p-5 ">
                  <div>
                    <table className="min-w-full table-auto bg-white border-2 border-grey-400 ">
                      <thead className=" py-5 px-5 border-2  border-grey-400 w-full ">
                        <tr className=" bg-white-100  font-bold text-md  ">
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.transactionId')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.product')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.date')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.planStartOn')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.nextRecurringDate')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.amount')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.billingCycle')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.method')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14">
                            {t('common.status')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14 ">
                            {t('plan.mySubscriptionData.autoPay')}
                          </th>
                          <th className=" p-12 text-left text-black font-bold text-14">
                            {t('plan.mySubscriptionData.invoice')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {reversedHistory?.map((payment, index) => (
                          <tr key={index} className="border-t ">
                            <td className="px-12 py-8  text-14 font-medium ">
                              {payment?.transactionId}
                            </td>
                            <td className="px-12 py-8 text-sm font-medium">
                              <div className="flex items-center ">
                                <p className="text-14 font-medium">
                                  {payment?.planName || payment?.packages?.name}
                                </p>
                                {countryId === 2
                                  ? (payment?.planName === 'Bundle' ||
                                      payment?.planName === 'Combo') && (
                                      <>
                                        <IconButton
                                          onClick={(event) => handleClickA(event, index)}
                                          className="ml-2"
                                        >
                                          {openDropdownIndex === index ? (
                                            <ExpandLessIcon />
                                          ) : (
                                            <ExpandMoreIcon />
                                          )}
                                        </IconButton>
                                        <Menu
                                          anchorEl={anchorEl}
                                          open={openDropdownIndex === index}
                                          onClose={handleCloseA}
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                          }}
                                          MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                            sx: {
                                              padding: 0,
                                            },
                                          }}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                '& .MuiList-root': {
                                                  padding: 0,
                                                },
                                              },
                                            },
                                          }}
                                        >
                                          {payment?.planName?.replace(/\b\w/g, (char) =>
                                            char.toUpperCase()
                                          ) === 'Combo' &&
                                            payment?.comboPlanName?.map((detail) => (
                                              <MenuItem
                                                key={index}
                                                onClick={handleCloseA}
                                                className=""
                                              >
                                                <div className="font-medium text-12 flex items-center ">
                                                  {detail?.replace(/\b\w/g, (char) =>
                                                    char?.toUpperCase()
                                                  ) === 'Quick Reviews' && (
                                                    <img
                                                      src="assets/images/quick-hub/quick-reviews-icon.png"
                                                      alt="Quick Review"
                                                      className="h-20 w-20 bg-white mr-5"
                                                    />
                                                  )}
                                                  {detail?.replace(/\b\w/g, (char) =>
                                                    char?.toUpperCase()
                                                  ) === 'Quick Ads' && (
                                                    <img
                                                      src="assets/images/quick-hub/quick-ads-icon.png"
                                                      alt="Quick Ads"
                                                      className="h-20 w-20 bg-white mr-5"
                                                    />
                                                  )}
                                                  {detail?.replace(/\b\w/g, (char) =>
                                                    char?.toUpperCase()
                                                  ) === 'Quick Social' && (
                                                    <img
                                                      src="assets/images/quick-hub/quick-social-icon.png"
                                                      alt="Quick Social"
                                                      className="h-20 w-20 bg-white mr-5"
                                                    />
                                                  )}
                                                  {detail?.replace(/\b\w/g, (char) =>
                                                    char?.toUpperCase()
                                                  ) === 'Quick Chat' && (
                                                    <img
                                                      src="assets/images/quick-hub/quick-chat-icon.png"
                                                      alt="Quick Chat"
                                                      className="h-20 w-20 bg-white mr-5"
                                                    />
                                                  )}
                                                  {detail?.replace(/\b\w/g, (char) =>
                                                    char.toUpperCase()
                                                  ) === 'Quick Campaigns' && (
                                                    <img
                                                      src="assets/images/quick-hub/quick-campaign-icon.png"
                                                      alt="Quick Campaigns"
                                                      className="h-20 w-20 bg-white mr-5"
                                                    />
                                                  )}
                                                  <h4>{detail}</h4>
                                                </div>
                                              </MenuItem>
                                            ))}
                                          {payment?.planName?.replace(/\b\w/g, (char) =>
                                            char?.toUpperCase()
                                          ) === 'Bundle' &&
                                            payment?.comboPlanName?.map((detail, i) => (
                                              <MenuItem key={i} onClick={handleCloseA}>
                                                <div className="font-medium text-12 flex items-center  ">
                                                  <div>
                                                    {detail?.replace(/\b\w/g, (char) =>
                                                      char.toUpperCase()
                                                    ) === 'Quick Reviews' && (
                                                      <img
                                                        src="/assets/images/quick-hub/quick-review-small.png"
                                                        alt="Quick Review"
                                                        className="h-24 w-24 bg-white mr-5"
                                                      />
                                                    )}
                                                    {detail?.replace(/\b\w/g, (char) =>
                                                      char?.toUpperCase()
                                                    ) === 'Quick Social' && (
                                                      <img
                                                        src="/assets/images/quick-hub/quick-social-small.png"
                                                        alt="Quick Social"
                                                        className="h-24 w-24 bg-white mr-5"
                                                      />
                                                    )}
                                                    {detail?.replace(/\b\w/g, (char) =>
                                                      char?.toUpperCase()
                                                    ) === 'Quick Chat' && (
                                                      <img
                                                        src="/assets/images/quick-hub/quick-chat-small.png"
                                                        alt="Quick Chat"
                                                        className="h-24 w-24 bg-white mr-5"
                                                      />
                                                    )}
                                                    {detail?.replace(/\b\w/g, (char) =>
                                                      char?.toUpperCase()
                                                    ) === 'Quick Campaigns' && (
                                                      <img
                                                        src="/assets/images/quick-hub/quick-campaign-small.png"
                                                        alt="Quick Campaigns"
                                                        className="h-24 w-24 bg-white mr-5"
                                                      />
                                                    )}
                                                  </div>
                                                  <h4>{detail}</h4>
                                                </div>
                                              </MenuItem>
                                            ))}
                                        </Menu>
                                      </>
                                    )
                                  : (payment?.packages?.name === 'Bundle' ||
                                      payment?.packages?.name === 'Combo') && (
                                      <>
                                        <IconButton
                                          onClick={(event) => handleClickA(event, index)}
                                          className="ml-2 p-0"
                                        >
                                          {openDropdownIndex === index ? (
                                            <ExpandLessIcon />
                                          ) : (
                                            <ExpandMoreIcon />
                                          )}
                                        </IconButton>
                                        <Menu
                                          anchorEl={anchorEl}
                                          open={openDropdownIndex === index}
                                          onClose={handleCloseA}
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                          }}
                                          MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                            sx: {
                                              padding: 0,
                                            },
                                          }}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                '& .MuiList-root': {
                                                  padding: 0,
                                                },
                                              },
                                            },
                                          }}
                                        >
                                          <MenuItem onClick={handleCloseA}>
                                            {t('common.noData')}
                                          </MenuItem>
                                        </Menu>
                                      </>
                                    )}

                                {countryId !== 2
                                  ? (payment?.packages?.planName === 'Bundle' ||
                                      payment?.packages?.planName === 'Combo') && (
                                      <>
                                        <IconButton
                                          onClick={(event) => handleClickA(event, index)}
                                          className="ml-2"
                                        >
                                          {openDropdownIndex === index ? (
                                            <ExpandLessIcon />
                                          ) : (
                                            <ExpandMoreIcon />
                                          )}
                                        </IconButton>
                                        <Menu
                                          anchorEl={anchorEl}
                                          open={openDropdownIndex === index}
                                          onClose={handleCloseA}
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                          }}
                                          MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                            sx: {
                                              padding: 0,
                                            },
                                          }}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                '& .MuiList-root': {
                                                  padding: 0,
                                                },
                                              },
                                            },
                                          }}
                                        >
                                          {payment?.packages?.planName?.replace(/\b\w/g, (char) =>
                                            char.toUpperCase()
                                          ) === 'Combo' &&
                                            payment?.packages?.comboPlanName?.map((detail) => (
                                              <MenuItem
                                                key={index}
                                                onClick={handleCloseA}
                                                className=""
                                              >
                                                <div className="font-medium text-12 flex items-center ">
                                                  {detail?.replace(/\b\w/g, (char) =>
                                                    char?.toUpperCase()
                                                  ) === 'Quick Reviews' && (
                                                    <img
                                                      src="/assets/images/quick-hub/quick-review-small.png"
                                                      alt="Quick Review"
                                                      className="h-20 w-20 bg-white mr-5"
                                                    />
                                                  )}
                                                  {detail?.replace(/\b\w/g, (char) =>
                                                    char?.toUpperCase()
                                                  ) === 'Quick Social' && (
                                                    <img
                                                      src="/assets/images/quick-hub/quick-social-small.png"
                                                      alt="Quick Social"
                                                      className="h-20 w-20 bg-white mr-5"
                                                    />
                                                  )}
                                                  {detail?.replace(/\b\w/g, (char) =>
                                                    char?.toUpperCase()
                                                  ) === 'Quick Chat' && (
                                                    <img
                                                      src="/assets/images/quick-hub/quick-chat-small.png"
                                                      alt="Quick Chat"
                                                      className="h-20 w-20 bg-white mr-5"
                                                    />
                                                  )}
                                                  {detail?.replace(/\b\w/g, (char) =>
                                                    char.toUpperCase()
                                                  ) === 'Quick Campaigns' && (
                                                    <img
                                                      src="/assets/images/quick-hub/quick-campaign-small.png"
                                                      alt="Quick Campaigns"
                                                      className="h-20 w-20 bg-white mr-5"
                                                    />
                                                  )}
                                                  <h4>{detail}</h4>
                                                </div>
                                              </MenuItem>
                                            ))}
                                          {payment?.packages?.planName?.replace(/\b\w/g, (char) =>
                                            char?.toUpperCase()
                                          ) === 'Bundle' &&
                                            payment?.packages?.comboPlanName?.map((detail, i) => (
                                              <MenuItem key={i} onClick={handleCloseA}>
                                                <div className="font-medium text-12 flex items-center  ">
                                                  <div>
                                                    {detail?.replace(/\b\w/g, (char) =>
                                                      char.toUpperCase()
                                                    ) === 'Quick Reviews' && (
                                                      <img
                                                        src="/assets/images/quick-hub/quick-review-small.png"
                                                        alt="Quick Review"
                                                        className="h-24 w-24 bg-white mr-5"
                                                      />
                                                    )}
                                                    {detail?.replace(/\b\w/g, (char) =>
                                                      char?.toUpperCase()
                                                    ) === 'Quick Social' && (
                                                      <img
                                                        src="/assets/images/quick-hub/quick-social-small.png"
                                                        alt="Quick Social"
                                                        className="h-24 w-24 bg-white mr-5"
                                                      />
                                                    )}
                                                    {detail?.replace(/\b\w/g, (char) =>
                                                      char?.toUpperCase()
                                                    ) === 'Quick Chat' && (
                                                      <img
                                                        src="/assets/images/quick-hub/quick-chat-small.png"
                                                        alt="Quick Chat"
                                                        className="h-24 w-24 bg-white mr-5"
                                                      />
                                                    )}
                                                    {detail?.replace(/\b\w/g, (char) =>
                                                      char?.toUpperCase()
                                                    ) === 'Quick Campaigns' && (
                                                      <img
                                                        src="/assets/images/quick-hub/quick-campaign-small.png"
                                                        alt="Quick Campaigns"
                                                        className="h-24 w-24 bg-white mr-5"
                                                      />
                                                    )}
                                                  </div>
                                                  <h4>{detail}</h4>
                                                </div>
                                              </MenuItem>
                                            ))}
                                        </Menu>
                                      </>
                                    )
                                  : (payment?.packages?.name === 'Bundle' ||
                                      payment?.packages?.name === 'Combo') && (
                                      <>
                                        <IconButton
                                          onClick={(event) => handleClickA(event, index)}
                                          className="ml-2 p-0"
                                        >
                                          {openDropdownIndex === index ? (
                                            <ExpandLessIcon />
                                          ) : (
                                            <ExpandMoreIcon />
                                          )}
                                        </IconButton>
                                        <Menu
                                          anchorEl={anchorEl}
                                          open={openDropdownIndex === index}
                                          onClose={handleCloseA}
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                          }}
                                          MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                            sx: {
                                              padding: 0,
                                            },
                                          }}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                '& .MuiList-root': {
                                                  padding: 0,
                                                },
                                              },
                                            },
                                          }}
                                        >
                                          <MenuItem onClick={handleCloseA}>
                                            {t('common.noData')}
                                          </MenuItem>
                                        </Menu>
                                      </>
                                    )}
                              </div>
                            </td>
                            <td className="px-12 py-8 text-14 font-medium ">
                              {countryId === 2
                                ? payment?.startDate
                                  ? formatDateWithTimeZone(payment?.startDate, 'MMM DD, YYYY')
                                  : ''
                                : payment?.cts
                                ? formatDateWithTimeZone(
                                    Number(payment?.cts) / 1000,
                                    'MMM DD, YYYY'
                                  )
                                : ''}
                            </td>
                            <td className="px-12 py-8  text-14 font-medium ">
                              {countryId === 2
                                ? payment?.startDate
                                  ? formatDateWithTimeZone(payment?.startDate, 'MMM DD, YYYY')
                                  : ''
                                : payment?.user_package_subscription?.[0]?.startDate
                                ? formatDateWithTimeZone(
                                    payment?.user_package_subscription[0]?.startDate,
                                    'MMM DD, YYYY'
                                  )
                                : ''}
                            </td>
                            <td className="px-12 py-8  text-14 font-medium ">
                              {countryId === 2
                                ? payment?.expireDate
                                  ? formatDateWithTimeZone(payment?.expireDate, 'MMM DD, YYYY')
                                  : ''
                                : payment?.user_package_subscription?.[0]?.expireDate
                                ? formatDateWithTimeZone(
                                    payment?.user_package_subscription[0]?.expireDate,
                                    'MMM DD, YYYY'
                                  )
                                : ''}
                            </td>
                            <td className="px-12 py-8  text-14 font-medium ">
                              {countryId === 2
                                ? `₹ ${payment?.invoiceAmount || ''}`
                                : `$ ${payment?.paymentIntentsDescTwo?.amount || ''}`}
                            </td>
                            <td className=" px-12 py-8 font-medium text-14 ">
                              {countryId === 2
                                ? !payment?.isOneTimePayment
                                  ? payment?.packageInterval === 'year'
                                    ? 'Yearly'
                                    : 'Monthly'
                                  : 'One Time'
                                : !payment?.isOneTimePayment
                                ? payment?.packages?.packageInterval === 'year'
                                  ? 'Yearly'
                                  : 'Monthly'
                                : 'One Time'}
                            </td>
                            <td className="px-12 py-8  text-14 font-medium">
                              {countryId === 2
                                ? payment?.paymentMethod
                                  ? payment?.paymentMethod?.replace(/\b\w/g, (char) =>
                                      char?.toUpperCase()
                                    )
                                  : ''
                                : payment?.paymentIntentsDescTwo?.payment_method_types?.includes(
                                    'card'
                                  )
                                ? 'Card'
                                : payment?.paymentIntentsDescTwo?.payment_method_types
                                    ?.join(', ')
                                    .replace(/\b\w/g, (char) => char.toUpperCase()) || ''}
                            </td>
                            <td className="px-12 py-8 text-14 font-medium">
                              <p
                                className={` ${
                                  payment?.status === 'activated' ||
                                  payment?.paymentIntentsDescTwo?.status === 'succeeded' ||
                                  payment?.paymentIntentsDescTwo?.status === 'paid' ||
                                  payment?.paymentIntentsDescTwo?.status === 'captured'
                                    ? 'text-darkgreen-500'
                                    : 'text-red'
                                }`}
                              >
                                {countryId === 2
                                  ? payment?.status === 'expired'
                                    ? t('plan.mySubscriptionData.expired')
                                    : payment?.status === 'activated'
                                    ? t('plan.mySubscriptionData.successful')
                                    : t('plan.mySubscriptionData.failed')
                                  : payment?.paymentIntentsDescTwo?.status === 'expired'
                                  ? t('plan.mySubscriptionData.expired')
                                  : payment?.paymentIntentsDescTwo?.status === 'succeeded' ||
                                    payment?.paymentIntentsDescTwo?.status === 'paid' ||
                                    payment?.paymentIntentsDescTwo?.status === 'captured'
                                  ? t('plan.mySubscriptionData.successful')
                                  : t('plan.mySubscriptionData.failed')}
                              </p>
                            </td>
                            <td className="px-12 py-8 text-14 font-medium ">
                              {payment.autopay ? 'Yes' : 'No'}
                            </td>
                            <td className="px-12 py-8 text-14 font-medium ">
                              {!payment?.isOneTimePayment ? (
                                <Button
                                  color="success"
                                  className="font-medium text-14 rounded-sm "
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={() =>
                                    handleDownloadInvoice(
                                      loginUserData?.countryId === 2
                                        ? payment?.invoiceRedirectLink
                                        : payment?.transactionId
                                    )
                                  }
                                >
                                  <p className="bg-darkgreen text-white px-10 py-2 rounded-sm inline-block text-center">
                                    {t('plan.mySubscriptionData.downloadReceipt')}
                                  </p>
                                </Button>
                              ) : (
                                '-'
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {getPaymentHistory && getPaymentHistory.length > 10 && (
                      <Pagination
                        totalCount={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center mt-96 text-center">
                  <div className="flex flex-col items-center max-w-640">
                    <img
                      src="assets/images/icon/noPaymentHistory.png"
                      alt="No Payment History"
                      className="h-224 w-224 mb-16"
                    />
                    <h1 className="md:text-28 text-24 font-bold mb-16">
                      {t('plan.mySubscriptionData.noPaymentYet')}
                    </h1>
                    <p className="md:text-18 text-16 font-500 mb-16 text-gray-700">
                      {t('plan.mySubscriptionData.noPaymentYetContent')}
                    </p>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      className="mb-16 rounded-md text-16 font-500"
                      onClick={() => history.push('/offer-pricing')}
                    >
                      {t('plan.mySubscriptionData.exploreSubscriptionPlans')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MySubscription;
