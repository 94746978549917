import { Button, Icon, Toolbar, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React, { useCallback, useRef, useState } from 'react';
import history from '@history';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';

const AddPhotoAndVideo = () => {
  const location = useLocation();
  const fileInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedImages, setSelectedImages] = useState([]);
  const activeTabLabel = location?.state?.activeTab?.label;

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = useCallback(
    (event) => {
      try {
        const file = event.target.files[0];
        if (file) {
          const fileKey = `isValidFile_${file.name}_${file.size}`;
          let isValidFile = sessionStorage.getItem(fileKey);

          if (isValidFile === null) {
            isValidFile =
              (file.type.startsWith('image/') && file.type !== 'image/webp') ||
              (file.type.startsWith('video/') && file.type !== 'video/webp');
            sessionStorage.setItem(fileKey, isValidFile);
          } else {
            isValidFile = JSON.parse(isValidFile);
          }
          if (isValidFile) {
            const isDuplicate = selectedImages?.some(
              (image) => image?.file?.name === file?.name && image?.file?.size === file?.size
            );

            if (!isDuplicate) {
              const fileWithPreview = {
                file,
                preview: URL.createObjectURL(file),
              };
              setSelectedImages((prevImages) =>
                prevImages?.length < 1 ? [fileWithPreview] : [...prevImages, fileWithPreview]
              );
            }
          } else {
            enqueueSnackbar(
              `Only valid ${activeTabLabel === 'Videos' ? 'Video' : 'Image'} files are allowed`,
              {
                variant: 'error',
                autoHideDuration: 2000,
              }
            );
          }
          setTimeout(() => {
            sessionStorage.removeItem(fileKey);
          }, 100);
        }
      } catch (error) {
        console.error('Error handling file change:', error);
      }
    },
    [activeTabLabel, selectedImages, enqueueSnackbar]
  );
  const handleFileDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);

    const errorMessageKey = `errorMessageShownFor${activeTabLabel}`;

    if (!sessionStorage.getItem(errorMessageKey)) {
      sessionStorage.removeItem(errorMessageKey);
    }

    files.forEach((file) => {
      const errorMessageAlreadyShown = sessionStorage.getItem(errorMessageKey);
      if (
        (activeTabLabel === 'Videos' &&
          file.type.startsWith('video/') &&
          file.type !== 'video/webp') ||
        ((activeTabLabel === 'Images' ||
          activeTabLabel === 'Profile Photo' ||
          activeTabLabel === 'Cover Photo') &&
          file.type.startsWith('image/') &&
          file.type !== 'image/webp')
      ) {
        const isDuplicate = selectedImages?.some(
          (image) => image?.file?.name === file?.name && image?.file?.size === file?.size
        );

        if (!isDuplicate) {
          const fileWithPreview = {
            file,
            preview: URL.createObjectURL(file),
          };

          const sessionKey = `file_${file.name}_${file.size}`;
          if (!sessionStorage.getItem(sessionKey)) {
            sessionStorage.setItem(sessionKey, 'added');
            setSelectedImages((prevImages) => [...prevImages, fileWithPreview]);
          }
          setTimeout(() => {
            sessionStorage.removeItem(sessionKey, 'added');
          }, 100);
        }
      } else if (!errorMessageAlreadyShown) {
        enqueueSnackbar(`Only valid ${activeTabLabel === 'Videos' ? 'Video' : 'Image'} files`, {
          variant: 'error',
          autoHideDuration: 2000,
        });
        sessionStorage.setItem(errorMessageKey, 'true');
      }
    });

    setTimeout(() => {
      sessionStorage.removeItem(errorMessageKey);
    }, 100);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const selectedSection = [
    {
      id: 1,
      label: t('primaryDetails.images'),
      title: t('primaryDetails.addImages'),
      title2: t('primaryDetails.addMoreImages'),
      desc1: t('primaryDetails.dragDropImage'),
    },
    {
      id: 2,
      label: t('primaryDetails.videos'),
      title: t('primaryDetails.addVideo'),
      title2: t('primaryDetails.addMoreVideo'),
      desc1: t('primaryDetails.dragDropVideo'),
    },
    {
      id: 3,
      label: t('primaryDetails.profilePhotos'),
      title: t('primaryDetails.addProfilePhoto'),
      desc1: t('primaryDetails.dragDropImage'),
    },
    {
      id: 4,
      label: t('primaryDetails.coverPhoto'),
      title: t('primaryDetails.coverPhoto'),
      desc1: t('primaryDetails.dragDropImage'),
    },
  ];

  const selectedTabData = selectedSection?.find((tab) => tab?.label === activeTabLabel);

  const handleRemoveImage = (index) => {
    const newImages = [...selectedImages];
    newImages.splice(index, 1);
    setSelectedImages(newImages);
  };

  return (
    <div className="bg-gray-A500 h-full p-16 sm:p-24">
      <Toolbar className="px-0 font-semibold min-h-fit mb-20">
        <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
          west
        </Icon>
        <Typography variant="subtitle1" color="inherit" className="flex-1 px-12 font-bold text-18">
          {selectedTabData?.title}
        </Typography>
      </Toolbar>
      <div
        className={`flex flex-col ${
          selectedImages?.length < 1
            ? 'max-w-[800px]'
            : 'min-w-auto md:min-w-[800px] w-fit max-w-[1435px]'
        }`}
      >
        <div className="bg-white min-w-auto md:min-w-[800px] rounded-md">
          <div className="p-20 rounded-6">
            <Typography className="text-16 mb-16">{t('primaryDetails.selectMedia')}</Typography>
            <div
              className="flex gap-10 flex-wrap"
              onDragOver={handleDragOver}
              onDrop={handleFileDrop}
            >
              {selectedImages?.map((image, index) => (
                <div
                  key={index}
                  className="relative w-full sm:w-[224px] h-192 sm:h-120 bg-gray-200 flex items-center justify-center rounded-md overflow-hidden"
                >
                  {activeTabLabel === 'Videos' ? (
                    <video
                      src={image?.preview}
                      className="w-full h-full object-cover"
                      controls={false}
                      muted
                      playsInline
                    >
                      {t('primaryDetails.notSupportd')}
                    </video>
                  ) : (
                    <img
                      src={image?.preview}
                      alt={`Selected ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                  )}
                  <CloseRoundedIcon
                    onClick={() => handleRemoveImage(index)}
                    className="text-18 absolute top-3 right-3 bg-white rounded-full text-black font-normal cursor-pointer hover:text-gray-700 focus:outline-none"
                  />
                </div>
              ))}
              {selectedImages?.length < 1 ? (
                <>
                  <div
                    className="flex flex-wrap flex-col bg-indigo-50 w-full max-w-480 items-center p-20 bg-opacity-50 rounded-6 mt-12 justify-center gap-6"
                    onDragOver={handleDragOver}
                    onDrop={handleFileDrop}
                  >
                    <div className="bg-gray-300 w-80 h-80 rounded-full flex justify-center items-center bg-opacity-50">
                      {activeTabLabel === 'Videos' ? (
                        <PlayArrowOutlinedIcon className="w-56 h-56 border-2 border-black rounded-full p-4" />
                      ) : (
                        <BrokenImageOutlinedIcon className="w-56 h-56 border-2 border-black rounded-full p-4" />
                      )}
                    </div>
                    <Typography>{selectedTabData?.desc1}</Typography>
                    <Typography>or</Typography>
                    <Button
                      className="rounded-md w-128 font-semibold"
                      variant="contained"
                      color="secondary"
                      onClick={handleButtonClick}
                    >
                      {t('primaryDetails.browseFile')}
                    </Button>
                  </div>
                </>
              ) : (
                (activeTabLabel === 'Images' || activeTabLabel === 'Videos') && (
                  <div
                    className="flex flex-col justify-center items-center rounded-md w-full sm:w-[224px] h-192 sm:h-120 gap-8 bg-opacity-50 bg-indigo-50"
                    onDragOver={handleDragOver}
                    onDrop={handleFileDrop}
                  >
                    <Button
                      className="rounded-full !min-w-60 !max-w-60 !min-h-60 !max-h-60 bg-gray-300 bg-opacity-80 flex items-center justify-center border-0"
                      onClick={handleButtonClick}
                      variant="outlined"
                    >
                      <span className="text-52">+</span>
                    </Button>
                    <Typography className="text-16">{selectedTabData?.title2}</Typography>
                  </div>
                )
              )}
            </div>
            <input
              type="file"
              accept={activeTabLabel === 'Videos' ? 'video/*' : 'image/*'}
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </div>
          <hr className="border-solid !border-gray-400 border-1" />
          <div className="p-20 flex items-center flex-col sm:flex-row justify-between">
            <Typography className="text-18 font-500">{t('reports.selectBusiness')}</Typography>
            <div className="flex items-center sm:w-auto w-full sm:justify-end justify-between gap-10">
              <Typography>Selected Business (20)</Typography>
              <Button
                className="rounded-md pl-28 pr-28 !h-32 min-h-32 max-h-32 font-semibold"
                variant="outlined"
                color="secondary"
              >
                Edit
              </Button>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-20 w-full max-w-[1435px]">
          <Button className="rounded-md w-128 font-semibold" variant="contained" color="secondary">
            {t('common.save')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddPhotoAndVideo;
