import { Tab, Tabs, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import Businesses from '../businesses/Businesses';

const PrimaryDetails = () => {
  const tabData = [
    {
      id: 1,
      label: t('primaryDetails.workingHours'),
      buttonName: t('primaryDetails.setWorkingHour'),
      url: 'quick-hub/openingAndClosingHour',
    },
    {
      id: 2,
      label: t('primaryDetails.services'),
      buttonName: t('primaryDetails.addServices'),
      url: 'quick-hub/services',
    },
    {
      id: 3,
      label: t('primaryDetails.attributes'),
      buttonName: t('primaryDetails.addAttributes'),
      url: 'quick-hub/attributes',
    },
    {
      id: 4,
      label: t('primaryDetails.menu'),
      buttonName: t('primaryDetails.addMenu'),
      url: 'quick-hub/menu',
    },
    {
      id: 5,
      label: t('primaryDetails.images'),
      buttonName: t('primaryDetails.addImage'),
      url: 'quick-hub/addImagesAndVideos',
    },
    {
      id: 6,
      label: t('primaryDetails.videos'),
      buttonName: t('primaryDetails.addVideos'),
      url: 'quick-hub/addImagesAndVideos',
    },
    {
      id: 7,
      label: t('primaryDetails.profilePhotos'),
      buttonName: t('primaryDetails.addProfilePhoto'),
      url: 'quick-hub/addImagesAndVideos',
    },
    {
      id: 8,
      label: t('primaryDetails.coverPhoto'),
      buttonName: t('primaryDetails.addCoverPhoto'),
      url: 'quick-hub/addImagesAndVideos',
    },
  ];
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div className="bg-gray-A500 min-h-screen p-16 sm:p-24">
      <Typography className="text-20 sm:text-28 font-bold">
        {t('navigation.primaryDetails')}
      </Typography>
      <div>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          // textColor="quickReview"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          {tabData?.map((tab, index) => {
            return (
              <Tab
                className={`sm:text-20 font-medium p-0 sm:mr-36 mr-16 ${
                  Number(activeTab) === Number(tab?.id) ? '!text-quick-hub' : 'text-black'
                }`}
                value={tab?.id}
                label={tab?.label}
                key={index}
              />
            );
          })}
        </Tabs>
        <hr className="border-1 border-solid !border-gray" />
      </div>
      <Businesses primaryDetailsScreen activeTab={tabData?.find((tab) => tab?.id === activeTab)} />
    </div>
  );
};

export default PrimaryDetails;
